/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  Alert,
  Card,
  Divider,
  Icon,
  message,
  Popconfirm,
  Spin,
  Tooltip,
  Button,
} from 'antd'
import { StyleSheet, css } from 'aphrodite'
import { hexToHSL } from '../../../utils/helpers'
import MasonryGrid from '../MasonryGrid'
import { ErrorModal } from '../ErrorModal'

import '../../../stylesheets/dashboard.css'
import MaterialList from './MaterialList'

const styles = StyleSheet.create({
  cardAction: {
    background: '#fafafa',
    cursor: 'pointer',
    width: '33.3%',
    float: 'left',
    textAlign: 'center',
    color: 'rgba(0,0,0,.45)',
    transition: 'all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1)',
  },
  cardActionLeft: {
    ':hover': {
      color: '#f5222d',
    },
  },
  cardActionMiddle: {
    borderRight: '1px solid #e8e8e8',
    ':hover': {
      color: '#1890ff',
    },
  },
  cardActionRight: {
    ':hover': {
      color: '#1890ff',
    },
  },
  cardActionsContainer: {
    borderTop: '1px solid #e8e8e8',
    listStyle: 'none',
    margin: 0,
    padding: 0,
  },
  cardBody: {
    borderTop: '1px solid #e8e8e8',
    minHeight: '200px',
    // height: '200px', // this would make the card scrollable and keep all the cards the same size
    overflowY: 'auto',
    padding: '12px 6px 18px 12px',
  },
  cardContainer: {
    minHeight: '500px',
    height: 'calc(100vh - 182px)',
    padding: '16px 12px 12px 12px',
  },
  cardMaterialText: {
    fontSize: '13px',
    fontWeight: '350',
    height: 40,
    overflow: 'hidden',
  },
  cardMaterialSpan: {
    color: '#009688',
    whiteSpace: 'pre-wrap',
  },
  cardIcon: {},
  cardNotes: {
    whiteSpace: 'pre-wrap',
  },
  cardSubtext: {
    fontSize: '13px',
    fontWeight: '350',
    color: '#676464',
  },
  cardTitle: {
    whiteSpace: 'pre-wrap',
  },
  dividerBottom: {
    background: '#a9a9a9',
    margin: 0,
  },
  dividerTop: {
    background: '#a9a9a9',
    marginBottom: 0,
    marginTop: '12px',
  },
  noSelections: {
    color: 'rgba(0, 0, 0, 0.25)',
    fontWeight: '500',
    height: '118px',
    lineHeight: '118px',
    textAlign: 'center',
  },
  label: {
    color: 'rgba(0, 0, 0, 0.85)',
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '35px',
  },
  selection: {
    border: '0.5px solid #e8e8e8',
    borderRadius: '50%',
    float: 'left',
    height: '37px',
    width: '37px',
    margin: '5px 0px 0px 5px',
  },
  selectionSwatch2: {
    borderRadius: '50%',
    maxWidth: '100%',
    width: 'auto',
  },
  spin: {
    margin: '100px 0',
    width: '100%',
  },
  cardButton: {
    width: '100%',
  },
})

class PaletteCards extends Component {
  static propTypes = {
    currentPaletteId: PropTypes.number,
    deletePalette: PropTypes.func.isRequired,
    palettes: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        materials: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.number.isRequired,
          })
        ).isRequired,
        palette_selections: PropTypes.arrayOf(
          PropTypes.shape({
            name: PropTypes.string,
            hex: PropTypes.string,
            identifier: PropTypes.string,
          })
        ),
      })
    ).isRequired,
    palettesLoading: PropTypes.bool,
    selection: PropTypes.object.isRequired,
    filters: PropTypes.object,
    setStateHandler: PropTypes.func.isRequired,
  }

  static defaultProps = {
    palettesLoading: false,
    currentPaletteId: null,
    filters: {
      nameSearchFilter: null,
    },
  }

  constructor(props) {
    super(props)

    this.masonryGridRef = React.createRef(null)
  }

  filterPalettes = (palettesArray) =>
    palettesArray.filter((palette) => {
      if (
        this.props.filters.materialFilter &&
        !palette.materials.some(
          (material) => this.props.filters.materialFilter === material.id
        )
      ) {
        return false
      }

      if (
        this.props.filters.nameSearchFilter !== null &&
        !palette.name
          .toLowerCase()
          .includes(this.props.filters.nameSearchFilter.toLowerCase())
      ) {
        return false
      }
      return true
    })

  handleEditClick = (palette) => {
    if (this.props.selection.currentPaletteId !== palette.id) {
      this.props.selection.setCurrentPaletteId(palette.id)
      this.props.setStateHandler('editPaletteColorSelections', true)
    }
  }

  handleEditMaterialsClick = (palette) => {
    if (this.props.selection.currentPaletteId !== palette.id) {
      this.props.selection.setCurrentPaletteId(palette.id)
      this.props.setStateHandler('editPaletteMaterialSelections', true)
    }
  }

  handlePaletteDelete = (palette) => {
    // eslint-disable-next-line camelcase
    const palette_id = palette.id
    this.props
      .deletePalette({ palette_id })
      .then(() => {
        message.success(`Deleted palette "${palette.name}"`)
        this.props.selection.setCurrentPaletteId(null)
      })
      .catch(ErrorModal)
  }

  generatePaletteCards = () => {
    if (this.props.palettes.length > 0) {
      const filteredPalettesArray = this.filterPalettes(this.props.palettes)
      return (
        <MasonryGrid
          ref={this.masonryGridRef}
          cellDimensions={{
            width: 330,
            minHeight: 330,
          }}
          dataSource={filteredPalettesArray}
          loading={this.props.palettesLoading}
          renderCell={(palette) => (
            <Card
              className={css(styles.card)}
              bodyStyle={{ padding: '0px' }}
              title={
                <React.Fragment>
                  <div className={css(styles.cardTitle)}>{palette.name}</div>
                  <React.Fragment>
                    {palette.materials && (
                      <MaterialList
                        materials={palette.materials}
                        masonryGridRef={this.masonryGridRef}
                      />
                    )}
                    <div className={css(styles.cardMaterialText)}>
                      Colors:
                      <span className={css(styles.cardMaterialSpan)}>
                        {palette.palette_selections &&
                        palette.palette_selections.length
                          ? ` ${palette.palette_selections.length}`
                          : ' 0'}
                      </span>
                    </div>
                  </React.Fragment>
                </React.Fragment>
              }
              key={palette.id}
            >
              <div className={css(styles.cardBody)}>
                {this.generatePaletteSelections(palette)}
              </div>
              <ul className={css(styles.cardActionsContainer)}>
                <Popconfirm
                  placement="bottomRight"
                  title={
                    <p>
                      Delete palette
                      <b>{` ${palette.name}`}</b>?
                    </p>
                  }
                  onConfirm={() => this.handlePaletteDelete(palette)}
                  okText="Yes"
                  okType="danger"
                  cancelText="No"
                >
                  <li className={css(styles.cardAction, styles.cardActionLeft)}>
                    <Button
                      className={css(styles.cardButton)}
                      data-testid="delete-button"
                    >
                      <Icon className={css(styles.cardIcon)} type="delete" />
                    </Button>
                  </li>
                </Popconfirm>
                <li className={css(styles.cardAction, styles.cardActionRight)}>
                  <Button
                    className={css(styles.cardButton)}
                    data-testid="colors-button"
                    onClick={() => this.handleEditClick(palette)}
                  >
                    Colors
                  </Button>
                </li>
                <li className={css(styles.cardAction, styles.cardActionRight)}>
                  <Button
                    className={css(styles.cardButton)}
                    data-testid="materials-button"
                    onClick={() => this.handleEditMaterialsClick(palette)}
                  >
                    Materials
                  </Button>
                </li>
              </ul>
            </Card>
          )}
        />
      )
    }
    if (this.props.palettesLoading) {
      return <Spin className={css(styles.spin)} tip="Loading..." />
    }

    return (
      <Alert
        message="No palettes have been added"
        description={'Add a palette by clicking the "Add Palette" button'}
        type="info"
        showIcon
      />
    )
  }

  generatePaletteSelections = (palette) => {
    if (palette.palette_selections.length === 0) {
      return (
        <div className={css(styles.noSelections)}>No Palette Selections</div>
      )
    }
    // Limit the number of colors shown on a card. If the user wants to see the
    // full list, they can edit the card.
    const selectionsArrayCopy = palette.palette_selections.slice(0, 28)
    if (selectionsArrayCopy[0].blendMode === 'MULTIPLY') {
      selectionsArrayCopy.sort((a, b) => {
        const hslA = hexToHSL(a.hex)
        const hslB = hexToHSL(b.hex)
        if (hslB[2] === hslA[2]) {
          if (hslB[0] === hslA[0]) {
            return hslB[1] - hslA[1]
          }
          return hslB[0] - hslA[0]
        }
        return hslB[2] - hslA[2]
      })
    }
    return selectionsArrayCopy.map((selection) => (
      <Tooltip
        title={
          <React.Fragment>
            <div>{selection.name}</div>
            {selection.identifier && <div>{`ID: ${selection.identifier}`}</div>}
          </React.Fragment>
        }
        key={selection.id}
      >
        <div
          className={css(styles.selection)}
          key={selection.id}
          style={{
            backgroundColor: `#${selection.hex}`,
          }}
        />
      </Tooltip>
    ))
  }

  render = () => (
    <React.Fragment>
      <Divider className={css(styles.dividerTop)} />
      <div className={css(styles.cardContainer)}>
        {this.props.palettes && this.generatePaletteCards()}
      </div>
      <Divider className={css(styles.dividerBottom)} />
    </React.Fragment>
  )
}

export { PaletteCards }
