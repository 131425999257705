export default function getLayerCloudinaryPath(
  cloudFolderName,
  fileName,
  roomId,
  settings = 'w_150,h_150,c_fill'
) {
  if (!cloudFolderName || !fileName || !roomId)
    throw Error(
      'Method getLayerCloudinaryPath() must receive cloudFolderName and fileName and roomId'
    )

  return `https://res.cloudinary.com/renderinghouse/image/upload/${settings}/app/${cloudFolderName}/rooms/${roomId}/layers/${fileName}`
}
