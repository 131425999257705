import React, { useMemo, useState } from 'react'
import { Button } from 'antd'
import { StyleSheet, css } from 'aphrodite/no-important'
import { useQuery } from '@apollo/client'
import PropTypes from 'prop-types'
import { GET_PRODUCT_LINES } from '../../../graphql/fcPruductLines.graphql'
import Alert from '../../common/Alert'
import Table, { TABLE_ITEMS_PER_PAGE } from '../../common/Table'
import ProductLineTableActions from './ProductLineTableActions'
import { Filter, Input } from '../../common/Filter'

const classes = StyleSheet.create({
  img: {
    maxWidth: 100,
    maxHeight: 100,
  },
  tooltipImage: {
    maxWidth: 150,
    minWidth: 150,
    minHeight: 150,
  },
  tooltip: {
    maxWidth: 550,
  },
  alert: {
    margin: 9,
  },
  tableActions: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: 8,
  },
})

const columns = (handleEditProductLine) => [
  {
    title: 'Product Name',
    sorter: true,
    defaultSortOrder: 'ascend',
    dataIndex: 'product_name',
    key: 'product_name',
    width: 120,
  },
  {
    title: 'Product Group',
    sorter: true,
    defaultSortOrder: 'ascend',
    dataIndex: 'product_group',
    key: 'product_group',
  },
  {
    title: 'Product Identifier Prefix',
    sorter: true,
    defaultSortOrder: 'ascend',
    dataIndex: 'product_identifier_prefix',
    key: 'product_identifier_prefix',
  },
  {
    title: 'Actions',
    dataIndex: 'actions',
    key: 'actions',
    render(_, productLine) {
      return (
        <ProductLineTableActions
          productLine={productLine}
          handleEditProductLine={handleEditProductLine}
        />
      )
    },
    width: 200,
  },
]

function ProductLineTable({
  handleEditProductLine,
  setIsModalVisible,
  currentPage,
  setCurrentPage,
}) {
  const [filterProductName, setFilterProductName] = useState('')
  const [tableOrder, setTableOrder] = useState([
    {
      name: 'product_name',
      order: 'ASC',
    },
  ])
  // const { accountId, subAccountId, ...filterFields } = filters
  const { loading, data, error } = useQuery(GET_PRODUCT_LINES, {
    variables: {
      pagination: {
        size: TABLE_ITEMS_PER_PAGE,
        page: currentPage,
      },
      ordering: {
        columns: tableOrder,
      },
      filter: {
        productName: filterProductName,
      },
    },
    fetchPolicy: 'cache-and-network',
  })

  const swatchesAssignmentsResultInfos = useMemo(
    () =>
      data?.swatchesAssignmentsResultInfos.items?.map((swatch) => ({
        ...swatch,
        key: swatch.id,
      })),
    [data?.swatchesAssignmentsResultInfos.items]
  )

  const filter = {
    value: filterProductName,
    set: (name) => setFilterProductName(name),
  }

  return (
    <>
      {error && <Alert margin />}
      <>
        <div className={css(classes.tableActions)}>
          <Filter loading={false} data={filter}>
            <Input
              field="productName"
              label="Product Name"
              placeholder="Search by product name ..."
            />
          </Filter>
          <Button
            type="primary"
            icon="plus"
            onClick={() => setIsModalVisible(true)}
          >
            Add Product Lines
          </Button>
        </div>
        {data?.swatchesAssignmentsResultInfos && (
          <Table
            loading={loading}
            columns={columns(handleEditProductLine)}
            data={swatchesAssignmentsResultInfos}
            emptyText="There are currently no active product lines to display"
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            totalCount={data?.swatchesAssignmentsResultInfos.totalCount}
            setTableOrder={setTableOrder}
            itemsPerPage={TABLE_ITEMS_PER_PAGE}
          />
        )}
      </>
    </>
  )
}

export default React.memo(ProductLineTable)

ProductLineTable.propTypes = {
  handleEditProductLine: PropTypes.func.isRequired,
  setIsModalVisible: PropTypes.func.isRequired,
  currentPage: PropTypes.number.isRequired,
  setCurrentPage: PropTypes.func.isRequired,
}

ProductLineTable.defaultProps = {}
