import { Select, Spin, Alert } from 'antd'
import { ApolloConsumer } from '@apollo/client'
import { compose, withState, lifecycle, withHandlers } from 'recompose'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import {
  withConsumer,
  withMutationAsProp,
  withQueryResultAsProp,
} from '../../../utils'
import {
  GET_COLOR_COLLECTIONS,
  GET_COLORS_BY_COLLECTION,
  ADD_COLOR,
} from '../../../graphql/colors.graphql'
import { ColorsForm } from './ColorsForm'
import { CreateColorForm } from './CreateColorForm'
import { SelectionConsumer } from '../../../contexts'

const { Option } = Select

class ColorLibraryForm extends Component {
  sortByIdentifierCode = (data) => {
    return [...data].sort((a, b) => {
      // Treat null like it is higher than anything else.
      if (!a.identifier && !b.identifier) {
        return 0
      }
      if (!a.identifier) {
        return 1
      }
      if (!b.identifier) {
        return -1
      }

      return a.identifier.localeCompare(b.identifier)
    })
  }

  handleCollectionSelectChange = async (value, key) => {
    const colorVendorProductInfoId = value
    const {
      selection,
      apolloClient,
      setIsSearching,
      setCollectionPrefix,
      setShowColorAddForm,
      setColorsArray,
    } = this.props

    const { setCurrentCollectionId } = selection

    if (colorVendorProductInfoId === 'null') {
      setCurrentCollectionId(null)
      setColorsArray(null)
    } else {
      setCollectionPrefix(key.props.prefix)
      setIsSearching(true)
      setCurrentCollectionId(colorVendorProductInfoId)
      const { data } = await apolloClient.query({
        query: GET_COLORS_BY_COLLECTION,
        variables: { color_vendor_product_info_id: colorVendorProductInfoId },
        fetchPolicy: 'network-only',
      })

      const sortedColorsArray = this.sortByIdentifierCode(
        data.colorsByCollectionId
      )

      setColorsArray(sortedColorsArray)
      setIsSearching(false)
      setShowColorAddForm(true)
    }
  }

  generateCollectionOptions = (collectionItem) =>
    collectionItem.map(this.generateCollectionList)

  generateCollectionList = (colorCollection) => (
    <Option
      value={colorCollection.id}
      key={`${colorCollection.product_name}:${colorCollection.id}`}
      prefix={colorCollection.product_identifier_prefix}
    >
      {colorCollection.product_name}
    </Option>
  )

  render = () => {
    const {
      colorCollections,
      isSearching,
      collectionPrefix,
      showColorAddForm,
      selection,
      colorsArray,
      addColorToColorsArray,
      updateColorsArray,
      removeColorFromColorsArray,
    } = this.props
    return (
      <React.Fragment>
        <Select
          style={{ width: '350px', margin: '0px 0px 15px 20px' }}
          defaultActiveFirstOption={false}
          onChange={this.handleCollectionSelectChange}
          placeholder="Select collection"
          value={
            selection.currentCollectionId
              ? selection.currentCollectionId
              : 'null'
          }
        >
          <Option key="null" value="null">
            Select A Collection
          </Option>
          {colorCollections.length > 0
            ? this.generateCollectionOptions(colorCollections)
            : null}
        </Select>
        {colorsArray &&
          showColorAddForm === true &&
          selection.currentCollectionId !== null && (
            <CreateColorForm
              collectionPrefix={collectionPrefix}
              colorLibraries={colorsArray}
              addColorToColorsArray={addColorToColorsArray}
            />
          )}

        {colorsArray &&
          isSearching === false &&
          selection.currentCollectionId !== null && (
            <ColorsForm
              colorLibraries={colorsArray}
              collectionPrefix={collectionPrefix}
              updateColorsArray={updateColorsArray}
              removeColorFromColorsArray={removeColorFromColorsArray}
            />
          )}

        {isSearching === true && (
          <Spin tip="Loading...">
            <Alert
              message="Searching Color Library"
              description="Your collection will load momentarily"
              type="info"
            />
          </Spin>
        )}
      </React.Fragment>
    )
  }
}

ColorLibraryForm.propTypes = {
  isSearching: PropTypes.bool.isRequired,
  showColorAddForm: PropTypes.bool.isRequired,
  colorCollections: PropTypes.arrayOf(PropTypes.object).isRequired,
  colorsArray: PropTypes.arrayOf(PropTypes.object).isRequired,
  collectionPrefix: PropTypes.string,
  selection: PropTypes.shape({
    currentAccountId: PropTypes.number,
    currentCollectionId: PropTypes.number,
    setCurrentAccountId: PropTypes.func,
    setCurrentPaletteId: PropTypes.func,
    setCurrentCollectionId: PropTypes.func,
  }).isRequired,
  addColorToColorsArray: PropTypes.func.isRequired,
  updateColorsArray: PropTypes.func.isRequired,
  removeColorFromColorsArray: PropTypes.func.isRequired,
  apolloClient: PropTypes.object.isRequired,
  setIsSearching: PropTypes.func.isRequired,
  setCollectionPrefix: PropTypes.func.isRequired,
  setShowColorAddForm: PropTypes.func.isRequired,
  setColorsArray: PropTypes.func.isRequired,
}

ColorLibraryForm.defaultProps = {
  collectionPrefix: null,
}

const WrappedComponent = compose(
  withConsumer(SelectionConsumer, { propName: 'selection' }),
  withConsumer(ApolloConsumer, { propName: 'apolloClient' }),
  withState('isSearching', 'setIsSearching', false),
  withState('collectionPrefix', 'setCollectionPrefix', null),
  withState('showColorAddForm', 'setShowColorAddForm', false),
  withQueryResultAsProp({
    gqlDocument: GET_COLOR_COLLECTIONS,
    propName: 'colorCollections',
    resultPropName: 'colorVendorProductInfos',
  }),
  withMutationAsProp({
    gqlDocument: ADD_COLOR,
    mutationPropName: 'addColorLibrary',
  }),
  lifecycle({
    componentDidMount() {
      this.props.selection.setCurrentCollectionId(null)
    },
    componentWillUnmount() {
      this.props.selection.setCurrentCollectionId(null)
    },
  }),
  withState('colorsArray', 'setColorsArray', []),
  withHandlers({
    // eslint-disable-next-line max-len
    addColorToColorsArray:
      ({ setColorsArray }) =>
      (newColor) =>
        setColorsArray((colors) => [newColor, ...colors]),
    updateColorsArray:
      ({ setColorsArray }) =>
      (updatedColor) =>
        setColorsArray((colors) => {
          const indexToUpdate = colors.findIndex(
            (color) => color.id === updatedColor.id
          )
          colors.splice(indexToUpdate, 1, updatedColor)
          return colors
        }),
    removeColorFromColorsArray:
      ({ setColorsArray }) =>
      (removedColor) =>
        setColorsArray((colors) => {
          const indexToUpdate = colors.findIndex(
            (color) => color.id === removedColor.id
          )
          colors.splice(indexToUpdate, 1)
          return colors
        }),
  })
)(ColorLibraryForm)

export { WrappedComponent as ColorLibraryForm }
