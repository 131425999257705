import { Button, Form, Input } from 'antd'
import { css, StyleSheet } from 'aphrodite'
import { ApolloConsumer } from '@apollo/client'
import { compose } from 'recompose'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import {
  generateAddFormItemOptionalInput,
  generateAddFormItemRequiredInput,
  withConsumer,
  withMutationAsProp,
} from '../../../utils'
import { ADD_COLOR } from '../../../graphql/colors.graphql'
import { SelectionConsumer } from '../../../contexts'

const styles = StyleSheet.create({
  addPropertyForm: {
    border: 'solid 1px #ccc',
    borderRadius: '10px',
    padding: '0px 10px 0px 10px',
    margin: 'auto',
    marginLeft: '10px',
    marginRight: '10px',
  },
  updatePropertyForm: {
    padding: '0px 10px 0px 10px',
    margin: 'auto',
  },
  tableStyle: {
    border: '1px solid #d8dee2',
    borderRadius: '10px',
    padding: '0px 10px 0px 10px',
    marginLeft: '10px',
    marginRight: '10px',
  },
})

const { Item } = Form
const FormItem = Form.Item

class CreateColorForm extends Component {
  handleAddColorButtonPress = async () => {
    const { addColorLibrary, form, selection, addColorToColorsArray } =
      this.props
    const { currentCollectionId } = selection

    form.validateFieldsAndScroll(
      async (errors, { name, identifier, hex, notes }) => {
        if (!errors) {
          console.log('Using form values: ', { name, identifier, hex, notes })
          form.resetFields()
          const colorResult = await addColorLibrary({
            input: {
              name,
              vendor_id: 4,
              color_vendor_product_info_id: parseFloat(currentCollectionId),
              identifier,
              hex,
              notes,
            },
          })
          if (colorResult && colorResult.data) {
            addColorToColorsArray(colorResult.data.addColorLibrary)
          }
        }
      }
    )
  }

  render = () => {
    const { form, collectionPrefix } = this.props
    const { getFieldDecorator } = form
    return (
      <React.Fragment>
        <Form
          key="addMaterialForm"
          layout="inline"
          className={css(styles.addPropertyForm)}
        >
          {generateAddFormItemRequiredInput(
            getFieldDecorator,
            'name',
            '* Color Name'
          )}
          <FormItem
            label={
              <span className={css(styles.label)}>
                {collectionPrefix !== null ? `${collectionPrefix}` : null}
              </span>
            }
            colon={false}
            className={css(styles.formItem)}
          >
            {getFieldDecorator('identifier', {
              validateTrigger: null,
            })(
              <Input
                placeholder="Identifier Code"
                onChange={
                  // Sanitize input troublesome for URLS: https://secure.n-able.com/webhelp/NC_9-1-0_SO_en/Content/SA_docs/API_Level_Integration/API_Integration_URLEncoding.html
                  (e) => {
                    e.target.value = e.target.value.replace(
                      /[$&+,/:;=?@"'<>#%]/,
                      ''
                    )
                  }
                }
              />
            )}
          </FormItem>
          {generateAddFormItemOptionalInput(
            getFieldDecorator,
            'hex',
            'Hex Code'
          )}
          {generateAddFormItemOptionalInput(
            getFieldDecorator,
            'notes',
            'Color notes'
          )}
          <Item>
            <Button type="primary" onClick={this.handleAddColorButtonPress}>
              Add Color
            </Button>
          </Item>
        </Form>
      </React.Fragment>
    )
  }
}

CreateColorForm.propTypes = {
  form: PropTypes.object.isRequired,
  collectionPrefix: PropTypes.string.isRequired,
  addColorLibrary: PropTypes.func.isRequired,
  selection: PropTypes.shape({
    currentCollectionId: PropTypes.string,
  }).isRequired,
  addColorToColorsArray: PropTypes.func.isRequired,
}

const WrappedComponent = compose(
  withConsumer(SelectionConsumer, { propName: 'selection' }),
  withConsumer(ApolloConsumer, { propName: 'apolloClient' }),
  withMutationAsProp({
    gqlDocument: ADD_COLOR,
    mutationPropName: 'addColorLibrary',
  })
)(Form.create()(CreateColorForm))

export { WrappedComponent as CreateColorForm }
