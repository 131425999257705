import React from 'react'
import PropTypes from 'prop-types'
import { Filter } from '../../common/Filter/Filter'
import { Input } from '../../common/Filter/Input'

export const SchemeFilter = ({ filter, setFilter, children }) => {
  const filterData = {
    value: filter.schemeName,
    set: (schemeName) => setFilter({ ...filter, schemeName }),
  }

  return (
    <Filter loading={false} data={filterData}>
      <Input
        field="schemeName"
        label="Scheme Name"
        placeholder="Search by scheme name ..."
      />
      {children}
    </Filter>
  )
}
SchemeFilter.propTypes = {
  filter: PropTypes.shape({
    schemeName: PropTypes.string,
  }).isRequired,
  setFilter: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
}
SchemeFilter.defaultProps = {
  children: undefined,
}
