import { css, StyleSheet } from 'aphrodite/no-important'
import React from 'react'
import { compose } from 'recompose'
import CookieBanner from 'react-cookie-banner'
import { hot } from 'react-hot-loader'
import {
  BrowserRouter,
  // Link,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom'

import PropTypes from 'prop-types'
import { AppLayout } from './components/layouts'
import { SignInPage } from './pages'

import {
  ApolloAuthenticationProvider,
  AuthenticationConsumer,
  SelectionProvider,
} from './contexts'

import { withComponent } from './utils'
// import { ErrorModal } from './components/common/ErrorModal'

const styles = StyleSheet.create({
  appFooter: {
    background: 'white',
    fontSize: '15px',
    position: 'fixed',
    bottom: 0,
    width: '100%',
    textAlign: 'right',
  },
  footerLinkWithSeparator: {
    borderRight: '1px solid #1890ff',
    padding: '0px 5px',
    ':link': {
      textDecoration: 'none',
    },
    ':visited': {
      textDecoration: 'none',
    },
    ':hover': {
      textDecoration: 'underline',
    },
    ':active': {
      textDecoration: 'underline',
    },
  },
  footerLink: {
    padding: '0px 5px',
    ':link': {
      textDecoration: 'none',
    },
    ':visited': {
      textDecoration: 'none',
    },
    ':hover': {
      textDecoration: 'underline',
    },
    ':active': {
      textDecoration: 'underline',
    },
  },
})

// Based on example at https://tylermcginnis.com/react-router-protected-routes-authentication/
const AuthenticatedRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => (
      <AuthenticationConsumer>
        {({ token }) =>
          token ? (
            <Component {...props} token={token} />
          ) : (
            <Redirect to="/signin" />
          )
        }
      </AuthenticationConsumer>
    )}
  />
)

AuthenticatedRoute.propTypes = {
  component: PropTypes.func.isRequired,
}

const AppFooter = () => (
  <div className={css(styles.appFooter)}>
    <CookieBanner
      styles={{
        banner: {
          height: 'auto',
          minHeight: '40px',
          display: 'grid',
          gridTemplateColumns: '8fr 1fr',
          gridGap: '3px',
          gridTemplateAreas: '"message button"',
        },
        message: {
          lineHeight: 2,
          gridArea: 'message',
        },
        button: {
          marginTop: 'auto',
          gridArea: 'button',
          width: '75px',
          height: '25px',
          right: '5px',
          bottom: '5px',
        },
      }}
      dismissOnScroll={false}
      message="This site uses cookies. By continuing to use this site, you are agreeing to our use of cookies. For more details, see the Privacy Policy."
      onAccept={() => {}}
      cookie="react-cookie-banner-accepted"
    />
    <a
      className={css(styles.footerLinkWithSeparator)}
      href="https://privacy.sherwin-williams.com"
      target="_blank"
      rel="noopener noreferrer"
    >
      Privacy Policy
    </a>
    <a
      className={css(styles.footerLinkWithSeparator)}
      href=" https://accessibility.sherwin-williams.com/"
      target="_blank"
      rel="noopener noreferrer"
    >
      Accessibility Statement
    </a>
    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
    <a
      className={css(styles.footerLink)}
      id="footerDoNotSellInfoMyLink"
      // eslint-disable-next-line no-script-url
      href="javascript:void(0);"
    >
      Do Not Sell or Share My Personal Information
    </a>
  </div>
)

const App = () => (
  <React.Fragment>
    <BrowserRouter>
      <Switch>
        <Route exact path="/signin" component={SignInPage} />
        <AuthenticatedRoute path="/" component={AppLayout} />
      </Switch>
    </BrowserRouter>
    <AppFooter />
  </React.Fragment>
)

// const StrictApp = () => (
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// )
// export default hot(module)(StrictApp)

const WrappedComponent = compose(
  hot(module),
  withComponent(ApolloAuthenticationProvider),
  withComponent(SelectionProvider)
)(App)

export default WrappedComponent
