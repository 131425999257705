import React from 'react'
import { Icon, Typography } from 'antd'
import PropTypes from 'prop-types'
import { red, green } from '@ant-design/colors'
import { css, StyleSheet } from 'aphrodite/no-important'

const classes = StyleSheet.create({
  message: {
    display: 'flex',
    alignItems: 'center',
  },
  text: {
    marginLeft: 5,
  },
})

export default function PasswordMessage({ valid, message }) {
  const icon = valid ? (
    <Icon type="check-square" />
  ) : (
    <Icon type="close-square" />
  )

  return (
    <Typography.Text
      className={css(classes.message)}
      style={{ color: valid ? green.primary : red.primary }}
    >
      {icon}
      <span className={css(classes.text)}>{message}</span>
    </Typography.Text>
  )
}

PasswordMessage.propTypes = {
  valid: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
}
PasswordMessage.defaultProps = {}
