import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useMutation } from '@apollo/client'
import { Col, Form, message, Row } from 'antd'
import { css, StyleSheet } from 'aphrodite/no-important'
import { UPDATE_ROOM } from '../../../graphql/fcRooms.graphql'
import RoomForm from '../RoomForm'
import { useUpdateEffect } from '../../../hooks/useUpdateEffect'

const classes = StyleSheet.create({
  root: {
    padding: '5px 20px',
  },
  editButton: {
    marginLeft: 'auto',
    display: 'block',
  },
})
function RoomEditTab({ form, room, account }) {
  const [file, setFile] = useState(null)
  const [baseFile, setBaseFile] = useState(null)
  const [updateRoom] = useMutation(UPDATE_ROOM)
  const handleUpdateRoom = async () => {
    let roomData = {}
    try {
      roomData = await form.validateFields()
    } catch (e) {
      message.error('Form is not valid!')
      return
    }

    roomData = {
      ...roomData,
      ...(file ? { imageFile: file } : {}),
      ...(baseFile ? { baseImageFile: baseFile } : {}),
    }

    try {
      await updateRoom({
        variables: {
          accountId: account.id,
          id: room.id,
          input: roomData,
        },
      })
      message.success('Room was successfully updated!')
    } catch (e) {
      message.error('Room was not added!')
    }
  }

  useUpdateEffect(() => {
    handleUpdateRoom()
  }, [file, baseFile])

  return (
    <div className={css(classes.root)}>
      <Row>
        <Col xs={24} sm={24} lg={12} xl={8} xxl={6}>
          <RoomForm
            form={form}
            room={room}
            account={account}
            setFile={setFile}
            setBaseFile={setBaseFile}
            onInputBlur={handleUpdateRoom}
          />
        </Col>
      </Row>
    </div>
  )
}

export default Form.create()(RoomEditTab)

RoomEditTab.propTypes = {
  account: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  room: PropTypes.object.isRequired,
}

RoomEditTab.defaultProps = {}
