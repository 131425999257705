import React from 'react'
import PropTypes from 'prop-types'
import { Filter, Input } from '../../common/Filter'

export default function RoomFilters({ filters, setFilters }) {
  const filter = {
    value: filters?.name,
    set: (name) => setFilters({ ...filters, name }),
  }

  return (
    <Filter data={filter} loading={false}>
      <Input field="name" label="Name" placeholder="Search by name ..." />
    </Filter>
  )
}

RoomFilters.propTypes = {
  filters: PropTypes.shape({
    name: PropTypes.string,
    buildingId: PropTypes.number,
  }),
  setFilters: PropTypes.func.isRequired,
}

RoomFilters.defaultProps = {
  filters: null,
}
