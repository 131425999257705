import React from 'react'
import PropTypes from 'prop-types'
import { Card, Icon } from 'antd'
import { css, StyleSheet } from 'aphrodite/no-important'
import { blue } from '@ant-design/colors'
import ImageSkeleton from './skeletons/ImageSkeleton'

const { Meta } = Card

const classes = StyleSheet.create({
  card: {
    maxHeight: 150,
    margin: 4,
    cursor: 'pointer',
    overflow: 'hidden',
    ':before': {
      content: "' '",
      width: '100%',
      height: '100px',
      position: 'absolute',
      transition: '300ms',
      border: '2px solid transparent',
    },
  },
  secondaryName: {
    position: 'absolute',
    bottom: 0,
    backgroundColor: 'rgba(0,0,0,.5)',
    color: '#fff',
    textAlign: 'center',
    width: '100%',
  },
  activeCard: {
    ':before': {
      border: `2px solid ${blue.primary}`,
      backgroundColor: 'rgba(0, 0, 0, .3)',
    },
  },
  meta: {
    padding: 5,
  },
  cover: {
    position: 'relative',
  },
  icon: {
    fontSize: 25,
    position: 'absolute',
    right: '50%',
    top: '50%',
    transform: 'translateX(50%) translateY(-50%) scale(0)',
    color: '#fff',
    transition: '200ms',
  },
  activeIcon: {
    transform: 'translateX(50%) translateY(-50%) scale(1)',
  },
  hex: {
    width: '100%',
    height: 100,
  },
  close: {
    backgroundColor: 'rgba(0, 0, 0, .54)',
    color: '#fff',
    fontSize: 20,
    position: 'absolute',
    width: 20,
    height: 20,
    top: 0,
    right: 0,
  },
})
export default function SmallCard({
  loading,
  data,
  selected,
  onSelect,
  onUnselect,
  width,
}) {
  const handleUnselect = (e) => {
    e.stopPropagation()
    onUnselect(data)
  }

  return (
    <Card
      onClick={() => !loading && onSelect?.(data)}
      className={css(classes.card, selected ? classes.activeCard : null)}
      style={{ width }}
      bodyStyle={{
        padding: 2,
        textAlign: 'center',
        height: loading ? 25 : 'unset',
        overflow: loading ? 'hidden' : 'unset',
      }}
      cover={
        <div className={css(classes.cover)}>
          <Icon
            className={css(classes.icon, selected ? classes.activeIcon : null)}
            type="check"
          />
          {onUnselect && (
            <Icon
              type="close"
              className={css(classes.close)}
              onClick={handleUnselect}
            />
          )}
          {data?.imageSrc && (
            <ImageSkeleton
              src={data.imageSrc}
              style={{ width: 98, height: 98, backgroundColor: 'darkgrey' }}
            />
          )}
          {data?.hex && (
            <div
              className={css(classes.hex)}
              style={{ backgroundColor: `#${data.hex}` }}
            />
          )}
          <div className={css(classes.secondaryName)}>{data.secondaryName}</div>
        </div>
      }
      loading={loading}
    >
      <Meta
        className={css(classes.meta)}
        description={!loading && data?.name}
      />
    </Card>
  )
}

SmallCard.propTypes = {
  loading: PropTypes.bool,
  data: PropTypes.objectOf({
    name: PropTypes.string.isRequired,
    imageSrc: PropTypes.string,
    hex: PropTypes.string,
  }).isRequired,
  selected: PropTypes.bool,
  onSelect: PropTypes.func,
  onUnselect: PropTypes.func,
  width: PropTypes.number,
}

SmallCard.defaultProps = {
  loading: false,
  selected: null,
  onSelect: null,
  onUnselect: null,
  width: 100,
}
