import React, { useState } from 'react'
import { useQuery } from '@apollo/client'
import { css, StyleSheet } from 'aphrodite/no-important'
import SelectAccount from '../../common/SelectAccount'
import Spinner from '../../common/Spinner'
import Alert from '../../common/Alert'
import { ACCOUNTS } from '../../../graphql/accounts.graphql'
import MaterialForm from './MaterialForm'
import { useSelectionContext } from '../../../contexts'
import MaterialTable from './MaterialTable'
import { Filter, Input } from '../../common/Filter'

const classes = StyleSheet.create({
  layerTab: {
    padding: 5,
  },
  header: {
    display: 'flex',
    marginBottom: 5,
  },
  filters: {
    width: 320,
  },
})

export default function LayerTab() {
  const [filterName, setFilterName] = useState('')
  const { currentAccountId, currentSubAccountId, hasAccountSubAccount } =
    useSelectionContext()

  const accountId = hasAccountSubAccount
    ? currentSubAccountId
    : currentAccountId

  const filter = {
    value: filterName,
    set: setFilterName,
  }

  const {
    loading: accountsLoading,
    data: accountsData,
    error: accountsError,
  } = useQuery(ACCOUNTS)

  if (accountsLoading) {
    return <Spinner />
  }

  if (accountsError) {
    return <Alert margin />
  }
  return (
    <div className={css(classes.layerTab)}>
      <div className={css(classes.header)}>
        <SelectAccount accounts={accountsData.accounts} />
        {accountId && (
          <div className={css(classes.filters)}>
            <Filter loading={false} data={filter}>
              <Input
                formItemClass={classes.formItem}
                field="name"
                label="Name"
                placeholder="Search by name ..."
              />
            </Filter>
          </div>
        )}
        {accountId && <MaterialForm accountId={accountId} />}
      </div>
      {accountId && (
        <MaterialTable accountId={accountId} filterName={filterName} />
      )}
    </div>
  )
}

LayerTab.propTypes = {}
LayerTab.defaultProps = {}
