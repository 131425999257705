import React from 'react'
import PropTypes from 'prop-types'
import { useQuery } from '@apollo/client'
import { Filter, Input, Select } from '../../common/Filter'
import { ACCOUNTS } from '../../../graphql/accounts.graphql'
import Alert from '../../common/Alert'

export default function SwatchFilters({ filters, setFilters }) {
  const accounts = useQuery(ACCOUNTS)

  if (accounts.error) {
    return <Alert />
  }

  const mainAccounts = accounts.data?.accounts
    ?.filter((acc) => !acc.parent_account)
    .sort((a, b) => a.account_name.localeCompare(b.account_name))
    .map(({ id, account_name: name }) => ({ id, name }))

  const subAccounts = accounts.data?.accounts
    ?.filter(
      (acc) =>
        acc.parent_account && acc.parent_account.id === filters?.accountId
    )
    .sort((a, b) => a.account_name.localeCompare(b.account_name))
    .map(({ id, account_name: name }) => ({ id, name }))

  const accId = filters?.subAccountId || filters?.accountId
  const projects = accounts.data?.accounts?.filter(
    (acc) => acc.id === accId
  )?.[0]?.properties

  const filter = {
    name: {
      value: filters?.name,
      set: (name) => setFilters?.({ ...filters, name }),
    },
    account: {
      value: filters?.accountId,
      set: (accountId) => setFilters?.({ ...filters, accountId }),
      list: mainAccounts,
    },
    subAccount: {
      value:
        filters?.subAccountId &&
        subAccounts?.find((acc) => acc.id === filters?.subAccountId)?.id,
      set: (subAccountId) => setFilters?.({ ...filters, subAccountId }),
      list: subAccounts,
    },
    project: {
      value:
        filters?.projectId &&
        projects?.find((p) => p.id === filters?.projectId)?.id,
      set: (projectId) => setFilters?.({ ...filters, projectId }),
      list: projects,
    },
  }

  return (
    <Filter loading={false} data={filter}>
      <Input field="name" label="Name" placeholder="Search by name ..." />
      <Select field="account" label="Account" placeholder="Select Account" />
      <Select
        field="subAccount"
        label="Sub Account"
        placeholder="Select Sub Account"
        visible={!!subAccounts?.length}
      />
      <Select
        field="project"
        label="Project"
        placeholder="Select Project"
        visible={!!filters?.accountId}
      />
    </Filter>
  )
}

SwatchFilters.propTypes = {
  filters: PropTypes.shape({
    name: PropTypes.string,
    accountId: PropTypes.number,
    subAccountId: PropTypes.number,
    projectId: PropTypes.number,
  }),
  setFilters: PropTypes.func.isRequired,
}

SwatchFilters.defaultProps = {
  filters: null,
}
