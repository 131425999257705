import React, { useEffect, useState } from 'react'
import { StyleSheet } from 'aphrodite/no-important'
import { Col, Form, Input, Row, Select } from 'antd'
import PropTypes from 'prop-types'
import { useQuery, useLazyQuery } from '@apollo/client'
import { StyledDropzone } from '../../common/StyledDropzone'
import { getSwatchesCloudinaryPath } from '../../../functions/getSwatchesCloudinaryPath'
import { GET_PRODUCT_LINES } from '../../../graphql/fcPruductLines.graphql'
import Alert from '../../common/Alert'
import {
  GET_SWATCHES_BY_NAME,
  GET_SWATCH_BY_VENDOR_AND_IDENTIFIER,
} from '../../../graphql/fcSwatches.graphql'

const classes = StyleSheet.create({
  dropdown: {
    width: '100%',
    height: 200,
    borderWidth: 2,
    borderColor: 'gray',
    borderStyle: 'dashed',
    borderRadius: 5,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  image: {
    maxWidth: '100%',
    maxHeight: '100%',
  },
})

export const IMAGE_KEY = 'image'

const invalidProductLineIdentifierCombinationErrorMessage =
  'This product identifier has already been used!'

export const swatchFormGetProductLinesVariables = {
  pagination: {
    size: 9999999,
    page: 0,
  },
  ordering: {
    columns: {
      name: 'product_name',
      order: 'ASC',
    },
  },
}

export default function SwatchForm({
  form,
  editMode,
  setFile,
  swatch,
  visible,
}) {
  const { getFieldDecorator } = form
  const [preview, setPreview] = useState(null)
  const { loading, data, error } = useQuery(GET_PRODUCT_LINES, {
    variables: swatchFormGetProductLinesVariables,
  })

  const [getSwatchesByName] = useLazyQuery(GET_SWATCHES_BY_NAME)
  const [getSwatchByVendorAndIdentifier] = useLazyQuery(
    GET_SWATCH_BY_VENDOR_AND_IDENTIFIER
  )

  const handleDrop = async (files) => {
    const file = files[0]
    const dataURL = await new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onload = () => {
        resolve(reader.result)
      }
      reader.onerror = reject
      reader.readAsDataURL(file)
    })

    setPreview(dataURL)
    setFile(file)
    // Set file for validation
    form.setFieldsValue({
      [IMAGE_KEY]: file.name,
    })
  }

  const validateNameAvailability = async (rule, value) => {
    const trimmedValue = value.trim()
    if (trimmedValue && swatch?.name !== trimmedValue) {
      if (editMode && swatch.name === trimmedValue) {
        return
      }

      const swatches = await getSwatchesByName({
        variables: {
          name: trimmedValue,
        },
        fetchPolicy: 'no-cache',
      }).then(({ data }) => {
        return data.swatchesByName
      })

      if (swatches.length > 0) {
        throw new Error()
      }
    }
  }

  const validateProductLineAvailability = async (rule) => {
    const vendorId = form.getFieldValue('vendorId')
    const identifier = form.getFieldValue('identifier').trim()

    if (
      !vendorId ||
      !identifier ||
      (vendorId === swatch?.vendor.id && identifier === swatch?.identifier)
    ) {
      return
    }

    const swatchFromDatabase = await getSwatchByVendorAndIdentifier({
      variables: {
        vendorId,
        identifier,
      },
      fetchPolicy: 'no-cache',
    }).then(({ data }) => {
      return data.swatchByVendorAndIdentifier
    })

    if (swatchFromDatabase) {
      if (rule.field === 'vendorId') {
        form.setFields({
          identifier: {
            value: identifier,
            errors: [new Error()],
          },
        })
        throw new Error()
      } else if (rule.field === 'identifier') {
        form.setFields({
          vendorId: {
            value: vendorId,
            errors: [
              new Error(invalidProductLineIdentifierCombinationErrorMessage),
            ],
          },
        })
        throw new Error()
      }
    }

    form.setFields({
      vendorId: {
        value: vendorId,
      },
      identifier: {
        value: identifier,
      },
    })
  }

  useEffect(() => {
    if (visible === false) {
      setPreview(null)
    }
  }, [visible])

  return (
    <Form>
      <Form.Item label="Name">
        {getFieldDecorator('name', {
          initialValue: swatch?.name || '',
          rules: [
            {
              required: true,
              message: 'Please input swatch name!',
            },
            {
              max: 255,
              message: 'Maximum name size is 255!',
            },
            {
              validator: validateNameAvailability,
              message: 'This name has already been used!',
            },
          ],
        })(<Input placeholder="Oak, Marble, ..." />)}
      </Form.Item>
      <Row gutter={10}>
        <Col span="16">
          {error && <Alert margin />}
          <Form.Item label="Product Line">
            {getFieldDecorator('vendorId', {
              initialValue: swatch?.vendor.id ?? null,
              getValueFromEvent: (vendorId) => vendorId || null,
              rules: [
                {
                  required: true,
                  message: 'Please select Floor Covering Product Line!',
                },
                {
                  validator: validateProductLineAvailability,
                  message: invalidProductLineIdentifierCombinationErrorMessage,
                },
              ],
            })(
              <Select
                showSearch
                placeholder="Select a material"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  // product_name
                  option.props.children[0]
                    .toLowerCase()
                    .includes(input.toLowerCase()) ||
                  // product_identifier
                  option.props.children[2]
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                allowClear
                loading={loading}
              >
                {data?.swatchesAssignmentsResultInfos.items.map((vendor) => (
                  <Select.Option key={vendor.id} value={vendor.id}>
                    {vendor.product_name} ({vendor.product_identifier_prefix})
                  </Select.Option>
                ))}
              </Select>
            )}
          </Form.Item>
        </Col>
        <Col span="8">
          <Form.Item label="Identifier">
            {getFieldDecorator('identifier', {
              initialValue: swatch?.identifier || '',
              rules: [
                {
                  validator: validateProductLineAvailability,
                  message: ' ',
                },
              ],
            })(<Input placeholder="0000, 0001, ABC" />)}
          </Form.Item>
        </Col>
      </Row>
      <Form.Item label="Image">
        {getFieldDecorator(IMAGE_KEY, {
          initialValue: swatch?.image || '',
          rules: [
            {
              required: true,
              message: 'Please upload swatch image!',
            },
          ],
        })(
          <StyledDropzone
            baseStyle={classes.dropdown}
            key={
              !editMode &&
              swatch?.image &&
              getSwatchesCloudinaryPath(swatch?.image)
            }
            accept="image/*"
            onDrop={handleDrop}
            message="Drop an image, or click here to select one."
            imgWidth="auto"
            imgHeight="auto"
            imageStyles={classes.image}
            imgSrc={swatch?.image && getSwatchesCloudinaryPath(swatch?.image)}
            previewSrc={preview}
          />
        )}
      </Form.Item>
    </Form>
  )
}

SwatchForm.propTypes = {
  form: PropTypes.object.isRequired,
  editMode: PropTypes.bool,
  setFile: PropTypes.func.isRequired,
  swatch: PropTypes.object,
  visible: PropTypes.bool.isRequired,
}

SwatchForm.defaultProps = {
  editMode: false,
  swatch: null,
}
