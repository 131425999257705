import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  message,
  Modal,
  Row,
  Select,
  Spin,
} from 'antd'

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { ApolloConsumer, makeReference } from '@apollo/client'
import { compose, lifecycle } from 'recompose'
import { StyleSheet, css } from 'aphrodite'
import cloneDeep from 'lodash/cloneDeep'
// import bounceKeyframes from '../../../stylesheets/bouncierKeyframes'

import {
  withConsumer,
  withMutationAsProp,
  withQueryResultAsProp,
} from '../../utils/enhancers'

import {
  ACCOUNT_PALETTE_QUERY,
  GET_BLEND_ELEMENTS,
  GET_PALETTE,
  GET_COLOR_COLLECTIONS,
  GET_COLORS_BY_COLLECTION,
  UPDATE_PALETTE,
} from '../../graphql/colors.graphql'

import { AuthenticationConsumer, SelectionConsumer } from '../../contexts'
import { ErrorModal } from '../common/ErrorModal'
import { Swatch } from '../common/Swatch'
import { RegexPatterns } from '../../const/regex'

const FormItem = Form.Item
const { Search } = Input
const { Option } = Select

const styles = StyleSheet.create({
  animatedSelection: {
    // animationName: [bounceKeyframes],
    animationDuration: '1s',
    animationIterationCount: '1',
  },
  button: {
    float: 'right',
    width: '10%',
  },
  buttonMargin: {
    marginRight: '8px',
    marginBottom: '8px',
  },
  buttonRow: {
    marginTop: '35px',
    padding: '0px 16px 12px 0px',
  },
  colorCard: {
    backgroundColor: 'white',
    border: '2px solid #e8e8e8',
    borderRadius: '5px',
    height: '100px',
    width: '100px',
    display: 'inline-block',
    verticalAlign: 'top',
    cursor: 'pointer',
    position: 'relative',
  },
  colorCardVirtualized: {
    marginTop: '2px',
    width: '97%',
  },
  colorsCol: {
    backgroundColor: 'whitesmoke',
    minHeight: '540px',
  },
  colorsContainer: {
    height: '420px',
    padding: '8px',
    overflowY: 'scroll',
  },
  colorName: {
    fontSize: '13px',
    padding: '4px',
    overflow: 'hidden',
    height: '44px',
  },
  dividerTop: {
    background: '#a9a9a9',
    marginBottom: 0,
  },
  filter: {
    width: '100%',
  },
  filterCol: {
    padding: '0 10px 5px 10px',
  },
  filtersCollapse: {
    margin: '0 12px',
  },
  filterSelect: {
    width: '100%',
  },
  formCol: {
    padding: '10px 16px',
  },
  formItem: {
    marginBottom: '5px',
  },
  header: {
    fontSize: '18px',
    margin: '12px 0px 8px 12px',
  },
  iconCorner: {
    position: 'absolute',
    bottom: 5,
    right: 5,
  },
  iconGreen: {
    color: 'rgb(28, 184, 65)',
  },
  iconRed: {
    color: 'rgb(228, 134, 134)',
  },
  label: {
    fontWeight: '500',
  },
  selectedColor: {
    // backgroundColor: '#e6f7ff',
    borderColor: '#1890ff',
  },
  selectionsCol: {
    backgroundColor: '#e6e6e6',
    minHeight: '540px',
  },
  selectionsContainer: {
    height: '592px',
    padding: '8px 0px 8px 8px',
    overflowY: 'scroll',
  },
  spin: {
    marginTop: '40px',
    width: '100%',
  },
  vendorCollapse: {
    backgroundColor: 'white',
    marginBottom: '12px',
  },
  vendorPanelContents: {
    paddingLeft: '12px',
  },
})
class PaletteSelectionsModal extends Component {
  static getSortedSelectionsFromProps = (props) => {
    if (
      props.palette.palette_selections !== undefined &&
      props.palette.palette_selections.length > 0
    ) {
      const clonedPaletteSelections = cloneDeep(
        props.palette.palette_selections
      )

      clonedPaletteSelections.sort((a, b) => {
        // Treat null like it is higher than anything else.
        if (
          !(a.color && a.color.identifier) &&
          !(b.color && b.color.identifier)
        ) {
          return 0
        }
        if (!(a.color && a.color.identifier)) {
          return 1
        }
        if (!(b.color && b.color.identifier)) {
          return -1
        }

        return a.color.identifier.localeCompare(b.color.identifier)
      })

      return clonedPaletteSelections
    }
    return []
  }

  static getSelectedBlendElementsFromProps = (props) => {
    const { blendElements, palette } = props

    const selectedBlendElements =
      palette && palette.materials
        ? palette.materials.map((paletteMaterial) =>
            blendElements.find(
              (blendElement) => blendElement.id === paletteMaterial.element_id
            )
          )
        : []

    return selectedBlendElements
  }

  state = {
    animatedSelection: null,
    colorsArray: null,
    // sort initial palette color selection.
    selections: PaletteSelectionsModal.getSortedSelectionsFromProps(this.props),
    selectedBlendElements:
      PaletteSelectionsModal.getSelectedBlendElementsFromProps(this.props),
    visible: true,
    lowerSearchText: '',
  }

  setLowerSearchText = (newLowerSearchText) => {
    this.setState({
      lowerSearchText: newLowerSearchText,
    })
  }

  handleCancel = () => {
    const { selection, setStateHandler } = this.props
    const { setCurrentPaletteId, setCurrentCollectionId } = selection
    this.setState({ visible: false })
    setTimeout(() => {
      setStateHandler('editPaletteColorSelections', false)
      setCurrentCollectionId(null)
      setCurrentPaletteId(null)
    }, 200)
  }

  handleCollectionSelectChange = async (value) => {
    const colorVendorProductInfoId = value
    const { selection, apolloClient } = this.props
    const { setCurrentCollectionId } = selection
    this.setState({
      colorsArray: null,
    })
    if (colorVendorProductInfoId === 'null') {
      setCurrentCollectionId(null)
      this.setState({
        colorsArray: null,
      })
    } else {
      setCurrentCollectionId(colorVendorProductInfoId)
      const { data } = await apolloClient.query({
        query: GET_COLORS_BY_COLLECTION,
        variables: { color_vendor_product_info_id: colorVendorProductInfoId },
      })

      const sortedColorsArray = [...data.colorsByCollectionId].sort((a, b) => {
        // Treat null like it is higher than anything else.
        if (!a.identifier && !b.identifier) {
          return 0
        }
        if (!a.identifier) {
          return 1
        }
        if (!b.identifier) {
          return -1
        }

        return a.identifier.localeCompare(b.identifier)
      })

      this.setState({
        colorsArray: sortedColorsArray,
      })
    }
  }

  handleColorClick = (color) => {
    const { selections, animatedSelection } = this.state
    if (selections.some((selection) => selection.id === color.id)) {
      this.setState({
        selections: [
          ...selections.filter((selection) => selection.id !== color.id),
        ],
      })
    } else {
      const newSelection = {
        id: color.id,
        blend_mode: 'MULTIPLY',
        name: color.name,
        vendor_id: color.vendor_id,
        color,
      }
      newSelection.color_id = color.id
      newSelection.hex = color.hex
      newSelection.identifier = color.identifier

      this.setState(
        {
          animatedSelection: newSelection,
          // Display new selection at top of list, even though that wouldn't be sorted order.
          selections: [newSelection, ...selections],
        },
        () => {
          setTimeout(() => {
            if (
              animatedSelection !== null &&
              animatedSelection.id === newSelection.id
            ) {
              this.setState({
                animatedSelection: null,
              })
            }
          }, 1000)
        }
      )
    }
  }

  handleAddAllColors = () => {
    const { colorsArray, selections } = this.state

    const paletteColors = colorsArray.map((color) => ({
      id: color.id,
      blend_mode: 'MULTIPLY',
      name: color.name,
      vendor_id: color.vendor_id,
      color_id: color.id,
      hex: color.hex,
      identifier: color.identifier,
      color,
    }))

    // Add only colors that are not already in the selections.
    const newSelections = paletteColors.filter((paletteColor) =>
      selections.every(
        (existingSelection) =>
          existingSelection.color.id !== paletteColor.color.id
      )
    )

    // Merge new selections and existing selections by adding only the new selections that do not
    // match any existing selections.
    this.setState({
      selections: [...selections, ...newSelections],
    })
  }

  handleUpdatePaletteSubmit = (e) => {
    const { form, selection, updatePalette } = this.props
    const { selections, selectedBlendElements } = this.state
    e.preventDefault()
    form.validateFields((errors, values) => {
      // eslint-disable-next-line camelcase
      const palette_id = selection.currentPaletteId
      if (!errors) {
        const paletteSelections = selections.map((paletteSelection) => ({
          color_id: paletteSelection.color_id,
          blend_mode: paletteSelection.blend_mode,
          palette_id,
          name: paletteSelection.name,
          hex: paletteSelection.hex,
        }))
        updatePalette({
          palette_id,
          name: values.paletteNameInput,
          palette_selections: paletteSelections,
          account_id:
            selection.currentSubAccountId || selection.currentAccountId,
          // Clean up the objects to match the SelectedElementInput type.
          selected_elements: selectedBlendElements.map((materialSelection) => ({
            id: materialSelection.id,
            blend_mode: materialSelection.blend_mode,
            display_name: materialSelection.display_name,
            identifier: materialSelection.identifier,
            material_category: materialSelection.material_category,
            name: materialSelection.name,
            remarks: materialSelection.remarks,
          })),
        })
          .then(() => {
            message.success(`Updated palette "${values.paletteNameInput}"`)
            this.handleCancel()
          })
          .catch(ErrorModal)
      }
    })
  }

  generateCollectionOptions = (collectionItem) =>
    collectionItem.map(this.generateCollectionList)

  generateCollectionList = (colorCollection) => (
    <Option
      key={`${colorCollection.product_name}:${colorCollection.id}`}
      value={colorCollection.id}
    >
      {colorCollection.product_name}
    </Option>
  )

  generateColorCard = (selection) => (
    <div
      style={{
        justifySelf: 'center',
      }}
    >
      <Swatch
        key={`${selection.id}`}
        color={{
          id: selection.id,
          identifier: selection.identifier,
          name: selection.name,
          colorId: selection.id,
          hex: selection.hex,
          identifier_prefix:
            selection.color_vendor_product_info.product_identifier_prefix,
        }}
        selected={false}
        cornerIconType="plus"
        size="large"
        dirName=""
        onClick={() => this.handleColorClick(selection, 'color')}
      />
    </div>
  ) // generateColorCard

  generatePaletteSelectionCard = (selection) => (
    <Swatch
      key={`${selection.id}`}
      color={{
        id: selection.id,
        identifier: selection.color && selection.color.identifier,
        name: selection.name,
        colorId: selection.id,
        hex: selection.hex,
        identifier_prefix:
          selection.color.color_vendor_product_info.product_identifier_prefix,
      }}
      material=""
      selected={false}
      cornerIconType="close"
      size="large"
      dirName=""
      onClick={() => this.handleColorClick(selection, 'selection')}
    />
  ) // generatePaletteSelectionCard

  render = () => {
    const { form, palette, colorCollections, selection } = this.props

    const { visible, colorsArray, selections, lowerSearchText } = this.state

    const { getFieldDecorator } = form
    const titleForCollection = colorCollections.filter(
      (collection) => collection.id === selection.currentCollectionId
    )

    if (!palette) {
      return null
    }

    return (
      <Modal
        visible={visible}
        title={
          palette.name !== undefined && palette.name !== null
            ? `Edit "${palette.name}" Colors`
            : 'Edit Colors'
        }
        footer={null}
        onCancel={this.handleCancel}
        bodyStyle={{ padding: '0' }}
        style={{ top: '50px' }}
        width="90%"
      >
        <Row>
          <Col xs={24} md={6} xl={5} className={css(styles.formCol)}>
            <Form hideRequiredMark>
              <FormItem
                label={<span className={css(styles.label)}>Palette Name</span>}
                colon={false}
                className={css(styles.formItem)}
              >
                {getFieldDecorator('paletteNameInput', {
                  initialValue: palette.name,
                  rules: [
                    { required: true, message: "You can't leave this empty." },
                  ],
                })(
                  <Input
                    onChange={
                      // Sanitize input troublesome for URLS: https://secure.n-able.com/webhelp/NC_9-1-0_SO_en/Content/SA_docs/API_Level_Integration/API_Integration_URLEncoding.html
                      (e) => {
                        e.target.value = e.target.value.replace(
                          RegexPatterns.excludedInputCharacters,
                          ''
                        )
                      }
                    }
                  />
                )}
              </FormItem>
              <FormItem
                label={
                  <span className={css(styles.label)}>Color Collection</span>
                }
                colon={false}
                className={css(styles.formItem)}
              >
                {getFieldDecorator('colorCollectionSelect', {
                  initialValue: 'Please Select a Collection',
                  rules: [
                    { required: true, message: "You can't leave this empty." },
                  ],
                })(
                  <Select
                    defaultActiveFirstOption={false}
                    onChange={this.handleCollectionSelectChange}
                    placeholder="Select collection"
                  >
                    <Option key="null" value="null">
                      Please Select A Collection
                    </Option>
                    {colorCollections.length > 0 &&
                      this.generateCollectionOptions(colorCollections)}
                  </Select>
                )}
              </FormItem>
            </Form>
          </Col>
          <Col xs={24} md={18} xl={12} className={css(styles.colorsCol)}>
            <h3 className={css(styles.header)}>Colors</h3>
            <Divider className={css(styles.dividerTop)} />
            <div>
              <h4>
                {titleForCollection && titleForCollection[0]
                  ? `${titleForCollection[0].product_name}`
                  : ''}
              </h4>
              {colorsArray === null && selection.currentCollectionId === null && (
                <div
                  style={{
                    fontSize: '20px',
                    textAlign: 'center',
                  }}
                >
                  Please Select A Collection
                </div>
              )}
              {colorsArray === null && selection.currentCollectionId !== null && (
                <div
                  style={{
                    fontSize: '20px',
                    textAlign: 'center',
                  }}
                >
                  <Spin />
                  {' Searching for colors in the selected collection '}
                  <Spin />
                </div>
              )}
              {colorsArray !== null && selection.currentCollectionId !== null && (
                <React.Fragment>
                  <Search
                    style={{
                      margin: '15px 0 15px 0',
                    }}
                    placeholder="Search by Color Name or Number"
                    onChange={(event) => {
                      const newLowerSearchText = event.target.value
                        .trim()
                        .toLowerCase()
                      this.setLowerSearchText(newLowerSearchText)
                    }}
                  />
                  <div
                    style={{
                      overflow: 'scroll',
                      height: '450px',
                      display: 'grid',
                      gridGap: '4px',
                      gridTemplateColumns: 'repeat(4, 1fr)',
                      marginBottom: '20px',
                    }}
                  >
                    {(lowerSearchText === ''
                      ? colorsArray
                      : colorsArray.filter(
                          (color) =>
                            (color.name &&
                              color.name
                                .toLowerCase()
                                .includes(lowerSearchText)) ||
                            (color.identifier &&
                              color.identifier
                                .toLowerCase()
                                .includes(lowerSearchText))
                        )
                    ).map(this.generateColorCard)}
                  </div>

                  <Button onClick={this.handleAddAllColors}>
                    Add All Colors
                  </Button>
                </React.Fragment>
              )}
            </div>
          </Col>
          {selections !== null && (
            <Col xs={24} md={24} xl={7} className={css(styles.selectionsCol)}>
              <h3 className={css(styles.header)}>Palette Selections</h3>
              <Divider className={css(styles.dividerTop)} />
              <div
                className={css(styles.selectionsContainer)}
                id="paletteSelectionsContainer"
              >
                {selections.map(this.generatePaletteSelectionCard)}
              </div>
            </Col>
          )}
        </Row>
        <Row className={css(styles.buttonRow)}>
          <Button
            className={css(styles.button)}
            onClick={this.handleUpdatePaletteSubmit}
            type="primary"
          >
            Submit
          </Button>
          <Button
            onClick={this.handleCancel}
            className={css(styles.button, styles.buttonMargin)}
          >
            Cancel
          </Button>
        </Row>
      </Modal>
    )
  }
} // class PaletteSelectionsModal

PaletteSelectionsModal.propTypes = {
  authentication: PropTypes.shape({
    token: PropTypes.string,
    signIn: PropTypes.func,
    signOut: PropTypes.func,
  }).isRequired,
  selection: PropTypes.shape({
    currentPaletteId: PropTypes.number,
    currentAccountId: PropTypes.number,
    currentSubAccountId: PropTypes.number,
    currentCollectionId: PropTypes.number,
    setCurrentAccountId: PropTypes.func,
    setCurrentPaletteId: PropTypes.func,
    setCurrentCollectionId: PropTypes.func,
  }).isRequired,
  form: PropTypes.object.isRequired,
  palette: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string,
    palette_selections: PropTypes.arrayOf(PropTypes.object).isRequired,
    materials: PropTypes.arrayOf(PropTypes.object).isRequired,
  }).isRequired,
  updatePalette: PropTypes.func.isRequired,
  colorCollections: PropTypes.arrayOf(PropTypes.object).isRequired,
  setStateHandler: PropTypes.func.isRequired,
  apolloClient: PropTypes.object.isRequired,
}

const WrappedComponent = compose(
  withConsumer(AuthenticationConsumer, { propName: 'authentication' }),
  withConsumer(SelectionConsumer, { propName: 'selection' }),
  withConsumer(ApolloConsumer, { propName: 'apolloClient' }),
  lifecycle({
    // this is done to insure that iif the user were to navigate from the admin color management
    // tab and then to the palette selection component the selection context of the
    // current collection ID is reset to null and the modal behaves as expected
    componentDidMount() {
      this.props.selection.setCurrentCollectionId(null)
    },
  }),
  withQueryResultAsProp({
    gqlDocument: GET_BLEND_ELEMENTS,
    resultPropName: 'blendElements',
  }),
  withQueryResultAsProp({
    gqlDocument: GET_PALETTE,
    variables: ({ selection }) => ({ id: selection.currentPaletteId }),
    resultPropName: 'palette',
    propName: 'palette',
  }),
  withQueryResultAsProp({
    gqlDocument: GET_COLOR_COLLECTIONS,
    propName: 'colorCollections',
    resultPropName: 'colorVendorProductInfos',
  }),
  withMutationAsProp({
    gqlDocument: UPDATE_PALETTE,
    mutationPropName: 'updatePalette',
    refetchQueries: [
      {
        gqlDocument: ACCOUNT_PALETTE_QUERY,
        variables: ({ selection }) => ({
          id: selection.currentSubAccountId || selection.currentAccountId,
        }),
      },
    ],
    update(cache) {
      const rootId = cache.identify(makeReference('ROOT_QUERY'))
      cache.evict({ id: rootId, fieldName: 'colors' })
    },
  })
)(Form.create()(PaletteSelectionsModal))
export { WrappedComponent as PaletteSelectionsModal }
