/* eslint-disable react/prop-types,react/no-unused-state */
import { Button, Form, Icon, Popconfirm, Select, Table } from 'antd'

import { css, StyleSheet } from 'aphrodite/no-important'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { compose } from 'recompose'

import { StyledDropzone } from '../../common/StyledDropzone'

import {
  ADD_VIEW_TEMPLATE_ELEMENT,
  BLEND_ELEMENTS,
  DELETE_VIEW_TEMPLATE_ELEMENT_BY_ID,
  UPDATE_VIEW_TEMPLATE_ELEMENT_BY_ID,
  VIEW_TEMPLATE_ELEMENTS_BY_VIEW_ELEMENT_ID,
} from '../../../graphql/brochures.graphql'

import { UPLOAD_FILE } from '../../../graphql/uploads.graphql'

import {
  generateUpdateFormItemInputNumber,
  withMutationAsProp,
  withQueryResultAsProp,
} from '../../../utils'

const { Item } = Form
const { Option } = Select

const styles = StyleSheet.create({
  addViewTemplateElementForm: {
    border: '1px solid #d8dee2',
    borderRadius: '10px',
    padding: '0px 10px 0px 10px',
    margin: 'auto',
  },
  updateViewTemplateElementForm: {
    padding: '0px 10px 0px 10px',
    margin: 'auto',
  },
  dropzoneBaseStyle: {
    width: 100,
    height: 100,
    borderWidth: 2,
    borderColor: 'lightgray',
    borderStyle: 'dashed',
    borderRadius: 5,
    backgroundColor: 'darkgray',
  },
})

class ViewTemplateElementElementsForm extends Component {
  static propTypes = {
    addViewTemplateElement: PropTypes.func.isRequired,
    deleteViewTemplateElement: PropTypes.func.isRequired,
    viewTemplateElements: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        src: PropTypes.string,
        material_template: PropTypes.shape({
          id: PropTypes.number,
          element_id: PropTypes.number,
          display_name: PropTypes.string,
        }).isRequired,
      })
    ).isRequired,
    updateViewTemplateElement: PropTypes.func.isRequired,
  }

  constructor() {
    super()
    this.state = { files: [], previews: {} }
  }

  handleAddViewTemplateElementButtonPress = () => {
    const { addViewTemplateElement, form, viewTemplateId } = this.props
    form.validateFieldsAndScroll(async (errors, { blendElementId }) => {
      if (!errors) {
        form.resetFields()
        await addViewTemplateElement({
          parentId: viewTemplateId,
          input: {
            element_id: blendElementId,
          },
        })
      }
    })
  }

  handleUpdateViewTemplateElementListOrderInputChange = async (
    id,
    fieldKey,
    label,
    value
  ) => {
    const { updateViewTemplateElement } = this.props
    await updateViewTemplateElement({
      id,
      input: { [fieldKey]: value },
    })
  }

  handleDeleteViewTemplateElementButtonPress = async (
    viewTemplateElementId
  ) => {
    // eslint-disable-next-line
    const { deleteViewTemplateElement } = this.props
    deleteViewTemplateElement({
      id: viewTemplateElementId,
    })
  }

  handleOnDrop =
    ({
      folder = 'color_visualizer',
      itemId,
      targetField,
      fileNameToDelete,
    } = {}) =>
    async (acceptedFiles) => {
      const { uploadFile, updateViewTemplateElement } = this.props
      const dataURL = await new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onload = () => {
          resolve(reader.result)
        }
        reader.onerror = reject
        reader.readAsDataURL(acceptedFiles[0])
      })

      this.setState((state) => ({
        files: acceptedFiles,
        previews: {
          ...state.previews,
          [`${itemId}${targetField}`]: dataURL,
        },
      }))

      const droppedFileSize = acceptedFiles[0].size
      let uploadFileResult
      try {
        uploadFileResult = await uploadFile({
          folder,
          file: acceptedFiles[0],
          file_name_to_delete: fileNameToDelete,
        })
      } catch (error) {
        console.error('Encountered an error while uploading:', error)
      }
      // Once the file has been uploaded, save the name of the file to the
      // appropriate table and column.
      if (
        uploadFileResult &&
        uploadFileResult.data &&
        uploadFileResult.data.uploadFile &&
        uploadFileResult.data.uploadFile.cloud_file_name
      ) {
        // eslint-disable-next-line camelcase
        const { cloud_file_name } = uploadFileResult.data.uploadFile
        await updateViewTemplateElement({
          id: itemId,
          input: {
            [targetField]: cloud_file_name,
            uploadFileSize: droppedFileSize,
          },
        })
      }
    } // handleOnDrop

  handleCancel = () => {
    this.setState({
      files: [],
    })
  }

  // No clearing of the cache is necessary, since these view template elements are never rendered
  // directly, but only used to initialize view element data.

  render() {
    const { blendElements, form, viewTemplateElements } = this.props
    const { previews } = this.state
    const { getFieldDecorator } = form

    const usedBlendElementIds = viewTemplateElements
      // eslint-disable-next-line camelcase
      .map(
        (view_template_element) =>
          view_template_element.material_template.element_id
      )

    const unusedBlendElements = blendElements.filter(
      (blendElement) => !usedBlendElementIds.includes(blendElement.id)
    )

    return (
      <React.Fragment>
        <Form
          key="addViewTemplateElementForm"
          layout="inline"
          className={css(styles.addViewTemplateElementForm)}
        >
          <Item label="Material">
            {getFieldDecorator('blendElementId', {
              rules: [
                {
                  required: true,
                  message: 'This field is required.',
                },
              ],
              // Don't validate after every onChange (we will therefore only
              // validate when the user presses the "Add User" button.)
              validateTrigger: null,
              initialValue: null,
            })(
              <Select
                style={{ width: '200px' }}
                disabled={unusedBlendElements.length === 0}
              >
                {unusedBlendElements
                  .sort((a, b) => a.display_name.localeCompare(b.display_name))
                  .map((blendElement) => (
                    <Option key={`${blendElement.id}`} value={blendElement.id}>
                      {blendElement.display_name}
                    </Option>
                  ))}
              </Select>
            )}
          </Item>
          <Item>
            <Button
              type="primary"
              disabled={unusedBlendElements.length === 0}
              onClick={this.handleAddViewTemplateElementButtonPress}
            >
              Add Layer
            </Button>
          </Item>
        </Form>
        <Table
          key="layersTable"
          size="middle"
          bordered
          pagination={false}
          dataSource={viewTemplateElements}
          columns={[
            {
              title: 'Material',
              dataIndex: 'material',
              key: 'material',
              defaultSortOrder: 'ascend',
              sorter: (a, b) =>
                a.material_template.display_name.localeCompare(
                  b.material_template.display_name
                ),
              render: (text, viewTemplateElement) =>
                viewTemplateElement.material_template.display_name,
            },
            {
              title: 'Mask',
              dataIndex: 'src',
              key: 'src',
              // eslint-disable-next-line camelcase
              render: (text, item) => (
                <StyledDropzone
                  key={item.src}
                  accept="image/*"
                  baseStyle={styles.dropzoneBaseStyle}
                  imgWidth={96}
                  imgHeight={96}
                  imgSrc={`https://res.cloudinary.com/renderinghouse/image/upload/w_100,h_100,c_fill/app/cv/shw/brochure_templates/images/${item.src}`}
                  message="Drop an image, or press to select one."
                  onDrop={this.handleOnDrop({
                    folder: 'app/cv/shw/brochure_templates/images',
                    itemId: item.id,
                    targetField: 'src',
                    label: 'ViewTemplateElement image',
                    fileNameToDelete: item.src,
                    item,
                  })}
                  onFileDialogCancel={this.handleCancel}
                  previewSrc={previews[`${item.id}src`]}
                />
              ),
            },
            {
              title: 'List Order',
              dataIndex: 'list_order',
              key: 'list_order',
              defaultSortOrder: 'ascend',
              sorter: (a, b) => a.list_order - b.list_order,
              render: (text, viewTemplateElement) =>
                generateUpdateFormItemInputNumber(
                  this.handleUpdateViewTemplateElementListOrderInputChange,
                  {},
                  viewTemplateElement.id,
                  viewTemplateElement.list_order,
                  'list_order',
                  '',
                  { min: 1, step: 1 }
                ),
            },
            {
              key: 'delete',
              width: 150,
              render: (text, item) => (
                <Popconfirm
                  placement="bottomRight"
                  title="Delete this item?"
                  onConfirm={() =>
                    this.handleDeleteViewTemplateElementButtonPress(item.id)
                  }
                  okText="Yes"
                  cancelText="No"
                >
                  <Button type="danger">
                    <Icon type="delete" />
                  </Button>
                </Popconfirm>
              ),
            },
          ]}
        />
      </React.Fragment>
    )
  }
}

const WrappedComponent = compose(
  withQueryResultAsProp({
    gqlDocument: BLEND_ELEMENTS,
    resultPropName: 'blendElements',
  }),
  withQueryResultAsProp({
    gqlDocument: VIEW_TEMPLATE_ELEMENTS_BY_VIEW_ELEMENT_ID,
    variables: ({ viewTemplateId }) => ({ id: viewTemplateId }),
    resultPropName: 'viewTemplate',
    transformFunc: (viewTemplate) =>
      ({ ...viewTemplate }.view_template_elements),
    propName: 'viewTemplateElements',
  }),
  withMutationAsProp({
    gqlDocument: ADD_VIEW_TEMPLATE_ELEMENT,
    mutationPropName: 'addViewTemplateElement',
    refetchQueries: [
      {
        gqlDocument: VIEW_TEMPLATE_ELEMENTS_BY_VIEW_ELEMENT_ID,
        variables: ({ viewTemplateId }) => ({ id: viewTemplateId }),
      },
    ],
  }),
  withMutationAsProp({
    gqlDocument: DELETE_VIEW_TEMPLATE_ELEMENT_BY_ID,
    mutationPropName: 'deleteViewTemplateElement',
    refetchQueries: [
      {
        gqlDocument: VIEW_TEMPLATE_ELEMENTS_BY_VIEW_ELEMENT_ID,
        variables: ({ viewTemplateId }) => ({ id: viewTemplateId }),
      },
    ],
  }),
  withMutationAsProp({
    gqlDocument: UPDATE_VIEW_TEMPLATE_ELEMENT_BY_ID,
    mutationPropName: 'updateViewTemplateElement',
    variables: ({ viewTemplateId }) => ({ viewTemplateId }),
  }),
  withMutationAsProp({
    gqlDocument: UPLOAD_FILE,
    mutationPropName: 'uploadFile',
  }),
  Form.create()
)(ViewTemplateElementElementsForm)

export { WrappedComponent as ViewTemplateElementsForm }
