import { css, StyleSheet } from 'aphrodite/no-important'
import { message as antMessage } from 'antd'
import React from 'react'
import Dropzone from 'react-dropzone'
import PropTypes from 'prop-types'

const defaultStyles = StyleSheet.create({
  dropzoneBaseStyle: {
    width: 200,
    height: 200,
    borderWidth: 2,
    borderColor: 'gray',
    borderStyle: 'dashed',
    borderRadius: 5,
  },
  dropzoneActiveStyle: {
    borderColor: 'cyan',
    borderStyle: 'dashed',
    backgroundColor: '#c5fcfe',
  },
  dropzoneRejectedStyle: {
    borderColor: 'red',
    borderStyle: 'solid',
    backgroundColor: 'gray',
  },
  dropZoneMessage: {
    fontSize: '18px',
    textAlign: 'center',
    paddingTop: '10px',
  },
})

const StyledDropzone = ({
  message = 'Drop file here.',
  baseStyle = defaultStyles.dropzoneBaseStyle,
  activeStyle = defaultStyles.dropzoneActiveStyle,
  rejectedStyle = defaultStyles.dropzoneRejectedStyle,
  imageStyles,
  ...props
}) => (
  <Dropzone
    {...props}
    maxSize={500000000}
    onDropRejected={(rejectedFile) => {
      if (
        rejectedFile &&
        rejectedFile[0] &&
        rejectedFile[0].size &&
        rejectedFile[0].size > 500000000
      ) {
        antMessage.error('Files larger than 5 MB are not supported.', 5)
      } else {
        antMessage.error('Could not upload file.')
      }
    }}
  >
    {({ getRootProps, getInputProps, isDragActive, isDragReject }) => {
      // Define a list with some optional elements.
      const styleList = [
        baseStyle,
        ...(isDragActive ? [activeStyle] : []),
        ...(isDragReject ? [rejectedStyle] : []),
      ]

      return (
        <div {...getRootProps()} className={css(styleList)}>
          <input {...getInputProps()} />
          {props.previewSrc || props.imgSrc ? (
            <img
              alt=""
              src={props.previewSrc || props.imgSrc}
              height={props.imgHeight}
              width={props.imgWidth}
              className={imageStyles ? css(imageStyles) : ''}
            />
          ) : (
            <div className={css(defaultStyles.dropZoneMessage)}>{message}</div>
          )}
        </div>
      )
    }}
  </Dropzone>
)

StyledDropzone.defaultProps = {
  message: 'Drop file here.',
  baseStyle: defaultStyles.dropzoneBaseStyle,
  activeStyle: defaultStyles.dropzoneActiveStyle,
  rejectedStyle: defaultStyles.dropzoneRejectedStyle,
  previewSrc: null,
  imgSrc: null,
  imgHeight: null,
  imgWidth: null,
  imageStyles: null,
}

StyledDropzone.propTypes = {
  message: PropTypes.string,
  baseStyle: PropTypes.object,
  activeStyle: PropTypes.object,
  rejectedStyle: PropTypes.object,
  previewSrc: PropTypes.string,
  imgSrc: PropTypes.string,
  imgHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  imgWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  imageStyles: PropTypes.object,
}

export { StyledDropzone }
