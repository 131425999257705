import { Tabs, Layout } from 'antd'
import PropTypes from 'prop-types'
import React from 'react'
import { compose } from 'recompose'
import { StyleSheet, css } from 'aphrodite'

import { AuthenticationConsumer, SelectionConsumer } from '../../../contexts'
import { CreateSchemeForm } from './CreateSchemeForm'
import { AssignSchemesForm } from './AssignSchemesForm'
import { withConsumer } from '../../../utils'

const { Content } = Layout
const { TabPane } = Tabs
const styles = StyleSheet.create({
  content: {
    backgroundColor: 'whitesmoke',
    marginBottom: '24px',
    paddingTop: '8px',
    minHeight: '50vh',
  },
  contentXs: {
    padding: '0',
  },
  header: {
    marginTop: '5px',
    paddingLeft: '12px',
  },
  layout: {
    background: '#fff',
    padding: '14px 0',
  },
  layoutXs: {
    padding: '8px 0',
  },
  singleForm: {
    marginTop: 12,
    padding: '12px 12px',
    minHeight: 300,
    overflowY: 'auto',
  },
  tabPane: {
    padding: '10px 20px',
  },
})

const SchemesForm = () => (
  <React.Fragment>
    <Content className={css(styles.content)}>
      <Tabs
        tabBarStyle={{
          marginBottom: 0,
          paddingLeft: '40px',
          color: '#000000',
          backgroundColor: 'whitesmoke',
        }}
      >
        <TabPane tab="Create Schemes" key="1" className={css(styles.tabPane)}>
          <CreateSchemeForm />
        </TabPane>
        <TabPane tab="Assign Schemes" key="2">
          <AssignSchemesForm />
        </TabPane>
      </Tabs>
    </Content>
  </React.Fragment>
)

SchemesForm.propTypes = {
  authentication: PropTypes.shape({
    token: PropTypes.string,
    signIn: PropTypes.func,
    signOut: PropTypes.func,
  }).isRequired,
  selection: PropTypes.shape({
    currentAccountId: PropTypes.number,
    setCurrentAccountId: PropTypes.func,
  }).isRequired,
}

const WrappedComponent = compose(
  withConsumer(AuthenticationConsumer, { propName: 'authentication' }),
  withConsumer(SelectionConsumer, { propName: 'selection' })
)(SchemesForm)

export { WrappedComponent as SchemesForm }
