/* eslint-disable react/prop-types */
import { css, StyleSheet } from 'aphrodite/no-important'
import PropTypes from 'prop-types'
import React, { useCallback, useState } from 'react'
import { compose } from 'recompose'

import { useHistory, useLocation } from 'react-router-dom'
import { AuthenticationConsumer, SelectionConsumer } from '../../../contexts'

import {
  ACCOUNT_PROPERTIES_BY_ACCOUNT_ID,
  DELETE_PROPERTY_BY_ID,
  UPDATE_PROPERTY_BY_ID,
} from '../../../graphql/properties.graphql'

import { REPS } from '../../../graphql/reps.graphql'

import {
  renderNothingIf,
  useQueryParams,
  withConsumer,
  withEnumValuesAsProp,
  withMutationAsProp,
  withQueryResultAsProp,
} from '../../../utils'
import {
  PropertiesFilter,
  useDefaultFilter,
} from './components/PropertiesFilter'
import { PropertiesTable, useProperties } from './components/PropertiesTable'
import { PropertiesTableActions } from './components/PropertiesTableActions'
import { QueryParams } from '../../../const/queryParams'

const { Activate, Delete } = PropertiesTableActions

const styles = StyleSheet.create({
  tableActions: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: 8,
  },
})

const DEFAULT_PAGE_SIZE = 50

function InactivePropertiesForm({
  account,
  updatePropertyById,
  deletePropertyById,
}) {
  const location = useLocation()
  const history = useHistory()
  const params = useQueryParams(location, history)
  const [currentPage, setCurrentPage] = useState(
    () => Number(params.get(QueryParams.PAGE)) || 1
  )
  const [pageSize, setPageSize] = useState(() =>
    Number(params.get(QueryParams.PAGE_SIZE) || DEFAULT_PAGE_SIZE)
  )
  const { filter, setFilter } = useDefaultFilter(
    params,
    setCurrentPage,
    setPageSize,
    DEFAULT_PAGE_SIZE
  )
  const properties = useProperties(
    account,
    filter,
    (property) => property.is_active === false
  )

  const handleDeletePropertyButtonPress = useCallback(
    (propertyId) => {
      deletePropertyById({
        id: propertyId,
      })
    },
    [deletePropertyById]
  )

  const handleActivatePropertyButtonPress = useCallback(
    (propertyId) => {
      updatePropertyById({
        id: propertyId,
        input: {
          is_active: true,
        },
      })
    },
    [updatePropertyById]
  )

  const activeActions = (property) => [
    <Activate
      key="activate"
      {...{
        propertyId: property.id,
        handleActivatePropertyButtonPress,
      }}
    />,
    <Delete
      key="delete"
      {...{
        propertyId: property.id,
        handleDeletePropertyButtonPress,
      }}
    />,
  ]

  return (
    <React.Fragment>
      <div className={css(styles.tableActions)}>
        <PropertiesFilter
          filter={filter}
          setFilter={setFilter}
          queryParams={params}
        />
      </div>
      <PropertiesTable
        type="inactive"
        account={account}
        properties={properties}
        actions={activeActions}
        updatePropertyById={updatePropertyById}
        queryParams={params}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        pageSize={pageSize}
        defaultPageSize={DEFAULT_PAGE_SIZE}
        setPageSize={setPageSize}
      />
    </React.Fragment>
  )
}

InactivePropertiesForm.propTypes = {
  authentication: PropTypes.shape({
    token: PropTypes.string,
  }).isRequired,
  deletePropertyById: PropTypes.func.isRequired,
  account: PropTypes.shape({
    id: PropTypes.number.isRequired,
    account_name: PropTypes.string.isRequired,
    account_type: PropTypes.string.isRequired,
    properties: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        property_name: PropTypes.string,
        prospect_number: PropTypes.string,
        property_type: PropTypes.string,
        contact_first_name: PropTypes.string,
        contact_last_name: PropTypes.string,
        phone: PropTypes.string,
        email: PropTypes.string,
      })
    ).isRequired,
  }).isRequired,
  updatePropertyById: PropTypes.func.isRequired,
}

const WrappedComponent = compose(
  withConsumer(AuthenticationConsumer, { propName: 'authentication' }),
  withConsumer(SelectionConsumer, { propName: 'selection' }),
  renderNothingIf(
    ({ selection }) => !(selection && selection.currentAccountId)
  ),
  withEnumValuesAsProp({
    typeName: 'Division',
    propName: 'divisions',
  }),
  withQueryResultAsProp({
    gqlDocument: REPS,
    resultPropName: 'reps',
  }),
  withQueryResultAsProp({
    gqlDocument: ACCOUNT_PROPERTIES_BY_ACCOUNT_ID,
    variables: ({ selection }) => ({
      accountId: selection.currentSubAccountId || selection.currentAccountId,
    }),
    resultPropName: 'account',
  }),
  withMutationAsProp({
    gqlDocument: DELETE_PROPERTY_BY_ID,
    mutationPropName: 'deletePropertyById',
    refetchQueries: [
      {
        gqlDocument: ACCOUNT_PROPERTIES_BY_ACCOUNT_ID,
        variables: ({ selection }) => ({
          accountId:
            selection.currentSubAccountId || selection.currentAccountId,
        }),
      },
    ],
  }),
  withMutationAsProp({
    gqlDocument: UPDATE_PROPERTY_BY_ID,
    mutationPropName: 'updatePropertyById',
  })
)(InactivePropertiesForm)

export { WrappedComponent as InactivePropertiesForm }
