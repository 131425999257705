import React, { useState } from 'react'
import { Modal, Input, message } from 'antd'
import { css, StyleSheet } from 'aphrodite/no-important'
import { useMutation } from '@apollo/client'
import PropTypes from 'prop-types'
import PasswordMessage from './PasswordMessage'
import { UPDATE_USER_PASSWORD } from '../../../graphql/users.graphql'

const classes = StyleSheet.create({
  passwordInput: {
    marginBottom: 5,
  },
})

const defaultValidation = {
  minCharactersLowercase: false,
  minCharactersUppercase: false,
  minCharacters: false,
  minNumbers: false,
}

export default function ChangePasswordModal({ isVisible, onClose, userId }) {
  const [updateUserPassword, { loading }] = useMutation(UPDATE_USER_PASSWORD)
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState()
  const [validation, setValidation] = useState(defaultValidation)

  const handleClose = () => {
    onClose()
    setPassword('')
    setConfirmPassword('')
    setValidation(defaultValidation)
  }

  // updateUserPassword
  const handleChangePassword = (event) => {
    const currentPassword = event.target.value
    setPassword(currentPassword)

    setValidation({
      minCharactersLowercase: currentPassword.search(/[a-z]/) > -1,
      minCharactersUppercase: currentPassword.search(/[A-Z]/) > -1,
      minCharacters: currentPassword.length >= 8,
      minNumbers: currentPassword.search(/[0-9]/) > -1,
    })
  }

  const handleSubmit = async (password, confirmPassword, userId) => {
    try {
      if (password !== confirmPassword) {
        message.error('Password in both inputs must be identical!')
        return
      }

      await updateUserPassword({ variables: { password, userId } })
      message.success('Password was updated!')
      handleClose()
    } catch (e) {
      message.error('Password was not updated!')
    }
  }

  const isValidPassword =
    validation.minCharactersLowercase &&
    validation.minCharactersUppercase &&
    validation.minCharacters &&
    validation.minNumbers

  return (
    <Modal
      title="Change password"
      visible={isVisible}
      onOk={() => handleSubmit(password, confirmPassword, userId)}
      onCancel={() => handleClose()}
      okText="Change password"
      okButtonProps={{ loading, disabled: !isValidPassword }}
    >
      <Input.Password
        className={css(classes.passwordInput)}
        onInput={handleChangePassword}
        value={password}
        placeholder="Enter password"
      />
      <Input.Password
        className={css(classes.passwordInput)}
        onInput={(event) => setConfirmPassword(event.target.value)}
        value={confirmPassword}
        placeholder="Confirm password"
      />
      <PasswordMessage
        message="At least one lowercase character"
        valid={validation.minCharactersLowercase}
      />
      <PasswordMessage
        message="At least one uppercase character"
        valid={validation.minCharactersUppercase}
      />
      <PasswordMessage
        message="At least 8 characters"
        valid={validation.minCharacters}
      />
      <PasswordMessage
        message="At least 1 number"
        valid={validation.minNumbers}
      />
    </Modal>
  )
}

ChangePasswordModal.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  userId: PropTypes.number.isRequired,
}

ChangePasswordModal.defaultProps = {}
