import { Enumeration } from '../../utils'
import { Tabs } from '../../components/rooms/const/tabs'

export const routeKeys = new Enumeration({
  ADMIN: '/admin',
  COLORS: '/colors',
  PROPERTIES: '/properties',
  BUILDINGS: '/accounts/:accountId/property/:propertyId/buildings',
  BUILDINGS_SUB_ACCOUNT:
    '/accounts/:accountId/sub-accounts/:subAccountId/property/:propertyId/buildings',
  FLOOR_COVERING: '/floor-covering',
  SWATCHES: '/floor-covering/swatches',
  SWATCH_ASSIGNMENTS: '/floor-covering/swatch-assignments',
  FLOOR_COVERING_PRODUCT_LINES: '/floor-covering/product-lines',

  // ROOMS PAGES
  ROOMS: '/accounts/:accountId/properties/:propertyId/rooms',
  ROOM_DETAIL: '/accounts/:accountId/properties/:propertyId/rooms/:roomId',
  ROOM_EDIT: `/accounts/:accountId/properties/:propertyId/rooms/:roomId/${Tabs.EDIT}`,
  ROOM_MASKS: `/accounts/:accountId/properties/:propertyId/rooms/:roomId/${Tabs.MASKS}`,
  ROOM_LAYERS: `/accounts/:accountId/properties/:propertyId/rooms/:roomId/${Tabs.FLOORING}`,
  ROOM_SCHEMES: `/accounts/:accountId/properties/:propertyId/rooms/:roomId/${Tabs.SCHEMES}`,

  // ROOMS PAGES
  ROOMS_SUB_ACCOUNT:
    '/accounts/:accountId/sub-accounts/:subAccountId/properties/:propertyId/rooms',
  ROOM_SUB_ACCOUNT:
    '/accounts/:accountId/sub-accounts/:subAccountId/properties/:propertyId/rooms/:roomId',
  ROOM_EDIT_SUB_ACCOUNT: `/accounts/:accountId/sub-accounts/:subAccountId/properties/:propertyId/rooms/:roomId/${Tabs.EDIT}`,
  ROOM_MASKS_SUB_ACCOUNT: `/accounts/:accountId/sub-accounts/:subAccountId/properties/:propertyId/rooms/:roomId/${Tabs.MASKS}`,
  ROOM_LAYERS_SUB_ACCOUNT: `/accounts/:accountId/sub-accounts/:subAccountId/properties/:propertyId/rooms/:roomId/${Tabs.FLOORING}`,
  ROOM_SCHEMES_SUB_ACCOUNT: `/accounts/:accountId/sub-accounts/:subAccountId/properties/:propertyId/rooms/:roomId/${Tabs.SCHEMES}`,

  REPORTS: '/reports',
  USER: '/user',
  SIGN_OUT: '/signout',
})
