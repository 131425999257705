import React from 'react'
import { Form, Select } from 'antd'
import PropTypes from 'prop-types'
import { css, StyleSheet } from 'aphrodite/no-important'
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom'
import { useSelectionContext } from '../../contexts'
import { useSelectedAccount } from '../../hooks/useSelectedAccount'
import { QueryParams } from '../../const/queryParams'
import { Accounts } from '../../const/accounts'

const { Item } = Form
const { Option } = Select

const classes = StyleSheet.create({
  label: {
    minWidth: 130,
  },
  input: {
    minWidth: 240,
  },
})

export default function SelectAccount({
  accounts,
  formClass,
  formItemClass,
  selectClass,
  labelClass,
}) {
  const selection = useSelectionContext()
  const history = useHistory()
  const location = useLocation()
  const selectedAccount = useSelectedAccount(accounts, selection)
  const onBlurSelectionSetting = (value) => {
    selection.setCurrentAccountId(value)
    selection.setCurrentCollectionId(null)
    selection.setCurrentSubAccountId(null)

    const account = accounts.find((account) => account.id === value)
    selection.setHasAccountSubAccount(account?.sub_accounts?.length > 0)

    const params = new URLSearchParams(location.search)

    if (params.has(QueryParams.PAGE)) {
      params.delete(QueryParams.PAGE)
      history.replace({
        pathname: location.pathname,
        search: params.toString(),
      })
    }
  }

  return (
    <Form layout="inline" className={formClass ? css(formClass) : ''}>
      <Item
        labelCol={{ className: css(classes.label, labelClass) }}
        label="Account"
        className={formItemClass ? css(formItemClass) : ''}
      >
        <Select
          className={css(classes.input, selectClass)}
          showSearch
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
          key={selection.currentAccountId}
          defaultValue={selection.currentAccountId}
          onBlur={(value) => onBlurSelectionSetting(value)}
          onChange={(value) => onBlurSelectionSetting(value)}
          onPressEnter={(evt) => evt.target.blur()}
        >
          <Option value={null}>Please make a selection.</Option>
          {accounts
            .filter((acc) => !acc.parent_account)
            .sort((a, b) => a.account_name.localeCompare(b.account_name))
            .map((account) => (
              <Option key={account.id} value={account.id}>
                {account.account_name}
              </Option>
            ))}
        </Select>
      </Item>
      {
        // If selected account have sub accounts, display select for them.
        (() => {
          if (!selectedAccount?.sub_accounts) return null

          let subAccounts = selectedAccount.sub_accounts
          if (selectedAccount && selectedAccount.sub_accounts.length > 0) {
            // Create default project sub account for usnorted properties.
            if (
              selectedAccount.properties &&
              selectedAccount.properties.length > 0 &&
              !selectedAccount.sub_accounts.find(
                (subAccount) => subAccount.id === selectedAccount.id
              )
            ) {
              subAccounts = [
                {
                  account_name: Accounts.DEFAULT_NAME,
                  id: selectedAccount.id,
                },
                ...selectedAccount.sub_accounts,
              ]
            }

            return (
              <Item
                label="Sub Account"
                className={formItemClass ? css(formItemClass) : ''}
                labelCol={{ className: css(classes.label, labelClass) }}
              >
                <Select
                  key={`${selection.currentSubAccountId}`}
                  defaultValue={selection.currentSubAccountId}
                  onBlur={(value) => selection.setCurrentSubAccountId(value)}
                  onChange={(value) => selection.setCurrentSubAccountId(value)}
                  onPressEnter={(evt) => evt.target.blur()}
                  className={css(classes.input, selectClass)}
                >
                  <Option value={null}>Please make a selection.</Option>
                  {subAccounts
                    .sort((a, b) =>
                      a.account_name.localeCompare(b.account_name)
                    )
                    .map((account) => (
                      <Option key={account.id} value={account.id}>
                        {account.account_name}
                      </Option>
                    ))}
                </Select>
              </Item>
            )
          }

          return null
        })()
      }
    </Form>
  )
}

SelectAccount.propTypes = {
  accounts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      account_name: PropTypes.string.isRequired,
    })
  ).isRequired,
  selectClass: PropTypes.object,
  labelClass: PropTypes.object,
  formClass: PropTypes.object,
  formItemClass: PropTypes.object,
}

SelectAccount.defaultProps = {
  selectClass: null,
  formItemClass: null,
  formClass: null,
  labelClass: null,
}
