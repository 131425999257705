import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { css, StyleSheet } from 'aphrodite/no-important'
import { Tooltip } from 'antd'
import ImageSkeleton from '../../common/skeletons/ImageSkeleton'
import { getSwatchesCloudinaryPath } from '../../../functions/getSwatchesCloudinaryPath'
import getLayerCloudinaryPath from '../functions/getLayerCloudinaryPath'

const classes = StyleSheet.create({
  maskImage: {
    backgroundColor: 'darkgray',
    borderBottom: '1px solid lightgray',
    margin: 2,
  },
  layers: {
    display: 'flex',
    flexWrap: 'wrap',
    height: 200,
    padding: 8,
  },
  hidden: {
    display: 'none',
  },
})

export default function SchemesCardPattern({ scheme, account, roomId }) {
  const [isHovered, setIsHovered] = useState(false)

  const renderLayer = (layer) => (
    <Tooltip
      key={layer.id}
      title={`${layer.mask.material.display_name}: ${layer.swatch.name}`}
    >
      <ImageSkeleton
        className={css(classes.maskImage, isHovered && classes.hidden)}
        alt="example"
        style={{ height: 75, width: 75 }}
        src={getSwatchesCloudinaryPath(layer.swatch.image, 'w_75,h_75,c_fill')}
      />
      <ImageSkeleton
        className={css(classes.maskImage, !isHovered && classes.hidden)}
        alt="example"
        style={{ height: 75, width: 75 }}
        src={getLayerCloudinaryPath(
          account.cloud_folder_name,
          layer.image,
          roomId,
          'w_75,h_75,c_fill'
        )}
      />
    </Tooltip>
  )

  const renderColour = ({ mask, color }) => (
    <Tooltip
      key={`${mask.id}.${color.id}`}
      title={`${mask.material.display_name}: ${color.name}`}
    >
      <div
        className={css(classes.maskImage)}
        style={{ height: 75, width: 75, backgroundColor: `#${color.hex}` }}
      />
    </Tooltip>
  )

  return (
    <div
      className={css(classes.layers)}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {scheme.elements.map((element) =>
        element.__typename === 'RoomLayer'
          ? renderLayer(element)
          : renderColour(element)
      )}
    </div>
  )
}

SchemesCardPattern.propTypes = {
  scheme: PropTypes.array.isRequired,
  account: PropTypes.object.isRequired,
  roomId: PropTypes.number.isRequired,
}

SchemesCardPattern.defaultProps = {}
