/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-multi-comp */
import { message } from 'antd'
import { defaultDataIdFromObject, InMemoryCache } from '@apollo/client/cache'
import { onError } from '@apollo/client/link/error'
import { setContext } from '@apollo/client/link/context'
import { createUploadLink } from 'apollo-upload-client'
import jwtDecode from 'jwt-decode'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import inspect from 'util-inspect'

import { ApolloClient, ApolloLink, ApolloProvider } from '@apollo/client'
import {
  CURRENT_APP_CONFIG,
  CURRENT_ROLE,
  TOKEN,
} from '../graphql/authentication.graphql'

const AuthenticationContext = React.createContext()

const AuthenticationConsumer = AuthenticationContext.Consumer

const INITIAL_STATE = {
  token: null,
  decodedToken: null,
  role: null,
  appConfig: null,
}

function mergeRefArray(existing, incoming) {
  const refSet = new Set()
  return [...(existing ?? []), ...(incoming ?? [])].filter(({ __ref }) => {
    const hasRef = refSet.has(__ref)
    if (!hasRef) refSet.add(__ref)
    return !hasRef
  })
}

// Define an HOC that provides an API to state, an injects it into the
// Provider's "value" prop.
class ApolloAuthenticationProvider extends Component {
  static propTypes = {
    children: PropTypes.object.isRequired,
  }

  state = INITIAL_STATE

  apolloClient = new ApolloClient({
    link: ApolloLink.from([
      // See: https://www.apollographql.com/docs/react/recipes/authentication.html#Header
      setContext((_, { headers }) => {
        console.log('ApolloLink.from setContext: this.state:', this.state)
        const { token } = this.state
        // return the headers to the context so httpLink can read them
        return {
          headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : '',
          },
        }
      }),
      onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors) {
          graphQLErrors.forEach(
            ({ message: errorMessage, locations, path }) => {
              console.log(
                `[GraphQL error]: Message: '${errorMessage}', Location: ${inspect(
                  locations,
                  { depth: null, maxArrayLength: null }
                )}, Path: '${path}'`
              )

              if (errorMessage === 'Not authorized!') {
                // If the user ever gets to use the UI to request something unauthorized on the server
                // and the server denies access, log the user out.
                message.error(
                  'Your credentials could not be used to perform the operation, please try signing in again.',
                  7
                )
                this.signOut()
              } else {
                message.error(`The operation failed: ${errorMessage}`, 5)
              }
            }
          )
        }
        if (networkError) {
          console.log(`[Network error]: ${networkError}`)
          message.error(
            'Experiencing network problems. Please try again later.',
            5
          )
        }
      }),
      createUploadLink({
        uri: `${process.env.API_SERVER}/api/graphql_internal`,
        // credentials: 'same-origin',
      }),
    ]),
    cache: new InMemoryCache({
      dataIdFromObject: (object) => {
        const defaultId = defaultDataIdFromObject(object)
        switch (object.__typename) {
          case 'Material':
            return object.element_id
              ? `${object.element_id}: ${object.id}`
              : defaultId
          default:
            return defaultId // fall back to default handling
        }
      },
      typePolicies: {
        Query: {
          fields: {
            swatchesAssignments: {
              // Don't cache separate results based on
              // any of this field's arguments.
              keyArgs: ['filter'],

              // Concatenate the incoming list items with
              // the existing list items.
              merge(
                existing = {
                  swatches: {
                    items: [],
                  },
                  projects: {
                    items: [],
                  },
                  swatchesIdsWithAllProjects: [],
                  projectsIdsWithAllSwatches: [],
                },
                incoming
              ) {
                if (!incoming && existing) {
                  return existing
                }

                // Detect duplicities
                const swatchSet = new Set()
                const filteredSwatches = [
                  ...existing.swatches.items,
                  ...incoming.swatches.items,
                ].filter(({ __ref }) => {
                  const result = !swatchSet.has(__ref)
                  swatchSet.add(__ref)
                  return result
                })

                const swatches = {
                  totalCount: incoming.swatches.totalCount,
                  items: filteredSwatches,
                }

                // Detect duplicities
                const projectSet = new Set()
                const filteredProjects = [
                  ...existing.projects.items,
                  ...incoming.projects.items,
                ].filter(({ __ref }) => {
                  const result = !projectSet.has(__ref)
                  projectSet.add(__ref)
                  return result
                })

                const projects = {
                  totalCount: incoming.projects.totalCount,
                  items: filteredProjects,
                }

                return {
                  swatches,
                  projects,
                  swatchesIdsWithAllProjects: [
                    ...existing.swatchesIdsWithAllProjects,
                    ...incoming.swatchesIdsWithAllProjects,
                  ],
                  projectsIdsWithAllSwatches: [
                    ...existing.projectsIdsWithAllSwatches,
                    ...incoming.projectsIdsWithAllSwatches,
                  ],
                }
              },
            },
            swatches: {
              // Don't cache separate results based on
              // any of this field's arguments.
              keyArgs: ['filter', 'ordering'],
              merge(
                existing = {
                  items: [],
                  totalCount: 0,
                },
                incoming
              ) {
                return {
                  ...existing,
                  ...incoming,
                  items: mergeRefArray(existing.items, incoming.items),
                }
              },
            },
            colors: {
              // Don't cache separate results based on
              // any of this field's arguments.
              keyArgs: ['filter'],
              merge(
                existing = {
                  items: [],
                  totalCount: 0,
                },
                incoming
              ) {
                return {
                  ...existing,
                  ...incoming,
                  items: mergeRefArray(existing.items, incoming.items),
                }
              },
            },
            layers: {
              // Don't cache separate results based on
              // any of this field's arguments.
              keyArgs: ['filter'],
              merge(
                existing = {
                  items: [],
                  totalCount: 0,
                },
                incoming
              ) {
                return {
                  ...existing,
                  ...incoming,
                  items: mergeRefArray(existing.items, incoming.items),
                }
              },
            },
            swatchesAssignmentsResultInfos: {
              // Don't cache separate results based on
              // any of this field's arguments.
              keyArgs: ['filter'],
              merge(
                existing = {
                  items: [],
                  totalCount: 0,
                },
                incoming
              ) {
                return {
                  ...existing,
                  ...incoming,
                  items: mergeRefArray(existing.items, incoming.items),
                }
              },
            },
            SwatchVendorProductInfo: {
              keyFields: ['id'],
            },
          },
        },
      },
    }),
  })

  // Connect to the server and send credentials.
  // If they are accepted, update the token and resolve to true.
  // Otherwise resolve to false.
  signIn = async (email, password) => {
    const { data } = await this.apolloClient.query({
      fetchPolicy: 'network-only',
      query: TOKEN,
      variables: { email, password },
    })

    const { token } = data

    if (!token) {
      // The server returned us a null token, authentication was rejected.
      // Resolve to false.
      return false
    }

    // The token from the server is expected to be decodable.
    let decodedToken
    try {
      decodedToken = jwtDecode(token)
    } catch (error) {
      message.error('The server returned a malformed token.', 5)
    }

    this.setState({
      token,
      decodedToken,
    })

    // This query will have an authorization header that uses the token we stored via setState
    // earlier.
    const currentRoleResponse = await this.apolloClient.query({
      fetchPolicy: 'network-only',
      query: CURRENT_ROLE,
    })

    if (!currentRoleResponse.data || !currentRoleResponse.data.currentRole) {
      // We didn't get a proper response, reset, and reject login.
      this.setState(INITIAL_STATE)
      console.error('Received invalid role.')
      return false
    }

    this.setState({
      role: currentRoleResponse.data.currentRole,
    })

    // This query will have an authorization header that uses the token we stored via setState
    // earlier.
    const currentAppConfigResponse = await this.apolloClient.query({
      fetchPolicy: 'network-only',
      query: CURRENT_APP_CONFIG,
      variables: { appName: 'color-visualizer-dashboard' },
    })

    if (
      !currentAppConfigResponse.data ||
      !currentAppConfigResponse.data.currentAppConfig ||
      !currentAppConfigResponse.data.currentAppConfig.app_config_data
    ) {
      this.setState(INITIAL_STATE)
      console.error('Received invalid app config.')
      return false
    }

    const appConfig = JSON.parse(
      currentAppConfigResponse.data.currentAppConfig.app_config_data
    )

    this.setState({
      appConfig,
    })

    return true
  }

  signOut = () => this.setState(INITIAL_STATE)

  render() {
    return (
      <ApolloProvider client={this.apolloClient}>
        <AuthenticationContext.Provider
          value={{
            token: this.state.token,
            decodedToken: this.state.decodedToken,
            role: this.state.role,
            appConfig: this.state.appConfig,
            signIn: this.signIn,
            signOut: this.signOut,
          }}
        >
          {this.props.children}
        </AuthenticationContext.Provider>
      </ApolloProvider>
    )
  }
} // class ApolloAuthenticationProvider

export { ApolloAuthenticationProvider, AuthenticationConsumer }
