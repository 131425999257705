import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { css, StyleSheet } from 'aphrodite/no-important'
import { Button } from 'antd'

const classes = StyleSheet.create({
  cardMaterialText: {
    fontSize: '13px',
    fontWeight: '350',
    height: 40,
    overflow: 'hidden',
  },
  cardMaterialTextFullHeight: {
    height: 'auto',
  },
  cardMaterialSpan: {
    color: '#009688',
    whiteSpace: 'pre-wrap',
  },
  showMoreButton: {
    display: 'block',
    margin: '4px auto',
  },
})

export default function MaterialList({ materials, masonryGridRef }) {
  const [materialWrapperRef, setMaterialWrapperRef] = useState(null)
  const [materialInnerRef, setMaterialInnerRef] = useState(null)
  const [isOpen, setIsOpen] = useState()

  const [materialHeights, setMaterialHeights] = useState({
    wrapper: null,
    inner: null,
  })

  useEffect(() => {
    if (
      materials &&
      materials.length > 0 &&
      materialWrapperRef &&
      materialInnerRef
    ) {
      setMaterialHeights({
        wrapper: materialWrapperRef.getBoundingClientRect().height,
        inner: materialInnerRef.getBoundingClientRect().height,
      })
    }
  }, [materials, materialWrapperRef, materialInnerRef])

  const handleShowButtonClick = () => {
    setIsOpen((prev) => setIsOpen(!prev))
    // eslint-disable-next-line no-unused-expressions
    masonryGridRef.current?.forceLayout()
  }

  return (
    <>
      <div
        className={css(
          classes.cardMaterialText,
          isOpen && classes.cardMaterialTextFullHeight
        )}
        ref={setMaterialWrapperRef}
      >
        {materials.length === 1 ? 'Material:' : 'Materials:'}
        <span
          className={css(classes.cardMaterialSpan)}
          ref={setMaterialInnerRef}
        >
          {` ${materials.map((material) => material.display_name).join(', ')}`}
        </span>
      </div>
      {materialHeights.wrapper < materialHeights.inner && (
        <Button
          className={css(classes.showMoreButton)}
          onClick={() => handleShowButtonClick()}
        >
          {isOpen ? 'Show less' : 'Show more'}
        </Button>
      )}
    </>
  )
}

MaterialList.propTypes = {
  materials: PropTypes.array.isRequired,
  masonryGridRef: PropTypes.object.isRequired,
}
MaterialList.defaultProps = {}
