import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Modal, message } from 'antd'

import { useMutation } from '@apollo/client'
import ChoosePointFromPicture from '../../common/ChoosePointFromPicture'
import {
  UPDATE_VIEW_ELEMENT_POINT_POSITION,
  DELETE_VIEW_ELEMENT_POINT_POSITION,
} from '../../../graphql/views.graphql'
import { clearCacheByPlanQuery } from '../../../utils/hemi'
import { executeQuery } from '../../../utils/restQueryBuilder'

export default function ViewElementDescriptionPointModal({
  viewElement,
  onClose,
  account,
  planName,
}) {
  const [currentPoint, setCurrentPoint] = useState(null)
  const [updateViewElementPointPosition, { loading: updateLoading }] =
    useMutation(UPDATE_VIEW_ELEMENT_POINT_POSITION, {
      update(cache, { data }) {
        cache.modify({
          id: `ViewElement:${viewElement.id}`,
          fields: {
            point_position() {
              return data
            },
          },
        })
      },
    })

  const [deleteViewElementPointPosition, { loading: deleteLoading }] =
    useMutation(DELETE_VIEW_ELEMENT_POINT_POSITION, {
      update(cache, { data }) {
        cache.modify({
          id: `ViewElement:${viewElement.id}`,
          fields: {
            point_position() {
              return data
            },
          },
        })
      },
    })

  const maskUrl = useMemo(
    () =>
      account?.cloud_folder_name && viewElement?.src
        ? `https://res.cloudinary.com/renderinghouse/image/upload/app/${account.cloud_folder_name}/images/${viewElement.src}`
        : null,
    [viewElement?.src]
  )

  useEffect(() => {
    if (viewElement?.point_position) {
      setCurrentPoint({
        x: viewElement?.point_position.x,
        y: viewElement?.point_position.y,
      })
    }
  }, [viewElement?.point_position])

  const handleClose = () => {
    setCurrentPoint(null)
    onClose()
  }

  const handleDeletePoint = async (viewElement) => {
    try {
      await deleteViewElementPointPosition({
        variables: { id: viewElement.id },
      })

      message.success('Point was deleted!')
      handleClose()
    } catch (e) {
      console.error(e)
      message.error('Point was not deleted!')
    }
  }

  const handleUpdateMaskPointPosition = async (viewElement) => {
    try {
      await updateViewElementPointPosition({
        variables: { id: viewElement.id, point: currentPoint },
      })
      message.success('Point was updated successfully!')
      handleClose()
    } catch (e) {
      console.error(e)
      message.error('Point was not updated!')
    }
  }

  const handleSubmitForm = async (viewElement) => {
    // If point is same do no nothing
    if (
      currentPoint?.x === viewElement?.point_position?.x &&
      currentPoint?.y === viewElement?.point_position?.y
    ) {
      handleClose()
      return
    }

    if (currentPoint === null) {
      await handleDeletePoint(viewElement)
    } else {
      await handleUpdateMaskPointPosition(viewElement)
    }

    await executeQuery(
      clearCacheByPlanQuery({
        clientName: account.account_name,
        planName,
      })
    )
  }

  return (
    <Modal
      title="Edit Mask Desription Point"
      visible={!!viewElement}
      className="full-screen"
      width="100%"
      bodyStyle={{
        height: 'calc(100vh - 10px - 55px - 53px)',
      }}
      onOk={() => handleSubmitForm(viewElement)}
      onCancel={handleClose}
      okText="Edit Label Position"
      okButtonProps={{ loading: updateLoading || deleteLoading }}
    >
      <ChoosePointFromPicture
        maskUrl={maskUrl}
        currentPoint={currentPoint}
        setCurrentPoint={setCurrentPoint}
        onDeletePoint={() => setCurrentPoint(null)}
      />
    </Modal>
  )
}

ViewElementDescriptionPointModal.propTypes = {
  viewElement: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  account: PropTypes.object.isRequired,
  planName: PropTypes.string.isRequired,
}
ViewElementDescriptionPointModal.defaultProps = {
  viewElement: null,
}
