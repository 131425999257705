import React from 'react'
import PropTypes from 'prop-types'
import Skeleton from './Skeleton'

export default function TextSkeleton({ children, ...rest }) {
  const overrideProps = {
    ...rest,
    style: { width: '100%', height: 24, borderRadius: '8px', ...rest?.style },
  }

  return children ? (
    <span {...rest}>{children}</span>
  ) : (
    <Skeleton {...overrideProps} />
  )
}

TextSkeleton.propTypes = {
  children: PropTypes.object.isRequired,
}
