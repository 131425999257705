import React from 'react'
import { css } from 'aphrodite/no-important'
import { classes } from './AssignementTableStyles'

export default function EmptyCell() {
  return (
    <div
      className={css(
        classes.cell,
        classes.rightBorder,
        classes.bottomBorder,
        classes.darkBorder
      )}
    />
  )
}

EmptyCell.propTypes = {}
EmptyCell.defaultProps = {}
