/* eslint-disable react/destructuring-assignment,camelcase */
import React from 'react'
import PropTypes from 'prop-types'
import { StyleSheet, css } from 'aphrodite'
import { Card, Icon, Popconfirm } from 'antd'
import { compose } from 'recompose'
import { Swatch } from './Swatch'
import {
  withConsumer,
  withMutationAsProp,
  withQueryResultAsProp,
} from '../../utils'
import { AuthenticationConsumer, SelectionConsumer } from '../../contexts'
import {
  ACCOUNT_SCHEME_SELECTIONS,
  DELETE_SCHEME,
  GET_SCHEME_BY_ID,
} from '../../graphql/colors.graphql'

//
// Styles
//

const styles = StyleSheet.create({
  card: {
    width: '50%',
    float: 'left',
    marginLeft: '7%',
  },
  cardAction: {
    background: '#fafafa',
    cursor: 'pointer',
    width: '50%',
    float: 'left',
    textAlign: 'center',
    color: 'rgba(0,0,0,.45)',
    transition: 'all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1)',
  },
  cardActionLeft: {
    borderRight: '1px solid #e8e8e8',
    ':hover': {
      color: '#f5222d',
    },
  },
  cardActionRight: {
    ':hover': {
      color: '#1890ff',
    },
  },
  cardActionsContainer: {
    clear: 'both',
    borderTop: '1px solid #e8e8e8',
    listStyle: 'none',
    padding: 0,
  },
  cardIcon: {
    padding: '16px',
  },
  cardSubtext: {
    fontSize: '13px',
    fontWeight: '350',
    color: '#676464',
  },
  swatchAdd: {
    textAlign: 'center',
    transition: 'all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1)',
    cursor: 'pointer',
    ':hover': {
      color: '#1890ff',
    },
    fontSize: '24px',
    padding: '0 4px',
  },
  swatchContainer: {},
  swatchCard: {
    display: 'flex',
    width: '45%',
    float: 'left',
    margin: '5px 1px 5px 1px',
  },
  selectionsContainer: {
    height: 'auto',
    padding: '10px',
  },
})

const generateSchemeSelectionCard = (selection) => (
  <Swatch
    key={`${selection.id}`}
    color={{
      id: selection.color.id,
      identifier: selection.color.identifier,
      name: selection.color.name,
      colorId: selection.color.id,
      hex: selection.color.hex,
      identifier_prefix:
        selection.color.color_vendor_product_info.product_identifier_prefix,
    }}
    material={selection.material.display_name}
    selected={false}
    size="large"
    dirName=""
  />
) // generateSchemeSelectionCard

const SchemeCard = (props) => {
  const { scheme } = props
  const { scheme_elements } = scheme
  const schemeElements = scheme_elements
  return (
    <Card
      className={css(styles.card)}
      bodyStyle={{ padding: '0px' }}
      headStyle={{ height: '55px' }}
      title={props.title}
    >
      <div
        className={css(styles.selectionsContainer)}
        id="schemeSelectionsContainer"
      >
        {[...schemeElements]
          .sort((a, b) =>
            a.material.display_name.localeCompare(
              b.material.display_name,
              undefined,
              { numeric: true }
            )
          )
          .map((schemeElement) => generateSchemeSelectionCard(schemeElement))}
      </div>
      <ul className={css(styles.cardActionsContainer)}>
        <Popconfirm
          title="Are you sure you want to delete this scheme?"
          placement="bottomRight"
          onConfirm={props.handleDelete}
          okText="Yes"
          okType="danger"
          cancelText="No"
        >
          <li className={css(styles.cardAction, styles.cardActionLeft)}>
            <Icon className={css(styles.cardIcon)} type="delete" />
          </li>
        </Popconfirm>
        <li className={css(styles.cardAction, styles.cardActionRight)}>
          <div onClick={props.handleEdit}>
            <Icon className={css(styles.cardIcon)} type="edit" />
          </div>
        </li>
      </ul>
    </Card>
  )
}

SchemeCard.propTypes = {
  title: PropTypes.string.isRequired,
  handleDelete: PropTypes.func.isRequired,
  handleEdit: PropTypes.func.isRequired,
  scheme: PropTypes.object.isRequired,
}

const WrappedComponent = compose(
  withConsumer(AuthenticationConsumer, { propName: 'authentication' }),
  withConsumer(SelectionConsumer, { propName: 'selection' }),
  withQueryResultAsProp({
    gqlDocument: GET_SCHEME_BY_ID,
    variables: ({ scheme_id }) => ({ id: scheme_id }),
    resultPropName: 'scheme',
    propName: 'scheme',
  }),
  withMutationAsProp({
    gqlDocument: DELETE_SCHEME,
    mutationPropName: 'deleteScheme',
    variables: ({
      // eslint-disable-next-line camelcase
      scheme_id,
    }) => ({ scheme_id }),
    refetchQueries: [
      {
        gqlDocument: ACCOUNT_SCHEME_SELECTIONS,
        variables: ({ selection }) => ({ id: selection.currentAccountId }),
      },
    ],
  })
)(SchemeCard)

export { WrappedComponent as SchemeCard }
