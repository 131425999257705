import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Modal, message } from 'antd'
import { useMutation } from '@apollo/client'
import { getMaskCloudinaryPath } from '../functions/getMaskCloudinaryPath'
import {
  UPDATE_MASK_POINT_POSITION,
  DELETE_MASK_POINT_POSITION,
} from '../../../graphql/fcMasks.graphql'
import ChoosePointFromPicture from '../../common/ChoosePointFromPicture'

export default function MaskDescriptionPointModal({
  mask,
  onClose,
  account,
  roomId,
}) {
  const [currentPoint, setCurrentPoint] = useState(null)
  const [updateMaskPointPosition, { loading: updateLoading }] = useMutation(
    UPDATE_MASK_POINT_POSITION,
    {
      update(cache, { data }) {
        cache.modify({
          id: `LayerMask:${mask.id}`,
          fields: {
            pointPosition() {
              return data
            },
          },
        })
      },
    }
  )

  const [deleteMaskPointPosition, { loading: deleteLoading }] = useMutation(
    DELETE_MASK_POINT_POSITION,
    {
      update(cache, { data }) {
        cache.modify({
          id: `LayerMask:${mask.id}`,
          fields: {
            pointPosition() {
              return data
            },
          },
        })
      },
    }
  )

  const maskUrl = useMemo(
    () =>
      account?.cloud_folder_name && mask?.image && roomId
        ? getMaskCloudinaryPath(
            account.cloud_folder_name,
            mask?.image,
            roomId,
            ''
          )
        : null,
    [account?.cloud_folder_name, mask?.image, roomId]
  )

  useEffect(() => {
    if (mask?.pointPosition) {
      setCurrentPoint({
        x: mask?.pointPosition.x,
        y: mask?.pointPosition.y,
      })
    }
  }, [mask?.pointPosition])

  const handleClose = () => {
    setCurrentPoint(null)
    onClose()
  }

  const handleDeletePoint = async (mask) => {
    try {
      await deleteMaskPointPosition({
        variables: { maskId: mask.id },
      })

      message.success('Point was deleted!')
      handleClose()
    } catch (e) {
      console.error(e)
      message.error('Point was not deleted!')
    }
  }

  const handleUpdateMaskPointPosition = async (mask) => {
    try {
      await updateMaskPointPosition({
        variables: { maskId: mask.id, point: currentPoint },
      })
      message.success('Point was updated successfully!')
      handleClose()
    } catch (e) {
      console.error(e)
      message.error('Point was not updated!')
    }
  }

  const handleSubmitForm = async (mask) => {
    // If point is same do no nothing
    if (
      currentPoint?.x === mask?.pointPosition?.x &&
      currentPoint?.y === mask?.pointPosition?.y
    ) {
      handleClose()
      return
    }

    if (currentPoint === null) {
      await handleDeletePoint(mask)
    } else {
      await handleUpdateMaskPointPosition(mask)
    }
  }

  return (
    <Modal
      title="Edit Mask Desription Point"
      visible={!!mask}
      className="full-screen"
      width="100%"
      bodyStyle={{
        height: 'calc(100vh - 10px - 55px - 53px)',
      }}
      onOk={() => handleSubmitForm(mask)}
      onCancel={handleClose}
      okText="Edit Label Position"
      okButtonProps={{ loading: updateLoading || deleteLoading }}
    >
      <ChoosePointFromPicture
        maskUrl={maskUrl}
        currentPoint={currentPoint}
        setCurrentPoint={setCurrentPoint}
        onDeletePoint={() => setCurrentPoint(null)}
      />
    </Modal>
  )
}

MaskDescriptionPointModal.propTypes = {
  mask: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  account: PropTypes.object.isRequired,
  roomId: PropTypes.number.isRequired,
}
MaskDescriptionPointModal.defaultProps = {
  mask: null,
}
