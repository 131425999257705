/* eslint-disable camelcase */
import { Button, Form, Modal, Row, message, Tooltip, Input } from 'antd'

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { StyleSheet, css } from 'aphrodite'
import { compose, withStateHandlers } from 'recompose'

import { AuthenticationConsumer } from '../../contexts'

import { SHARE_PROPERTY_BY_ID } from '../../graphql/properties.graphql'

import { withConsumer } from '../../utils'
import { withMutationAsProp } from '../../utils/enhancers'

const styles = StyleSheet.create({
  button: {
    float: 'right',
  },
  buttonCancel: {
    marginRight: '8px',
  },
  buttonRow: {
    marginTop: '35px',
  },
  formItem: {
    marginBottom: '5px',
  },
  label: {
    fontWeight: '500',
  },
})

const formItemLayout = {
  labelCol: {
    // 1600px ≤ width
    xxl: {
      span: 8,
    },
    // 1200px ≤ width < 1600px
    xl: {
      span: 8,
    },
    // 992px ≤ width < 1200px
    lg: {
      span: 8,
    },
    // 768px ≤ width < 992px
    md: {
      span: 8,
    },
    // 576px ≤ width < 768px
    sm: {
      span: 8,
    },
    // width < 576px and also default setting
    xs: {
      span: 24,
    },
  },
  wrapperCol: {
    xxl: {
      span: 16,
    },
    xl: {
      span: 16,
    },
    lg: {
      span: 16,
    },
    md: {
      span: 16,
    },
    sm: {
      span: 16,
    },
    xs: {
      span: 24,
    },
  },
}

const hasErrors = (fieldsError) =>
  Object.keys(fieldsError).some((field) => fieldsError[field])

class SharePropertyModal extends Component {
  render = () => {
    const {
      authentication,
      form,
      property,
      propertyURL,
      sharePropertyById,
      isModalVisible,
      showModal,
      closeModal,
    } = this.props

    const { getFieldDecorator, getFieldsError } = form
    return (
      <React.Fragment>
        <Tooltip title="Invite ">
          <Button type="primary" icon="mail" size="large" onClick={showModal} />
        </Tooltip>
        <Modal
          title="Invite via Email"
          visible={isModalVisible}
          onCancel={closeModal}
          width="470px"
          footer={null}
          style={{ top: '50px' }}
        >
          <Form onSubmit={this.handleAddPaletteSubmit} hideRequiredMark>
            <Form.Item
              {...formItemLayout}
              label={
                <span className={css(styles.label)}>Sender&apos;s Name</span>
              }
              className={css(styles.formItem)}
            >
              {getFieldDecorator('senderName', {
                rules: [{ required: true, message: 'Please enter a name.' }],
                initialValue:
                  (authentication &&
                    authentication.decodedToken &&
                    authentication.decodedToken.first_name) ||
                  '',
              })(<Input />)}
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              label={
                <span className={css(styles.label)}>Sender&apos;s Email</span>
              }
              className={css(styles.formItem)}
            >
              {getFieldDecorator('senderEmail', {
                rules: [
                  {
                    type: 'email',
                    message: 'Please enter a valid email address.',
                  },
                  { required: true, message: 'This field is required.' },
                ],
                initialValue:
                  (authentication &&
                    authentication.decodedToken &&
                    authentication.decodedToken.email) ||
                  '',
              })(<Input />)}
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              label={
                <span className={css(styles.label)}>Recipient Email(s)</span>
              }
              className={css(styles.formItem)}
            >
              {getFieldDecorator('toField', {
                rules: [{ required: true, message: 'This field is required.' }],
                initialValue:
                  (property && property.rep && property.rep.email) || '',
              })(<Input />)}
            </Form.Item>
            <Row className={css(styles.buttonRow)}>
              <Button
                className={css(styles.button)}
                type="primary"
                htmlType="submit"
                disabled={hasErrors(getFieldsError())}
                onClick={
                  () =>
                    form.validateFields(async (err, values) => {
                      const { senderName, senderEmail, toField } = values
                      const toEmails = toField.split(/[,;]/)
                      if (!err) {
                        console.log('Received values of form: ', values)
                        closeModal()
                        await sharePropertyById({
                          id: property.id,
                          projectName: property.name,
                          projectId: property.project_id,
                          senderName,
                          senderEmail,
                          toEmails,
                          propertyURL,
                        })
                        message.success('Shared link sent!')
                      }
                    }) // validateFields()
                }
              >
                Send
              </Button>
              <Button
                onClick={closeModal}
                className={css(styles.button, styles.buttonCancel)}
              >
                Cancel
              </Button>
            </Row>
          </Form>
        </Modal>
      </React.Fragment>
    )
  } // render()
} // class SharePropertyModal

SharePropertyModal.propTypes = {
  authentication: PropTypes.shape({
    decodedToken: PropTypes.shape({
      email: PropTypes.string.isRequired,
      first_name: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  form: PropTypes.object.isRequired,
  property: PropTypes.object.isRequired,
  propertyURL: PropTypes.string.isRequired,
  sharePropertyById: PropTypes.func.isRequired,
  isModalVisible: PropTypes.bool.isRequired,
  showModal: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
}

const WrappedComponent = compose(
  withConsumer(AuthenticationConsumer, { propName: 'authentication' }),
  withStateHandlers(
    ({ initialIsModalVisible = false }) => ({
      isModalVisible: initialIsModalVisible,
    }),
    {
      showModal: (/* previous state */) => (/* handler input */) => ({
        isModalVisible: true,
      }),
      closeModal: (/* previous state */) => (/* handler input */) => ({
        isModalVisible: false,
      }),
    }
  ),
  withMutationAsProp({
    gqlDocument: SHARE_PROPERTY_BY_ID,
    mutationPropName: 'sharePropertyById',
  }),
  Form.create()
)(SharePropertyModal)

export { WrappedComponent as SharePropertyModal }
