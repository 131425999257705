import {
  Form,
  Input,
  InputNumber,
  Select,
  Checkbox,
  Button,
  Upload,
} from 'antd'

import changeCase from 'change-case'

import React from 'react'
import { StyleSheet } from 'aphrodite'
import { css } from 'aphrodite/no-important'

const { Item } = Form
const { Option } = Select

const styles = StyleSheet.create({
  uploadGuideButton: {
    margin: '0 10px',
  },
})

const generateUnsafeAddFormItemOptionalInput = (
  getFieldDecorator,
  fieldKey,
  placeholder
) => (
  <Item>
    {getFieldDecorator(fieldKey, {
      // Don't validate after every onChange (we will therefore only
      // validate when the user presses the "Add User" button.)
      validateTrigger: null,
    })(<Input placeholder={placeholder} />)}
  </Item>
)

const generateAddFormItemOptionalInput = (
  getFieldDecorator,
  fieldKey,
  placeholder
) => (
  <Item>
    {getFieldDecorator(fieldKey, {
      // Don't validate after every onChange (we will therefore only
      // validate when the user presses the "Add User" button.)
      validateTrigger: null,
    })(
      <Input
        placeholder={placeholder}
        onChange={
          // Sanitize input troublesome for URLS: https://secure.n-able.com/webhelp/NC_9-1-0_SO_en/Content/SA_docs/API_Level_Integration/API_Integration_URLEncoding.html
          (e) => {
            e.target.value = e.target.value.replace(/[$&+,/:;=?@"'<>#%]/, '')
          }
        }
      />
    )}
  </Item>
)

const generateUnsafeAddFormItemRequiredInput = (
  getFieldDecorator,
  fieldKey,
  placeholder
) => (
  <Item>
    {getFieldDecorator(fieldKey, {
      rules: [
        {
          required: true,
          message: 'This field is required.',
        },
      ],
      // Don't validate after every onChange (we will therefore only
      // validate when the user presses the "Add User" button.)
      validateTrigger: null,
    })(<Input placeholder={placeholder} />)}
  </Item>
)

const generateAddFormItemRequiredInput = (
  getFieldDecorator,
  fieldKey,
  placeholder
) => (
  <Item>
    {getFieldDecorator(fieldKey, {
      rules: [
        {
          required: true,
          message: 'This field is required.',
        },
      ],
      // Don't validate after every onChange (we will therefore only
      // validate when the user presses the "Add User" button.)
      validateTrigger: null,
    })(
      <Input
        placeholder={placeholder}
        onChange={
          // Sanitize input troublesome for URLS: https://secure.n-able.com/webhelp/NC_9-1-0_SO_en/Content/SA_docs/API_Level_Integration/API_Integration_URLEncoding.html
          (e) => {
            e.target.value = e.target.value.replace(/[$&+,/:;=?@"'<>#%]/, '')
          }
        }
      />
    )}
  </Item>
)

const generatePaintGuideUploadAndDeleteItem = (props) => {
  const {
    paintGuideName,
    paintGuideURL,
    label = 'Paint Guide',
    itemLayout,
    handleUpload,
    accountName,
    propertyId,
    handleDelete,
  } = props
  const generateURL = (name, url) => (
    <a
      target="_blank"
      rel="noopener noreferrer"
      href={`${process.env.FRONT_END_APP_SERVER}/fetchPaintGuide?=${url}`}
    >
      {name}
    </a>
  )
  return (
    <Item
      label={label}
      {...itemLayout}
      style={{ display: 'inline-flex', width: '100%' }}
    >
      {paintGuideName
        ? generateURL(paintGuideName, paintGuideURL)
        : 'No Paint Guide'}
      <Upload
        accept=".pdf"
        style={{ margin: '0px 20px' }}
        customRequest={async ({ file, onSuccess }) => {
          await handleUpload({
            propertyId,
            accountName,
            file,
            fileSize: file.size,
          })
          onSuccess('ok')
        }}
        showUploadList={false}
      >
        <Button type="primary" className={css(styles.uploadGuideButton)}>
          {paintGuideName ? 'Update Guide' : 'Upload Guide'}
        </Button>
      </Upload>
      <Button
        type="primary"
        onClick={async () => {
          await handleDelete({ propertyId, fileURL: paintGuideURL })
        }}
        disabled={!paintGuideURL || !paintGuideName}
      >
        Delete Guide
      </Button>
    </Item>
  )
}

const generateDownloadBrochureButton = ({
  itemLayout,
  handleDownloadBrochure,
  isLoading,
}) => {
  return (
    <Item
      label="Brochure with all schemes"
      {...itemLayout}
      style={{ display: 'inline-flex', width: '100%' }}
    >
      <Button
        type="primary"
        onClick={handleDownloadBrochure}
        loading={isLoading}
      >
        Download brochure
      </Button>
    </Item>
  )
}

const generateAddFormItemRequiredSelect = (
  getFieldDecorator,
  optionValues,
  allowNull,
  fieldKey,
  label,
  disabled = false
) => (
  <Item label={label}>
    {getFieldDecorator(fieldKey, {
      // Don't validate after every onChange (we will therefore only
      // validate when the user presses the "Add User" button.)
      validateTrigger: null,
      initialValue: allowNull ? null : optionValues[0],
    })(
      <Select disabled={disabled}>
        {allowNull && <Option value={null}>Select an option.</Option>}
        {optionValues.map((optionValue) => (
          <Option key={`${optionValue}`} value={optionValue}>
            {changeCase.titleCase(optionValue)}
          </Option>
        ))}
      </Select>
    )}
  </Item>
)

// Don't sanitize.
const generateUnsafeUpdateFormItemInput = (
  handler,
  itemLayout,
  id,
  originalFieldValue,
  fieldKey,
  label,
  disabled = false
) => (
  <Item label={label} {...itemLayout}>
    <Input
      key={`${id}${originalFieldValue}`}
      defaultValue={originalFieldValue}
      disabled={disabled}
      onBlur={
        // Convert empty or whitespace string input to a null.
        (evt) =>
          handler(
            id,
            fieldKey,
            label,
            evt.target.value === '' ? null : evt.target.value
          )
      }
      onPressEnter={(evt) => evt.target.blur()}
    />
  </Item>
)

const generateUpdateFormItemInput = (
  handler,
  itemLayout,
  id,
  originalFieldValue,
  fieldKey,
  label,
  disabled = false,
  error
) => (
  <Item
    label={label}
    {...itemLayout}
    validateStatus={error ? 'error' : ''}
    help={error}
  >
    <Input
      key={`${id}${originalFieldValue}`}
      defaultValue={originalFieldValue}
      disabled={disabled}
      onChange={
        // Sanitize input troublesome for URLS: https://secure.n-able.com/webhelp/NC_9-1-0_SO_en/Content/SA_docs/API_Level_Integration/API_Integration_URLEncoding.html
        (e) => {
          e.target.value = e.target.value.replace(/[$&+,/:;=?@"'<>#%]/, '')
        }
      }
      onBlur={
        // Convert empty or whitespace string input to a null.
        (evt) =>
          handler(
            id,
            fieldKey,
            label,
            evt.target.value === '' ? null : evt.target.value
          )
      }
      onPressEnter={(evt) => evt.target.blur()}
    />
  </Item>
)

const generateUpdateFormItemInputNumber = (
  handler,
  itemLayout,
  id,
  originalFieldValue,
  fieldKey,
  label,
  { min, max, step }
) => (
  <Item label={label} {...itemLayout}>
    <InputNumber
      key={`${id}${originalFieldValue}`}
      defaultValue={originalFieldValue}
      onBlur={
        // Convert empty or whitespace string input to a null.
        (evt) =>
          handler(
            id,
            fieldKey,
            label,
            evt.target.value === '' ? null : parseFloat(evt.target.value)
          )
      }
      onPressEnter={(evt) => evt.target.blur()}
      min={min}
      max={max}
      step={step}
    />
  </Item>
)

const generateUpdateFormItemSelect = (
  handler,
  itemLayout,
  id,
  options,
  optionValueFunc,
  optionValueDisplayFunc,
  allowNull,
  originalFieldValue,
  fieldKey,
  label,
  disabled = false,
  additionalJsx = null
) => (
  <Item label={label} {...itemLayout}>
    <Select
      key={`${id}${originalFieldValue}`}
      defaultValue={originalFieldValue}
      onBlur={(value) => handler(id, fieldKey, label, value)}
      onChange={(value) => handler(id, fieldKey, label, value)}
      onPressEnter={(evt) => evt.target.blur()}
      disabled={disabled}
    >
      {allowNull && <Option value={null}>Select an option.</Option>}
      {options.map((option) => (
        <Option
          key={`${id}${originalFieldValue}${optionValueFunc(option)}`}
          value={optionValueFunc(option)}
        >
          {optionValueDisplayFunc(option)}
        </Option>
      ))}
    </Select>
    {additionalJsx}
  </Item>
)

const generateDivisionCheckboxes = (
  label,
  itemLayout,
  selectedDivisions,
  handleDivisionChange,
  userId,
  existingDivisions
) => (
  <Item label={label} {...itemLayout}>
    <div>
      {existingDivisions.map((division) => (
        <Checkbox
          key={`${userId + division}`}
          checked={selectedDivisions.includes(division)}
          onChange={(val) => {
            const newDivisions = [...selectedDivisions]
            if (!val.target.checked) {
              newDivisions.splice(newDivisions.indexOf(division), 1)
            } else {
              newDivisions.push(division)
            }
            handleDivisionChange(userId, newDivisions)
          }}
        >
          {changeCase.titleCase(division)}
        </Checkbox>
      ))}
    </div>
  </Item>
)

const generatePropertyURL = (account, property) => {
  const propertyURL = encodeURI(
    `${process.env.FRONT_END_APP_SERVER}/account/${
      account.parent_account
        ? `${account.parent_account.account_name}/subAccount/${account.account_name}`
        : account.account_name
    }/project/${property.id}${property.is_shopping_cart_mode ? '/home' : ''}`
  )

  return propertyURL
}

const generateBuildingURL = (account, property, building) => {
  const buildingURL = encodeURI(
    `${process.env.FRONT_END_APP_SERVER}/account/${
      account.parent_account
        ? `${account.parent_account.account_name}/subAccount/${account.account_name}`
        : account.account_name
    }/project/${property.id}/building/${building.id}${
      property.is_shopping_cart_mode ? '/design' : ''
    }`
  )

  return buildingURL
}

export {
  generateUnsafeAddFormItemOptionalInput,
  generateAddFormItemOptionalInput,
  generateUnsafeAddFormItemRequiredInput,
  generatePaintGuideUploadAndDeleteItem,
  generateAddFormItemRequiredInput,
  generateAddFormItemRequiredSelect,
  generateDownloadBrochureButton,
  generateUnsafeUpdateFormItemInput,
  generateUpdateFormItemInput,
  generateUpdateFormItemInputNumber,
  generateUpdateFormItemSelect,
  generateDivisionCheckboxes,
  generatePropertyURL,
  generateBuildingURL,
}
