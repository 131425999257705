import React from 'react'
import { Icon, Popconfirm, Tooltip } from 'antd'

export default function SchemesCardActions({
  onEditClick,
  onRemoveClick,
  scheme,
  onShowPreview,
}) {
  return [
    <Tooltip title="Show preview" key="preview">
      <Icon
        type="zoom-in"
        style={{ color: 'rgba(0, 0, 0, 0.65)' }}
        onClick={() => onShowPreview(scheme)}
      />
    </Tooltip>,
    <Tooltip title="Edit Scheme" key="edit">
      <Icon
        type="edit"
        theme="twoTone"
        twoToneColor="#1890ff"
        onClick={() => onEditClick(scheme)}
      />
    </Tooltip>,
    <Popconfirm
      key="delete"
      placement="left"
      title={`Are you sure you want to delete scheme ${scheme.name}?`}
      onConfirm={() => onRemoveClick(scheme)}
      okText="Yes"
      cancelText="No"
    >
      <Tooltip title="Delete schema">
        <Icon type="delete" theme="twoTone" twoToneColor="#ff4d4f" />
      </Tooltip>
    </Popconfirm>,
  ]
}
