import React from 'react'
import { useQuery } from '@apollo/client'
import { css, StyleSheet } from 'aphrodite/no-important'
import PropTypes from 'prop-types'
import { BuildingsForm } from '../components/forms/properties/BuildingsForm'
import Spinner from '../components/common/Spinner'
import { PROPERTY_AND_BUILDERS_BY_PROPERTY_ID } from '../graphql/properties.graphql'
import PageHeader from '../components/common/PageHeader'
import { useAccountAndProperty } from '../hooks/useAccountAndProperty'
import Alert from '../components/common/Alert'
import { useAccountSelectionFromParams } from '../hooks/useAccountSelectionFromParams'

const classes = StyleSheet.create({
  root: {
    position: 'relative',
    height: '100%',
  },
})

export default function BuildingPage({ match, history }) {
  const { params } = match
  const currentAccountId = params.subAccountId || params.accountId
  const {
    currentAccount,
    currentProperty,
    loading: accountsLoading,
    error: accountsError,
  } = useAccountAndProperty(Number(currentAccountId), Number(params.propertyId))
  const {
    loading: propertyLoading,
    data: propertyData,
    error: buildingsError,
  } = useQuery(PROPERTY_AND_BUILDERS_BY_PROPERTY_ID, {
    variables: {
      propertyId: Number(params.propertyId),
    },
  })

  useAccountSelectionFromParams()

  return (
    <div className={css(classes.root)}>
      <PageHeader
        title="Buildings"
        subTitle={currentProperty}
        onBack={() => history.goBack()}
      />
      {(accountsLoading || propertyLoading) && <Spinner />}
      {(accountsError || buildingsError) && <Alert margin />}
      {currentAccount && propertyData && (
        <BuildingsForm
          account={currentAccount}
          property={propertyData.property}
        />
      )}
    </div>
  )
}

BuildingPage.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
}
