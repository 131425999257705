import React from 'react'
import { Form, Input } from 'antd'
import PropTypes from 'prop-types'

export default function ProductLineForm({ form, productLine }) {
  const { getFieldDecorator } = form

  return (
    <Form>
      <Form.Item label="Product Name">
        {getFieldDecorator('product_name', {
          initialValue: productLine?.product_name || '',
          rules: [
            {
              required: true,
              message: 'Please input product name!',
            },
          ],
        })(<Input />)}
      </Form.Item>
      <Form.Item label="Product Group">
        {getFieldDecorator('product_group', {
          initialValue: productLine?.product_group || '',
          rules: [
            {
              required: true,
              message: 'Please input product group name!',
            },
          ],
        })(<Input />)}
      </Form.Item>
      <Form.Item label="Product Identifier Prefix">
        {getFieldDecorator('product_identifier_prefix', {
          initialValue: productLine?.product_identifier_prefix || '',
        })(<Input />)}
      </Form.Item>
    </Form>
  )
}

ProductLineForm.propTypes = {
  form: PropTypes.object.isRequired,
  productLine: PropTypes.object,
}

ProductLineForm.defaultProps = {
  productLine: null,
}
