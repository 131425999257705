import React, { useState } from 'react'
import { Form, Modal, message } from 'antd'
import PropTypes from 'prop-types'

import { useMutation } from '@apollo/client'
import RoomForm from '../RoomForm'
import { ADD_ROOM, GET_ROOMS } from '../../../graphql/fcRooms.graphql'

function RoomModal({ form, visible, handleCloseModal, propertyId, account }) {
  const [file, setFile] = useState(null)
  const [baseFile, setBaseFile] = useState(null)
  const [addRoom, { loading }] = useMutation(ADD_ROOM, {
    refetchQueries: [GET_ROOMS],
  })

  const handleClose = () => {
    handleCloseModal()
    setFile(null)
    form.resetFields()
  }

  const handleAddRoom = async (propertyId, room) => {
    const roomToAdd = {
      projectId: propertyId,
      ...room,
    }
    try {
      await addRoom({
        variables: {
          accountId: account.id,
          input: roomToAdd,
        },
      })
      message.success('Room was successfully created!')
    } catch (e) {
      message.error('Room was not added!')
    } finally {
      handleClose()
    }
  }

  const handleSubmit = async (propertyId) => {
    let roomData = {}
    try {
      roomData = await form.validateFields()
    } catch (e) {
      message.error('Form is not valid!')
      return
    }

    roomData = {
      ...roomData,
      ...(file ? { imageFile: file } : {}),
      ...(baseFile ? { baseImageFile: baseFile } : {}),
    }
    await handleAddRoom(propertyId, roomData)
  }

  return (
    <Modal
      title="Add Room"
      visible={visible}
      onOk={() => handleSubmit(propertyId)}
      onCancel={() => handleClose()}
      okText="Add"
      okButtonProps={{ loading }}
    >
      <RoomForm
        form={form}
        account={account}
        setFile={setFile}
        setBaseFile={setBaseFile}
        visible={visible}
        propertyId={propertyId}
      />
    </Modal>
  )
}

RoomModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  propertyId: PropTypes.number.isRequired,
  account: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
}

export default Form.create()(RoomModal)
