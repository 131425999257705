import React from 'react'
import { StyleSheet, css } from 'aphrodite'
import { PageHeader as AntdPageHeader } from 'antd'
import PropTypes from 'prop-types'

const classes = StyleSheet.create({
  root: {
    backgroundColor: '#fff',
    marginBottom: 8,
    borderBottom: '1px solid rgb(235, 237, 240)',
  },
})

export default function PageHeader({
  title,
  onBack,
  subTitle,
  rootClass,
  ...props
}) {
  return (
    <AntdPageHeader
      className={css(classes.root, rootClass)}
      onBack={onBack}
      title={title}
      subTitle={subTitle || ''}
      {...props}
    />
  )
}

PageHeader.propTypes = {
  title: PropTypes.oneOf([PropTypes.string, PropTypes.object]).isRequired,
  onBack: PropTypes.func,
  subTitle: PropTypes.string,
  rootClass: PropTypes.object,
}

PageHeader.defaultProps = {
  onBack: null,
  subTitle: '',
  rootClass: null,
}
