/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-multi-comp */
import React, { useContext, useMemo, useState } from 'react'
import PropTypes from 'prop-types'

const SelectionContext = React.createContext()

const SelectionConsumer = SelectionContext.Consumer

// Define an HOC that provides an API to state, an injects it into the
// Provider's "value" prop.
function SelectionProvider({ children }) {
  const [currentAccountId, setCurrentAccountId] = useState(null)
  const [currentPaletteId, setCurrentPaletteId] = useState(null)
  const [currentSchemeId, setCurrentSchemeId] = useState(null)
  const [currentCollectionId, setCurrentCollectionId] = useState(null)
  const [currentSubAccountId, setCurrentSubAccountId] = useState(null)
  const [hasAccountSubAccount, setHasAccountSubAccount] = useState(null)

  const value = useMemo(
    () => ({
      currentAccountId,
      setCurrentAccountId,
      currentPaletteId,
      setCurrentPaletteId,
      currentSchemeId,
      setCurrentSchemeId,
      currentCollectionId,
      setCurrentCollectionId,
      currentSubAccountId,
      setCurrentSubAccountId,
      hasAccountSubAccount,
      setHasAccountSubAccount,
    }),
    [
      currentAccountId,
      setCurrentAccountId,
      currentPaletteId,
      setCurrentPaletteId,
      currentSchemeId,
      setCurrentSchemeId,
      currentCollectionId,
      setCurrentCollectionId,
      currentSubAccountId,
      setCurrentSubAccountId,
      hasAccountSubAccount,
      setHasAccountSubAccount,
    ]
  )

  return (
    <SelectionContext.Provider value={value}>
      {children}
    </SelectionContext.Provider>
  )
}

SelectionProvider.propTypes = {
  children: PropTypes.object.isRequired,
}

export const useSelectionContext = () => useContext(SelectionContext)

export { SelectionProvider, SelectionConsumer }
