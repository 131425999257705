import * as F from './functions'

export const query = (url) => ({
  route: url,
  params: [],
})

export const addPath = (...items) =>
  F.overrideProp('route', (route) => `${route}/${items.join('/')}`)

export const addParam = (key, ...values) =>
  F.appendToProp('params', [key, values.join()])

export const urlFromQuery = (_query) => {
  const paramString = _query.params.map(F.join('=')).join('&')
  return _query.route + (paramString && '?') + paramString
}

export const executeQuery = (_query, message = 'Fetching') => {
  const url = urlFromQuery(_query)
  console.log(`${message}:`, url)
  return fetch(url).catch((error) =>
    console.log(`${message} ERROR:`, url, error)
  )
}
