import { Button, Form, Icon, Popconfirm, Table } from 'antd'
import { css, StyleSheet } from 'aphrodite/no-important'
import jwtDecode from 'jwt-decode'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { compose } from 'recompose'
import { AuthenticationConsumer } from '../../../contexts'

import {
  ADD_USER,
  DELETE_USER_BY_ID,
  UPDATE_USER_BY_ID,
  UPDATE_USER_PASSWORD,
  UPDATE_USER_ROLE,
  USERS,
  ROLES,
  UPDATE_USER_DIVISIONS_BY_ID,
} from '../../../graphql/users.graphql'

import {
  generateAddFormItemOptionalInput,
  generateUnsafeAddFormItemRequiredInput,
  generateUnsafeUpdateFormItemInput,
  generateUpdateFormItemInput,
  generateUpdateFormItemSelect,
  withConsumer,
  withMutationAsProp,
  withQueryResultAsProp,
  generateDivisionCheckboxes,
  withEnumValuesAsProp,
} from '../../../utils'

const { Item } = Form

const styles = StyleSheet.create({
  addUserForm: {
    border: '1px solid #d8dee2',
    borderRadius: '10px',
    padding: '0px 10px 0px 10px',
    margin: 'auto',
  },
  updateUserForm: {
    padding: '0px 10px 0px 10px',
    margin: 'auto',
  },
})

const updateUserFormItemLayout = {
  labelCol: {
    // 1600px ≤ width
    xxl: {
      span: 2,
    },
    // 1200px ≤ width < 1600px
    xl: {
      span: 2,
    },
    // 992px ≤ width < 1200px
    lg: {
      span: 2,
    },
    // 768px ≤ width < 992px
    md: {
      span: 4,
    },
    // 576px ≤ width < 768px
    sm: {
      span: 4,
    },
    // width < 576px and also default setting
    xs: {
      span: 24,
    },
  },
  wrapperCol: {
    xxl: {
      span: 22,
    },
    xl: {
      span: 22,
    },
    lg: {
      span: 22,
    },
    md: {
      span: 20,
    },
    sm: {
      span: 20,
    },
    xs: {
      span: 24,
    },
  },
}

class UsersForm extends Component {
  static propTypes = {
    form: PropTypes.object.isRequired,
    addUser: PropTypes.func.isRequired,
    authentication: PropTypes.shape({
      appConfig: PropTypes.object.isRequired,
      token: PropTypes.string.isRequired,
    }).isRequired,
    deleteUserById: PropTypes.func.isRequired,
    updateUserById: PropTypes.func.isRequired,
    updateUserPassword: PropTypes.func.isRequired,
    updateUserRole: PropTypes.func.isRequired,
    updateUserDivisions: PropTypes.func.isRequired,
    users: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        first_name: PropTypes.string,
        last_name: PropTypes.string,
      })
    ).isRequired,
    roles: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string,
      })
    ).isRequired,
    divisions: PropTypes.arrayOf(PropTypes.string).isRequired,
  }

  handleAddUserButtonPress = () => {
    const { addUser, form, updateUserPassword } = this.props
    // eslint-disable-next-line camelcase
    form.validateFieldsAndScroll(
      async (errors, { email, password, first_name, last_name }) => {
        if (!errors) {
          console.log('Using form values: ', {
            email,
            password,
            first_name,
            last_name,
          })
          form.resetFields()
          // Create user. If the user is created, but also throws an error or we
          // lose the connection, we will not set the password, and the user will
          // stay in a the default NEEDS_PASSWORD state.
          const result = await addUser({
            input: {
              email,
              first_name,
              last_name,
            },
          })
          console.log('addUser result:', result)
          // If everything goes well, set the password via a special mutation, not
          // the regular generated mutations.
          if (
            result &&
            result.data &&
            result.data.addUser &&
            result.data.addUser.id
          ) {
            updateUserPassword({
              userId: result.data.addUser.id,
              password,
            })
          }
        }
      }
    )
  }

  handleDeleteUserButtonPress = (userId) => {
    console.log('handleDeleteUserButtonPress: userId:', userId)
    const { deleteUserById } = this.props
    deleteUserById({
      id: userId,
    })
  }

  handleUpdateUserFormItemInputChange = (userId, targetField, label, value) => {
    console.log(
      `handleUpdateUserFormItemInputChange: userId: ${userId}, targetField: ${targetField}, label: ${label}, value: ${value}`
    )
    // Convert email address to lowercase before sending as user update.
    if (targetField === 'email') {
      value = value.toLowerCase()
    }
    const { updateUserById } = this.props
    updateUserById({
      id: userId,
      input: { [targetField]: value },
    })
  }

  handleUpdateUserPasswordInputChange = (userId, targetField, label, value) => {
    const { updateUserPassword } = this.props
    console.log(
      `handleUpdateUserPasswordInputChange: userId: ${userId}, targetField: ${targetField}, label: ${label}, value: hidden`
    )
    updateUserPassword({
      userId,
      password: value,
    })
  }

  handleUpdateUserRoleInputChange = (userId, targetField, label, roleId) => {
    const { updateUserRole } = this.props
    console.log(
      `handleUpdateUserPasswordInputChange: userId: ${userId}, targetField: ${targetField}, label: ${label}, roleId: ${roleId}`
    )
    updateUserRole({
      userId,
      roleId,
    })
  }

  handleUserDivisionsChange = (userId, divisions) => {
    const { updateUserDivisions } = this.props
    updateUserDivisions({
      userId,
      input: {
        divisions,
      },
    })
  }

  render() {
    const { authentication, form, roles, users, divisions } = this.props
    const { getFieldDecorator } = form
    const { appConfig, token } = authentication
    const payload = jwtDecode(token)
    const { id: tokenUserId } = payload

    return (
      <React.Fragment>
        <Form
          key="addUserForm"
          layout="inline"
          className={css(styles.addUserForm)}
        >
          {generateUnsafeAddFormItemRequiredInput(
            getFieldDecorator,
            'email',
            '* Email address'
          )}
          {generateUnsafeAddFormItemRequiredInput(
            getFieldDecorator,
            'password',
            '* Initial password'
          )}
          {generateAddFormItemOptionalInput(
            getFieldDecorator,
            'first_name',
            'First Name'
          )}
          {generateAddFormItemOptionalInput(
            getFieldDecorator,
            'last_name',
            'Last Name'
          )}
          <Item>
            <Button type="primary" onClick={this.handleAddUserButtonPress}>
              <Icon type="user-add" />
              Add User
            </Button>
          </Item>
        </Form>
        <Table
          key="usersTable"
          size="middle"
          bordered
          pagination={false}
          dataSource={users
            .filter(
              (user) =>
                user.role.id !== 1 ||
                (appConfig && appConfig.showSuperAdminUsersAndRoleOption)
            )
            .map((user) => ({ key: user.id, ...user }))}
          columns={[
            {
              title: 'Email',
              dataIndex: 'email',
              key: 'email',
            },
            {
              title: 'First Name',
              dataIndex: 'first_name',
              key: 'first_name',
            },
            {
              title: 'Last Name',
              dataIndex: 'last_name',
              key: 'last_name',
            },
            {
              title: 'Role',
              dataIndex: 'role.name',
              key: 'role.name',
            },
            {
              key: 'delete',
              width: 150,
              render: (text, user) => (
                <Popconfirm
                  placement="bottomRight"
                  title="Delete this user?"
                  onConfirm={() => this.handleDeleteUserButtonPress(user.id)}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button type="danger">
                    <Icon type="delete" />
                  </Button>
                </Popconfirm>
              ),
            },
          ]}
          expandedRowRender={(user) => (
            <Form key="updateUserForm" className={css(styles.updateUserForm)}>
              {generateUnsafeUpdateFormItemInput(
                this.handleUpdateUserFormItemInputChange,
                updateUserFormItemLayout,
                user.id,
                user.email,
                'email',
                'Email'
              )}
              {
                // Users should be allowed to update ONLY _their own_ password field.
                user.id === tokenUserId
                  ? generateUnsafeUpdateFormItemInput(
                      this.handleUpdateUserPasswordInputChange,
                      updateUserFormItemLayout,
                      user.id,
                      '****',
                      'password',
                      'Password'
                    )
                  : // Display input as disabled (read-only). Provide a no-op handler.
                    generateUnsafeUpdateFormItemInput(
                      () => {},
                      updateUserFormItemLayout,
                      user.id,
                      '****',
                      'password',
                      'Password',
                      true
                    )
              }
              {generateUpdateFormItemInput(
                this.handleUpdateUserFormItemInputChange,
                updateUserFormItemLayout,
                user.id,
                user.first_name,
                'first_name',
                'First Name'
              )}
              {generateUpdateFormItemInput(
                this.handleUpdateUserFormItemInputChange,
                updateUserFormItemLayout,
                user.id,
                user.last_name,
                'last_name',
                'Last Name'
              )}
              {
                // Letting a user change _their own_ role is probably just asking for trouble.
                // Let's not do that. Display as disabled (read-only). Provide a no-op handler.
                user.id === tokenUserId
                  ? generateUpdateFormItemSelect(
                      () => {},
                      updateUserFormItemLayout,
                      user.id,
                      roles.filter(
                        (role) =>
                          role.id !== 1 ||
                          (appConfig &&
                            appConfig.showSuperAdminUsersAndRoleOption)
                      ),
                      (role) => role.id,
                      (role) => role.name,
                      false,
                      user.role.id,
                      'role',
                      'Role',
                      true
                    )
                  : generateUpdateFormItemSelect(
                      this.handleUpdateUserRoleInputChange,
                      updateUserFormItemLayout,
                      user.id,
                      roles.filter(
                        (role) =>
                          role.id !== 1 ||
                          (appConfig &&
                            appConfig.showSuperAdminUsersAndRoleOption)
                      ),
                      (role) => role.id,
                      (role) => role.name,
                      false,
                      user.role.id,
                      'role',
                      'Role'
                    )
              }
              {generateDivisionCheckboxes(
                'Subcribe',
                updateUserFormItemLayout,
                user.divisions,
                this.handleUserDivisionsChange,
                user.id,
                divisions
              )}
            </Form>
          )}
        />
      </React.Fragment>
    )
  }
}

const WrappedComponent = compose(
  withConsumer(AuthenticationConsumer, { propName: 'authentication' }),
  withQueryResultAsProp({
    gqlDocument: USERS,
    resultPropName: 'users',
  }),
  withQueryResultAsProp({
    gqlDocument: ROLES,
    resultPropName: 'roles',
  }),
  withMutationAsProp({
    gqlDocument: ADD_USER,
    mutationPropName: 'addUser',
    refetchQueries: [{ gqlDocument: USERS }],
  }),
  withMutationAsProp({
    gqlDocument: DELETE_USER_BY_ID,
    mutationPropName: 'deleteUserById',
    refetchQueries: [{ gqlDocument: USERS }],
  }),
  withMutationAsProp({
    gqlDocument: UPDATE_USER_BY_ID,
    mutationPropName: 'updateUserById',
  }),
  withMutationAsProp({
    gqlDocument: UPDATE_USER_PASSWORD,
    mutationPropName: 'updateUserPassword',
  }),
  withMutationAsProp({
    gqlDocument: UPDATE_USER_ROLE,
    mutationPropName: 'updateUserRole',
  }),
  withMutationAsProp({
    gqlDocument: UPDATE_USER_DIVISIONS_BY_ID,
    mutationPropName: 'updateUserDivisions',
  }),
  withEnumValuesAsProp({
    typeName: 'Division',
    propName: 'divisions',
  }),

  Form.create()
)(UsersForm)

export { WrappedComponent as UsersForm }
