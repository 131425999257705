import { Button, Form, Icon, Popconfirm, Table, Input } from 'antd'
import { css, StyleSheet } from 'aphrodite/no-important'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { compose } from 'recompose'

import { AuthenticationConsumer } from '../../../contexts'

import {
  COLOR_LIBRARY,
  ADD_BLEND_ELEMENT,
  DELETE_COLOR_BY_ID,
  UPDATE_COLOR_INFO,
} from '../../../graphql/colors.graphql'

import {
  withConsumer,
  withMutationAsProp,
  generateUpdateFormItemInput,
} from '../../../utils'

const { Search } = Input

const styles = StyleSheet.create({
  addPropertyForm: {
    border: '1px solid #d8dee2',
    borderRadius: '10px',
    padding: '0px 10px 0px 10px',
    margin: 'auto',
    marginLeft: '10px',
    marginRight: '10px',
  },
  updatePropertyForm: {
    padding: '0px 10px 0px 10px',
    margin: 'auto',
  },
  tableStyle: {
    padding: '0px 10px 0px 10px',
  },
})

class ColorsForm extends Component {
  static propTypes = {
    authentication: PropTypes.shape({
      token: PropTypes.string,
      signIn: PropTypes.func,
      signOut: PropTypes.func,
    }).isRequired,
    addBlendElement: PropTypes.func.isRequired,
    colorLibraries: PropTypes.arrayOf(PropTypes.object).isRequired,
    collectionPrefix: PropTypes.string.isRequired,
    updateColorLibraryById: PropTypes.func.isRequired,
    deleteColorLibraryById: PropTypes.func.isRequired,
    removeColorFromColorsArray: PropTypes.func.isRequired,
  }

  state = {
    lowerSearchText: '',
  }

  handleDeleteColorById = async (colorId) => {
    console.log('handleDeleteColorById: colorId:', colorId)
    const { deleteColorLibraryById, removeColorFromColorsArray } = this.props
    const deleteResult = await deleteColorLibraryById({
      id: colorId,
    })
    if (deleteResult && deleteResult.data) {
      removeColorFromColorsArray(deleteResult.data.deleteColorLibraryById)
    }
  }

  handleUpdateColorFormItemInputChange = async (
    colorId,
    targetField,
    label,
    value
  ) => {
    console.log(
      `handleUpdateProductLinesFormItemInputChange: colorId: ${colorId}, targetField: ${targetField}, label: ${label}, value: ${value}`
    )
    // eslint-disable-next-line
    const { updateColorLibraryById, colorLibraries, updateColorsArray } = this.props
    const updateResult = await updateColorLibraryById({
      id: colorId,
      input: { [targetField]: value },
    })
    if (updateResult && updateResult.data) {
      updateColorsArray(updateResult.data.updateColorLibraryById)
    }
  }

  setLowerSearchText = (newLowerSearchText) => {
    this.setState({
      lowerSearchText: newLowerSearchText,
    })
  }

  render() {
    const { colorLibraries, collectionPrefix, updateColorLibraryById } =
      this.props

    const { lowerSearchText } = this.state

    const paginationConfig = {
      pagination: {
        pageSize: 10,
        showTotal: (total) => `Total Colors: ${total}`,
        pageSizeOptions: ['10', '50', '100', '250'],
        showSizeChanger: true,
      },
    }
    return (
      <React.Fragment>
        <Search
          style={{
            margin: '15px 0 15px 20px',
            width: '400px',
          }}
          placeholder="Search by Color Name or Identifier"
          onChange={(event) => {
            const newLowerSearchText = event.target.value.trim().toLowerCase()
            this.setLowerSearchText(newLowerSearchText)
          }}
        />
        <Table
          {...paginationConfig}
          className={css(styles.tableStyle)}
          key="propertiesTable"
          size="small"
          bordered
          dataSource={
            lowerSearchText === ''
              ? colorLibraries.map((color) => ({ key: color.id, ...color }))
              : colorLibraries
                  .filter(
                    (color) =>
                      (color.name &&
                        color.name.toLowerCase().includes(lowerSearchText)) ||
                      color.identifier.includes(lowerSearchText)
                  )
                  .map((color) => ({ key: color.id, ...color }))
          }
          columns={[
            {
              title: 'Color Name',
              dataIndex: 'name',
              key: 'name',
              width: '40%',
            },
            {
              title: 'Identifier',
              dataIndex: 'identifier',
              key: 'identifier',
              width: '15%',
              render: (identifier) => {
                if (identifier !== null && collectionPrefix !== null) {
                  return `${collectionPrefix} ${identifier}`
                }
                if (identifier !== null) {
                  return `${identifier}`
                }
                return null
              },
            },
            {
              title: 'Color',
              dataIndex: 'hex',
              key: 'hex',
              width: '5%',
              render: (hex) => {
                if (hex !== null) {
                  return (
                    <div
                      style={{
                        backgroundColor: `#${hex}`,
                        height: '40px',
                        minWidth: '40px',
                        border: 'solid 3px white',
                        outline: 'solid 1px black',
                      }}
                    />
                  )
                }
                return null
              },
            },
            {
              title: 'Remarks/Comments',
              dataIndex: 'notes',
              key: 'notes',
              width: '30%',
            },

            {
              title: 'Delete',
              key: 'delete',
              width: '5%',
              render: (text, color) => (
                <Popconfirm
                  placement="bottomRight"
                  title="Delete this color?"
                  onConfirm={() => this.handleDeleteColorById(color.id)}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button type="danger">
                    <Icon type="delete" />
                  </Button>
                </Popconfirm>
              ),
            },
          ]}
          expandedRowRender={
            // Each record is a Property.
            (record) => (
              <React.Fragment>
                <Form
                  key="updateProductLinesForm"
                  className={css(styles.updatePropertyForm)}
                >
                  {generateUpdateFormItemInput(
                    this.handleUpdateColorFormItemInputChange,
                    updateColorLibraryById,
                    record.id,
                    record.name,
                    'name',
                    'Color Name'
                  )}
                  {generateUpdateFormItemInput(
                    this.handleUpdateColorFormItemInputChange,
                    updateColorLibraryById,
                    record.id,
                    record.identifier,
                    'identifier',
                    'Identifier Code'
                  )}
                  {generateUpdateFormItemInput(
                    this.handleUpdateColorFormItemInputChange,
                    updateColorLibraryById,
                    record.id,
                    record.hex,
                    'hex',
                    'Hex Code'
                  )}
                  {generateUpdateFormItemInput(
                    this.handleUpdateColorFormItemInputChange,
                    updateColorLibraryById,
                    record.id,
                    record.notes,
                    'notes',
                    'Remarks/Comments'
                  )}
                </Form>
              </React.Fragment>
            )
          }
        />
      </React.Fragment>
    )
  }
}

const WrappedComponent = compose(
  withConsumer(AuthenticationConsumer, { propName: 'authentication' }),

  withMutationAsProp({
    gqlDocument: ADD_BLEND_ELEMENT,
    mutationPropName: 'addBlendElement',
    refetchQueries: [
      {
        gqlDocument: COLOR_LIBRARY,
      },
    ],
  }),
  withMutationAsProp({
    gqlDocument: UPDATE_COLOR_INFO,
    mutationPropName: 'updateColorLibraryById',
  }),
  withMutationAsProp({
    gqlDocument: DELETE_COLOR_BY_ID,
    mutationPropName: 'deleteColorLibraryById',
  })
)(ColorsForm)

export { WrappedComponent as ColorsForm }
