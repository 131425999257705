import React from 'react'
import PropTypes from 'prop-types'
import ButtonGroup from 'antd/es/button/button-group'
import { Button, Popconfirm, Tooltip, message } from 'antd'
import { useMutation } from '@apollo/client'
import {
  DELETE_SWATCH,
  GET_SWATCHES,
} from '../../../graphql/fcSwatches.graphql'

export default function SwatchTableActions({ swatch, handleEditSwatch }) {
  const [deleteRoom, { loading }] = useMutation(DELETE_SWATCH)

  const handleDeleteSwatch = async (swatchId) => {
    try {
      await deleteRoom({
        variables: {
          id: swatchId,
        },
        refetchQueries: [GET_SWATCHES],
      })
      message.success('Swatch was successfully deleted!')
    } catch (e) {
      console.error(e)
      message.error('Swatch was not deleted!')
    }
  }

  return (
    <ButtonGroup>
      <Tooltip title="Edit Swatch">
        <Button
          onClick={() => handleEditSwatch(swatch)}
          type="primary"
          icon="edit"
          size="large"
        />
      </Tooltip>
      <Popconfirm
        placement="left"
        title={`Are you sure you want to delete swatch ${swatch.name}?`}
        onConfirm={() => handleDeleteSwatch(swatch.id)}
        okText="Yes"
        cancelText="No"
      >
        <Tooltip title="Delete swatch">
          <Button type="danger" icon="delete" size="large" loading={loading} />
        </Tooltip>
      </Popconfirm>
    </ButtonGroup>
  )
}

SwatchTableActions.propTypes = {
  swatch: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  handleEditSwatch: PropTypes.func.isRequired,
}
