import React from 'react'
import PropTypes from 'prop-types'
import { Table as AntdTable } from 'antd'
import { css, StyleSheet } from 'aphrodite'

const classes = StyleSheet.create({
  table: {
    paddingBottom: 30,
  },
})

export const TABLE_ITEMS_PER_PAGE = 10

export default function Table({
  loading,
  data,
  columns,
  emptyText,
  currentPage,
  setCurrentPage,
  totalCount,
  setTableOrder,
  itemsPerPage,
}) {
  const handleSortChange = (_, __, sorter) => {
    setTableOrder([
      {
        name: sorter.field,
        order: sorter.order === 'ascend' ? 'ASC' : 'DESC',
      },
    ])
  }

  // If only one item is at table page, and you remove this item
  // We need to set current page lower by 1
  // otherwise it displays empty table page
  if (data && data.length === 0 && currentPage > 0) {
    setCurrentPage((currentPage) => currentPage - 1)
  }

  return (
    <AntdTable
      className={css(classes.table)}
      defaultSortOrder="ascend"
      sortDirections={['ascend', 'descend', 'ascend']}
      loading={loading}
      dataSource={data}
      columns={columns}
      locale={{ emptyText }}
      size="middle"
      bordered
      pagination={{
        current: currentPage + 1,
        pageSize: itemsPerPage,
        total: totalCount,
        onChange: (page) => setCurrentPage(page - 1),
      }}
      onChange={setTableOrder && handleSortChange}
    />
  )
}

Table.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  loading: PropTypes.bool,
  emptyText: PropTypes.string,
  currentPage: PropTypes.number.isRequired,
  setCurrentPage: PropTypes.func.isRequired,
  itemsPerPage: PropTypes.number.isRequired,
  totalCount: PropTypes.number,
  setTableOrder: PropTypes.func,
}
Table.defaultProps = {
  loading: false,
  emptyText: 'No Data',
  totalCount: 0,
  setTableOrder: () => {},
}
