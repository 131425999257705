/* eslint-disable react/destructuring-assignment */
import React from 'react'
import PropTypes from 'prop-types'
import { Popconfirm, Checkbox, Spin } from 'antd'
import { StyleSheet, css } from 'aphrodite'
import MultiGrid from 'react-virtualized/dist/commonjs/MultiGrid'
import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer'

const styles = StyleSheet.create({
  bodyRow: {
    justifyContent: 'center',
  },
  leftRow: {
    justifyContent: 'left',
    borderLeft: '1px solid #eee',
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    padding: '8px',
    borderRight: '1px solid #eee',
    backgroundColor: 'white',
    borderLeft: '1px solid #eee',
    borderTop: '1px solid #eee',
  },
  topRow: {
    alignContent: 'center',
    borderTop: '1px solid #eee',
    borderBottom: '1px solid #eee',
  },
  schemeHeader: {
    transform: 'rotate(270deg)',
    fontFamily: 'Helvetica Neue, Helvetica, Arial, sans-serif,',
    fontWeight: 600,
    fontSize: '16px',
  },
  projectHeader: {
    marginTop: '15px',
    fontFamily: 'Helvetica Neue, Helvetica, Arial, sans-serif,',
    fontWeight: 600,
    fontSize: '16px',
    textAlign: 'center',
    paddingRight: '500px',
    paddingTop: '20px',
    paddingBottom: '10px',
    '@media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 1)':
      {
        paddingRight: '20px',
      },
  },
  autoSizerWrapper: {
    flex: '1 1 auto',
    height: '70vh',
    width: '85vw',
    '@media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 1)':
      {
        height: '60vh',
        width: '75vw',
      },
  },
})

class AssignmentsTable extends React.Component {
  static propTypes = {
    loading: PropTypes.bool.isRequired,
    anchor: PropTypes.string.isRequired,
    rowDataSource: PropTypes.arrayOf(PropTypes.object),
    columnDataSource: PropTypes.arrayOf(PropTypes.object),
    cellData: PropTypes.func.isRequired,
    onToggle: PropTypes.func.isRequired,
  }

  static defaultProps = {
    rowDataSource: [],
    columnDataSource: [],
  }

  //
  // Event handlers
  //

  handleCheckChange = (row, column) =>
    this.props.onToggle(row, column, this.props.cellData(row, column).checked)

  //
  // Render
  //

  renderCell = ({ columnIndex, rowIndex, key, style }) => {
    const cellStyle = [styles.row]
    const row = this.props.rowDataSource[rowIndex - 1]
    const column = this.props.columnDataSource[columnIndex - 1]
    let cellContent

    if (columnIndex === 0 && rowIndex === 0) {
      // "Anchor" cell -- top left
      cellStyle.push(styles.topRow, styles.leftRow)
      cellContent = this.props.anchor
    } else if (columnIndex === 0) {
      // Leftmost column, corresponding to rowHeader values
      cellStyle.push(styles.leftRow)
      cellContent = row.name
    } else if (rowIndex === 0) {
      // Topmost row, corresponding to columnHeader values
      cellStyle.push(styles.topRow)
      cellContent = column.name
    } else {
      // Table body
      cellStyle.push(styles.bodyRow)

      const { checked, disabled, deselectConfirmation } = this.props.cellData(
        row,
        column
      )

      if (checked && deselectConfirmation) {
        cellContent = (
          <Popconfirm
            title={deselectConfirmation}
            onConfirm={() => this.handleCheckChange(row, column)}
            placement="bottomRight"
            okType="danger"
            okText="Yes"
            cancelText="No"
          >
            <Checkbox disabled={disabled} checked={checked} />
          </Popconfirm>
        )
      } else {
        cellContent = (
          <Checkbox
            disabled={false}
            checked={this.props.cellData(row, column).checked}
            onChange={() => this.handleCheckChange(row, column)}
          />
        )
      }
    }

    return (
      <div key={key} style={style} className={css(...cellStyle)}>
        {cellContent}
      </div>
    )
  }

  renderLoaded = () => (
    <div
      className={css(styles.autoSizerWrapper)}
      style={{ flex: '1 1 auto', height: '60vh', width: '80vw' }}
    >
      <AutoSizer>
        {({ height, width }) => (
          <table>
            <tr>
              <td />
              <th className={css(styles.projectHeader)}>Project Name</th>
            </tr>
            <tr>
              <th className={css(styles.schemeHeader)}>Schemes</th>
              <td>
                <MultiGrid
                  data={{
                    rows: this.props.rowDataSource,
                    columns: this.props.columnDataSource,
                  }}
                  fixedColumnCount={1}
                  fixedRowCount={1}
                  enableFixedColumnScroll
                  enableFixedRowScroll
                  hideTopRightGridScrollbar
                  hideBottomLeftGridScrollbar
                  scrollToColumn={0}
                  scrollToRow={0}
                  cellRenderer={this.renderCell}
                  columnWidth={({ index }) => (index === 0 ? 180 : 120)}
                  columnCount={this.props.columnDataSource.length + 1}
                  height={height}
                  overscanColumnCount={10}
                  overscanRowCount={10}
                  estimatedRowSize={40}
                  rowHeight={({ index }) => (index === 0 ? 100 : 70)}
                  rowCount={this.props.rowDataSource.length + 1}
                  width={width}
                />
              </td>
            </tr>
          </table>
        )}
      </AutoSizer>
    </div>
  )

  render = () => (this.props.loading ? <Spin /> : this.renderLoaded())
}

export { AssignmentsTable }
