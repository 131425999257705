// Based on example at:
// https://github.com/ant-design/ant-design/issues/6576#issuecomment-422401478
import { matchPath } from 'react-router-dom'
import { Enumeration } from '../utils'
import { AdminPage } from './AdminPage'
import { ColorsPage } from './ColorsPage'
import { PropertiesPage } from './PropertiesPage'
import { ReportsPage } from './ReportsPage'
import { UserPage } from './UserPage'
import { SignOutPage } from './SignOutPage'
import BuildingPage from './BuildingPage'
import { routeKeys } from './const/routeKeys'
import RoomsPage from './RoomsPage'
import FloorCoveringPage from './FloorCoveringPage'
import RoomDetailPage from './RoomDetailPage'

const menuItemConfig = new Enumeration({
  ADMIN: {
    key: routeKeys.ADMIN,
    toPath: routeKeys.ADMIN,
    iconType: 'setting',
    itemTitle: 'Admin',
  },
  COLORS: {
    key: routeKeys.COLORS,
    toPath: routeKeys.COLORS,
    iconType: 'experiment',
    itemTitle: 'Colors',
  },
  PROPERTIES: {
    key: routeKeys.PROPERTIES,
    toPath: routeKeys.PROPERTIES,
    iconType: 'shop',
    itemTitle: 'Projects',
    subMenuColor: '#00bbff',
  },
  FLOOR_COVERING: {
    key: routeKeys.FLOOR_COVERING,
    toPath: routeKeys.FLOOR_COVERING,
    iconType: 'border-outer',
    itemTitle: 'Swatches',
  },
  REPORTS: {
    key: routeKeys.REPORTS,
    toPath: routeKeys.REPORTS,
    iconType: 'line-chart',
    itemTitle: 'Reports',
  },
  USER: {
    key: routeKeys.USER,
    toPath: routeKeys.USER,
    iconType: 'user',
    itemTitle: 'User',
  },
  SIGN_OUT: {
    key: routeKeys.SIGN_OUT,
    toPath: routeKeys.SIGN_OUT,
    iconType: 'logout',
    itemTitle: 'Sign out',
  },
})

// Each value in this object is the set of props for a <Route />
const routeConfig = new Enumeration({
  ADMIN: {
    key: routeKeys.ADMIN,
    exact: true,
    path: routeKeys.ADMIN,
    component: AdminPage,
  },
  COLORS: {
    key: routeKeys.COLORS,
    exact: true,
    path: routeKeys.COLORS,
    component: ColorsPage,
  },
  PROPERTIES: {
    key: routeKeys.PROPERTIES,
    exact: true,
    path: routeKeys.PROPERTIES,
    component: PropertiesPage,
  },
  FLOOR_COVERING: {
    key: routeKeys.FLOOR_COVERING,
    path: routeKeys.FLOOR_COVERING,
    component: FloorCoveringPage,
  },
  BUILDINGS: {
    key: routeKeys.BUILDINGS,
    exact: true,
    path: routeKeys.BUILDINGS,
    component: BuildingPage,
  },
  BUILDINGS_SUB_ACCOUNT: {
    key: routeKeys.BUILDINGS_SUB_ACCOUNT,
    exact: true,
    path: routeKeys.BUILDINGS_SUB_ACCOUNT,
    component: BuildingPage,
  },
  ROOMS: {
    key: routeKeys.ROOMS,
    exact: true,
    path: routeKeys.ROOMS,
    component: RoomsPage,
  },
  ROOMS_SUB_ACCOUNT: {
    key: routeKeys.ROOMS_SUB_ACCOUNT,
    exact: true,
    path: routeKeys.ROOMS_SUB_ACCOUNT,
    component: RoomsPage,
  },
  ROOM_DETAIL: {
    key: routeKeys.ROOM_DETAIL,
    path: routeKeys.ROOM_DETAIL,
    component: RoomDetailPage,
  },
  ROOM_SUB_ACCOUNT: {
    key: routeKeys.ROOM_SUB_ACCOUNT,
    path: routeKeys.ROOM_SUB_ACCOUNT,
    component: RoomDetailPage,
  },
  REPORTS: {
    key: routeKeys.REPORTS,
    exact: true,
    path: routeKeys.REPORTS,
    component: ReportsPage,
  },
  USER: {
    key: routeKeys.USER,
    exact: true,
    path: routeKeys.USER,
    component: UserPage,
  },
  SIGN_OUT: {
    key: routeKeys.SIGN_OUT,
    exact: true,
    path: routeKeys.SIGN_OUT,
    component: SignOutPage,
  },
})

const getMatchedKey = (location) =>
  (
    Object.values(routeConfig).find((route) =>
      matchPath(location.pathname, route)
    ) || {}
  ).path

// Export all the named exports from each module.
export * from './AdminPage'
export * from './PropertiesPage'
export * from './ColorsPage'
export * from './ReportsPage'
export * from './SignInPage'
export * from './SignOutPage'
export * from './UserPage'

export { getMatchedKey, menuItemConfig, routeConfig }
