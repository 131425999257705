import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { MultiGrid } from 'react-virtualized'
import { css } from 'aphrodite/no-important'
import { useResizeTable } from './hooks/useResizeTable'
import Spinner from '../Spinner'
import { COLUMN_HEADER_HEIGHT, HEIGHT, ROW_HEADER_HEIGHT } from './const/sizes'
import { classes } from './AssignementTableStyles'
import Cell from './Cell'
import EmptyCell from './EmptyCell'
import MultiGridHeader from './MultiGridHeader'

const SCROLL_EDGE = 20
export default function AssignmentTable({
  columns,
  rows,
  loading,
  onChange,
  checkedArray,
  infiniteScroll,
  onBatchAssignment,
  assignLoading,
  ...props
}) {
  const root = useRef()
  const width = useResizeTable(root)

  const handleScroll = ({
    scrollTop,
    clientHeight,
    scrollHeight,
    scrollLeft,
    clientWidth,
    scrollWidth,
  }) => {
    if (infiniteScroll.loading) {
      return
    }

    if (
      scrollTop > 0 &&
      clientHeight + scrollTop > scrollHeight - SCROLL_EDGE
    ) {
      infiniteScroll.fetchMoreRow()
    }

    if (
      scrollLeft > 0 &&
      clientWidth + scrollLeft > scrollWidth - SCROLL_EDGE
    ) {
      infiniteScroll.fetchMoreColumn()
    }
  }

  const cellRenderer = ({ columnIndex, key, rowIndex, style }) => {
    if (rowIndex === 0 && columnIndex === 0) {
      return <EmptyCell />
    }

    // Row Header
    if (rowIndex === 0) {
      return (
        <MultiGridHeader
          item={rows[columnIndex - 1]}
          style={style}
          onBatchAssignment={onBatchAssignment}
          assignLoading={assignLoading}
        />
      )
    }

    // Column Header
    if (columnIndex === 0) {
      return (
        <MultiGridHeader
          key={key}
          style={style}
          item={columns[rowIndex - 1]}
          onBatchAssignment={onBatchAssignment}
          assignLoading={assignLoading}
        />
      )
    }

    return (
      <Cell
        style={style}
        key={key}
        checked={checkedArray.some(
          ([zero, first]) =>
            zero === rows[columnIndex - 1].id &&
            first === columns[rowIndex - 1].id
        )}
        onChange={(checked) => {
          onChange(rows[columnIndex - 1], columns[rowIndex - 1], checked)
        }}
      />
    )
  }

  return (
    <>
      <div ref={root} className={css(classes.root)}>
        {loading && <Spinner />}
        {!loading && (
          <>
            <MultiGrid
              onScroll={infiniteScroll && handleScroll}
              data={{ rows, columns, checkedArray }}
              cellRenderer={cellRenderer}
              fixedColumnCount={1}
              fixedRowCount={1}
              height={HEIGHT}
              width={width}
              // Plus one row/column header
              columnCount={rows.length + 1}
              rowCount={columns.length + 1}
              columnWidth={ROW_HEADER_HEIGHT}
              rowHeight={COLUMN_HEADER_HEIGHT}
              style={{ backgroundColor: '#e6e6e6' }}
              {...props}
            />
          </>
        )}
        {infiniteScroll?.loading && (
          <div className={css(classes.infinitySpinner)}>
            <Spinner />
          </div>
        )}
      </div>
    </>
  )
}

AssignmentTable.propTypes = {
  loading: PropTypes.bool,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
  onChange: PropTypes.func.isRequired,
  checkedArray: PropTypes.array.isRequired,
  infiniteScroll: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    fetchMoreRow: PropTypes.func.isRequired,
    fetchMoreColumn: PropTypes.func.isRequired,
  }),
  onBatchAssignment: PropTypes.func.isRequired,
  assignLoading: PropTypes.bool,
}

AssignmentTable.defaultProps = {
  loading: false,
  columns: [],
  rows: [],
  infiniteScroll: null,
  assignLoading: false,
}
