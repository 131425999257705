import React from 'react'
import { Tabs } from 'antd'
import { compose } from 'recompose'

import PropTypes from 'prop-types'
import { PalettesForm } from '../components/forms/colors/PalettesForm'
import { SchemesForm } from '../components/forms/colors/SchemesForm'

import { AuthenticationConsumer, SelectionConsumer } from '../contexts'
import { ACCOUNTS } from '../graphql/accounts.graphql'
import { withConsumer, withQueryResultAsProp } from '../utils'
import SelectAccount from '../components/common/SelectAccount'
import { useWorkingAccount } from '../hooks/useWorkingAccount'

const { TabPane } = Tabs

const ColorsPage = ({ accounts, selection }) => {
  const { selectedAccount, selectedSubAccount } = useWorkingAccount(
    accounts,
    selection
  )
  const isClientSelected = !!(selectedSubAccount || selectedAccount)

  return (
    <>
      <SelectAccount accounts={accounts} />
      {isClientSelected && (
        <Tabs tabBarStyle={{ marginBottom: 0 }}>
          <TabPane tab="Manage Palettes" key="1">
            <PalettesForm />
          </TabPane>
          <TabPane tab="Manage Schemes" key="2">
            <SchemesForm />
          </TabPane>
        </Tabs>
      )}
    </>
  )
}

ColorsPage.propTypes = {
  accounts: PropTypes.arrayOf(PropTypes.object).isRequired,
  selection: PropTypes.object.isRequired,
}

const WrappedComponent = compose(
  withConsumer(AuthenticationConsumer, { propName: 'authentication' }),
  withConsumer(SelectionConsumer, { propName: 'selection' }),
  withQueryResultAsProp({
    gqlDocument: ACCOUNTS,
    resultPropName: 'accounts',
  })
)(ColorsPage)

export { WrappedComponent as ColorsPage }
