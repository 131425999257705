/* eslint-disable react/prop-types */
import { Button } from 'antd'
import { css, StyleSheet } from 'aphrodite/no-important'
import PropTypes from 'prop-types'
import React, { useCallback, useState } from 'react'
import { compose } from 'recompose'

import { useHistory, useLocation } from 'react-router-dom'
import { UpdatePropertyForm } from './UpdatePropertyForm'

import { SelectionConsumer } from '../../../contexts'

import {
  DELETE_PROPERTY_BY_ID,
  UPDATE_PROPERTY_BY_ID,
  ACCOUNT_PROPERTIES_BY_ACCOUNT_ID,
} from '../../../graphql/properties.graphql'

import { REPS } from '../../../graphql/reps.graphql'

import {
  renderNothingIf,
  useQueryParams,
  withConsumer,
  withMutationAsProp,
  withQueryResultAsProp,
} from '../../../utils'
import { PropertiesTableActions } from './components/PropertiesTableActions'
import {
  PropertiesFilter,
  useDefaultFilter,
} from './components/PropertiesFilter'
import { PropertiesTable, useProperties } from './components/PropertiesTable'
import AddPropertyModal from './components/AddPropertyModal'
import { QueryParams } from '../../../const/queryParams'

const { Buildings, Rooms, Invite, Lock, Archive, Delete } =
  PropertiesTableActions

const styles = StyleSheet.create({
  tableActions: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: 8,
  },
  addButton: {
    marginLeft: 'auto',
  },
})

const DEFAULT_PAGE_SIZE = 50

function PropertiesForm({ account, updatePropertyById, deletePropertyById }) {
  const location = useLocation()
  const history = useHistory()
  const params = useQueryParams(location, history)
  const [currentPage, setCurrentPage] = useState(
    () => Number(params.get(QueryParams.PAGE)) || 1
  )
  const [pageSize, setPageSize] = useState(() =>
    Number(params.get(QueryParams.PAGE_SIZE) || DEFAULT_PAGE_SIZE)
  )

  const { filter, setFilter } = useDefaultFilter(
    params,
    setCurrentPage,
    setPageSize,
    DEFAULT_PAGE_SIZE
  )
  const [isAddModalVisible, setIsAddModalVisible] = useState(false)
  const properties = useProperties(
    account,
    filter,
    (property) => property.is_active && !property.is_finalized
  )

  const handleDeletePropertyButtonPress = useCallback(
    (propertyId) =>
      deletePropertyById({
        id: propertyId,
      }),
    [deletePropertyById]
  )

  const handleDeactivatePropertyButtonPress = useCallback(
    (propertyId) =>
      updatePropertyById({
        id: propertyId,
        input: {
          is_active: false,
        },
      }),
    [updatePropertyById]
  )

  const handleFinalizePropertyButtonPress = useCallback(
    (propertyId) =>
      updatePropertyById({
        id: propertyId,
        input: {
          is_finalized: true,
        },
      }),
    [updatePropertyById]
  )

  const getAccountSubAccountPair = (currentAccount) => ({
    accountId: currentAccount.parent_account
      ? currentAccount.parent_account.id
      : currentAccount.id,
    subAccountId: currentAccount.parent_account ? currentAccount.id : null,
  })

  const activeActions = (property) => [
    <Buildings
      key="buildings"
      {...{
        propertyId: property.id,
        ...getAccountSubAccountPair(account),
        history,
      }}
    />,
    <Rooms
      key="rooms"
      {...{
        propertyId: property.id,
        ...getAccountSubAccountPair(account),
        history,
      }}
    />,
    <Invite
      key="invite"
      {...{
        property,
        account,
      }}
    />,
    <Lock
      key="lock"
      {...{
        propertyId: property.id,
        handleFinalizePropertyButtonPress,
      }}
    />,
    <Archive
      key="archive"
      {...{
        propertyId: property.id,
        handleDeactivatePropertyButtonPress,
      }}
    />,
    <Delete
      key="delete"
      {...{
        propertyId: property.id,
        handleDeletePropertyButtonPress,
      }}
    />,
  ]

  return (
    <React.Fragment>
      <div className={css(styles.tableActions)}>
        <PropertiesFilter
          filter={filter}
          setFilter={setFilter}
          queryParams={params}
        />
        <Button
          type="primary"
          icon="plus"
          className={css(styles.addButton)}
          onClick={() => setIsAddModalVisible(!isAddModalVisible)}
        >
          Add Project
        </Button>
      </div>
      <AddPropertyModal
        account={account}
        visible={isAddModalVisible}
        handleCloseModal={() => setIsAddModalVisible(false)}
      />
      <PropertiesTable
        type="active"
        account={account}
        properties={properties}
        actions={activeActions}
        actionsWidth={260}
        updatePropertyById={updatePropertyById}
        queryParams={params}
        currentPage={currentPage}
        pageSize={pageSize}
        defaultPageSize={DEFAULT_PAGE_SIZE}
        setCurrentPage={setCurrentPage}
        setPageSize={setPageSize}
        expandedRow={
          // Each record is a Property.
          (record) => <UpdatePropertyForm propertyId={record.id} />
        }
      />
    </React.Fragment>
  )
}

PropertiesForm.propTypes = {
  deletePropertyById: PropTypes.func.isRequired,
  account: PropTypes.shape({
    id: PropTypes.number.isRequired,
    account_name: PropTypes.string.isRequired,
    account_type: PropTypes.string.isRequired,
    properties: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string,
        project_id: PropTypes.string,
        division: PropTypes.string,
        is_active: PropTypes.bool,
        is_finalized: PropTypes.bool,
        rep: PropTypes.shape({
          id: PropTypes.number.isRequired,
          full_name: PropTypes.string,
          greeting_name: PropTypes.string,
          email: PropTypes.string,
          phone: PropTypes.string,
        }),
      })
    ).isRequired,
  }).isRequired,
  updatePropertyById: PropTypes.func.isRequired,
}

const WrappedComponent = compose(
  withConsumer(SelectionConsumer, { propName: 'selection' }),
  renderNothingIf(
    ({ selection }) => !(selection && selection.currentAccountId)
  ),
  withQueryResultAsProp({
    gqlDocument: REPS,
    resultPropName: 'reps',
  }),
  withQueryResultAsProp({
    gqlDocument: ACCOUNT_PROPERTIES_BY_ACCOUNT_ID,
    variables: ({ selection }) => ({
      accountId: selection.currentSubAccountId || selection.currentAccountId,
    }),
    resultPropName: 'account',
  }),
  withMutationAsProp({
    gqlDocument: DELETE_PROPERTY_BY_ID,
    mutationPropName: 'deletePropertyById',
    refetchQueries: [
      {
        gqlDocument: ACCOUNT_PROPERTIES_BY_ACCOUNT_ID,
        variables: ({ selection }) => ({
          accountId:
            selection.currentSubAccountId || selection.currentAccountId,
        }),
      },
    ],
  }),
  withMutationAsProp({
    gqlDocument: UPDATE_PROPERTY_BY_ID,
    mutationPropName: 'updatePropertyById',
  })
)(PropertiesForm)

export { WrappedComponent as PropertiesForm }
