/* eslint-disable camelcase */

import { Form, Table } from 'antd'
import { css, StyleSheet } from 'aphrodite/no-important'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { compose } from 'recompose'

import {
  GET_COLOR_COLLECTIONS,
  UPDATE_PRODUCT_INFO,
} from '../../../graphql/colors.graphql'

import { withMutationAsProp, generateUpdateFormItemInput } from '../../../utils'

const styles = StyleSheet.create({
  addPropertyForm: {
    border: '1px solid #d8dee2',
    borderRadius: '10px',
    padding: '0px 10px 0px 10px',
    margin: 'auto',
    marginLeft: '10px',
    marginRight: '10px',
  },
  updatePropertyForm: {
    padding: '0px 10px 0px 10px',
    margin: 'auto',
  },
  tableStyle: {
    border: '1px solid #d8dee2',
    borderRadius: '10px',
    padding: '0px 10px 0px 10px',
    marginLeft: '10px',
    marginRight: '10px',
  },
})

class ProductLinesList extends Component {
  static propTypes = {
    productLines: PropTypes.arrayOf(PropTypes.object).isRequired,
    updateColorVendorProductInfoById: PropTypes.func.isRequired,
  }

  handleUpdateProductLinesFormItemInputChange = async (
    productId,
    targetField,
    label,
    value
  ) => {
    console.log(
      `handleUpdateProductLinesFormItemInputChange: productId: ${productId}, targetField: ${targetField}, label: ${label}, value: ${value}`
    )
    // eslint-disable-next-line
    const { updateColorVendorProductInfoById, productLines, setStateHandler, updateProductLines  } = this.props
    const updateResult = await updateColorVendorProductInfoById({
      id: productId,
      input: { [targetField]: value },
    })

    if (updateResult && updateResult.data) {
      updateProductLines(updateResult.data.updateColorVendorProductInfoById)
    }
  }

  render() {
    const { productLines, updateColorVendorProductInfoById } = this.props
    return (
      <React.Fragment>
        <Table
          className={css(styles.tableStyle)}
          key="propertiesTable"
          size="small"
          bordered
          pagination={false}
          dataSource={productLines.map((productLine) => ({
            key: productLine.id,
            ...productLine,
          }))}
          columns={[
            {
              title: 'Product Line',
              dataIndex: 'product_name',
              key: 'product_name',
              width: '32%',
            },
            {
              title: 'Product Group',
              dataIndex: 'product_group',
              key: 'product_group',
              width: '32%',
            },
            {
              title: 'Line Identifier',
              dataIndex: 'product_identifier_prefix',
              key: 'product_identifier_prefix',
              width: '32%',
              render: (product_identifier_prefix) => {
                if (product_identifier_prefix !== null) {
                  return `${product_identifier_prefix}`
                }
                return null
              },
            },
          ]}
          expandedRowRender={
            // Each record is a Property.
            (record) => (
              <React.Fragment>
                <Form
                  key="updateProductLinesForm"
                  className={css(styles.updatePropertyForm)}
                >
                  {generateUpdateFormItemInput(
                    this.handleUpdateProductLinesFormItemInputChange,
                    updateColorVendorProductInfoById,
                    record.id,
                    record.product_name,
                    'product_name',
                    'Product Name'
                  )}
                  {generateUpdateFormItemInput(
                    this.handleUpdateProductLinesFormItemInputChange,
                    updateColorVendorProductInfoById,
                    record.id,
                    record.product_group,
                    'product_group',
                    'Product Group'
                  )}
                  {generateUpdateFormItemInput(
                    this.handleUpdateProductLinesFormItemInputChange,
                    updateColorVendorProductInfoById,
                    record.id,
                    record.product_identifier_prefix,
                    'product_identifier_prefix',
                    'Product Line Identifier (optional)'
                  )}
                </Form>
              </React.Fragment>
            )
          }
        />
      </React.Fragment>
    )
  }
}

const WrappedComponent = compose(
  withMutationAsProp({
    gqlDocument: UPDATE_PRODUCT_INFO,
    mutationPropName: 'updateColorVendorProductInfoById',
    refetchQueries: [
      {
        gqlDocument: GET_COLOR_COLLECTIONS,
      },
    ],
  })
)(ProductLinesList)

export { WrappedComponent as ProductLinesList }
