import React from 'react'
import PropTypes from 'prop-types'
import { useQuery } from '@apollo/client'
import { Button } from 'antd'
import { css, StyleSheet } from 'aphrodite/no-important'
import { MASKS_BY_ROOM_ID } from '../../../graphql/fcMasks.graphql'
import Alert from '../../common/Alert'
import { Filter, Select } from '../../common/Filter'

const classes = StyleSheet.create({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: 8,
  },
})

export default function LayerFilters({
  roomId,
  onAddLayerClick,
  filters,
  setFilters,
}) {
  const { loading, data, error } = useQuery(MASKS_BY_ROOM_ID, {
    variables: {
      id: roomId,
    },
  })

  if (error) {
    return <Alert />
  }

  const masks = data?.masksByRoomId
  const filter = {
    value: filters?.maskId,
    set: (maskId) => setFilters({ ...filters, maskId }),
    list: masks?.map((mask) => ({
      ...mask,
      name: mask?.material?.display_name,
    })),
  }

  return (
    <div className={css(classes.root)}>
      {error && <Alert />}
      <Filter data={filter} loading={loading}>
        <Select field="maskId" label="Mask" placeholder="Select Mask" />
      </Filter>
      <Button type="primary" icon="plus" onClick={onAddLayerClick}>
        Add flooring
      </Button>
    </div>
  )
}

LayerFilters.propTypes = {
  roomId: PropTypes.number.isRequired,
  onAddLayerClick: PropTypes.func.isRequired,
  filters: PropTypes.shape({
    maskId: PropTypes.number,
  }),
  setFilters: PropTypes.func.isRequired,
}

LayerFilters.defaultProps = {
  filters: null,
}
