import flow from 'lodash/fp/flow'

import { query, addPath, addParam } from './restQueryBuilder'
import * as F from './functions'

// ///////// //
// Constants //
// ///////// //

const IS_PRODUCTION = process.env.NODE_ENV === 'production'
const HEMI_BASE_URL = IS_PRODUCTION
  ? process.env.HEMI_SERVER_PREFIX
  : 'http://localhost:5050'
const API_KEY = '123'

export const hemiQuery = ({ clientName, type = 'cv' }) =>
  flow(
    addPath('api', type, 'client', clientName),
    addParam('key', API_KEY)
  )(query(HEMI_BASE_URL))

export const planQuery = F.lift(F.getProp('communityElevationInfo'), (info) =>
  flow(hemiQuery, addPath('nbr', info.community.name, 'plan', info.plan.name))
)

export const elevationQuery = F.lift(
  F.getPath('communityElevationInfo', 'elevation', 'id'),
  (elevId) => flow(planQuery, addParam('elevId', elevId))
)

export const clearCacheByPlanQuery = F.lift(F.getProp('planName'), (planName) =>
  flow(
    hemiQuery,
    addPath('reset_cache'),
    addParam('glob', `*/plan/${planName}*`)
  )
)

export const clearCacheByElevationQuery = F.lift(
  F.pick('planName', 'elevId'),
  ({ planName, elevId }) =>
    flow(
      hemiQuery,
      addPath('reset_cache'),
      addParam('glob', `*/plan/${planName}*elevId=${elevId}*`)
    )
)

export const clearCacheBySchemeNameQuery = F.lift(
  F.getProp('schemeName'),
  (schemeName) =>
    flow(
      hemiQuery,
      addPath('reset_cache'),
      addParam('glob', `*/scheme/${schemeName}*`)
    )
)

export const prefetchElevationLayersQuery = flow(
  elevationQuery,
  addPath('prefetch')
)

export const floorplanQuery = ({
  sels,
  walts,
  alts,
  timestamp = Date.now(),
  ...options
}) => {
  const eQuery = elevationQuery({ ...options, type: 'fp' })
  return flow(
    addParam('sels', ...sels),
    addParam('walts', ...walts),
    addParam('alts', ...alts),
    addParam('t', timestamp)
  )(eQuery)
}

export const exteriorQuery = ({
  schemeName,
  paletteSelections,
  width,
  mimetype,
  timestamp = Date.now(),
  ...options
}) => {
  const eQuery = elevationQuery(options)
  const schemeMode = !!schemeName
  const palettesMode = paletteSelections.length > 0
  const hybridMode = schemeMode && palettesMode

  let exterior = eQuery
  if (hybridMode) exterior = addPath('hybrid')(exterior)
  if (schemeMode) exterior = addPath('scheme', schemeName)(exterior)
  if (palettesMode && !hybridMode) exterior = addPath('palette')(exterior)
  if (palettesMode) {
    exterior = flow(
      addParam('pals', ...paletteSelections.map(F.getProp('paletteId'))),
      addParam('sels', ...paletteSelections.map(F.getProp('selectionId'))),
      addParam('mats', ...paletteSelections.map(F.getProp('materialId')))
    )(exterior)
  }
  if (width) exterior = addParam('w', width)(exterior)
  if (mimetype) exterior = addParam('o', mimetype)(exterior)

  return addParam('t', timestamp)(exterior)
}
