import React from 'react'
import {
  useHistory,
  useLocation,
  useParams,
  useRouteMatch,
} from 'react-router-dom/cjs/react-router-dom'
import { useQuery } from '@apollo/client'
import { Icon, Tabs as AntdTabs } from 'antd'
import { generatePath, Route, Switch } from 'react-router-dom'
import PageHeader from '../components/common/PageHeader'
import { GET_ROOM_BY_ID } from '../graphql/fcRooms.graphql'
import { useAccountAndProperty } from '../hooks/useAccountAndProperty'
import Spinner from '../components/common/Spinner'
import RoomEditTab from '../components/rooms/RoomDetails/RoomEditTab'
import RoomMasksTab from '../components/rooms/masks/RoomMasksTab'
import { Tabs } from '../components/rooms/const/tabs'
import RoomFlooringTab from '../components/rooms/layers/RoomFlooringTab'
import Alert from '../components/common/Alert'
import RoomSchemesTab from '../components/rooms/schemes/RoomSchemesTab'

const { TabPane } = AntdTabs

export default function RoomDetailPage() {
  const history = useHistory()
  const params = useParams()
  const location = useLocation()
  const { path } = useRouteMatch()

  const propertyId = Number(params.propertyId)
  const currentAccountId = Number(params.subAccountId || params.accountId)
  const roomId = Number(params.roomId)

  const {
    loading: accountLoading,
    error: accountError,
    currentAccount,
    currentProperty,
  } = useAccountAndProperty(currentAccountId, propertyId)

  const { loading, data, error } = useQuery(GET_ROOM_BY_ID, {
    variables: {
      id: roomId,
    },
  })

  const handleTabClick = (key) => {
    const newPath = generatePath(`${path}/${key}`, {
      accountId: params.accountId,
      subAccountId: params.subAccountId,
      propertyId,
      roomId,
    })

    // NOTE:
    // Since we're not navigating to a new route on tab change,
    // and are instead replacing the current entry,
    // just using `history.goBack()` should be enough
    // to get us back to the correct position.
    // (Except for deep-linking which is not currently possible anyways.)
    //
    // If we ever allow back button navigation between tabs,
    // we will have to expand the PageHeader back button to find the
    // latest route not matching any routes/subroutes of this page.
    history.replace(newPath)
  }

  const room = data?.roomById

  if (loading || accountLoading) {
    return <Spinner />
  }

  return (
    <>
      <PageHeader
        title="Room"
        subTitle={room?.name}
        onBack={() => history.goBack()}
      />
      {(error || accountError) && <Alert />}
      {room === null && (
        <>
          <Alert
            margin
            type="warning"
            message={`Room page with id: ${roomId} does not exist!`}
          />
        </>
      )}
      {room && currentAccount && (
        <>
          <AntdTabs
            activeKey={location.pathname.split('/').pop()}
            onTabClick={handleTabClick}
            animated={false}
            tabBarStyle={{ margin: 0 }}
          >
            <TabPane
              tab={
                <span>
                  <Icon type="edit" />
                  Edit
                </span>
              }
              key={Tabs.EDIT}
            />
            <TabPane
              tab={
                <span>
                  <Icon type="appstore" />
                  Masks
                </span>
              }
              key={Tabs.MASKS}
            />
            <TabPane
              tab={
                <span>
                  <Icon type="copy" />
                  Flooring
                </span>
              }
              key={Tabs.FLOORING}
            />
            <TabPane
              tab={
                <span>
                  <Icon type="bg-colors" />
                  Schemes
                </span>
              }
              key={Tabs.SCHEMES}
            />
          </AntdTabs>

          <Switch>
            <Route
              path={`${path}/${Tabs.EDIT}`}
              component={() => (
                <RoomEditTab
                  room={room}
                  propertyId={propertyId}
                  account={currentAccount}
                />
              )}
            />
            <Route
              path={`${path}/${Tabs.MASKS}`}
              component={() => <RoomMasksTab room={room} />}
            />
            <Route
              path={`${path}/${Tabs.FLOORING}`}
              component={() => (
                <RoomFlooringTab account={currentAccount} roomId={room.id} />
              )}
            />
            <Route
              path={`${path}/${Tabs.SCHEMES}`}
              component={() => (
                <RoomSchemesTab
                  account={currentAccount}
                  room={room}
                  project={currentProperty}
                />
              )}
            />
          </Switch>
        </>
      )}
    </>
  )
}

RoomDetailPage.propTypes = {}
RoomDetailPage.defaultProps = {}
