import { Layout, Button, Form } from 'antd'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { compose } from 'recompose'
import { StyleSheet, css } from 'aphrodite'

import { CreatePalettesModal } from '../../modals/CreatePalettesModal'
import { PaletteCards } from '../../common/PaletteCards/PaletteCards'
import { PaletteSelectionsModal } from '../../modals/PaletteSelectionsModal'
import { PaletteMaterialSelectionsModal } from '../../modals/PaletteMaterialSelectionsModal'
import {
  ACCOUNT_PALETTE_QUERY,
  DELETE_PALETTE,
} from '../../../graphql/colors.graphql'

import { AuthenticationConsumer, SelectionConsumer } from '../../../contexts'

import {
  withConsumer,
  withMutationAsProp,
  withQueryResultAsProp,
} from '../../../utils'

const { Item } = Form
const { Content } = Layout
const styles = StyleSheet.create({
  content: {
    backgroundColor: 'whitesmoke',
    marginBottom: '24px',
    minHeight: '540px',
  },
  contentXs: {
    padding: '0',
  },
  header: {
    marginLeft: '12px',
  },
  headerItem: {
    marginBottom: 0,
  },
  layout: {
    background: '#fff',
    padding: '14px 0',
  },
  layoutXs: {
    padding: '8px 0',
  },
  singleForm: {
    marginTop: 12,
    padding: '12px 12px',
    minHeight: 300,
    overflowY: 'auto',
  },
  tabPane: {
    backgroundColor: 'white',
    padding: '22px 30px',
  },
})

class CreatePalettesForm extends Component {
  state = {
    addPaletteVisible: false,
    editPaletteColorSelections: false,
    editPaletteMaterialSelections: false,
  }

  handleCreatePaletteButtonPress = () => {
    this.setState({
      addPaletteVisible: true,
    })
  }

  handleAddPaletteCancel = () => {
    this.setState({
      addPaletteVisible: false,
    })
  }

  setStateHandler = (property, value) => {
    this.setState({
      [property]: value,
    })
  }

  render() {
    const {
      addPaletteVisible,
      communityFilter,
      editPaletteColorSelections,
      editPaletteMaterialSelections,
    } = this.state
    const { selection, account, deletePalette } = this.props
    return (
      <React.Fragment>
        <Content className={css(styles.content)}>
          <div className={css(styles.header)}>
            <Item className={css(styles.headerItem)}>
              <Button
                type="primary"
                onClick={this.handleCreatePaletteButtonPress}
              >
                Create New Palette
              </Button>
            </Item>
          </div>
          {addPaletteVisible && (
            <CreatePalettesModal
              setStateHandler={this.setStateHandler}
              visible={addPaletteVisible}
              handleClose={this.handleAddPaletteCancel}
            />
          )}
          {selection.currentPaletteId !== null &&
            editPaletteColorSelections === true && (
              <PaletteSelectionsModal
                setStateHandler={this.setStateHandler}
                communityIdFilter={communityFilter}
                paletteId={selection.currentPaletteId}
              />
            )}
          {selection.currentPaletteId !== null &&
            editPaletteMaterialSelections === true && (
              <PaletteMaterialSelectionsModal
                setStateHandler={this.setStateHandler}
                communityIdFilter={communityFilter}
                paletteId={selection.currentPaletteId}
              />
            )}
          <div>
            {account !== undefined && account !== null && (
              <PaletteCards
                key={account.id}
                setStateHandler={this.setStateHandler}
                palettes={account.palettes}
                deletePalette={deletePalette}
                selection={selection}
              />
            )}
          </div>
        </Content>
      </React.Fragment>
    )
  }
}

CreatePalettesForm.propTypes = {
  authentication: PropTypes.shape({
    token: PropTypes.string,
    signIn: PropTypes.func,
    signOut: PropTypes.func,
  }).isRequired,
  selection: PropTypes.shape({
    currentPaletteId: PropTypes.number,
    currentAccountId: PropTypes.number,
    setCurrentAccountId: PropTypes.func,
    currentSubAccountId: PropTypes.number,
  }).isRequired,
  account: PropTypes.shape({
    id: PropTypes.number.isRequired,
    palettes: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        materials: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.number.isRequired,
          })
        ).isRequired,
        palette_selections: PropTypes.arrayOf(
          PropTypes.shape({
            name: PropTypes.string,
            hex: PropTypes.string,
            identifier: PropTypes.string,
          })
        ),
      })
    ).isRequired,
  }),
  deletePalette: PropTypes.func.isRequired,
}

CreatePalettesForm.defaultProps = {
  account: null,
}

const WrappedComponent = compose(
  withConsumer(AuthenticationConsumer, { propName: 'authentication' }),
  withConsumer(SelectionConsumer, { propName: 'selection' }),
  withQueryResultAsProp({
    gqlDocument: ACCOUNT_PALETTE_QUERY,
    variables: ({ selection }) => ({
      id: selection.currentSubAccountId || selection.currentAccountId,
    }),
    resultPropName: 'account',
    propName: 'account',
  }),
  withMutationAsProp({
    gqlDocument: DELETE_PALETTE,
    mutationPropName: 'deletePalette',
    variables: ({
      // eslint-disable-next-line camelcase
      palette_id,
    }) => ({ palette_id }),
    refetchQueries: [
      {
        gqlDocument: ACCOUNT_PALETTE_QUERY,
        variables: ({ selection }) => ({
          id: selection.currentSubAccountId || selection.currentAccountId,
        }),
      },
    ],
  })
)(CreatePalettesForm)

export { WrappedComponent as CreatePalettesForm }
