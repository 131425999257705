import { useMemo } from 'react'

/**
 * Get query params helper for current location
 * @param location Router location
 * @param history Router history
 * @returns {UrlQueryParamsHelper}
 */
export const useQueryParams = (location, history) => {
  return useMemo(() => {
    const params = new URLSearchParams(location.search)
    const handler = {
      get: (param) => params.has(param) && params.get(param),
      set: (param, value) => {
        const strValue = value?.toString()
        if (strValue?.length > 0) {
          params.set(param, strValue)
        } else if (params.has(param)) {
          params.delete(param)
        }
        return handler
      },
      update: (replace = true) => {
        history[replace ? 'replace' : 'push']({
          pathname: location.pathname,
          search: params.toString(),
          hash: location.hash,
        })
      },
    }
    return handler
  }, [location, history])
}
