import { Button, Form, Icon, Popconfirm, Table, message } from 'antd'
import { css, StyleSheet } from 'aphrodite/no-important'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { compose } from 'recompose'
import changeCase from 'change-case'

import {
  GET_BLEND_ELEMENTS,
  ADD_BLEND_ELEMENT,
  DELETE_BLEND_ELEMENT_BY_ID,
} from '../../../graphql/colors.graphql'

import {
  generateAddFormItemRequiredInput,
  generateAddFormItemOptionalInput,
  withQueryResultAsProp,
  withMutationAsProp,
} from '../../../utils'

const { Item } = Form

const styles = StyleSheet.create({
  addPropertyForm: {
    border: '1px solid #d8dee2',
    borderRadius: '10px',
    padding: '0px 10px 0px 10px',
    margin: 'auto',
    marginLeft: '10px',
    marginRight: '10px',
  },
  updatePropertyForm: {
    padding: '0px 10px 0px 10px',
    margin: 'auto',
  },
  tableStyle: {
    border: '1px solid #d8dee2',
    borderRadius: '10px',
    padding: '0px 10px 0px 10px',
    marginLeft: '10px',
    marginRight: '10px',
    marginBottom: 40,
  },
})

class MaterialsForm extends Component {
  static propTypes = {
    form: PropTypes.object.isRequired,
    addBlendElement: PropTypes.func.isRequired,
    blendElements: PropTypes.arrayOf(PropTypes.object).isRequired,
    deleteBlendElementById: PropTypes.func.isRequired,
  }

  handleAddMaterialButtonPress = async () => {
    const { addBlendElement, form } = this.props
    await form.validateFieldsAndScroll(
      async (errors, { name, identifier, remarks }) => {
        if (!errors) {
          console.log('Using form values: ', { name, identifier, remarks })
          form.resetFields()
          try {
            await addBlendElement({
              input: {
                name: changeCase.camelCase(name),
                display_name: name,
                identifier,
                blend_mode: 'MULTIPLY',
                material_category: 'BODY',
                remarks,
              },
            })
            message.success('Material was added!')
          } catch (e) {
            message.success('Material was not added!')
            console.error(e)
          }
        }
      }
    )
  }

  handleDeleteMaterialById = async (materialId) => {
    console.log('handleDeleteMaterialById: materialId:', materialId)
    const { deleteBlendElementById } = this.props
    try {
      await deleteBlendElementById({
        id: materialId,
      })
      message.success('Material was deleted!')
    } catch (e) {
      message.success('Material was not deleted!')
      console.error(e)
    }
  }

  render() {
    const { form, blendElements } = this.props
    const { getFieldDecorator } = form
    return (
      <React.Fragment>
        <Form
          key="addMaterialForm"
          layout="inline"
          className={css(styles.addPropertyForm)}
        >
          {generateAddFormItemRequiredInput(
            getFieldDecorator,
            'name',
            '* Material Name'
          )}
          {generateAddFormItemRequiredInput(
            getFieldDecorator,
            'identifier',
            '* Identifier Code'
          )}
          {generateAddFormItemOptionalInput(
            getFieldDecorator,
            'remarks',
            'Material Remarks'
          )}
          <Item>
            <Button type="primary" onClick={this.handleAddMaterialButtonPress}>
              Add Material
            </Button>
          </Item>
        </Form>
        <Table
          className={css(styles.tableStyle)}
          key="propertiesTable"
          size="small"
          bordered
          pagination={false}
          dataSource={blendElements.map((material) => ({
            key: material.id,
            ...material,
          }))}
          columns={[
            {
              title: 'Name',
              dataIndex: 'display_name',
              key: 'display_name',
              width: '30%',
            },
            {
              title: 'Identifier',
              dataIndex: 'identifier',
              key: 'identifier',
              width: '30%',
            },
            {
              title: 'Remarks/Comments',
              dataIndex: 'remarks',
              key: 'remarks',
              width: '30%',
            },
            {
              key: 'delete',
              width: '10%',
              render: (text, material) => (
                <Popconfirm
                  placement="bottomRight"
                  title="Delete this material?"
                  onConfirm={() => this.handleDeleteMaterialById(material.id)}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button type="danger">
                    <Icon type="delete" />
                  </Button>
                </Popconfirm>
              ),
            },
          ]}
        />
      </React.Fragment>
    )
  }
}

const WrappedComponent = compose(
  withQueryResultAsProp({
    gqlDocument: GET_BLEND_ELEMENTS,
    resultPropName: 'blendElements',
  }),
  withMutationAsProp({
    gqlDocument: ADD_BLEND_ELEMENT,
    mutationPropName: 'addBlendElement',
    refetchQueries: [
      {
        gqlDocument: GET_BLEND_ELEMENTS,
      },
    ],
  }),
  withMutationAsProp({
    gqlDocument: DELETE_BLEND_ELEMENT_BY_ID,
    mutationPropName: 'deleteBlendElementById',
    variables: ({ blendElement }) => ({ id: blendElement.id }),
    refetchQueries: [
      {
        gqlDocument: GET_BLEND_ELEMENTS,
      },
    ],
  }),
  Form.create()
)(MaterialsForm)

export { WrappedComponent as MaterialsForm }
