import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useSelectionContext } from '../contexts'

export const useAccountSelectionFromParams = () => {
  const selection = useSelectionContext()
  const params = useParams()

  useEffect(() => {
    selection.setCurrentAccountId(Number(params.accountId))
    // If account has sub-account we can choose sub-account value 'Default Project'
    // which has same id as main account
    // That's why we need store account id as sub-account id
    selection.setCurrentSubAccountId(
      Number(params.subAccountId) || Number(params.accountId)
    )
    selection.setHasAccountSubAccount(!!params.subAccountId)
  }, [])
}
