import PropTypes from 'prop-types'

export const layerModalModes = {
  add: 'add',
  update: 'update',
  copy: 'copy',
}

export const layerModalModesPropType = PropTypes.oneOf(
  Object.values(layerModalModes)
)
