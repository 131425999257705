export const getMaskCloudinaryPath = (
  cloudFolderName,
  fileName,
  roomId,
  settings = 'w_150,h_150,c_fill'
) => {
  if (!cloudFolderName || !fileName || !roomId)
    throw Error(
      'Method getMaskCloudinaryPath() must receive cloudFolderName and fileName and roomId'
    )

  return `https://res.cloudinary.com/renderinghouse/image/upload/${settings}/app/${cloudFolderName}/rooms/${roomId}/masks/${fileName}`
}
