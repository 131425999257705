import { generateUrl } from './urlGenerator'

const getMaterialDisplayName = (material) =>
  material.display_name !== material.name
    ? `${material.display_name} (${material.name})`
    : `${material.name}`

const rgbToHSL = (myR, myG, myB) => {
  const r = myR / 255
  const g = myG / 255
  const b = myB / 255

  const max = Math.max(r, g, b)
  const min = Math.min(r, g, b)
  let h = (max + min) / 2
  let s = (max + min) / 2
  const l = (max + min) / 2

  if (max === min) {
    h = 0
    s = 0
  } else {
    const d = max - min
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min)
    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0)
        break
      case g:
        h = (b - r) / d + 2
        break
      case b:
        h = (r - g) / d + 4
        break
      default:
        h = 0
    }
    h /= 6
  }
  return [Math.round(360 * h), Math.round(100 * s), Math.round(100 * l)]
}

const hexToHSL = (hex) => {
  const red = parseInt(hex.substring(0, 2), 16)
  const green = parseInt(hex.substring(2, 4), 16)
  const blue = parseInt(hex.substring(4, 6), 16)
  return rgbToHSL(red, green, blue)
}

// https://www.w3.org/TR/2008/REC-WCAG20-20081211/#relativeluminancedef
/* eslint-disable */
const calcLuminance = (hex) => {
  const color = parseInt(hex, 16)
  const r = ((color & 0xff0000) >> 16)/255
  const g = ((color & 0x00ff00) >> 8)/255
  const b = (color & 0x0000ff)/255
  const rs = (r <= 0.03928) ? r/12.92 : Math.pow((r + 0.055/1.055), 2.4)
  const gs = (g <= 0.03928) ? g/12.92 : Math.pow((g + 0.055/1.055), 2.4)
  const bs = (b <= 0.03928) ? b/12.92 : Math.pow((b + 0.055/1.055), 2.4)
  return (0.2126 * rs) + (0.7152 * gs) + (0.0722 * bs)
}
/* eslint-enable */

// returns either white or black for a given color
// this function can be used to return a suitable text color to use given an
// input of a background color
const getTextColor = (hex) => {
  const lum = calcLuminance(hex)
  return lum > 0.7 ? '000000' : 'ffffff'
}

const imageUrl = ({ dirName, brickSrc, stoneSrc, customOverlaySrc, mods }) => {
  const noClientName = null
  if (brickSrc)
    return `${generateUrl(noClientName, 'texturePhotosBrick', mods)}${brickSrc}`
  if (stoneSrc)
    return `${generateUrl(noClientName, 'texturePhotosStone', mods)}${stoneSrc}`
  if (customOverlaySrc)
    return `${generateUrl(dirName, 'image', mods)}${customOverlaySrc}`

  return null
}

export { getMaterialDisplayName, getTextColor, rgbToHSL, hexToHSL, imageUrl }
