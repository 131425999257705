import { useQuery } from '@apollo/client'
import { ACCOUNT_WITH_MATERIALS } from '../graphql/accounts.graphql'

/**
 * @typedef AccountMaterial
 * @prop {id} number
 * @prop {string} display_name
 */

/**
 * @typedef AccountWithMaterials
 * @prop {number} id
 * @prop {string} account_name
 * @prop {string} cloud_folder_name
 * @prop {number} prospect_number
 * @prop {string} account_type
 * @prop {string} contact_first_name
 * @prop {string} contact_last_name
 * @prop {string} phone
 * @prop {string} email
 * @prop {AccountMaterials[]} materials
 */

/**
 * @template DataType
 * @typedef ApolloQueryResult
 * @prop {boolean} loading
 * @prop {object=} error
 * @prop {DataType} data
 */

/**
 * @param {number} id
 * @returns {ApolloQueryResult<{ account: AccountWithMaterials }>}
 */
export default function useAccountWithMaterials(id) {
  return useQuery(ACCOUNT_WITH_MATERIALS, {
    variables: {
      id,
    },
    fetchPolicy: 'network-only',
  })
}

/**
 * @param {number} id
 * @returns {ApolloQueryResult<{ materials: AccountMaterial[] }>}
 */
export function useAccountMaterials(id) {
  const { data, ...rest } = useAccountWithMaterials(id)
  return { ...rest, data: { materials: data?.account?.materials } }
}
