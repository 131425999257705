import { useMutation, useQuery } from '@apollo/client'
import { useEffect, useMemo, useState } from 'react'
import { message } from 'antd'
import { debounce } from 'lodash'
import {
  GET_SWATCHES_ASSIGNMENTS,
  ASSIGN_SWATCHES,
} from '../../../../graphql/fcSwatches.graphql'
import { useSelectionContext } from '../../../../contexts'

export const PAGE_SIZE = 20
const defaultSelections = {
  assign: [],
  remove: [],
}

export function useSwatchAssignments({
  swatchName,
  projectName,
  setBlockInfinityScroll,
}) {
  const { currentAccountId, currentSubAccountId, hasAccountSubAccount } =
    useSelectionContext()
  const [currentSelections, setCurrentSelections] = useState(defaultSelections)

  const accountId = hasAccountSubAccount
    ? currentSubAccountId
    : currentAccountId

  const variables = useMemo(
    () => ({
      pagination: {
        swatches: {
          page: 0,
          size: PAGE_SIZE,
        },
        projects: {
          page: 0,
          size: PAGE_SIZE,
        },
      },
      filter: {
        accountId,
        swatches: swatchName,
        projects: projectName,
      },
      ordering: {
        swatches: {
          columns: [{ name: 'name', order: 'ASC' }],
        },
        projects: {
          columns: [{ name: 'name', order: 'ASC' }],
        },
      },
    }),
    [accountId, swatchName, projectName]
  )

  const {
    loading,
    data,
    error,
    fetchMore: apolloFetchMore,
    refetch,
  } = useQuery(GET_SWATCHES_ASSIGNMENTS, {
    notifyOnNetworkStatusChange: true,
    skip: !accountId,
    variables,
  })
  const [assignSwatches] = useMutation(ASSIGN_SWATCHES)

  // Refetch if filter changes
  useEffect(() => {
    if (accountId) {
      // We need to block infinity scroll when filter changed
      setBlockInfinityScroll(true)
      refetch(variables)
    }
  }, [swatchName, projectName])

  const resetCurrentSelections = () => {
    setCurrentSelections(defaultSelections)
  }

  const swatches = data?.swatchesAssignments.swatches.items
  const projects = data?.swatchesAssignments.projects.items

  const initialSelections = useMemo(() => {
    const result = swatches?.reduce(
      (prev, current) => [
        ...prev,
        ...current?.assignment.projectIds.map((projectId) => [
          projectId,
          current.id,
        ]),
      ],
      []
    )
    return result || []
  }, [swatches])

  const handleUpdateAssignments = async (currentSelections, accountId) => {
    if (
      currentSelections.assign.length <= 0 &&
      currentSelections.remove.length <= 0
    ) {
      return
    }

    const toAdd = currentSelections.assign.map(([projectId, swatchId]) => ({
      projectId,
      swatchId,
    }))

    const toRemove = currentSelections.remove.map(([projectId, swatchId]) => ({
      projectId,
      swatchId,
    }))

    try {
      await assignSwatches({
        variables: {
          accountId,
          assign: toAdd,
          remove: toRemove,
        },
        update(cache, { data }) {
          const { assigned, removed } = data.assignSwatches

          // ASSIGN
          assigned.forEach(({ projectId, swatchId }) => {
            cache.modify({
              id: `Swatch:${swatchId}`,
              fields: {
                assignment(existingAssignment) {
                  return {
                    ...existingAssignment,
                    projectIds: [...existingAssignment.projectIds, projectId],
                  }
                },
              },
            })
          })

          // UNASSIGNED
          removed.forEach(({ projectId, swatchId }) => {
            cache.modify({
              id: `Swatch:${swatchId}`,
              fields: {
                assignment(existingAssignment) {
                  return {
                    ...existingAssignment,
                    projectIds: existingAssignment.projectIds.filter(
                      (projId) => projId !== projectId
                    ),
                  }
                },
              },
            })
          })

          cache.evict({ fieldName: 'swatches' })
        },
      })

      resetCurrentSelections()
      message.success('Swatch assignments were updated!')
    } catch (e) {
      console.error(e)
      message.error('Swatch assignments were not updated!')
    }
  }

  const debounceUpdate = useMemo(
    () => debounce(handleUpdateAssignments, 1000),
    []
  )

  const handleChange = (project, swatch, checked) => {
    if (checked) {
      setCurrentSelections((currentSelections) => {
        const copySelection = {
          assign: [...currentSelections.assign],
          remove: [...currentSelections.remove],
        }
        const index = copySelection.remove.findIndex(
          ([projectId, swatchId]) =>
            projectId === project.id && swatchId === swatch.id
        )

        if (index > -1) {
          copySelection.remove.splice(index, 1)
        } else {
          copySelection.assign.push([project.id, swatch.id])
        }

        return copySelection
      })
    } else {
      setCurrentSelections((currentSelections) => {
        const copySelection = {
          assign: [...currentSelections.assign],
          remove: [...currentSelections.remove],
        }
        const index = copySelection.assign.findIndex(
          ([projectId, swatchId]) =>
            projectId === project.id && swatchId === swatch.id
        )

        if (index > -1) {
          copySelection.assign.splice(index, 1)
        } else {
          copySelection.remove.push([project.id, swatch.id])
        }

        return copySelection
      })
    }
  }

  useEffect(() => {
    if (accountId) {
      debounceUpdate(currentSelections, accountId)
    }
  }, [currentSelections])

  const fetchMore = (type, count) => {
    const allowedTypes = ['swatches', 'projects']
    if (!allowedTypes.includes(type)) {
      throw Error(`Type must be ${allowedTypes.join(' or ')}`)
    }

    const totalCount = data?.swatchesAssignments[type].totalCount

    if (totalCount <= count) {
      return
    }

    // Before call fetch more we need to allow infinity scroll
    if (count / PAGE_SIZE === 1) {
      setBlockInfinityScroll(false)
    }

    apolloFetchMore({
      variables: {
        pagination: {
          ...variables.pagination,
          [type]: {
            page: Math.ceil(count / PAGE_SIZE),
            size: PAGE_SIZE,
          },
        },
      },
    })
  }

  return {
    initialSelections,
    currentSelections,
    loading,
    error,
    swatches,
    projects,
    fetchMore,
    handleChange,
  }
}
