import { css, StyleSheet } from 'aphrodite/no-important'
import React from 'react'

import { CredentialsForm } from '../components/forms/signin/CredentialsForm'

const styles = StyleSheet.create({
  dashboardTitle: {
    textAlign: 'center',
    color: 'gray',
    padding: '30px',
    margin: 'auto',
  },
})

const SignInPage = () => (
  <React.Fragment>
    <h2 className={css(styles.dashboardTitle)}>
      Sherwin-Williams Color Visualizer Dashboard
    </h2>
    <CredentialsForm nextRoute="/colors" />
  </React.Fragment>
)

export { SignInPage }
