import React from 'react'
import PropTypes from 'prop-types'
import ButtonGroup from 'antd/es/button/button-group'
import { Button, Popconfirm, Tooltip } from 'antd'
import { useMutation } from '@apollo/client'
import { useParams } from 'react-router-dom/cjs/react-router-dom'
import { DELETE_LAYER } from '../../../graphql/fcLayers.graphql'

export default function LayerTableActions({
  room,
  layer,
  setVisible,
  setLayerToUpdate,
  setLayerToCopy,
  setLayerToPreview,
}) {
  const params = useParams()
  const [deleteLayer, { loading }] = useMutation(DELETE_LAYER)

  const handleDeleteLayer = async (accountId, layerId) => {
    await deleteLayer({
      variables: {
        accountId,
        id: layerId,
      },
      update(cache) {
        const roomIdentity = cache.identify(room)
        const layerIdentity = cache.identify(layer)

        cache.modify({
          id: roomIdentity,
          fields: {
            layers(field) {
              return field.filter((item) => item.__ref !== layerIdentity)
            },
          },
        })

        cache.evict({ id: layerIdentity })
        cache.gc()
      },
    })
  }

  const handleEditLayer = (layer) => {
    setLayerToUpdate(layer)
    setVisible(true)
  }

  const handleCopyLayer = (layer) => {
    const copyLayer = {
      ...layer,
      name: `${layer.name} (Copy)`,
    }
    setLayerToCopy(copyLayer)
    setVisible(true)
  }

  return (
    <ButtonGroup>
      <Tooltip title="Display preview">
        <Button
          icon="zoom-in"
          size="large"
          onClick={() => setLayerToPreview(layer)}
        />
      </Tooltip>
      <Tooltip title="Copy flooring">
        <Button
          onClick={() => handleCopyLayer(layer)}
          type="primary"
          icon="copy"
          size="large"
        />
      </Tooltip>
      <Tooltip title="Edit flooring">
        <Button
          onClick={() => handleEditLayer(layer)}
          type="primary"
          icon="edit"
          size="large"
        />
      </Tooltip>
      <Popconfirm
        placement="left"
        title={`Are you sure you want to delete flooring ${layer.name}?`}
        onConfirm={() => handleDeleteLayer(Number(params.accountId), layer.id)}
        okText="Yes"
        cancelText="No"
      >
        <Tooltip title="Delete flooring">
          <Button type="danger" icon="delete" size="large" loading={loading} />
        </Tooltip>
      </Popconfirm>
    </ButtonGroup>
  )
}

LayerTableActions.propTypes = {
  room: PropTypes.object.isRequired,
  layer: PropTypes.object.isRequired,
  setVisible: PropTypes.func.isRequired,
  setLayerToUpdate: PropTypes.func.isRequired,
  setLayerToCopy: PropTypes.func.isRequired,
  setLayerToPreview: PropTypes.func.isRequired,
}
LayerTableActions.defaultProps = {}
