import React, { useRef, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Skeleton from './Skeleton'

export default function ImageSkeleton({
  src,
  style = {},
  alt,
  skeletonProps,
  onLoad,
  ...rest
}) {
  const [loading, setLoading] = useState(true)
  const imageRef = useRef()
  const isLoaded = src && (imageRef.current?.complete || !loading)

  useEffect(() => {
    setLoading(true)
  }, [src])

  return (
    <>
      <img
        ref={imageRef}
        src={src}
        onLoad={() => {
          onLoad()
          setLoading(false)
        }}
        {...rest}
        style={{
          ...style,
          ...(isLoaded ? null : { display: 'none' }),
        }}
        alt={alt}
      />

      {!isLoaded && <Skeleton style={style} {...skeletonProps} {...rest} />}
    </>
  )
}

ImageSkeleton.propTypes = {
  src: PropTypes.string.isRequired,
  style: PropTypes.object,
  skeletonProps: PropTypes.object,
  alt: PropTypes.string.isRequired,
  onLoad: PropTypes.func,
}

ImageSkeleton.defaultProps = {
  style: {},
  skeletonProps: {},
  onLoad: () => {},
}
