import { useEffect, useMemo, useState } from 'react'
import { debounce } from 'lodash'

export function useResizeTable(ref) {
  const [width, setWidth] = useState(null)
  // RESIZE
  const handleResize = () => setWidth(ref.current.getBoundingClientRect().width)
  const handleDebounceResize = useMemo(() => debounce(handleResize, 1000), [])
  useEffect(() => {
    handleResize()
    window.addEventListener('resize', handleDebounceResize)
    return () => window.removeEventListener('resize', handleDebounceResize)
  }, [])

  return width
}
