import React from 'react'
import PropTypes from 'prop-types'
import { css } from 'aphrodite/no-important'
import { Form } from 'antd'
import { classes } from './styles'

const mapChild = ({ loading, key, data, props, children }) => ({
  ...children,
  key: children.key || key,
  props: {
    ...children.props,
    ...props,
    parent: {
      loading,
      data,
    },
  },
})

export const Filter = ({ children, loading, data, ...props }) => {
  if (!children) return null

  // Map child components to items array, that will be rendered inside of filter
  // Valid filter components proper data and functions are mapped (component must have 'field' property)
  // Other components are just added
  const items = []
  if (Array.isArray(children)) {
    const dataIsArray = !data.set
    children.forEach((child) => {
      if (!child) return
      const key = child.props.field
      items.push(
        key
          ? mapChild({
              loading,
              key,
              data: dataIsArray ? data?.[key] : data,
              props,
              children: child,
            })
          : child
      )
    })
  } else if (children) {
    const key = children.props.field
    items.push(
      key ? mapChild({ loading, key, data, props, children }) : children
    )
  }

  return (
    <Form layout="inline" className={css(classes.filter)}>
      {items}
    </Form>
  )
}

Filter.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.func,
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.arrayOf(PropTypes.func),
  ]),
  loading: PropTypes.bool.isRequired,
  data: PropTypes.object,
}

Filter.defaultProps = {
  children: null,
  data: null,
}
