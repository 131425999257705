import React from 'react'
import PropTypes from 'prop-types'
import { useQuery } from '@apollo/client'
import { css, StyleSheet } from 'aphrodite/no-important'
import Title from 'antd/es/typography/Title'
import { MASKS_BY_ROOM_ID } from '../../../../graphql/fcMasks.graphql'
import Alert from '../../../common/Alert'
import { getMaskCloudinaryPath } from '../../functions/getMaskCloudinaryPath'
import SmallCard from '../../../common/SmallCard'

const classes = StyleSheet.create({
  schemes: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
  },
})

export default function MaskSelection({
  roomId,
  onSelect,
  selectedMask,
  cloudFolderName,
}) {
  const { loading, data, error } = useQuery(MASKS_BY_ROOM_ID, {
    variables: {
      id: roomId,
    },
  })

  if (error) {
    return <Alert />
  }

  return (
    <>
      <Title level={3}>Select Mask</Title>
      <div className={css(classes.schemes)}>
        {data?.masksByRoomId.map((mask) => (
          <SmallCard
            key={mask?.id}
            loading={loading}
            selected={!loading && selectedMask?.id === mask?.id}
            data={{
              ...mask,
              name: mask?.material?.display_name,
              imageSrc: getMaskCloudinaryPath(
                cloudFolderName,
                mask?.image ?? 'generic',
                roomId,
                'w_90,h_90,c_fill'
              ),
            }}
            onSelect={onSelect}
          />
        ))}
      </div>
    </>
  )
}

MaskSelection.propTypes = {
  roomId: PropTypes.number.isRequired,
  onSelect: PropTypes.func.isRequired,
  selectedMask: PropTypes.number.isRequired,
  cloudFolderName: PropTypes.string.isRequired,
}
MaskSelection.defaultProps = {}
