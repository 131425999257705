import { useMemo } from 'react'

/**
 * Picks selected account based on selection.
 *
 * @param {[Account]} accounts - Account array
 * @param {SelectionContext} selection - Current selection
 * @returns {(Account | undefined)}
 */
export const useSelectedAccount = (accounts, selection) => {
  return useMemo(() => {
    const { currentAccountId } = selection
    return accounts.find((account) => account.id === currentAccountId)
  }, [accounts, selection.currentAccountId])
}
