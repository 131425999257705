import React, { useMemo, useState } from 'react'
import { Button, Tooltip } from 'antd'
import { StyleSheet, css } from 'aphrodite/no-important'
import { useQuery } from '@apollo/client'
import PropTypes from 'prop-types'
import { GET_SWATCHES } from '../../../graphql/fcSwatches.graphql'
import { getSwatchesCloudinaryPath } from '../../../functions/getSwatchesCloudinaryPath'
import SwatchTableActions from './SwatchTableActions'
import Alert from '../../common/Alert'
import Table, { TABLE_ITEMS_PER_PAGE } from '../../common/Table'
import SwatchFilters from './SwatchFilters'
import ImageSkeleton from '../../common/skeletons/ImageSkeleton'

const classes = StyleSheet.create({
  img: {
    maxWidth: 100,
    maxHeight: 100,
  },
  tooltipImage: {
    maxWidth: 150,
    minWidth: 150,
    minHeight: 150,
  },
  tooltip: {
    maxWidth: 550,
  },
  alert: {
    margin: 9,
  },
  tableActions: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: 8,
  },
  addButton: {
    marginLeft: 'auto',
  },
})

const columns = (handleEditSwatch, sort, setSort) => [
  {
    title: 'Image',
    dataIndex: 'image',
    key: 'image',
    width: 120,
    render(fileName, swatch) {
      const src = fileName && getSwatchesCloudinaryPath(fileName)

      if (src) {
        return (
          <Tooltip
            placement="right"
            overlayClassName={css(classes.tooltip)}
            title={
              <img className={css(classes.tooltipImage)} src={src} alt="room" />
            }
          >
            <ImageSkeleton
              className={css(classes.img)}
              skeletonProps={{ style: { width: 100, height: 100 } }}
              src={src}
              alt={swatch.name}
            />
          </Tooltip>
        )
      }
      return 'No Image'
    },
  },
  {
    title: 'Name',
    sorter: true,
    dataIndex: 'name',
    key: 'name',
    sortOrder: sort,
    onHeaderCell: () => ({
      onClick: () => setSort(sort === 'ascend' ? 'descend' : 'ascend'),
    }),
  },
  {
    title: 'Product Line',
    dataIndex: 'product_name',
    key: 'product_name',
    render(_, swatch) {
      return swatch?.vendor.product_name
    },
  },
  {
    title: 'Product Identifier',
    dataIndex: 'product_identifier_prefix',
    key: 'product_identifier_prefix',
    render(_, swatch) {
      if (swatch?.vendor.product_identifier_prefix && swatch.identifier) {
        return `${swatch?.vendor.product_identifier_prefix} ${swatch.identifier}`
      }

      return 'None'
    },
  },
  {
    title: 'Actions',
    dataIndex: 'actions',
    key: 'actions',
    render(_, swatch) {
      return (
        <SwatchTableActions
          swatch={swatch}
          handleEditSwatch={handleEditSwatch}
        />
      )
    },
    width: 200,
  },
]

function SwatchTable({
  handleEditSwatch,
  setIsModalVisible,
  currentPage,
  setCurrentPage,
}) {
  const [sort, setSort] = useState('ascend')
  const [filters, setFilters] = useState({})
  const [tableOrder, setTableOrder] = useState([
    {
      name: 'name',
      order: 'ASC',
    },
  ])
  const { accountId, subAccountId, ...filterFields } = filters
  const { loading, data, error } = useQuery(GET_SWATCHES, {
    variables: {
      pagination: {
        size: TABLE_ITEMS_PER_PAGE,
        page: currentPage,
      },
      ordering: {
        columns: tableOrder,
      },
      filter: {
        accountId: subAccountId || accountId,
        ...filterFields,
      },
    },
    fetchPolicy: 'no-cache',
  })

  const swatches = useMemo(
    () =>
      data?.swatches.items?.map((swatch) => ({ ...swatch, key: swatch.id })),
    [data?.swatches.items]
  )

  return (
    <>
      {error && <Alert margin />}
      <>
        <div className={css(classes.tableActions)}>
          <SwatchFilters filters={filters} setFilters={setFilters} />
          <Button
            type="primary"
            icon="plus"
            className={css(classes.addButton)}
            onClick={() => setIsModalVisible(true)}
          >
            Add Swatch
          </Button>
        </div>
        {data?.swatches && (
          <Table
            loading={loading}
            columns={columns(handleEditSwatch, sort, setSort)}
            data={swatches}
            emptyText="There are currently no active swatches to display"
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            totalCount={data?.swatches.totalCount}
            setTableOrder={setTableOrder}
            itemsPerPage={TABLE_ITEMS_PER_PAGE}
          />
        )}
      </>
    </>
  )
}

export default React.memo(SwatchTable)

SwatchTable.propTypes = {
  handleEditSwatch: PropTypes.func.isRequired,
  setIsModalVisible: PropTypes.func.isRequired,
  currentPage: PropTypes.number.isRequired,
  setCurrentPage: PropTypes.func.isRequired,
  filters: PropTypes.shape({
    name: PropTypes.string,
    accountId: PropTypes.number,
    subAccountId: PropTypes.number,
    projectId: PropTypes.number,
  }),
}

SwatchTable.defaultProps = {
  filters: null,
}
