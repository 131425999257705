import React from 'react'
import PropTypes from 'prop-types'
import { Button, Modal } from 'antd'
import RoomImage from '../../common/RoomImage'

export default function SchemePreviewModal({
  scheme,
  account,
  room,
  project,
  onClose,
}) {
  return (
    <Modal
      visible={!!scheme}
      title={`Preview of scheme - ${scheme?.name}`}
      className="full-screen"
      width="100%"
      bodyStyle={{
        height: 'calc(100vh - 10px - 55px - 53px)',
      }}
      onCancel={() => onClose()}
      footer={[
        <Button key="back" onClick={() => onClose()}>
          Close
        </Button>,
      ]}
    >
      <RoomImage
        scheme={scheme}
        account={account}
        room={room}
        project={project}
      />
    </Modal>
  )
}

SchemePreviewModal.propTypes = {
  scheme: PropTypes.object.isRequired,
  account: PropTypes.object.isRequired,
  room: PropTypes.object.isRequired,
  project: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
}
SchemePreviewModal.defaultProps = {}
