import React from 'react'
import PropTypes from 'prop-types'
import { Card, message } from 'antd'
import Meta from 'antd/es/card/Meta'
import { useMutation, useQuery } from '@apollo/client'
import { css, StyleSheet } from 'aphrodite/no-important'
import CardsSkeleton from '../../common/skeletons/CardsSkeleton'
import {
  GET_SCHEMES_BY_ROOM_ID,
  DELETE_SCHEME,
} from '../../../graphql/fcSchemes.graphql'
import CardActionSkeleton from '../masks/CardActionSkeleton'
import SchemesCardPattern from './SchemesCardPattern'
import SchemesCardActions from './SchemesCardActions'
import Alert from '../../common/Alert'

const CARD_WIDTH = 300
const classes = StyleSheet.create({
  cards: {
    display: 'grid',
    gridTemplateColumns: `repeat(auto-fill, ${CARD_WIDTH}px)`,
    gridTemplateRows: 'repeat(auto-fill, 314px)',
    gap: 10,
  },
  card: {
    width: CARD_WIDTH,
  },
})

export default function SchemesCardList({
  onEditClick,
  roomId,
  account,
  onShowPreview,
}) {
  const { data, loading, error } = useQuery(GET_SCHEMES_BY_ROOM_ID, {
    variables: {
      roomId,
    },
  })
  const [deleteScheme, { loading: deleteSchemeLoading }] =
    useMutation(DELETE_SCHEME)

  const handleRemoveScheme = async (scheme) => {
    try {
      await deleteScheme({
        variables: {
          id: scheme.id,
        },
        update(cache, { data }) {
          cache.modify({
            fields: {
              roomSchemesByRoomId(existingSchemes, { readField }) {
                return existingSchemes.filter(
                  (scheme) =>
                    readField('id', scheme) !== data.deleteRoomScheme.id
                )
              },
            },
          })
        },
      })
      message.success('Scheme was successfully deleted!')
    } catch (e) {
      console.error(e)
      message.error('Scheme was not deleted!')
    }
  }

  return (
    <>
      {data?.roomSchemesByRoomId?.length <= 0 && (
        <Alert
          type="info"
          message="No scheme inserted!"
          description="Please insert mask to start using floor covering."
        />
      )}
      {error && <Alert />}
      <div className={css(classes.cards)}>
        {loading && <CardsSkeleton width={CARD_WIDTH} />}
        {data?.roomSchemesByRoomId?.map((scheme) => (
          <Card
            disabled
            key={scheme.id}
            className={css(classes.card)}
            cover={
              <SchemesCardPattern
                scheme={scheme}
                roomId={roomId}
                account={account}
              />
            }
            actions={
              deleteSchemeLoading
                ? null
                : SchemesCardActions({
                    onEditClick,
                    onRemoveClick: handleRemoveScheme,
                    scheme,
                    onShowPreview,
                  })
            }
          >
            {!deleteSchemeLoading && <Meta title={scheme.name} />}
            {deleteSchemeLoading && <CardActionSkeleton />}
          </Card>
        ))}
      </div>
    </>
  )
}

SchemesCardList.propTypes = {
  account: PropTypes.object.isRequired,
  onEditClick: PropTypes.func.isRequired,
  roomId: PropTypes.number.isRequired,
  onShowPreview: PropTypes.func.isRequired,
}
SchemesCardList.defaultProps = {}
