import React, { useMemo, useState } from 'react'
import { Form, Modal, message } from 'antd'
import PropTypes from 'prop-types'
import { gql, useMutation } from '@apollo/client'
import MaskForm from './MaskForm'
import { ADD_MASK, UPDATE_MASK } from '../../../graphql/fcMasks.graphql'
import { useAccountMaterials } from '../../../hooks/useAccountWithMaterials'

function MaskModal({
  form,
  visible,
  handleCloseModal,
  mask,
  roomId,
  account,
  usedMaterials,
}) {
  const [file, setFile] = useState(null)
  const { loading: materialsLoading, data } = useAccountMaterials(account.id)
  const [updateMask, { loading: updateLoading }] = useMutation(UPDATE_MASK)
  const [addMask, { loading: addLoading }] = useMutation(ADD_MASK, {
    update(cache, { data }) {
      cache.modify({
        fields: {
          masksByRoomId(existingMasks) {
            const newMask = cache.writeFragment({
              data: data.addMask,
              fragment: gql`
                fragment NewMask on Mask {
                  id
                }
              `,
            })
            return [newMask, ...existingMasks]
          },
        },
      })
    },
  })
  const handleClose = () => {
    handleCloseModal()
    setFile(null)
    form.resetFields()
  }

  const handleUpdateRoom = async (maskId, maskToUpdate) => {
    try {
      await updateMask({
        variables: {
          id: maskId,
          input: maskToUpdate,
        },
      })
      message.success('Mask was successfully updated!')
    } catch (e) {
      message.error('Mask was not updated!')
    } finally {
      handleClose()
    }
  }

  const handleAddRoom = async (mask, roomId) => {
    const maskToAdd = {
      roomId,
      ...mask,
    }
    try {
      await addMask({
        variables: {
          input: maskToAdd,
        },
      })
      message.success('Mask was successfully added!')
    } catch (e) {
      message.error('Mask was not added!')
      console.error(e)
    } finally {
      handleClose()
    }
  }

  const handleSubmit = async (maskToEdit) => {
    let maskData = {}
    try {
      maskData = await form.validateFields()
    } catch (e) {
      message.error('Form is not valid!')
      return
    }

    maskData = { ...maskData, ...(file ? { imageFile: file } : {}) }

    if (mask) {
      await handleUpdateRoom(maskToEdit.id, maskData)
    } else {
      await handleAddRoom(maskData, roomId)
    }
  }

  const loading = updateLoading || addLoading || materialsLoading

  // We need to filter out materials which are already used
  const availableMaterials = useMemo(
    () =>
      data?.materials?.filter((material) => {
        return (
          // material which belongs to mask which we are updating must be at list
          (mask?.material?.id && mask.material.id === material.id) ||
          // Get rid of materials which are already used
          !usedMaterials.includes(material.id)
        )
      }) || [],
    [usedMaterials, data?.materials, mask]
  )

  return (
    <Modal
      title={mask ? 'Edit Mask' : 'Add Mask'}
      visible={visible}
      onOk={() => handleSubmit(mask)}
      onCancel={() => handleClose()}
      okText={mask ? 'Update' : 'Add'}
      okButtonProps={{ loading }}
    >
      {!materialsLoading && (
        <MaskForm
          form={form}
          mask={mask}
          setFile={setFile}
          visible={visible}
          roomId={roomId}
          account={account}
          materials={availableMaterials}
        />
      )}
    </Modal>
  )
}

MaskModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  mask: PropTypes.object,
  form: PropTypes.object.isRequired,
  roomId: PropTypes.number.isRequired,
  account: PropTypes.object.isRequired,
  usedMaterials: PropTypes.array.isRequired,
}

MaskModal.defaultProps = {
  mask: null,
}

export default Form.create()(MaskModal)
