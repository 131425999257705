/* eslint-disable react/prop-types */
import { Button, Form, Icon, Popconfirm, Table } from 'antd'
import { css, StyleSheet } from 'aphrodite/no-important'
// import changeCase from 'change-case'
import PropTypes from 'prop-types'
import React, { Component } from 'react'

import { compose } from 'recompose'

import { ViewForm } from './ViewForm'

import {
  ADD_BUILDING,
  DELETE_BUILDING_BY_ID,
  UPDATE_BUILDING_BY_ID,
  BUILDINGS_BY_PROPERTY,
} from '../../../graphql/buildings.graphql'

import { ADD_VIEW } from '../../../graphql/views.graphql'

import {
  generateAddFormItemRequiredInput,
  generateBuildingURL,
  generateUnsafeUpdateFormItemInput,
  generateUpdateFormItemInput,
  withMutationAsProp,
} from '../../../utils'

const { Item } = Form

const styles = StyleSheet.create({
  addBuildingForm: {
    border: '1px solid #d8dee2',
    borderRadius: '10px',
    padding: '0px 10px 0px 10px',
    margin: 'auto',
  },
  updateBuildingForm: {
    padding: '0px 10px 0px 10px',
    margin: 'auto',
  },
})

const updateBuildingFormItemLayout = {
  labelCol: {
    // 1600px ≤ width
    xxl: {
      span: 6,
    },
    // 1200px ≤ width < 1600px
    xl: {
      span: 6,
    },
    // 992px ≤ width < 1200px
    lg: {
      span: 6,
    },
    // 768px ≤ width < 992px
    md: {
      span: 8,
    },
    // 576px ≤ width < 768px
    sm: {
      span: 14,
    },
    // width < 576px and also default setting
    xs: {
      span: 24,
    },
  },
  wrapperCol: {
    xxl: {
      span: 18,
    },
    xl: {
      span: 18,
    },
    lg: {
      span: 18,
    },
    md: {
      span: 16,
    },
    sm: {
      span: 10,
    },
    xs: {
      span: 24,
    },
  },
}

class BuildingsForm extends Component {
  static propTypes = {
    form: PropTypes.object.isRequired,
    addBuilding: PropTypes.func.isRequired,
    addView: PropTypes.func.isRequired,
    account: PropTypes.shape({
      id: PropTypes.number.isRequired,
      account_name: PropTypes.string.isRequired,
      account_type: PropTypes.string.isRequired,
      parent_account: PropTypes.shape({
        id: PropTypes.number,
        account_name: PropTypes.string,
      }),
    }).isRequired,
    deleteBuildingById: PropTypes.func.isRequired,
    property: PropTypes.shape({
      buildings: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
          building_name: PropTypes.string,
          prospect_number: PropTypes.string,
          building_type: PropTypes.string,
          contact_first_name: PropTypes.string,
          contact_last_name: PropTypes.string,
          phone: PropTypes.string,
          email: PropTypes.string,
        })
      ).isRequired,
      is_shopping_cart_mode: PropTypes.bool.isRequired,
    }).isRequired,
    updateBuildingById: PropTypes.func.isRequired,
  }

  handleAddBuildingButtonPress = () => {
    const { property, addBuilding, addView, form } = this.props
    form.validateFieldsAndScroll(async (errors, { buildingName, division }) => {
      if (!errors) {
        console.log('Using form values: ', { buildingName, division })
        form.resetFields()
        const mutationResult = await addBuilding({
          parentId: property.id,
          input: {
            name: buildingName,
            division,
          },
        })
        console.log('addBuildingResult:', mutationResult)
        // If that succeeds, create a view as well.
        if (
          mutationResult &&
          mutationResult.data &&
          mutationResult.data.addBuilding
        ) {
          const addViewResult = await addView({
            parentId: mutationResult.data.addBuilding.id,
            input: { name: `${buildingName} Default View` },
          })
          console.log('addBuildingResult:', addViewResult)
        }
      }
    })
  }

  handleDeleteBuildingButtonPress = (buildingId) => {
    console.log('handleDeleteBuildingButtonPress: buildingId:', buildingId)
    // eslint-disable-next-line
    const { deleteBuildingById } = this.props
    deleteBuildingById({
      id: buildingId,
    })
  }

  handleUpdateBuildingFormItemInputChange = (
    buildingId,
    targetField,
    label,
    value
  ) => {
    console.log(
      `handleUpdateBuildingFormItemInputChange: buildingId: ${buildingId}, targetField: ${targetField}, label: ${label}, value: ${value}`
    )
    // eslint-disable-next-line
    const { updateBuildingById } = this.props
    updateBuildingById({
      id: buildingId,
      input: { [targetField]: value },
    })
  }

  render() {
    const { form, account, property } = this.props
    const { getFieldDecorator } = form
    return (
      <React.Fragment>
        <Form
          key="addBuildingForm"
          layout="inline"
          className={css(styles.addBuildingForm)}
        >
          {generateAddFormItemRequiredInput(
            getFieldDecorator,
            'buildingName',
            '* Building name'
          )}
          <Item>
            <Button type="primary" onClick={this.handleAddBuildingButtonPress}>
              Add Building
            </Button>
          </Item>
        </Form>
        <Table
          key="buildingsTable"
          size="middle"
          bordered
          pagination={false}
          dataSource={property.buildings.map((building) => ({
            key: building.id,
            ...building,
          }))}
          columns={[
            {
              title: 'Building Name',
              dataIndex: 'name',
              key: 'name',
              sorter: (a, b) => a.name.localeCompare(b.name),
              defaultSortOrder: 'ascend',
            },
            {
              key: 'url',
              title: 'Visualizer URL',
              render: (text, building) => {
                const buildingURL = generateBuildingURL(
                  account,
                  property,
                  building
                )
                return (
                  <a
                    href={buildingURL}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {buildingURL}
                  </a>
                )
              },
            },
            {
              key: 'delete',
              width: 150,
              render: (text, building) => (
                <Popconfirm
                  placement="bottomRight"
                  title="Delete this building?"
                  onConfirm={() =>
                    this.handleDeleteBuildingButtonPress(building.id)
                  }
                  okText="Yes"
                  cancelText="No"
                >
                  <Button type="danger">
                    <Icon type="delete" />
                  </Button>
                </Popconfirm>
              ),
            },
          ]}
          expandedRowRender={(record) => (
            <React.Fragment>
              <Form
                key="updateBuildingForm"
                className={css(styles.updateBuildingForm)}
              >
                {generateUpdateFormItemInput(
                  this.handleUpdateBuildingFormItemInputChange,
                  updateBuildingFormItemLayout,
                  record.id,
                  record.name,
                  'name',
                  'Building Name'
                )}
                {generateUnsafeUpdateFormItemInput(
                  this.handleUpdateBuildingFormItemInputChange,
                  updateBuildingFormItemLayout,
                  record.id,
                  record.street_address,
                  'street_address',
                  'Street Address (optional)'
                )}
              </Form>
              {record.views?.length > 0 && (
                <>
                  <div>View</div>
                  <ViewForm
                    account={account}
                    viewId={
                      // Assume there's only one view defined per building.
                      record.views?.[0]?.id || 0
                    }
                    buildingName={record.name}
                  />
                </>
              )}
            </React.Fragment>
          )}
        />
      </React.Fragment>
    )
  }
}

const WrappedComponent = compose(
  withMutationAsProp({
    gqlDocument: ADD_BUILDING,
    mutationPropName: 'addBuilding',
    // since we await addBuilding before addView mutation, and both queries
    // run the same refetch query we must await for the completion of the
    // addBuilding mutation's refetch query before firing the addView mutation.
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        gqlDocument: BUILDINGS_BY_PROPERTY,
        variables: ({ property }) => ({
          propertyId: property.id,
        }),
      },
    ],
  }),
  withMutationAsProp({
    gqlDocument: ADD_VIEW,
    mutationPropName: 'addView',
    refetchQueries: [
      {
        gqlDocument: BUILDINGS_BY_PROPERTY,
        variables: ({ property }) => ({
          propertyId: property.id,
        }),
      },
    ],
  }),
  withMutationAsProp({
    gqlDocument: DELETE_BUILDING_BY_ID,
    mutationPropName: 'deleteBuildingById',
    refetchQueries: [
      {
        gqlDocument: BUILDINGS_BY_PROPERTY,
        variables: ({ property }) => ({
          propertyId: property.id,
        }),
      },
    ],
  }),
  withMutationAsProp({
    gqlDocument: UPDATE_BUILDING_BY_ID,
    mutationPropName: 'updateBuildingById',
  }),
  Form.create()
)(BuildingsForm)

export { WrappedComponent as BuildingsForm }
