/* eslint-disable react/destructuring-assignment */
import React from 'react'
import PropTypes from 'prop-types'
import { Icon } from 'antd'
import { StyleSheet, css } from 'aphrodite'

import flow from 'lodash/fp/flow'

import { assoc, assocAll } from '../../utils/functions'
import { getTextColor, imageUrl } from '../../utils/helpers'

const largeElementCardStyle = {
  float: 'left',
  cursor: 'pointer',
  fontSize: '12px',
  fontWeight: 'bold',
  margin: '3px',
  width: '100px',
  height: '60px',
  border: '1px solid #ddd',
}

const schemePickerElementCardStyle = {
  float: 'left',
  cursor: 'pointer',
  fontSize: '12px',
  fontWeight: 'bold',
  margin: '0px',
  width: '170px',
  height: '60px',
  border: '1px solid #ddd',
}

const summaryElementCardStyle = {
  fontSize: '12px',
  fontWeight: 'bold',
  width: '130px',
  height: '80px',
  '@media (max-width: 550px)': {
    height: '40px !important',
  },
}

const brochureElementCardStyle = {
  fontSize: '10px',
  fontWeight: 'bold',
  width: '100px',
  height: '50px',
}

const styles = StyleSheet.create({
  cornerIcon: {
    position: 'absolute',
    bottom: '3px',
    right: '3px',
    zIndex: 10,
  },
  cvSwatch: {
    cursor: 'default',
    height: '80px',
    width: '150px',
    '@media (max-width: 550px)': {
      height: '50px',
      width: '100px',
    },
    '@media (max-width: 1200px)': {
      height: '50px',
      width: '100px',
    },
    '@media only screen and (min-device-width: 319px) and (max-device-width: 850px) and (min-device-height: 319px) and (max-device-height: 450px) and (orientation: landscape)':
      {
        height: '50px',
        width: '100px',
      },
  },
  swatch: {
    height: '80px',
    width: '150px',
    ':hover': {
      border: '1px solid black',
    },
    '@media (max-width: 550px)': {
      height: '50px',
      width: '100px',
    },
    '@media (max-width: 1200px)': {
      height: '50px',
      width: '100px',
    },
    '@media only screen and (min-device-width: 319px) and (max-device-width: 850px) and (min-device-height: 319px) and (max-device-height: 450px) and (orientation: landscape)':
      {
        height: '50px',
        width: '100px',
      },
  },
  summary: {
    height: '100px',
    width: '160px',
    '@media (max-width: 550px)': {
      height: '50px',
      width: '100px',
    },
    '@media (max-width: 1200px)': {
      height: '50px',
      width: '100px',
      margin: '2px',
    },
    '@media only screen and (min-device-width: 321px) and (max-device-width: 850px) and (min-device-height: 319px) and (max-device-height: 450px) and (orientation: landscape)':
      {
        height: '45px',
        width: '90px',
        lineHeight: '1',
      },
  },
  brochure: {
    height: '50px',
    width: '100px',
    fontSize: '15px',
    fontWeight: '150',
    margin: '5px',
  },
  schemePicker: {
    height: '80px',
    width: '170px',
    ':hover': {
      border: '1px solid black',
    },
    '@media (max-width: 550px)': {
      height: '50px',
      width: '100px',
    },
    '@media (max-width: 1200px)': {
      height: '50px',
      width: '100px',
    },
    '@media only screen and (min-device-width: 319px) and (max-device-width: 850px) and (min-device-height: 319px) and (max-device-height: 450px) and (orientation: landscape)':
      {
        height: '50px',
        width: '100px',
      },
  },
  swatchBody: {
    fontSize: '15px',
    fontWeight: '150',
    '@media (max-width: 1200px)': {
      fontSize: '10px',
    },
    '@media only screen and (min-device-width: 319px) and (max-device-width: 850px) and (min-device-height: 319px) and (max-device-height: 450px) and (orientation: landscape)':
      {
        fontSize: '10px',
      },
  },
  swatchOverlay: {
    width: '100%',
    height: '100%',
    padding: '4px',
    position: 'relative',
    fontSize: '15px',
    '@media (max-width: 1200px)': {
      fontSize: '10px',
    },
    '@media only screen and (min-device-width: 319px) and (max-device-width: 850px) and (min-device-height: 319px) and (max-device-height: 450px) and (orientation: landscape)':
      {
        fontSize: '10px',
      },
  },
})

class Swatch extends React.PureComponent {
  static propTypes = {
    color: PropTypes.shape({
      id: PropTypes.number,
      identifier: PropTypes.string,
      name: PropTypes.string,
      colorId: PropTypes.number,
      brickId: PropTypes.number,
      stoneId: PropTypes.number,
      customOverlayId: PropTypes.number,
      hex: PropTypes.string,
      swatch: PropTypes.string,
      identifier_prefix: PropTypes.string,
    }).isRequired,
    dirName: PropTypes.string.isRequired,
    material: PropTypes.string,
    onClick: PropTypes.func,
    selected: PropTypes.bool.isRequired,
    // The selected icon will take precedence over plus and close.
    cornerIconType: PropTypes.string,
    size: PropTypes.string.isRequired,
  }

  static defaultProps = {
    material: null,
    onClick: null,
    cornerIconType: null,
  }

  needsOverlay = () =>
    !this.props.color.hex &&
    (this.props.size === 'large' ||
      this.props.selected ||
      this.props.size === 'summary' ||
      this.props.size === 'schemePicker')

  style = () => {
    const isColorType = this.props.color.colorId !== null
    const textColor = isColorType ? getTextColor(this.props.color.hex) : 'fff'
    const { color, dirName } = this.props

    const commonStyle = {
      float: 'left',
      color: `#${textColor}`,
    }
    const applyColorAndBackgroundStyle = isColorType
      ? assoc('background', `#${color.hex}`)
      : assocAll({
          textShadow: '0 1px 0 rgba(0, 0, 0, 1)',
          backgroundImage: `url("${imageUrl({
            dirName,
            brickSrc: color.brickId && color.swatch,
            stoneSrc: color.stoneId && color.swatch,
            customOverlaySrc: color.customOverlayId && color.swatch,
            mods: 'w_100',
          })}")`,
        })
    const applySizeSpecificStyle = {
      small: assocAll({
        width: '20%',
        height: '30px',
        textAlign: 'center',
      }),
      large: assocAll(largeElementCardStyle),
      summary: assocAll(summaryElementCardStyle),
      schemePicker: assocAll(schemePickerElementCardStyle),
      brochure: assocAll(brochureElementCardStyle),
    }

    return flow(
      applyColorAndBackgroundStyle,
      applySizeSpecificStyle[this.props.size]
    )(commonStyle)
  }

  classNameSelector = (size, onClick) => {
    if (size === 'summary') {
      return css(styles.summary)
    }
    if (size === 'brochure') {
      return css(styles.brochure)
    }
    if (size === 'schemePicker') {
      return css(styles.schemePickerElementCardStyle)
    }
    if (onClick) {
      return css(styles.swatch)
    }
    return css(styles.cvSwatch)
  }

  hasIdentifier = (ident, identPrefix) => {
    if (ident === null) {
      return ''
    }
    if (identPrefix !== null) {
      return `${identPrefix} ${ident}`
    }
    return `${ident}`
  }

  render = () => (
    <div
      key={this.props.color.id}
      style={this.style()}
      className={this.classNameSelector(this.props.size, this.props.onClick)}
      onClick={this.props.onClick}
    >
      <div
        className={css(styles.swatchOverlay)}
        style={
          this.needsOverlay() ? { backgroundColor: 'rgba(0,0,0,0.3)' } : null
        }
      >
        {(this.props.size === 'large' ||
          this.props.size === 'summary' ||
          this.props.size === 'brochure' ||
          this.props.size === 'schemePicker') && (
          <React.Fragment>
            {this.props.material}
            <div className={css(styles.swatchBody)}>
              {this.hasIdentifier(
                this.props.color.identifier,
                this.props.color.identifier_prefix
              )}
              <div>{this.props.color.name}</div>
            </div>
          </React.Fragment>
        )}
        {this.props.selected && (
          <Icon
            type="check"
            className={
              this.props.size === 'large' || this.props.size === 'summary'
                ? css(styles.cornerIcon)
                : null
            }
          />
        )}
        {
          // If we already drew a checkmark, don't draw a plus or close icon.
          !this.props.selected && this.props.cornerIconType === 'plus' && (
            <Icon type="plus" className={css(styles.cornerIcon)} />
          )
        }
        {
          // If we already drew a checkmark, don't draw a plus or close icon.
          !this.props.selected && this.props.cornerIconType === 'close' && (
            <Icon type="close" className={css(styles.cornerIcon)} />
          )
        }
      </div>
    </div>
  )
}

export { Swatch }
