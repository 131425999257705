import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { StyleSheet, css } from 'aphrodite'
import { AssignmentsTable } from '../../common/AssignmentsTable'
import {
  withConsumer,
  withMutationAsProp,
  withQueryResultAsProp,
} from '../../../utils'
import { AuthenticationConsumer, SelectionConsumer } from '../../../contexts'
import {
  ADD_PROPERTY_SCHEME_ASSIGNMENT,
  REMOVE_PROPERTY_SCHEME_ASSIGNMENT,
  ACCOUNT_SCHEMES_PROPERTIES,
} from '../../../graphql/colors.graphql'
import { ErrorModal } from '../../common/ErrorModal'
import { AssignSchemesFilter, useDefaultFilter } from './AssignSchemesFilter'

const styles = StyleSheet.create({
  tableWrapper: {
    height: '80vh',
    width: '100%',
    '@media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 1)':
      {
        height: '70vh',
      },
  },
  filter: {
    margin: '15px',
  },
})

const propertyHasScheme = (scheme, property) =>
  property.schemes.some((s) => s.id === scheme.id)

const schemeAssignmentData = (schemes, properties) =>
  schemes.reduce(
    (schemesData, scheme) =>
      Object.assign(schemesData, {
        [scheme.id]: properties.reduce(
          (propertiesData, property) =>
            Object.assign(propertiesData, {
              [property.id]: propertyHasScheme(scheme, property),
            }),
          {}
        ),
      }),
    {}
  )

const filterByName = (data, search) => {
  let result
  if (data && search?.length > 0) {
    const searchNormalized = search.trim().toLowerCase()
    result = data.filter((item) =>
      item?.name?.toLowerCase().includes(searchNormalized)
    )
  } else if (data) {
    result = [...data]
  }
  return result?.sort((a, b) => a.name.localeCompare(b.name))
}

const AssignSchemesForm = ({
  addPropertySchemeAssignment,
  removePropertySchemeAssignment,
  account,
}) => {
  const { filter, setFilter } = useDefaultFilter()
  const schemes = filterByName(account.schemes, filter.schemeName)
  const properties = filterByName(account.properties, filter.projectName)
  const assignmentData =
    schemes && properties && schemeAssignmentData(schemes, properties)
  return (
    <div className={css(styles.tableWrapper)}>
      <div className={css(styles.filter)}>
        <AssignSchemesFilter filter={filter} setFilter={setFilter} />
      </div>
      <AssignmentsTable
        loading={false}
        anchor=""
        rowDataSource={schemes}
        columnDataSource={properties}
        rowHeader={account.schemes.name}
        columnHeader={account.properties.name}
        cellData={(scheme, property) => ({
          checked: assignmentData[scheme.id][property.id],
          deselectConfirmation: 'Remove scheme from this project?',
        })}
        onToggle={(scheme, property, wasChecked) => {
          const mutation = wasChecked
            ? removePropertySchemeAssignment
            : addPropertySchemeAssignment
          mutation({ property_id: property.id, scheme_id: scheme.id }).catch(
            ErrorModal
          )
        }}
      />
    </div>
  )
}

AssignSchemesForm.propTypes = {
  account: PropTypes.object,
  addPropertySchemeAssignment: PropTypes.func.isRequired,
  removePropertySchemeAssignment: PropTypes.func.isRequired,
}

AssignSchemesForm.defaultProps = {
  account: null,
}

const WrappedComponent = compose(
  withConsumer(AuthenticationConsumer, { propName: 'authentication' }),
  withConsumer(SelectionConsumer, { propName: 'selection' }),
  withQueryResultAsProp({
    gqlDocument: ACCOUNT_SCHEMES_PROPERTIES,
    variables: ({ selection }) => ({
      id: selection.currentSubAccountId || selection.currentAccountId,
    }),
    resultPropName: 'account',
    propName: 'account',
  }),
  withMutationAsProp({
    gqlDocument: ADD_PROPERTY_SCHEME_ASSIGNMENT,
    mutationPropName: 'addPropertySchemeAssignment',
    variables: ({
      // eslint-disable-next-line camelcase
      property_id,
      scheme_id,
    }) => ({ property_id, scheme_id }),
    refetchQueries: [
      {
        gqlDocument: ACCOUNT_SCHEMES_PROPERTIES,
        variables: ({ selection }) => ({
          id: selection.currentSubAccountId || selection.currentAccountId,
        }),
      },
    ],
  }),
  withMutationAsProp({
    gqlDocument: REMOVE_PROPERTY_SCHEME_ASSIGNMENT,
    mutationPropName: 'removePropertySchemeAssignment',
    variables: ({
      // eslint-disable-next-line camelcase
      property_id,
      scheme_id,
    }) => ({ property_id, scheme_id }),
    refetchQueries: [
      {
        gqlDocument: ACCOUNT_SCHEMES_PROPERTIES,
        variables: ({ selection }) => ({
          id: selection.currentSubAccountId || selection.currentAccountId,
        }),
      },
    ],
  })
)(AssignSchemesForm)

export { WrappedComponent as AssignSchemesForm }
