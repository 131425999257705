import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { generatePath } from 'react-router-dom'
import { css, StyleSheet } from 'aphrodite/no-important'
import { getRoomCloudinaryPath } from '../rooms/functions/getRoomCloudinaryPath'
import ImageSkeleton from './skeletons/ImageSkeleton'

const classes = StyleSheet.create({
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
    objectPosition: '50% 50%',
  },
})

const HEMI_BASE_URL = process.env.HEMI_SERVER_PREFIX
const BASE_SCHEME = `api/cv/client/:clientName/nbr/:nbrhoodName/rooms/:roomId/:roomName`
const HYBRID_SCHEME = `${BASE_SCHEME}/hybrid/scheme/:schemeId/:schemeName`

export const ROOM_LAYER_TYPE = 'RoomLayer'
export const ROOM_COLOR_TYPE = 'RoomColor'

function isLayer(element) {
  if (element.__typename) {
    return element.__typename === ROOM_LAYER_TYPE
  }

  return !!element.id
}

/**
 * @typedef RoomImageProps
 * @prop {Account} account
 * @prop {Room} room
 * @prop {Scheme} scheme
 */

/**
 *
 * @param {RoomImageProps} props
 */
export default function RoomImage({ account, room, scheme, project }) {
  const accountName = account?.account_name
  const cloudFolderName = account?.cloud_folder_name

  const baseImage =
    cloudFolderName &&
    room?.image &&
    getRoomCloudinaryPath(cloudFolderName, room.image, '')

  const imageUrl = useMemo(() => {
    if (!scheme) return baseImage

    const pathSegment = generatePath(HYBRID_SCHEME, {
      clientName: accountName,
      nbrhoodName: project,
      roomId: room.id,
      roomName: room.name,
      schemeId: scheme.id,
      schemeName: scheme.name,
    })

    // TODO: Only send overrides
    // Low priority

    const queryArgs = {
      mats: scheme.elements
        .map((element) => (isLayer(element) ? 0 : element.mask.materialId))
        .join(','),
      pals: scheme.elements
        .map((element) =>
          isLayer(element) ? element.id : element.color.palette_id
        )
        .join(','),
      sels: scheme.elements
        .map((element) =>
          isLayer(element) ? element.id : element.color.color_id
        )
        .join(','),
      key: room.id,
    }

    const overrideSegment = Object.entries(queryArgs)
      .map(([key, value]) => `${key}=${value}`)
      .join('&')

    return `${HEMI_BASE_URL}/${pathSegment}?${overrideSegment}`
  }, [accountName, room, scheme, baseImage])

  return <ImageSkeleton src={imageUrl} className={css(classes.image)} />
}

RoomImage.propTypes = {
  account: PropTypes.object.isRequired,
  room: PropTypes.object.isRequired,
  scheme: PropTypes.object,
  project: PropTypes.object.isRequired,
}

RoomImage.defaultProps = {
  scheme: null,
}
