import React, { useState } from 'react'
import { useQuery } from '@apollo/client'
import { ACCOUNTS } from '../graphql/accounts.graphql'
import Spinner from '../components/common/Spinner'
import Alert from '../components/common/Alert'
import SwatchAssignmentsTable from '../components/swatches/SwatchAssignments/SwatchAssignmentTable'
import SwatchAssignmentsFilter from '../components/swatches/SwatchAssignments/SwatchAssignmentsFilter'
import { useSelectionContext } from '../contexts'
import { useSwatchAssignments } from '../components/swatches/SwatchAssignments/hooks/useSwatchAssignments'

export default function SwatchAssignmentsPage() {
  const { currentAccountId } = useSelectionContext()
  const [swatchName, setSwatchName] = useState('')
  const [projectName, setProjectName] = useState('')
  const [blockInfinityScroll, setBlockInfinityScroll] = useState(true)
  const {
    initialSelections,
    error: assignmentError,
    currentSelections,
    swatches,
    projects,
    loading: assignmentLoading,
    handleChange,
    fetchMore,
  } = useSwatchAssignments({ swatchName, projectName, setBlockInfinityScroll })

  const {
    loading: accountsLoading,
    data: accountsData,
    error: accountsError,
  } = useQuery(ACCOUNTS)

  if (accountsLoading) {
    return <Spinner />
  }

  if (accountsError || assignmentError) {
    return <Alert margin />
  }

  const hasProjects = projects && projects.length > 0
  const hasSwatches = swatches && swatches.length > 0
  return (
    <div>
      <SwatchAssignmentsFilter
        accounts={accountsData.accounts}
        filters={{
          swatchName,
          setSwatchName,
          projectName,
          setProjectName,
        }}
      />
      {hasProjects && hasSwatches && (
        <SwatchAssignmentsTable
          key={currentAccountId}
          projects={projects}
          initialSelections={initialSelections}
          currentSelections={currentSelections}
          swatches={swatches}
          blockInfinityScroll={blockInfinityScroll}
          loading={assignmentLoading}
          handleChange={handleChange}
          fetchMore={fetchMore}
          swatchName={swatchName}
          projectName={projectName}
        />
      )}
      {!hasProjects && !projectName && (
        <Alert
          margin
          type="info"
          message="No projects!"
          description="If you want to assign swatches you must create a project first."
        />
      )}
      {!hasSwatches && !swatchName && (
        <Alert
          margin
          type="info"
          message="No swatches!"
          description="If you want to assign swatches you must create a swatch first."
        />
      )}
      {(!hasProjects || !hasSwatches) && (swatchName || projectName) && (
        <Alert
          margin
          type="info"
          message="No projects or swatches found!"
          description="No projects or swatches found for selected filter."
        />
      )}
    </div>
  )
}
