import { StyleSheet } from 'aphrodite/no-important'

export const classes = StyleSheet.create({
  root: {
    backgroundColor: '#e6e6e6',
    position: 'relative',
  },
  cell: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    width: '100%',
    height: '100%',
    padding: '4px 5px 8px',
  },
  rightBorder: {
    borderRight: '1px solid',
  },
  darkBorder: {
    borderColor: '#a7a7a7',
  },
  lightBorder: {
    borderColor: '#d0d0d0',
  },
  bottomBorder: {
    borderBottom: '1px solid',
  },
  infinitySpinner: {
    position: 'absolute',
    width: 70,
    height: 70,
    bottom: 0,
    right: 0,
  },
  column: {
    flexDirection: 'column',
  },
})
