import React from 'react'
import Spin from 'antd/lib/spin'
import { StyleSheet, css } from 'aphrodite'
import { PropTypes } from 'prop-types'

const classes = StyleSheet.create({
  root: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
})

export default function Spinner({ spinnerClass }) {
  return (
    <div className={css(classes.root, spinnerClass)}>
      <Spin size="large" />
    </div>
  )
}

Spinner.propTypes = {
  spinnerClass: PropTypes.object,
}

Spinner.defaultProps = {
  spinnerClass: null,
}
