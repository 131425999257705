import React from 'react'
import PropTypes from 'prop-types'
import { Button, Form, Input, message } from 'antd'
import { css, StyleSheet } from 'aphrodite/no-important'
import { useMutation } from '@apollo/client'
import {
  ADD_LAYER_MATERIAL,
  LAYER_MATERIALS,
} from '../../../graphql/fcMaterials.graphql'
import { ACCOUNT_WITH_MATERIALS } from '../../../graphql/accounts.graphql'

const classes = StyleSheet.create({
  form: {
    marginLeft: 'auto',
  },
  addButton: {
    marginTop: 4,
  },
})
function MaterialForm({ form, accountId }) {
  const [addLayerMaterial, { loading }] = useMutation(ADD_LAYER_MATERIAL, {
    refetchQueries: [
      { query: ACCOUNT_WITH_MATERIALS, variables: { id: accountId } },
      LAYER_MATERIALS,
    ],
  })
  const { getFieldDecorator } = form

  const handleSubmit = async () => {
    let layerMaterial = null
    try {
      layerMaterial = await form.validateFields()
    } catch (e) {
      message.error('Form is not valid!')
      return
    }

    try {
      await addLayerMaterial({
        variables: { input: { ...layerMaterial, accountId } },
      })

      form.resetFields()
    } catch (e) {
      message.error('Layer material was not added!')
    }
  }

  return (
    <>
      <Form layout="inline" className={css(classes.form)}>
        <Form.Item label="Material Name">
          {getFieldDecorator('displayName', {
            initialValue: '',
            rules: [
              {
                required: true,
                message: 'Please input material name!',
              },
            ],
          })(<Input />)}
        </Form.Item>
      </Form>
      <Button
        className={css(classes.addButton)}
        loading={loading}
        onClick={() => handleSubmit()}
        on
        type="primary"
        icon="plus"
      >
        Add Material
      </Button>
    </>
  )
}

export default Form.create()(MaterialForm)

MaterialForm.propTypes = {
  form: PropTypes.object.isRequired,
  accountId: PropTypes.number.isRequired,
}

MaterialForm.defaultProps = {}
