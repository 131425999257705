let block = false

export const useInfinitScroll = ({
  loading,
  pageSize,
  totalCount,
  currentCount,
  cb,
}) => {
  const onScroll = async (e) => {
    const panelHeight = e.target.getBoundingClientRect().height
    const { scrollTop } = e.target

    // Skip if loading or all items were loaded
    if (block || loading || currentCount >= totalCount) {
      return
    }

    if (e.target.scrollHeight < panelHeight + scrollTop + 50) {
      block = true
      await cb({
        page: Math.ceil(currentCount / pageSize),
        size: pageSize,
      })
      block = false
    }
  }

  return { onScroll }
}
