import PropTypes from 'prop-types'
import { Component } from 'react'

import { AuthenticationConsumer } from '../contexts'
import { withConsumer } from '../utils'

class SignOutPage extends Component {
  static propTypes = {
    authentication: PropTypes.shape({
      token: PropTypes.string,
      signIn: PropTypes.func,
      signOut: PropTypes.func,
    }).isRequired,
  }

  componentDidMount() {
    const { authentication } = this.props
    console.log('Signing out.')
    // Trigger a state change in the Authentication provider. This will
    // trigger a re-render, and AuthenticatedRoute will render a
    // <Redirect /> to the sign-in page.
    authentication.signOut()
  }

  // This componenet doesn't actually render anything, it just triggers an
  // update to the Authentication context state.
  render() {
    return null
  }
}

const WrappedComponent = withConsumer(AuthenticationConsumer, {
  propName: 'authentication',
})(SignOutPage)

export { WrappedComponent as SignOutPage }
