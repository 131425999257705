import React from 'react'
import PropTypes from 'prop-types'
import Alert from '../../../common/Alert'
import ColorSelection from './ColorSelection'
import LayerSelection from './LayerSelection'

export default function LayerAndColorSelection({
  roomId,
  mask,
  account,
  onSelect,
}) {
  return (
    <>
      {!mask && <Alert type="info" message="Select Mask to proceed!" />}

      {mask && (
        <>
          {mask.material.element_id === null && (
            <LayerSelection
              mask={mask}
              roomId={roomId}
              cloudFolder={account.cloud_folder_name}
              onLayerChange={onSelect}
            />
          )}
          {mask.material.element_id != null && (
            <ColorSelection
              mask={mask}
              materialId={mask?.material?.id}
              onColorChange={onSelect}
            />
          )}
        </>
      )}
    </>
  )
}

LayerAndColorSelection.propTypes = {
  roomId: PropTypes.number.isRequired,
  account: PropTypes.object.isRequired,
  onSelect: PropTypes.func.isRequired,
  mask: PropTypes.object,
}
LayerAndColorSelection.defaultProps = {
  mask: null,
}
