import { useMemo } from 'react'
import { Accounts } from '../const/accounts'

/**
 * @typedef WorkingAccountResult
 * @property {(Account | undefined)} selectedAccount - Selected account
 * @property {(Account | undefined)} selectedSubAccount - Selected child account
 */

/**
 * Picks selected account or child account based on selection.
 *
 * @param {[Account]} accounts - Account array
 * @param {SelectionContext} selection - Current selection
 * @returns {WorkingAccountResult}
 */
export const useWorkingAccount = (accounts, selection) => {
  return useMemo(() => {
    const { currentAccountId, currentSubAccountId } = selection
    const selectedAccount = accounts.find(
      (account) => account.id === currentAccountId
    )

    if (selectedAccount) {
      if (selectedAccount.sub_accounts.length > 0) {
        if (!currentSubAccountId) return {}
        if (currentAccountId === currentSubAccountId) {
          return {
            selectedAccount,
            selectedSubAccount: {
              ...selectedAccount,
              account_name: Accounts.DEFAULT_NAME,
            },
          }
        }

        const selectedSubAccount = selectedAccount.sub_accounts.find(
          (account) => account.id === currentSubAccountId
        )

        if (selectedSubAccount) return { selectedAccount, selectedSubAccount }
      } else {
        return { selectedAccount }
      }
    }

    return {}
  }, [accounts, selection.currentAccountId, selection.currentSubAccountId])
}
