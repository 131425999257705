import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Form, Modal, message, Row, Col } from 'antd'
import { gql, useMutation } from '@apollo/client'
import { css, StyleSheet } from 'aphrodite/no-important'
import SchemesForm from './SchemesForm'
import {
  UPDATE_SCHEME,
  ADD_SCHEME,
} from '../../../../graphql/fcSchemes.graphql'
import MaskSelection from './MaskSelection'
import LayerAndColorSelection from './LayerAndColorSelection'

const classes = StyleSheet.create({
  selectScheme: {
    padding: '0 40px',
  },
})

function SchemesModal({ scheme, visible, onClose, roomId, account, form }) {
  const { validateFields } = form
  const [selectedMask, setSelectedMask] = useState(null)
  const [maskSelection, setMaskSelection] = useState({})
  const [updateScheme, { loading: updateLoading }] = useMutation(UPDATE_SCHEME)
  const [addScheme, { addLoading }] = useMutation(ADD_SCHEME, {
    update(cache, { data }) {
      cache.modify({
        fields: {
          roomSchemesByRoomId(existingSchemes) {
            const newScheme = cache.writeFragment({
              data: data.addRoomScheme,
              fragment: gql`
                fragment NewScheme on Scheme {
                  id
                }
              `,
            })
            return [...existingSchemes, newScheme]
          },
        },
      })
    },
  })

  // If we are updating scheme me need special format to display current scheme
  useEffect(() => {
    if (scheme) {
      const maskSelectionToEdit = {}
      scheme.elements.forEach((element) => {
        if (element.__typename === 'RoomColor') {
          maskSelectionToEdit[element.mask.id] = {
            mask: element.mask,
            selected: element.color,
          }
        } else if (element.__typename === 'RoomLayer') {
          maskSelectionToEdit[element.mask.id] = {
            mask: element.mask,
            selected: element,
          }
        }
      })
      setMaskSelection(maskSelectionToEdit)
    }
  }, [scheme])

  const handleClose = (success = false) => {
    onClose(success, roomId)
    form.resetFields()
    setMaskSelection({})
  }

  const handleAddScheme = async (input) => {
    try {
      await addScheme({
        variables: { input },
      })
      message.success(`Scheme ${input.name} was added!`)
      handleClose(true)
    } catch (e) {
      console.error(e)
      message.error('Scheme was not added!')
      handleClose(false)
    }
  }

  const handleEditScheme = async (id, input) => {
    try {
      await updateScheme({
        variables: { id, input },
      })
      message.success(`Scheme ${input.name} was updated!`)
      handleClose(true)
    } catch (e) {
      console.error(e)
      message.error('Scheme was not updated!')
      handleClose(false)
    }
  }

  const handleSubmit = async (scheme) => {
    try {
      const formResult = await validateFields()

      const data = {
        layers: [],
        colors: [],
      }

      Object.values(maskSelection).forEach(({ selected, mask }) => {
        if (selected.__typename === 'PaletteSelection') {
          data.colors.push({ colorId: selected.id, maskId: mask.id })
        } else if (selected.__typename === 'RoomLayer') {
          data.layers.push(selected.id)
        }
      })

      const input = {
        ...formResult,
        ...data,
      }

      if (scheme) {
        await handleEditScheme(scheme.id, input)
      } else {
        await handleAddScheme(input)
      }
    } catch (e) {
      message.error('Form is not valid!')
    }
  }

  const handleSelectMask = (selected, mask) => {
    setMaskSelection((prev) => {
      return {
        ...prev,
        [mask.id]: {
          selected,
          mask,
        },
      }
    })
  }

  const handleUnselectMask = (mask) => {
    setMaskSelection((prev) => {
      const copy = { ...prev }
      delete copy[mask.id]
      return copy
    })
  }

  return (
    <Modal
      title={scheme ? 'Edit Scheme' : 'Add Scheme'}
      visible={visible}
      onOk={() => handleSubmit(scheme)}
      onCancel={() => handleClose(false)}
      okText={scheme ? 'Update' : 'Add'}
      bodyStyle={{
        height: 'calc(100vh - 10px - 55px - 53px)',
        overflowY: 'auto',
      }}
      width="100%"
      className="full-screen"
      okButtonProps={{ loading: updateLoading || addLoading }}
      destroyOnClose
    >
      <Row>
        <Col span={8}>
          <MaskSelection
            roomId={roomId}
            onSelect={(mask) => setSelectedMask(mask)}
            selectedMask={selectedMask}
            cloudFolderName={account.cloud_folder_name}
          />
        </Col>
        <Col span={8}>
          <LayerAndColorSelection
            roomId={roomId}
            mask={selectedMask}
            account={account}
            onSelect={handleSelectMask}
          />
        </Col>
        <Col span={8} className={css(classes.selectScheme)}>
          <SchemesForm
            form={form}
            scheme={scheme}
            maskSelection={maskSelection}
            onMaskUnselect={handleUnselectMask}
            roomId={roomId}
            cloudFolder={account.cloud_folder_name}
          />
        </Col>
      </Row>
    </Modal>
  )
}

SchemesModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  roomId: PropTypes.number.isRequired,
  account: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  scheme: PropTypes.object,
  onClose: PropTypes.object,
}

SchemesModal.defaultProps = {
  scheme: null,
  onClose: () => {},
}

export default Form.create()(SchemesModal)
