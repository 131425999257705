import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useQuery } from '@apollo/client'
import { css, StyleSheet } from 'aphrodite/no-important'
import { Divider } from 'antd'
import { GET_COLORS } from '../../../../graphql/fcSchemes.graphql'
import SmallCard from '../../../common/SmallCard'
import Alert from '../../../common/Alert'
import { useInfinitScroll } from '../../../../hooks/useInfinitScroll'
import Spinner from '../../../common/Spinner'
import { Filter, Input } from '../../../common/Filter'
import { PAGE_SIZE } from './const/pagination'

const classes = StyleSheet.create({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    alignContent: 'flex-start',
    height: 'calc(100vh - 270px)',
    overflowY: 'auto',
  },
  spinner: {
    zIndex: 1,
    backgroundColor: 'rgba(255, 255,255, .6)',
  },
  formItem: {
    paddingBottom: 8,
  },
  divider: {
    margin: 8,
  },
  input: {
    minWidth: 340,
  },
})

const getData = (color) => {
  let secondaryName = null
  if (color.product_identifier_prefix) {
    secondaryName = `${color.product_identifier_prefix}${color.identifier}`
  }

  return { ...color, secondaryName }
}

export default function ColorSelection({ materialId, mask, onColorChange }) {
  const [filterName, setFilterName] = useState(null)
  const [uniqueColors, setUniqueColors] = useState([])
  const { loading, data, error, fetchMore } = useQuery(GET_COLORS, {
    notifyOnNetworkStatusChange: true,
    skip: !mask || !materialId,
    variables: {
      filter: {
        materialId,
        maskId: mask?.id,
        name: filterName,
      },
      pagination: {
        page: 0,
        size: PAGE_SIZE,
      },
      ordering: {
        columns: [{ name: 'name', order: 'ASC' }],
      },
    },
  })

  useEffect(() => {
    if (data?.colors) {
      const uniqueColorsMap = new Map()

      data.colors.items.forEach((color) => {
        if (!uniqueColorsMap.has(color.color_id)) {
          uniqueColorsMap.set(color.color_id, color)
        }
      })

      setUniqueColors(Array.from(uniqueColorsMap.values()))
    }
  }, [data])

  const { onScroll } = useInfinitScroll({
    loading,
    pageSize: PAGE_SIZE,
    totalCount: data?.colors?.totalCount,
    currentCount: data?.colors?.items.length,
    cb: async (pagination) => {
      await fetchMore({
        variables: {
          pagination,
        },
      })
    },
  })

  const filter = {
    value: filterName,
    set: setFilterName,
  }

  return (
    <>
      {error && <Alert />}
      {uniqueColors && (
        <>
          <Filter loading={loading} data={filter}>
            <Input
              formItemClass={classes.formItem}
              inputClass={classes.input}
              field="name"
              label="Name"
              placeholder="Search by name ..."
            />
          </Filter>
          <Divider className={css(classes.divider)} />
        </>
      )}
      {data?.colors.totalCount === 0 && (
        <Alert
          type="info"
          message={`No colors${filterName?.length ? ' found' : ''}.`}
        />
      )}
      <div className={css(classes.root)} onScroll={onScroll}>
        {loading && <Spinner spinnerClass={classes.spinner} />}
        {uniqueColors.map((color) => (
          <SmallCard
            width={140}
            key={color?.id}
            data={getData(color)}
            onSelect={(color) => onColorChange(color, mask)}
          />
        ))}
      </div>
    </>
  )
}

ColorSelection.propTypes = {
  mask: PropTypes.object.isRequired,
  onColorChange: PropTypes.func.isRequired,
  materialId: PropTypes.number,
}
ColorSelection.defaultProps = {
  materialId: null,
}
