import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Modal } from 'antd'
import { css, StyleSheet } from 'aphrodite/no-important'
import ImageSkeleton from '../../common/skeletons/ImageSkeleton'
import { getRoomCloudinaryPath } from '../functions/getRoomCloudinaryPath'
import getLayerCloudinaryPath from '../functions/getLayerCloudinaryPath'
import Spinner from '../../common/Spinner'

const classes = StyleSheet.create({
  imageHolder: {
    position: 'relative',
    width: '100%',
    height: '100%',
    userSelect: 'none',
  },
  image: {
    left: '50%',
    transform: 'translate(-50%, -50%)',
    top: '50%',
    position: 'absolute',
    maxWidth: '100%',
    maxHeight: '100%',
  },
})
export default function LayerPreviewModal({
  layer,
  onClose,
  cloudFolder,
  room,
}) {
  const [imagesLoadedCount, setImagesLoadedCount] = useState(0)

  const isLoaded = imagesLoadedCount >= 2

  // Handle close
  useEffect(() => {
    setImagesLoadedCount(0)
  }, [layer])

  return (
    <>
      <Modal
        visible={!!layer}
        title={`Preview of flooring - ${layer?.name}`}
        className="full-screen"
        width="100%"
        bodyStyle={{
          height: 'calc(100vh - 10px - 55px - 53px)',
        }}
        onCancel={() => onClose()}
        footer={[
          <Button key="back" onClick={() => onClose()}>
            Close
          </Button>,
        ]}
      >
        {!isLoaded && <Spinner />}
        <div className={css(classes.imageHolder)}>
          <ImageSkeleton
            style={{ visibility: isLoaded ? 'visible' : 'hidden' }}
            alt={layer?.name}
            className={css(classes.image)}
            src={
              layer?.image &&
              getRoomCloudinaryPath(
                cloudFolder,
                room?.image,
                'c_fit,w_1920,h_1080'
              )
            }
            onLoad={() => setImagesLoadedCount((prev) => prev + 1)}
          />
          <ImageSkeleton
            style={{ visibility: isLoaded ? 'visible' : 'hidden' }}
            alt={layer?.name}
            className={css(classes.image)}
            src={
              layer?.image &&
              getLayerCloudinaryPath(
                cloudFolder,
                layer.image,
                room.id,
                'c_fit,w_1920,h_1080'
              )
            }
            onLoad={() => setImagesLoadedCount((prev) => prev + 1)}
          />
        </div>
      </Modal>
    </>
  )
}

LayerPreviewModal.propTypes = {
  layer: PropTypes.shape({
    name: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
  }),
  room: PropTypes.shape({
    id: PropTypes.number.isRequired,
    image: PropTypes.string.isRequired,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
  cloudFolder: PropTypes.string.isRequired,
}
LayerPreviewModal.defaultProps = {
  layer: null,
}
