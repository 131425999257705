import React from 'react'
import { Tabs } from 'antd'

import { compose } from 'recompose'
import PropTypes from 'prop-types'
import {
  AccountsForm,
  UsersForm,
  ManageTemplatesForm,
} from '../components/forms/admin'
import { ColorLibraryForm, ProductLinesForm } from '../components/forms/colors'
import { withConsumer } from '../utils'
import { SelectionConsumer } from '../contexts'
import MaterialTabs from '../components/forms/admin/MaterialTabs'

const { TabPane } = Tabs

const AdminPage = (props) => {
  const { selection } = props
  const clearCollectionOnTabChange = () => {
    selection.setCurrentCollectionId(null)
  }
  return (
    <React.Fragment>
      <Tabs onChange={clearCollectionOnTabChange}>
        <TabPane tab="Customer Accounts" key="1">
          <AccountsForm />
        </TabPane>
        <TabPane tab="Dashboard Users" key="2">
          <UsersForm />
        </TabPane>
        <TabPane tab="Manage Materials" key="3">
          <MaterialTabs />
        </TabPane>
        <TabPane tab="Manage Colors" key="4">
          <ColorLibraryForm />
        </TabPane>
        <TabPane tab="Manage Product Lines" key="5">
          <ProductLinesForm />
        </TabPane>
        <TabPane tab="Manage Templates" key="6">
          <ManageTemplatesForm />
        </TabPane>
      </Tabs>
    </React.Fragment>
  )
}

AdminPage.propTypes = {
  selection: PropTypes.shape({
    currentAccountId: PropTypes.number,
    currentCollectionId: PropTypes.number,
    setCurrentAccountId: PropTypes.func,
    setCurrentPaletteId: PropTypes.func,
    setCurrentCollectionId: PropTypes.func,
  }).isRequired,
}

const WrappedComponent = compose(
  withConsumer(SelectionConsumer, { propName: 'selection' })
)(AdminPage)

export { WrappedComponent as AdminPage }
