import React from 'react'
import PropTypes from 'prop-types'
import { Drawer } from 'antd'

export default function ModalDrawer({
  children,
  visible,
  setVisible,
  title,
  ...props
}) {
  return (
    <Drawer
      title={title}
      placement="right"
      closable
      onClose={() => setVisible(false)}
      visible={visible}
      width={400}
      {...props}
    >
      {children}
    </Drawer>
  )
}

ModalDrawer.propTypes = {
  children: PropTypes.object.isRequired,
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
}
ModalDrawer.defaultProps = {}
