import React from 'react'
import { Form, Modal, message } from 'antd'
import PropTypes from 'prop-types'
import { useMutation } from '@apollo/client'
import ProductLineForm from './ProductLineForm'
import {
  ADD_PRODUCT_LINE,
  UPDATE_PRODUCT_LINE,
  GET_PRODUCT_LINES,
} from '../../../graphql/fcPruductLines.graphql'
import { swatchFormGetProductLinesVariables } from '../SwatchModal/SwatchForm'

const DUPLICATE_NAME_ERROR_TYPE = 'duplicate_product_name'

const MUTATION_CACHE_PROPS = {
  refetchQueries: [GET_PRODUCT_LINES],
  // Update cache for SwatchModal
  update(cache, { data }) {
    const previousData = cache.readQuery({
      query: GET_PRODUCT_LINES,
      variables: swatchFormGetProductLinesVariables,
    })

    const items = [
      {
        ...data.addSwatchVendorProductInfo,
        __typename: 'SwatchVendorProductInfo',
      },
      ...(previousData?.swatchesAssignmentsResultInfos?.items || []),
    ].sort((a, b) => a.product_name.localeCompare(b.product_name))

    cache.writeQuery({
      query: GET_PRODUCT_LINES,
      variables: swatchFormGetProductLinesVariables,
      data: {
        swatchesAssignmentsResultInfos: {
          items,
          totalCount: items.length,
        },
      },
    })
  },
}

function ProductLineModal({ form, visible, handleCloseModal, productLine }) {
  const [updateProductLine, { loading: updateLoading }] = useMutation(
    UPDATE_PRODUCT_LINE,
    MUTATION_CACHE_PROPS
  )
  const [addProductLine, { loading: addLoading }] = useMutation(
    ADD_PRODUCT_LINE,
    MUTATION_CACHE_PROPS
  )

  const handleClose = () => {
    handleCloseModal()
    form.resetFields()
  }

  const handleUpdateSwatch = async (productLineId, productLineToUpdate) => {
    try {
      await updateProductLine({
        variables: {
          id: productLineId,
          input: productLineToUpdate,
        },
      })
      message.success('Product line was successfully updated!')
      handleClose()
    } catch (err) {
      const errorType = err?.graphQLErrors?.[0]?.extensions?.type

      if (errorType === DUPLICATE_NAME_ERROR_TYPE) {
        form.setFields({
          product_name: {
            value: form.getFieldValue('product_name'),
            errors: [
              new Error('A Product Line with this name already exists!'),
            ],
          },
        })
      } else {
        message.error('Product line was not updated!')
        console.error(err)
        handleClose()
      }
    }
  }

  const handleAddSwatch = async (productLine) => {
    try {
      await addProductLine({
        variables: {
          input: productLine,
        },
      })
      message.success('Product line was successfully added!')
      handleClose()
    } catch (err) {
      const errorType = err?.graphQLErrors?.[0]?.extensions?.type

      if (errorType === DUPLICATE_NAME_ERROR_TYPE) {
        form.setFields({
          product_name: {
            value: form.getFieldValue('product_name'),
            errors: [
              new Error('A Product Line with this name already exists!'),
            ],
          },
        })
      } else {
        message.error('Product line was not added!')
        console.error(err)
        handleClose()
      }
    }
  }

  const handleSubmit = async (productLineToUpdate) => {
    let productLineData = null
    try {
      productLineData = await form.validateFields()
    } catch (e) {
      message.error('Form is not valid!')
      return
    }

    if (productLine) {
      await handleUpdateSwatch(productLineToUpdate.id, productLineData)
    } else {
      await handleAddSwatch(productLineData)
    }
  }

  return (
    <Modal
      title={productLine ? 'Edit Product Line' : 'Add Product Line'}
      visible={visible}
      onOk={() => handleSubmit(productLine)}
      onCancel={() => handleClose()}
      okText={productLine ? 'Update' : 'Add'}
      okButtonProps={{ loading: updateLoading || addLoading }}
    >
      <ProductLineForm productLine={productLine} form={form} />
    </Modal>
  )
}

ProductLineModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  productLine: PropTypes.object,
  form: PropTypes.object.isRequired,
}

ProductLineModal.defaultProps = {
  productLine: null,
}

export default Form.create()(ProductLineModal)
