/* eslint-disable react/prop-types */
import { Button, Form, Select } from 'antd'
import { css, StyleSheet } from 'aphrodite/no-important'
// import changeCase from 'change-case'
import PropTypes from 'prop-types'
import React, { Component } from 'react'

import { compose } from 'recompose'

import { withRouter } from 'react-router-dom'
import { clearCacheByPlanQuery } from '../../../utils/hemi'
import { executeQuery } from '../../../utils/restQueryBuilder'
import { StyledDropzone } from '../../common/StyledDropzone'

import { AuthenticationConsumer, SelectionConsumer } from '../../../contexts'

import { LayersForm } from './LayersForm'

import { ACCOUNT_WITH_ID_NAME_AND_CLOUD_FOLDER_AND_MATERIALS } from '../../../graphql/accounts.graphql'
import {
  INITIALIZE_VIEW_FROM_VIEW_TEMPLATE,
  VIEW_TEMPLATES,
} from '../../../graphql/brochures.graphql'
import { VIEW_AND_ELEMENTS_AND_BUILDING_BY_VIEW_ID } from '../../../graphql/buildings.graphql'
import { UPLOAD_FILE } from '../../../graphql/uploads.graphql'
import { UPDATE_VIEW_BY_ID } from '../../../graphql/views.graphql'

// import {
//   VIEWS_BY_BUILDING,
// } from '../../../graphql/buildings.graphql'

import {
  generateUpdateFormItemInput,
  withConsumer,
  withMutationAsProp,
  withQueryResultAsProp,
} from '../../../utils'

const { Item } = Form
const { Option } = Select

const styles = StyleSheet.create({
  dropzoneBaseStyle: {
    width: 150,
    height: 150,
    borderWidth: 2,
    borderColor: 'lightgray',
    borderStyle: 'dashed',
    borderRadius: 5,
    backgroundColor: 'darkgray',
  },
  updateViewForm: {
    border: '1px solid #d8dee2',
    borderRadius: '10px',
    padding: '0px 10px 0px 10px',
    margin: 'auto',
  },
})

const updateViewFormItemLayout = {
  labelCol: {
    // 1600px ≤ width
    xxl: {
      span: 6,
    },
    // 1200px ≤ width < 1600px
    xl: {
      span: 6,
    },
    // 992px ≤ width < 1200px
    lg: {
      span: 6,
    },
    // 768px ≤ width < 992px
    md: {
      span: 8,
    },
    // 576px ≤ width < 768px
    sm: {
      span: 14,
    },
    // width < 576px and also default setting
    xs: {
      span: 24,
    },
  },
  wrapperCol: {
    xxl: {
      span: 18,
    },
    xl: {
      span: 18,
    },
    lg: {
      span: 18,
    },
    md: {
      span: 16,
    },
    sm: {
      span: 10,
    },
    xs: {
      span: 24,
    },
  },
}

class ViewForm extends Component {
  static propTypes = {
    // form: PropTypes.object.isRequired,
    account: PropTypes.shape({
      id: PropTypes.number,
      account_name: PropTypes.string,
      cloud_folder_name: PropTypes.string.isRequired,
      prospect_number: PropTypes.string,
      account_type: PropTypes.string,
      contact_first_name: PropTypes.string,
      contact_last_name: PropTypes.string,
      phone: PropTypes.string,
      email: PropTypes.string,
    }).isRequired,
    authentication: PropTypes.shape({
      token: PropTypes.string,
    }).isRequired,
    viewId: PropTypes.number.isRequired,
    buildingName: PropTypes.string.isRequired,
  }

  constructor() {
    super()
    this.state = {
      files: [],
      previews: {},
      selectedViewTemplates: {},
    }
  }

  clearCacheForBuilding = (accountName, buildingName) =>
    executeQuery(
      clearCacheByPlanQuery({
        clientName: accountName,
        planName: buildingName,
      }),
      'Clearing cache for building.'
    )

  handleViewTemplateSelection = (viewId, selectedViewTemplateId) => {
    this.setState((state) => ({
      ...state,
      selectedViewTemplates: {
        ...state.selectedViewTemplates,
        [viewId]: selectedViewTemplateId,
      },
    }))
  }

  handleInitializeFromTemplateButtonPress = (viewId) => {
    const { account, buildingName, initializeViewFromViewTemplate } = this.props
    const { selectedViewTemplates } = this.state
    initializeViewFromViewTemplate({
      viewId,
      viewTemplateId: selectedViewTemplates[viewId],
    })

    this.clearCacheForBuilding(account.account_name, buildingName)

    this.handleViewTemplateSelection(viewId, undefined)
  }

  handleUpdateViewFormItemInputChange = (viewId, targetField, label, value) => {
    // eslint-disable-next-line
    const { updateViewById } = this.props
    updateViewById({
      id: viewId,
      input: { [targetField]: value },
    })
  }

  handleOnDrop =
    ({
      folder = 'color_visualizer',
      viewId,
      targetField,
      label,
      fileNameToDelete,
    } = {}) =>
    async (acceptedFiles, rejectedFiles) => {
      console.log(
        'acceptedFiles:',
        acceptedFiles,
        ', rejectedFiles:',
        rejectedFiles,
        ', viewId:',
        viewId,
        ', targetField:',
        targetField,
        ', label:',
        label,
        ', fileNameToDelete:',
        fileNameToDelete
      )
      const { uploadFile, updateViewById, account, buildingName } = this.props

      const dataURL = await new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onload = () => {
          resolve(reader.result)
        }
        reader.onerror = reject
        reader.readAsDataURL(acceptedFiles[0])
      })

      this.setState((state) => ({
        files: acceptedFiles,
        previews: {
          ...state.previews,
          [`${viewId}${targetField}`]: dataURL,
        },
      }))

      const droppedFileSize = acceptedFiles[0].size
      let uploadFileResult
      try {
        uploadFileResult = await uploadFile({
          folder,
          file: acceptedFiles[0],
          file_name_to_delete: fileNameToDelete,
        })
      } catch (error) {
        console.error('Encountered an error while uploading:', error)
      }
      // Once the file has been uploaded, save the name of the file to the
      // appropriate table and column.
      console.log('uploadFileResult:', uploadFileResult)
      if (
        uploadFileResult &&
        uploadFileResult.data &&
        uploadFileResult.data.uploadFile &&
        uploadFileResult.data.uploadFile.cloud_file_name
      ) {
        // eslint-disable-next-line camelcase
        const { cloud_file_name } = uploadFileResult.data.uploadFile
        updateViewById({
          id: viewId,
          input: {
            [targetField]: cloud_file_name,
            uploadFileSize: droppedFileSize,
          },
        })
        this.clearCacheForBuilding(account.account_name, buildingName)
      }
    }

  handleCancel = () => {
    console.log('Canceled')
    this.setState({
      files: [],
    })
  }

  render() {
    const { files, previews, selectedViewTemplates } = this.state
    console.log('files:', files)
    const {
      // form,
      account,
      view,
      viewTemplates,
    } = this.props

    // const { getFieldDecorator } = form
    return (
      <div className={css(styles.updateViewForm)}>
        <Form key="updateViewForm" layout="horizontal">
          {generateUpdateFormItemInput(
            this.handleUpdateViewFormItemInputChange,
            updateViewFormItemLayout,
            view.id,
            view.name,
            'name',
            'View Name'
          )}
          <Item label="View Template" {...updateViewFormItemLayout}>
            <Select
              style={{ width: '200px' }}
              disabled={viewTemplates.length === 0}
              key={`${view.id}`}
              defaultValue={null}
              onBlur={(selectedViewTemplateValue) =>
                this.handleViewTemplateSelection(
                  view.id,
                  selectedViewTemplateValue
                )
              }
              onChange={(selectedViewTemplateId) =>
                this.handleViewTemplateSelection(
                  view.id,
                  selectedViewTemplateId
                )
              }
              onPressEnter={(evt) => evt.target.blur()}
            >
              {[...viewTemplates]
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((viewTemplate) => (
                  <Option key={`${viewTemplate.id}`} value={viewTemplate.id}>
                    {viewTemplate.name}
                  </Option>
                ))}
            </Select>
            <Button
              style={{ margin: '0px 20px' }}
              type="primary"
              disabled={
                viewTemplates.length === 0 ||
                selectedViewTemplates[view.id] === undefined
              }
              onClick={() =>
                this.handleInitializeFromTemplateButtonPress(view.id)
              }
            >
              Initialize from Template
            </Button>
          </Item>
          <Item label="Thumbnail" {...updateViewFormItemLayout}>
            <StyledDropzone
              key={view.thumbnail_src}
              accept="image/*"
              baseStyle={styles.dropzoneBaseStyle}
              onDrop={this.handleOnDrop({
                folder: `app/${account.cloud_folder_name}/images`,
                viewId: view.id,
                targetField: 'thumbnail_src',
                label: 'Thumbnail',
                fileNameToDelete: view.thumbnail_src,
              })}
              onFileDialogCancel={this.handleCancel}
              message="Drop an image, or press to select one."
              imgWidth={146}
              imgHeight={146}
              imgSrc={`https://res.cloudinary.com/renderinghouse/image/upload/w_146,h_146,c_fill/app/${account.cloud_folder_name}/images/${view.thumbnail_src}`}
              previewSrc={previews[`${view.id}thumbnail_src`]}
            />
          </Item>
          <Item label="Base Image" {...updateViewFormItemLayout}>
            <StyledDropzone
              key={view.base_image_src}
              accept="image/*"
              baseStyle={styles.dropzoneBaseStyle}
              onDrop={this.handleOnDrop({
                folder: `app/${account.cloud_folder_name}/images`,
                viewId: view.id,
                targetField: 'base_image_src',
                label: 'Thumbnail',
                fileNameToDelete: view.base_image_src,
              })}
              onFileDialogCancel={this.handleCancel}
              message="Drop an image, or press to select one."
              imgWidth={146}
              imgHeight={146}
              imgSrc={`https://res.cloudinary.com/renderinghouse/image/upload/w_146,h_146,c_fill/app/${account.cloud_folder_name}/images/${view.base_image_src}`}
              previewSrc={previews[`${view.id}base_image_src`]}
            />
          </Item>
        </Form>
        Layers
        <LayersForm
          view={view}
          cloudFolderName={account.cloud_folder_name}
          accountName={account.account_name}
        />
      </div>
    )
  }
}

const WrappedComponent = compose(
  withRouter,
  withConsumer(AuthenticationConsumer, { propName: 'authentication' }),
  withConsumer(SelectionConsumer, { propName: 'selection' }),
  withQueryResultAsProp({
    gqlDocument: VIEW_AND_ELEMENTS_AND_BUILDING_BY_VIEW_ID,
    variables: ({ viewId }) => ({ id: viewId }),
    resultPropName: 'view',
  }),
  withQueryResultAsProp({
    gqlDocument: VIEW_TEMPLATES,
    resultPropName: 'viewTemplates',
  }),
  withQueryResultAsProp({
    gqlDocument: ACCOUNT_WITH_ID_NAME_AND_CLOUD_FOLDER_AND_MATERIALS,
    variables: ({ match }) => ({
      id: Number(match.params.accountId),
    }),

    resultPropName: 'account',
  }),
  withMutationAsProp({
    gqlDocument: INITIALIZE_VIEW_FROM_VIEW_TEMPLATE,
    mutationPropName: 'initializeViewFromViewTemplate',
    refetchQueries: [
      {
        gqlDocument: VIEW_AND_ELEMENTS_AND_BUILDING_BY_VIEW_ID,
        variables: ({ viewId }) => ({ id: viewId }),
        resultPropName: 'view',
      },
    ],
  }),
  withMutationAsProp({
    gqlDocument: UPDATE_VIEW_BY_ID,
    mutationPropName: 'updateViewById',
  }),
  withMutationAsProp({
    gqlDocument: UPLOAD_FILE,
    mutationPropName: 'uploadFile',
  }),
  Form.create()
)(ViewForm)

export { WrappedComponent as ViewForm }
