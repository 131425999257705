import React, { useState } from 'react'
import { Form, Modal, message } from 'antd'
import PropTypes from 'prop-types'
import { useMutation } from '@apollo/client'
import {
  ADD_SWATCH,
  UPDATE_SWATCH,
  GET_SWATCHES,
} from '../../../graphql/fcSwatches.graphql'
import SwatchForm from './SwatchForm'

function SwatchModal({ form, visible, handleCloseModal, swatch }) {
  const [file, setFile] = useState(null)
  const [updateSwatch, { loading: updateLoading }] = useMutation(
    UPDATE_SWATCH,
    {
      refetchQueries: [GET_SWATCHES],
    }
  )
  const [addSwatch, { loading: addLoading }] = useMutation(ADD_SWATCH, {
    refetchQueries: [GET_SWATCHES],
  })

  const handleClose = () => {
    handleCloseModal()
    setFile(null)
    form.resetFields()
  }

  const handleUpdateSwatch = async (swatchId, swatchToUpdate) => {
    try {
      await updateSwatch({
        variables: {
          id: swatchId,
          input: swatchToUpdate,
        },
      })
      message.success('Swatch was successfully updated!')
    } catch (e) {
      message.error('Swatch was not updated!')
    } finally {
      handleClose()
    }
  }

  const handleAddSwatch = async (swatch) => {
    try {
      await addSwatch({
        variables: {
          input: swatch,
        },
      })
      message.success('Swatch was successfully added!')
    } catch (e) {
      message.error('Swatch was not added!')
      console.log(e)
    } finally {
      handleClose()
    }
  }

  const handleSubmit = async (swatchToUpdate) => {
    let swatchData = {}
    try {
      swatchData = await form.validateFields()
    } catch (e) {
      message.error('Form is not valid!')
      return
    }

    swatchData = { ...swatchData, ...(file ? { imageFile: file } : {}) }

    if (swatch) {
      await handleUpdateSwatch(swatchToUpdate.id, swatchData)
    } else {
      await handleAddSwatch(swatchData)
    }
  }

  return (
    <Modal
      title={swatch ? 'Edit Swatch' : 'Add Swatch'}
      visible={visible}
      onOk={() => handleSubmit(swatch)}
      onCancel={() => handleClose()}
      okText={swatch ? 'Update' : 'Add'}
      okButtonProps={{ loading: updateLoading || addLoading }}
    >
      <SwatchForm
        form={form}
        swatch={swatch}
        setFile={setFile}
        visible={visible}
      />
    </Modal>
  )
}

SwatchModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  swatch: PropTypes.bool,
  form: PropTypes.object.isRequired,
}

SwatchModal.defaultProps = {
  swatch: null,
}

export default Form.create()(SwatchModal)
