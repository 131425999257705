import { Button, Form, Icon, Popconfirm, Table } from 'antd'
import { css, StyleSheet } from 'aphrodite/no-important'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { compose } from 'recompose'

import { UPLOAD_FILE } from '../../../graphql/uploads.graphql'

import {
  ADD_VIEW_TEMPLATE,
  DELETE_VIEW_TEMPLATE_BY_ID,
  UPDATE_VIEW_TEMPLATE_BY_ID,
  VIEW_TEMPLATES,
} from '../../../graphql/brochures.graphql'

import { StyledDropzone } from '../../common/StyledDropzone'

import { ViewTemplateElementsForm } from './ViewTemplateElementsForm'

import {
  generateAddFormItemRequiredInput,
  generateUpdateFormItemInput,
  withQueryResultAsProp,
  withMutationAsProp,
} from '../../../utils'

const { Item } = Form

const styles = StyleSheet.create({
  addItemForm: {
    border: '1px solid #d8dee2',
    borderRadius: '10px',
    padding: '0px 10px 0px 10px',
    margin: 'auto',
    marginLeft: '10px',
    marginRight: '10px',
  },
  descriptionHeader: {
    fontSize: 25,
    padding: '0px 10px 10px 10px',
  },
  divider: {
    margin: '50px',
  },
  updateItemForm: {
    padding: '0px 10px 0px 10px',
    margin: 'auto',
  },
  tableStyle: {
    border: '1px solid #d8dee2',
    borderRadius: '10px',
    padding: '0px 10px 0px 10px',
    marginLeft: '10px',
    marginRight: '10px',
  },
})

const updateFormItemLayout = {
  labelCol: {
    // 1600px ≤ width
    xxl: {
      span: 2,
    },
    // 1200px ≤ width < 1600px
    xl: {
      span: 2,
    },
    // 992px ≤ width < 1200px
    lg: {
      span: 2,
    },
    // 768px ≤ width < 992px
    md: {
      span: 4,
    },
    // 576px ≤ width < 768px
    sm: {
      span: 4,
    },
    // width < 576px and also default setting
    xs: {
      span: 24,
    },
  },
  wrapperCol: {
    xxl: {
      span: 22,
    },
    xl: {
      span: 22,
    },
    lg: {
      span: 22,
    },
    md: {
      span: 20,
    },
    sm: {
      span: 20,
    },
    xs: {
      span: 24,
    },
  },
}

class ViewTemplatesForm extends Component {
  static propTypes = {
    addViewTemplate: PropTypes.func.isRequired,
    deleteViewTemplateById: PropTypes.func.isRequired,
    updateViewTemplateById: PropTypes.func.isRequired,
    uploadFile: PropTypes.func.isRequired,
    form: PropTypes.object.isRequired,
    viewTemplates: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        base_image_src: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        thumbnail_src: PropTypes.string.isRequired,
      })
    ).isRequired,
  }

  constructor() {
    super()
    this.state = { previews: {} }
  }

  handleOnDrop =
    ({
      folder = 'color_visualizer',
      itemId,
      targetField,
      label,
      fileNameToDelete,
    } = {}) =>
    async (acceptedFiles, rejectedFiles) => {
      console.log(
        'acceptedFiles:',
        acceptedFiles,
        ', rejectedFiles:',
        rejectedFiles,
        ', itemId:',
        itemId,
        ', targetField:',
        targetField,
        ', label:',
        label,
        ', fileNameToDelete:',
        fileNameToDelete
      )
      const { uploadFile, updateViewTemplateById } = this.props

      const dataURL = await new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onload = () => {
          resolve(reader.result)
        }
        reader.onerror = reject
        reader.readAsDataURL(acceptedFiles[0])
      })

      this.setState((state) => ({
        previews: {
          ...state.previews,
          [`${itemId}${targetField}`]: dataURL,
        },
      }))

      const droppedFileSize = acceptedFiles[0].size
      let uploadFileResult
      try {
        uploadFileResult = await uploadFile({
          folder,
          file: acceptedFiles[0],
          file_name_to_delete: fileNameToDelete,
        })
      } catch (error) {
        console.error('Encountered an error while uploading:', error)
      }
      // Once the file has been uploaded, save the name of the file to the
      // appropriate table and column.
      console.log('uploadFileResult:', uploadFileResult)
      if (
        uploadFileResult &&
        uploadFileResult.data &&
        uploadFileResult.data.uploadFile &&
        uploadFileResult.data.uploadFile.cloud_file_name
      ) {
        // eslint-disable-next-line camelcase
        const { cloud_file_name } = uploadFileResult.data.uploadFile
        updateViewTemplateById({
          id: itemId,
          input: {
            [targetField]: cloud_file_name,
            uploadFileSize: droppedFileSize,
          },
        })
      }
    } // handleOnDrop

  handleAddViewTemplateButtonPress = async () => {
    const { addViewTemplate, form } = this.props
    form.validateFieldsAndScroll(async (errors, { viewTemplateName }) => {
      if (!errors) {
        form.resetFields()
        addViewTemplate({
          input: {
            name: viewTemplateName,
            // For now, hard code this value to 1 (the 'digital_homescapes' layout), until
            // Sherwin-Williams has more than one kind of template layout.
            brochure_layout_id: 1,
          },
        })
      }
    })
  }

  handleDeleteViewTemplateButtonPress = (id) => {
    const { deleteViewTemplateById } = this.props
    deleteViewTemplateById({
      id,
    })
  }

  handleUpdateViewTemplateFormItemInputChange = (
    id,
    targetField,
    label,
    value
  ) => {
    console.log(
      `handleUpdateViewTemplateFormItemInputChange: id: ${id}, targetField: ${targetField}, label: ${label}, value: ${value}`
    )
    // eslint-disable-next-line
    const { updateViewTemplateById } = this.props
    updateViewTemplateById({
      id,
      input: { [targetField]: value },
    })
  }

  render() {
    const { previews } = this.state
    const { form, viewTemplates } = this.props
    const { getFieldDecorator } = form
    return (
      <React.Fragment>
        <div className={css(styles.descriptionHeader)}>View Templates</div>
        <Form
          key="addViewTemplateForm"
          layout="inline"
          className={css(styles.addItemForm)}
        >
          {generateAddFormItemRequiredInput(
            getFieldDecorator,
            'viewTemplateName',
            'Name'
          )}
          <Item>
            <Button
              type="primary"
              onClick={this.handleAddViewTemplateButtonPress}
            >
              <Icon type="user-add" />
              Add Template
            </Button>
          </Item>
        </Form>
        <Table
          key="viewTemplatesTable"
          size="middle"
          bordered
          pagination={false}
          dataSource={viewTemplates}
          columns={[
            {
              title: 'Name',
              dataIndex: 'name',
              key: 'name',
            },
            {
              key: 'delete',
              width: 150,
              render: (text, item) => (
                <Popconfirm
                  placement="bottomRight"
                  title="Delete this item?"
                  onConfirm={() =>
                    this.handleDeleteViewTemplateButtonPress(item.id)
                  }
                  okText="Yes"
                  cancelText="No"
                >
                  <Button type="danger">
                    <Icon type="delete" />
                  </Button>
                </Popconfirm>
              ),
            },
          ]}
          expandedRowRender={(item) => (
            <React.Fragment>
              <Form key="updateItemForm" className={css(styles.updateItemForm)}>
                {generateUpdateFormItemInput(
                  this.handleUpdateViewTemplateFormItemInputChange,
                  updateFormItemLayout,
                  item.id,
                  item.name,
                  'name',
                  'Name'
                )}
                <Item label="Thumbnail" {...updateFormItemLayout}>
                  <StyledDropzone
                    key={item.thumbnail_src}
                    accept="image/*"
                    baseStyle={styles.dropzoneBaseStyle}
                    onDrop={this.handleOnDrop({
                      folder: 'app/cv/shw/brochure_templates/images',
                      itemId: item.id,
                      targetField: 'thumbnail_src',
                      label: 'Thumbnail',
                      fileNameToDelete: item.thumbnail_src,
                    })}
                    onFileDialogCancel={this.handleCancel}
                    message="Drop an image, or press to select one."
                    imgWidth={146}
                    imgHeight={146}
                    imgSrc={`https://res.cloudinary.com/renderinghouse/image/upload/w_146,h_146,c_fill/app/cv/shw/brochure_templates/images/${item.thumbnail_src}`}
                    previewSrc={previews[`${item.id}thumbnail_src`]}
                  />
                </Item>
                <Item label="Base Image" {...updateFormItemLayout}>
                  <StyledDropzone
                    key={item.base_image_src}
                    accept="image/*"
                    baseStyle={styles.dropzoneBaseStyle}
                    onDrop={this.handleOnDrop({
                      folder: 'app/cv/shw/brochure_templates/images',
                      itemId: item.id,
                      targetField: 'base_image_src',
                      label: 'Thumbnail',
                      fileNameToDelete: item.base_image_src,
                    })}
                    onFileDialogCancel={this.handleCancel}
                    message="Drop an image, or press to select one."
                    imgWidth={146}
                    imgHeight={146}
                    imgSrc={`https://res.cloudinary.com/renderinghouse/image/upload/w_146,h_146,c_fill/app/cv/shw/brochure_templates/images/${item.base_image_src}`}
                    previewSrc={previews[`${item.id}base_image_src`]}
                  />
                </Item>
              </Form>
              Layers
              <ViewTemplateElementsForm viewTemplateId={item.id} />
            </React.Fragment>
          )}
        />
      </React.Fragment>
    )
  }
}

const WrappedComponent = compose(
  withQueryResultAsProp({
    gqlDocument: VIEW_TEMPLATES,
    resultPropName: 'viewTemplates',
  }),
  withMutationAsProp({
    gqlDocument: ADD_VIEW_TEMPLATE,
    mutationPropName: 'addViewTemplate',
    refetchQueries: [
      {
        gqlDocument: VIEW_TEMPLATES,
      },
    ],
  }),
  withMutationAsProp({
    gqlDocument: DELETE_VIEW_TEMPLATE_BY_ID,
    mutationPropName: 'deleteViewTemplateById',
    refetchQueries: [
      {
        gqlDocument: VIEW_TEMPLATES,
      },
    ],
  }),
  withMutationAsProp({
    gqlDocument: UPDATE_VIEW_TEMPLATE_BY_ID,
    mutationPropName: 'updateViewTemplateById',
  }),
  withMutationAsProp({
    gqlDocument: UPLOAD_FILE,
    mutationPropName: 'uploadFile',
  }),
  Form.create()
)(ViewTemplatesForm)

export { WrappedComponent as ViewTemplatesForm }
