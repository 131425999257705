import { Button, Form, Icon, Popconfirm, Table } from 'antd'
import { css, StyleSheet } from 'aphrodite/no-important'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { compose } from 'recompose'

import {
  ADD_BROCHURE_LAYOUT,
  DELETE_BROCHURE_LAYOUT_BY_ID,
  UPDATE_BROCHURE_LAYOUT_BY_ID,
  BROCHURE_LAYOUTS,
} from '../../../graphql/brochures.graphql'

import { AuthenticationConsumer } from '../../../contexts'

import {
  generateAddFormItemRequiredInput,
  generateUpdateFormItemInput,
  withConsumer,
  withQueryResultAsProp,
  withMutationAsProp,
} from '../../../utils'

const { Item } = Form

const styles = StyleSheet.create({
  addItemForm: {
    border: '1px solid #d8dee2',
    borderRadius: '10px',
    padding: '0px 10px 0px 10px',
    margin: 'auto',
    marginLeft: '10px',
    marginRight: '10px',
  },
  descriptionHeader: {
    fontSize: 25,
    padding: '0px 10px 10px 10px',
  },
  divider: {
    margin: '50px',
  },
  updateItemForm: {
    padding: '0px 10px 0px 10px',
    margin: 'auto',
  },
  tableStyle: {
    border: '1px solid #d8dee2',
    borderRadius: '10px',
    padding: '0px 10px 0px 10px',
    marginLeft: '10px',
    marginRight: '10px',
  },
})

const updateFormItemLayout = {
  labelCol: {
    // 1600px ≤ width
    xxl: {
      span: 2,
    },
    // 1200px ≤ width < 1600px
    xl: {
      span: 2,
    },
    // 992px ≤ width < 1200px
    lg: {
      span: 2,
    },
    // 768px ≤ width < 992px
    md: {
      span: 4,
    },
    // 576px ≤ width < 768px
    sm: {
      span: 4,
    },
    // width < 576px and also default setting
    xs: {
      span: 24,
    },
  },
  wrapperCol: {
    xxl: {
      span: 22,
    },
    xl: {
      span: 22,
    },
    lg: {
      span: 22,
    },
    md: {
      span: 20,
    },
    sm: {
      span: 20,
    },
    xs: {
      span: 24,
    },
  },
}

class BrochureLayoutsForm extends Component {
  static propTypes = {
    addBrochureLayout: PropTypes.func.isRequired,
    authentication: PropTypes.object.isRequired,
    brochureLayouts: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
      })
    ).isRequired,
    deleteBrochureLayoutById: PropTypes.func.isRequired,
    form: PropTypes.object.isRequired,
    updateBrochureLayoutById: PropTypes.func.isRequired,
  }

  handleAddBrochureLayoutButtonPress = async () => {
    const { addBrochureLayout, form } = this.props
    form.validateFieldsAndScroll(async (errors, { brochureLayoutName }) => {
      if (!errors) {
        form.resetFields()
        addBrochureLayout({
          input: {
            name: brochureLayoutName,
          },
        })
      }
    })
  }

  handleDeleteBrochureLayoutButtonPress = (id) => {
    const { deleteBrochureLayoutById } = this.props
    deleteBrochureLayoutById({
      id,
    })
  }

  handleUpdateBrochureLayoutFormItemInputChange = (
    id,
    targetField,
    label,
    value
  ) => {
    console.log(
      `handleUpdateBrochureLayoutFormItemInputChange: id: ${id}, targetField: ${targetField}, label: ${label}, value: ${value}`
    )
    // eslint-disable-next-line
    const { updateBrochureLayoutById } = this.props
    updateBrochureLayoutById({
      id,
      input: { [targetField]: value },
    })
  }

  render() {
    const { authentication, form, brochureLayouts } = this.props
    const { appConfig } = authentication
    const { showBrochureLayoutsForm } = appConfig
    const { getFieldDecorator } = form
    return !showBrochureLayoutsForm ? null : (
      <React.Fragment>
        <div className={css(styles.descriptionHeader)}>Brochure Layouts</div>
        <Form
          key="addBrochureLayoutForm"
          layout="inline"
          className={css(styles.addItemForm)}
        >
          {generateAddFormItemRequiredInput(
            getFieldDecorator,
            'brochureLayoutName',
            'Name'
          )}
          <Item>
            <Button
              type="primary"
              onClick={this.handleAddBrochureLayoutButtonPress}
            >
              <Icon type="user-add" />
              Add Layout
            </Button>
          </Item>
        </Form>
        <Table
          key="brochureLayoutsTable"
          size="middle"
          bordered
          pagination={false}
          dataSource={brochureLayouts}
          columns={[
            {
              title: 'Name',
              dataIndex: 'name',
              key: 'name',
            },
            {
              key: 'delete',
              width: 150,
              render: (text, item) => (
                <Popconfirm
                  placement="bottomRight"
                  title="Delete this item?"
                  onConfirm={() =>
                    this.handleDeleteBrochureLayoutButtonPress(item.id)
                  }
                  okText="Yes"
                  cancelText="No"
                >
                  <Button type="danger">
                    <Icon type="delete" />
                  </Button>
                </Popconfirm>
              ),
            },
          ]}
          expandedRowRender={(item) => (
            <Form key="updateItemForm" className={css(styles.updateItemForm)}>
              {generateUpdateFormItemInput(
                this.handleUpdateBrochureLayoutFormItemInputChange,
                updateFormItemLayout,
                item.id,
                item.name,
                'name',
                'Name'
              )}
            </Form>
          )}
        />
      </React.Fragment>
    )
  }
}

const WrappedComponent = compose(
  withConsumer(AuthenticationConsumer, { propName: 'authentication' }),
  withQueryResultAsProp({
    gqlDocument: BROCHURE_LAYOUTS,
    resultPropName: 'brochureLayouts',
  }),
  withMutationAsProp({
    gqlDocument: ADD_BROCHURE_LAYOUT,
    mutationPropName: 'addBrochureLayout',
    refetchQueries: [
      {
        gqlDocument: BROCHURE_LAYOUTS,
      },
    ],
  }),
  withMutationAsProp({
    gqlDocument: DELETE_BROCHURE_LAYOUT_BY_ID,
    mutationPropName: 'deleteBrochureLayoutById',
    refetchQueries: [
      {
        gqlDocument: BROCHURE_LAYOUTS,
      },
    ],
  }),
  withMutationAsProp({
    gqlDocument: UPDATE_BROCHURE_LAYOUT_BY_ID,
    mutationPropName: 'updateBrochureLayoutById',
  }),
  Form.create()
)(BrochureLayoutsForm)

export { WrappedComponent as BrochureLayoutsForm }
