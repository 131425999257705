import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Filter } from '../../common/Filter/Filter'
import { Input } from '../../common/Filter/Input'

export const AssignSchemesFilter = ({ filter, setFilter, children }) => {
  const filterData = {
    schemeName: {
      value: filter.schemeName,
      set: (schemeName) => setFilter({ ...filter, schemeName }),
    },
    projectName: {
      value: filter.projectName,
      set: (projectName) => setFilter({ ...filter, projectName }),
    },
  }

  return (
    <Filter loading={false} data={filterData}>
      <Input
        field="schemeName"
        label="Scheme Name"
        placeholder="Search by scheme name ..."
      />
      <Input
        field="projectName"
        label="Project Name"
        placeholder="Search by project name ..."
      />
      {children}
    </Filter>
  )
}
AssignSchemesFilter.propTypes = {
  filter: PropTypes.shape({
    schemeName: PropTypes.string,
    projectName: PropTypes.string,
  }).isRequired,
  setFilter: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
}
AssignSchemesFilter.defaultProps = {
  children: undefined,
}

export const useDefaultFilter = () => {
  const [filter, setFilter] = useState({
    projectName: undefined,
    schemeName: undefined,
  })
  return { filter, setFilter }
}
