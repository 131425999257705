import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useQuery } from '@apollo/client'
import { StyleSheet, css } from 'aphrodite/no-important'
import LayerFilters from './LayerFilters'
import LayerModal from './LayerModal'
import { GET_ROOM_LAYERS_BY_ID } from '../../../graphql/fcLayers.graphql'
import Table, { TABLE_ITEMS_PER_PAGE } from '../../common/Table'
import LayerTableActions from './LayerTableActions'
import Alert from '../../common/Alert'
import ImageSkeleton from '../../common/skeletons/ImageSkeleton'
import TextSkeleton from '../../common/skeletons/TextSkeleton'
import { getMaskCloudinaryPath } from '../functions/getMaskCloudinaryPath'
import getLayerCloudinaryPath from '../functions/getLayerCloudinaryPath'
import { getSwatchesCloudinaryPath } from '../../../functions/getSwatchesCloudinaryPath'
import LayerPreviewModal from './LayerPreviewModal'

const classes = StyleSheet.create({
  imageContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'darkgray',
    width: 100,
    height: 100,
    border: '1px solid lightgray',
  },
  image: {
    width: 'auto',
    height: 'auto',
    maxWidth: '100%',
    maxHeight: '100%',
  },
})

const columns = (
  cloudFolder,
  room,
  setVisible,
  setLayerToUpdate,
  setLayerToCopy,
  setLayerToPreview
) => [
  {
    title: 'Image',
    dataIndex: 'image',
    key: 'image',
    width: 120,
    render(_, layer) {
      return (
        <ImageSkeleton
          alt={layer?.name}
          className={css(classes.image)}
          skeletonProps={{
            style: {
              width: '100%',
              height: '100%',
            },
          }}
          src={
            layer?.image &&
            getLayerCloudinaryPath(
              cloudFolder,
              layer.image,
              room.id,
              'w_100,h_100,c_fit'
            )
          }
        />
      )
    },
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    render(_, layer) {
      return <TextSkeleton>{layer?.name}</TextSkeleton>
    },
  },
  {
    title: 'Mask',
    dataIndex: 'mask',
    key: 'mask',
    width: 120,
    render(_, layer) {
      return (
        <div className={css(classes.imageContainer)}>
          <ImageSkeleton
            alt={layer?.mask?.material?.display_name}
            skeletonProps={{
              style: {
                width: '100%',
                height: '100%',
              },
            }}
            src={
              layer?.mask?.image &&
              getMaskCloudinaryPath(
                cloudFolder,
                layer.mask.image,
                room?.id,
                'w_100,h_100,c_fit'
              )
            }
          />
        </div>
      )
    },
  },
  {
    title: 'Mask Name',
    dataIndex: 'mask_name',
    key: 'mask_name',
    render(_, layer) {
      return <TextSkeleton>{layer?.mask?.material?.display_name}</TextSkeleton>
    },
  },
  {
    title: 'Swatch',
    dataIndex: 'swatch',
    key: 'swatch',
    width: 120,
    render(_, layer) {
      return (
        <div className={css(classes.imageContainer)}>
          <ImageSkeleton
            alt={layer?.swatch?.name}
            skeletonProps={{
              style: {
                width: '100%',
                height: '100%',
              },
            }}
            src={
              layer?.swatch?.image &&
              getSwatchesCloudinaryPath(layer.swatch.image, 'w_100,h_100,c_fit')
            }
          />
        </div>
      )
    },
  },
  {
    title: 'Swatch Name',
    dataIndex: 'swatch_name',
    key: 'swatch_name',
    render(_, layer) {
      return <TextSkeleton>{layer?.swatch?.name}</TextSkeleton>
    },
  },
  {
    title: 'Actions',
    dataIndex: 'actions',
    key: 'actions',
    width: 180,
    align: 'center',
    render(_, layer) {
      return (
        <LayerTableActions
          room={room}
          layer={layer}
          setVisible={setVisible}
          setLayerToUpdate={setLayerToUpdate}
          setLayerToCopy={setLayerToCopy}
          setLayerToPreview={setLayerToPreview}
        />
      )
    },
  },
]

function topUp(array, desiredSize, newElement) {
  if (array.length < desiredSize) {
    return [...array, ...new Array(desiredSize - array.length).fill(newElement)]
  }

  return array
}

export default function LayerTable({ account, roomId }) {
  const [filters, setFilters] = useState({})
  const [currentPage, setCurrentPage] = useState(0)
  const { data, error } = useQuery(GET_ROOM_LAYERS_BY_ID, {
    variables: {
      id: roomId,
    },
  })
  const [modalVisible, setModalVisible] = useState(false)
  const [modalMode, setModalMode] = useState(LayerModal.modes.update)
  const [layerForModal, setLayerForModal] = useState(null)
  const [layerToPreview, setLayerToPreview] = useState(null)

  // If we ever implement layer pagination, this should:
  // - when loading, display TABLE_ITEMS_PER_PAGE skeleton items
  // - after deletion, potentially display a loading indicator for an item
  // TODO: replace `room?.layers?.length` with the paginated total
  const room = data?.roomById
  const roomLayers = room?.layers?.filter(
    (layer) => !filters?.maskId || filters?.maskId === layer.mask?.id
  )
  const layers = topUp(
    roomLayers ?? [],
    Math.min(roomLayers?.length ?? 0, TABLE_ITEMS_PER_PAGE),
    {}
  )

  function handleAddLayerClick() {
    setModalMode(LayerModal.modes.add)
    setLayerForModal(null)
    setModalVisible(true)
  }

  function setLayerToCopy(layer) {
    setModalMode(LayerModal.modes.copy)
    setLayerForModal(layer)
  }

  function setLayerToUpdate(layer) {
    setModalMode(LayerModal.modes.update)
    setLayerForModal(layer)
  }

  function handleClose() {
    setModalVisible(false)
    setLayerForModal(null)
  }

  return (
    <>
      {error && <Alert margin />}
      <LayerFilters
        roomId={roomId}
        onAddLayerClick={handleAddLayerClick}
        filters={filters}
        setFilters={setFilters}
      />
      <Table
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        totalCount={layers?.length ?? 0}
        emptyText="There are currently no flooring to display"
        data={layers}
        columns={columns(
          account.cloud_folder_name,
          room,
          setModalVisible,
          setLayerToUpdate,
          setLayerToCopy,
          setLayerToPreview
        )}
        onPreview={(layer) => setLayerToPreview(layer)}
        itemsPerPage={TABLE_ITEMS_PER_PAGE}
      />
      {modalVisible && (
        <LayerModal
          account={account}
          room={room}
          mode={modalMode}
          layer={layerForModal}
          onClose={handleClose}
        />
      )}
      <LayerPreviewModal
        layer={layerToPreview}
        onClose={() => setLayerToPreview(null)}
        cloudFolder={account.cloud_folder_name}
        room={room}
      />
    </>
  )
}

LayerTable.propTypes = {
  roomId: PropTypes.number.isRequired,
  account: PropTypes.object.isRequired,
}

LayerTable.defaultProps = {}
