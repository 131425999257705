import React, { useCallback, useState } from 'react'
import { css, StyleSheet } from 'aphrodite/no-important'
import SwatchTable from '../components/swatches/SwatchTable/SwatchTable'
import SwatchModal from '../components/swatches/SwatchModal/SwatcheModal'

const classes = StyleSheet.create({
  root: {
    position: 'relative',
    height: '100%',
  },
  alert: {
    margin: 8,
  },
})

export default function SwatchesPage() {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [swatchToEdit, setSwatchToEdit] = useState(null)
  const [currentPage, setCurrentPage] = useState(0)

  const handleEditRoom = useCallback((swatch) => {
    setSwatchToEdit(swatch)
    setIsModalVisible(true)
  }, [])

  const handleCloseModal = () => {
    setSwatchToEdit(null)
    setIsModalVisible(false)
  }

  return (
    <div className={css(classes.root)}>
      <SwatchTable
        handleEditSwatch={handleEditRoom}
        setIsModalVisible={setIsModalVisible}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
      <SwatchModal
        swatch={swatchToEdit}
        visible={isModalVisible}
        handleCloseModal={handleCloseModal}
      />
    </div>
  )
}
