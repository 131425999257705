import React, { useCallback, useState } from 'react'
import { css, StyleSheet } from 'aphrodite/no-important'
import PropTypes from 'prop-types'
import { Button, notification } from 'antd'
import { generatePath } from 'react-router-dom'
import Spinner from '../components/common/Spinner'
import PageHeader from '../components/common/PageHeader'
import { routeKeys } from './const/routeKeys'
import RoomTable from '../components/rooms/RoomTable/RoomTable'
import RoomModal from '../components/rooms/RoomModal/RoomModal'
import { useAccountAndProperty } from '../hooks/useAccountAndProperty'
import Alert from '../components/common/Alert'
import SchemesModal from '../components/rooms/schemes/SchemesModal/SchemesModal'
import { useAccountSelectionFromParams } from '../hooks/useAccountSelectionFromParams'

const classes = StyleSheet.create({
  root: {
    position: 'relative',
    height: '100%',
  },
  allSchemesButton: {
    display: 'block',
    marginLeft: 'auto',
  },
})

export default function RoomsPage({ match, history }) {
  const { params } = match
  const propertyId = Number(params.propertyId)
  const currentAccountId = params.subAccountId || params.accountId
  const { currentAccount, currentProperty, loading, error } =
    useAccountAndProperty(Number(currentAccountId), propertyId)
  const [isRoomModalVisible, setIsRoomModalVisible] = useState(false)
  const [roomToEdit, setRoomToEdit] = useState(null)

  const [isSchemeModalVisible, setIsSchemeModalVisible] = useState(false)
  const [schemeRoomId, setSchemeRoomId] = useState(null)

  useAccountSelectionFromParams()

  const handleEditRoom = useCallback((room) => {
    setRoomToEdit(room)
    setIsRoomModalVisible(true)
  }, [])

  const handleCloseRoomModal = () => {
    setRoomToEdit(null)
    setIsRoomModalVisible(false)
  }

  const handleAddScheme = (roomId) => {
    setSchemeRoomId(roomId)
    setIsSchemeModalVisible(true)
  }

  const handleCloseSchemeModal = (success, roomId) => {
    if (success && roomId) {
      notification.success({
        message: 'To see all schemes click on button below',
        duration: 3,
        btn: (
          <Button
            className={css(classes.allSchemesButton)}
            type="primary"
            onClick={() => {
              notification.destroy()
              history.push(
                generatePath(routeKeys.ROOM_SCHEMES, {
                  accountId: currentAccount.id,
                  roomId,
                  propertyId,
                })
              )
            }}
          >
            All Schemes
          </Button>
        ),
      })
    }

    setIsSchemeModalVisible(false)
    setSchemeRoomId(null)
  }

  return (
    <div className={css(classes.root)}>
      <PageHeader
        title="Rooms"
        subTitle={currentProperty}
        onBack={() => history.goBack()}
      />
      {loading && <Spinner />}
      {error && <Alert margin />}
      {currentAccount && (
        <>
          <RoomTable
            cloudFolder={currentAccount.cloud_folder_name}
            propertyId={propertyId}
            handleEditRoom={handleEditRoom}
            handleAddScheme={handleAddScheme}
            setIsModalVisible={setIsRoomModalVisible}
          />
          <SchemesModal
            account={currentAccount}
            roomId={schemeRoomId}
            visible={isSchemeModalVisible}
            onClose={handleCloseSchemeModal}
          />
          <RoomModal
            room={roomToEdit}
            visible={isRoomModalVisible}
            handleCloseModal={handleCloseRoomModal}
            propertyId={propertyId}
            account={currentAccount}
          />
        </>
      )}
    </div>
  )
}

RoomsPage.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
}
