/* eslint-disable react/prop-types,react/no-unused-state */
import { Button, Form, Icon, Popconfirm, Select, Table, Tooltip } from 'antd'

import { css, StyleSheet } from 'aphrodite/no-important'
import changeCase from 'change-case'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { compose } from 'recompose'

import { withRouter } from 'react-router-dom'
import ButtonGroup from 'antd/es/button/button-group'
import { AuthenticationConsumer, SelectionConsumer } from '../../../contexts'
import { executeQuery } from '../../../utils/restQueryBuilder'
import { clearCacheByPlanQuery } from '../../../utils/hemi'
import { StyledDropzone } from '../../common/StyledDropzone'

import { ACCOUNT_WITH_ID_NAME_AND_CLOUD_FOLDER_AND_MATERIALS } from '../../../graphql/accounts.graphql'

import { VIEW_LAYERS_BY_VIEW_ID } from '../../../graphql/views.graphql'

import {
  ADD_LAYER,
  DELETE_LAYER_BY_ID,
  UPDATE_LAYER_BY_ID,
} from '../../../graphql/layers.graphql'

import { UPLOAD_FILE } from '../../../graphql/uploads.graphql'

import {
  generateUpdateFormItemInputNumber,
  withConsumer,
  withMutationAsProp,
  withQueryResultAsProp,
} from '../../../utils'
import ViewElementDescriptionPointModal from './ViewElementDescriptionPointModal'

const { Item } = Form
const { Option } = Select

const styles = StyleSheet.create({
  addLayerForm: {
    border: '1px solid #d8dee2',
    borderRadius: '10px',
    padding: '0px 10px 0px 10px',
    margin: 'auto',
  },
  updateLayerForm: {
    padding: '0px 10px 0px 10px',
    margin: 'auto',
  },
  dropzoneBaseStyle: {
    width: 100,
    height: 100,
    borderWidth: 2,
    borderColor: 'lightgray',
    borderStyle: 'dashed',
    borderRadius: 5,
    backgroundColor: 'darkgray',
  },
})

class LayersForm extends Component {
  static propTypes = {
    account: PropTypes.shape({
      id: PropTypes.number,
      account_name: PropTypes.string,
      cloud_folder_name: PropTypes.string.isRequired,
      materials: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          element_id: PropTypes.number,
          display_name: PropTypes.string,
        })
      ),
    }).isRequired,
    cloudFolderName: PropTypes.string.isRequired,
    accountName: PropTypes.string.isRequired,
    addLayer: PropTypes.func.isRequired,
    deleteLayerById: PropTypes.func.isRequired,
    view: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string,
      thumbnail_src: PropTypes.string,
      base_image_src: PropTypes.string,
      view_elements: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
          src: PropTypes.string,
          material: PropTypes.shape({
            id: PropTypes.number,
            element_id: PropTypes.number,
            display_name: PropTypes.string,
          }).isRequired,
        })
      ),
    }).isRequired,
    updateLayerById: PropTypes.func.isRequired,
  }

  constructor() {
    super()
    this.state = { files: [], previews: {}, viewElementPointToEdit: null }
  }

  handleAddLayerButtonPress = () => {
    const { addLayer, form, view, account } = this.props
    form.validateFieldsAndScroll(async (errors, { materialElementId }) => {
      if (!errors) {
        form.resetFields()
        await addLayer({
          parentId: view.id,
          input: {
            element_id: materialElementId,
          },
        })
        this.clearCacheForBuilding(account.account_name, view.building.name)
      }
    })
  }

  handleUpdateViewElementListOrderInputChange = async (
    id,
    fieldKey,
    label,
    value
  ) => {
    const { updateLayerById } = this.props
    await updateLayerById({
      id,
      input: { [fieldKey]: value },
    })
  }

  handleDeleteLayerButtonPress = (layerId) => {
    // eslint-disable-next-line
    const { deleteLayerById, account, view } = this.props
    deleteLayerById({
      id: layerId,
    })
    this.clearCacheForBuilding(account.account_name, view.building.name)
  }

  handleOnDrop =
    ({
      folder = 'color_visualizer',
      viewElementId,
      targetField,
      fileNameToDelete,
      accountName,
      view,
    } = {}) =>
    async (acceptedFiles) => {
      const { uploadFile, updateLayerById } = this.props
      const dataURL = await new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onload = () => {
          resolve(reader.result)
        }
        reader.onerror = reject
        reader.readAsDataURL(acceptedFiles[0])
      })

      this.setState((state) => ({
        files: acceptedFiles,
        previews: {
          ...state.previews,
          [`${viewElementId}${targetField}`]: dataURL,
        },
      }))

      const droppedFileSize = acceptedFiles[0].size
      let uploadFileResult
      try {
        uploadFileResult = await uploadFile({
          folder,
          file: acceptedFiles[0],
          file_name_to_delete: fileNameToDelete,
        })
      } catch (error) {
        console.error('Encountered an error while uploading:', error)
      }
      // Once the file has been uploaded, save the name of the file to the
      // appropriate table and column.
      if (
        uploadFileResult &&
        uploadFileResult.data &&
        uploadFileResult.data.uploadFile &&
        uploadFileResult.data.uploadFile.cloud_file_name
      ) {
        // eslint-disable-next-line camelcase
        const { cloud_file_name } = uploadFileResult.data.uploadFile
        await updateLayerById({
          id: viewElementId,
          input: {
            [targetField]: cloud_file_name,
            uploadFileSize: droppedFileSize,
          },
        })
        this.clearCacheForBuilding(accountName, view.building.name)
      }
    }

  handleCancel = () => {
    this.setState({
      files: [],
    })
  }

  clearCacheForBuilding = (accountName, buildingName) =>
    executeQuery(
      clearCacheByPlanQuery({
        clientName: accountName,
        planName: buildingName,
      }),
      'Clearing cache for building.'
    )

  render() {
    const { form, account, accountName, view, cloudFolderName } = this.props
    const { previews, viewElementPointToEdit } = this.state
    const { getFieldDecorator } = form

    const usedMaterialElementIds = view.view_elements
      // eslint-disable-next-line camelcase
      .map((view_element) => view_element.material.element_id)

    const unusedMaterials = account.materials.filter(
      (material) => !usedMaterialElementIds.includes(material.element_id)
    )

    return (
      <React.Fragment>
        <ViewElementDescriptionPointModal
          viewElement={viewElementPointToEdit}
          account={account}
          accountName={accountName}
          onClose={() => this.setState({ viewElementPointToEdit: null })}
          planName={view.building.name}
        />
        <Form
          key="addLayerForm"
          layout="inline"
          className={css(styles.addLayerForm)}
        >
          <Item label="Material">
            {getFieldDecorator('materialElementId', {
              rules: [
                {
                  required: true,
                  message: 'This field is required.',
                },
              ],
              // Don't validate after every onChange (we will therefore only
              // validate when the user presses the "Add User" button.)
              validateTrigger: null,
              initialValue: null,
            })(
              <Select
                style={{ width: '200px' }}
                disabled={unusedMaterials.length === 0}
              >
                {unusedMaterials
                  .sort((a, b) => a.display_name.localeCompare(b.display_name))
                  .map((material) => (
                    <Option
                      key={`${material.element_id}`}
                      value={material.element_id}
                    >
                      {changeCase.titleCase(material.display_name)}
                    </Option>
                  ))}
              </Select>
            )}
          </Item>
          <Item>
            <Button
              type="primary"
              disabled={unusedMaterials.length === 0}
              onClick={this.handleAddLayerButtonPress}
            >
              Add Layer
            </Button>
          </Item>
        </Form>
        <Table
          key="layersTable"
          size="middle"
          bordered
          pagination={false}
          dataSource={view.view_elements}
          columns={[
            {
              title: 'Material',
              dataIndex: 'material',
              key: 'material',
              defaultSortOrder: 'ascend',
              sorter: (a, b) =>
                a.material.display_name.localeCompare(b.material.display_name),
              // eslint-disable-next-line camelcase
              render: (text, view_element) =>
                view_element.material.display_name,
            },
            {
              title: 'Mask',
              dataIndex: 'src',
              key: 'src',
              // eslint-disable-next-line camelcase
              render: (text, view_element) => (
                <StyledDropzone
                  key={view_element.src}
                  accept="image/*"
                  baseStyle={styles.dropzoneBaseStyle}
                  imgWidth={96}
                  imgHeight={96}
                  imgSrc={`https://res.cloudinary.com/renderinghouse/image/upload/w_100,h_100,c_fill/app/${cloudFolderName}/images/${view_element.src}`}
                  message="Drop an image, or press to select one."
                  onDrop={this.handleOnDrop({
                    folder: `app/${cloudFolderName}/images`,
                    viewElementId: view_element.id,
                    targetField: 'src',
                    label: 'Layer image',
                    fileNameToDelete: view_element.src,
                    account,
                    accountName,
                    view,
                  })}
                  onFileDialogCancel={this.handleCancel}
                  previewSrc={previews[`${view_element.id}src`]}
                />
              ),
            },
            {
              title: 'List Order',
              dataIndex: 'list_order',
              key: 'list_order',
              defaultSortOrder: 'ascend',
              sorter: (a, b) => a.list_order - b.list_order,
              render: (text, viewElement) =>
                generateUpdateFormItemInputNumber(
                  this.handleUpdateViewElementListOrderInputChange,
                  {},
                  viewElement.id,
                  viewElement.list_order,
                  'list_order',
                  '',
                  { min: 1, step: 1 }
                ),
            },
            {
              key: 'delete',
              width: 150,
              render: (text, layer) => (
                <ButtonGroup>
                  <Popconfirm
                    placement="bottomRight"
                    title="Delete this layer?"
                    onConfirm={() =>
                      this.handleDeleteLayerButtonPress(layer.id)
                    }
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button type="danger">
                      <Icon type="delete" />
                    </Button>
                  </Popconfirm>
                  <Tooltip
                    title="Edit description point"
                    key="description-point"
                  >
                    <Button
                      onClick={() =>
                        this.setState({ viewElementPointToEdit: layer })
                      }
                      disabled={!layer.src}
                    >
                      <Icon type="plus-circle" />
                    </Button>
                  </Tooltip>
                </ButtonGroup>
              ),
            },
          ]}
        />
      </React.Fragment>
    )
  }
}

const WrappedComponent = compose(
  withRouter,
  withConsumer(AuthenticationConsumer, { propName: 'authentication' }),
  withConsumer(SelectionConsumer, { propName: 'selection' }),
  withQueryResultAsProp({
    gqlDocument: ACCOUNT_WITH_ID_NAME_AND_CLOUD_FOLDER_AND_MATERIALS,
    variables: ({ match }) => ({
      id: Number(match.params.subAccountId || match.params.accountId),
      assignedToPalette: true,
    }),
    resultPropName: 'account',
  }),
  withMutationAsProp({
    gqlDocument: ADD_LAYER,
    mutationPropName: 'addLayer',
    refetchQueries: [
      {
        gqlDocument: VIEW_LAYERS_BY_VIEW_ID,
        variables: ({ view }) => ({ viewId: view.id }),
      },
    ],
  }),
  withMutationAsProp({
    gqlDocument: DELETE_LAYER_BY_ID,
    mutationPropName: 'deleteLayerById',
    refetchQueries: [
      {
        gqlDocument: VIEW_LAYERS_BY_VIEW_ID,
        variables: ({ view }) => ({ viewId: view.id }),
      },
    ],
  }),
  withMutationAsProp({
    gqlDocument: UPDATE_LAYER_BY_ID,
    mutationPropName: 'updateLayerById',
  }),
  withMutationAsProp({
    gqlDocument: UPLOAD_FILE,
    mutationPropName: 'uploadFile',
  }),
  Form.create()
)(LayersForm)

export { WrappedComponent as LayersForm }
