/* eslint-disable camelcase */
import { Button, Form, Input, Modal, Row } from 'antd'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { StyleSheet, css } from 'aphrodite'
import _ from 'lodash'
import { compose } from 'recompose'

import { MaterialPaletteSelection } from '../common/MaterialPaletteSelection'

import {
  ACCOUNT_PALETTE_NAMES,
  ADD_PALETTE,
  GET_BLEND_ELEMENTS,
} from '../../graphql/colors.graphql'

import {
  withConsumer,
  withMutationAsProp,
  withQueryResultAsProp,
} from '../../utils/enhancers'
import { AuthenticationConsumer, SelectionConsumer } from '../../contexts'
import { ErrorModal } from '../common/ErrorModal'
import { RegexPatterns } from '../../const/regex'

const styles = StyleSheet.create({
  button: {
    float: 'right',
  },
  buttonCancel: {
    marginRight: '8px',
  },
  buttonRow: {
    marginTop: '35px',
  },
  formItem: {
    marginBottom: '5px',
  },
  label: {
    fontWeight: '500',
  },
})

class CreatePalettesModal extends Component {
  state = {
    selectedMaterials: [],
  }

  handleCancel = () => {
    const { handleClose } = this.props
    handleClose()
  }

  handleMaterialAssign = (checkedMaterials) => {
    const { selectedMaterials } = this.state
    this.setState({
      selectedMaterials: [...selectedMaterials, ...checkedMaterials],
    })
  }

  handleMaterialUnassign = (material) => {
    const { selectedMaterials } = this.state
    this.setState({
      selectedMaterials: [
        ...selectedMaterials.filter((selected) => selected.id !== material.id),
      ],
    })
  }

  handleAddPaletteSubmit = (e) => {
    const { addPalette, handleClose, form, selection, setStateHandler } =
      this.props
    const { selectedMaterials } = this.state
    e.preventDefault()

    form.validateFields((errors, values) => {
      if (!errors) {
        const materialSelections = selectedMaterials.map((material) =>
          _.omit(material, '__typename')
        )
        const name = values.addPaletteNameField
        const accountId =
          selection.currentSubAccountId || selection.currentAccountId
        addPalette({
          name,
          account_id: accountId,
          selected_elements: materialSelections,
        })
          .then(({ data }) => {
            handleClose()
            selection.setCurrentPaletteId(data.addPalette)
            setStateHandler('editPaletteColorSelections', true)
          })
          .catch(ErrorModal)
      }
    })
  }

  render = () => {
    const { visible, form, blendElements } = this.props
    const { selectedMaterials } = this.state
    const { getFieldDecorator } = form
    return (
      <Modal
        visible={visible}
        onCancel={this.handleCancel}
        width="470px"
        footer={null}
        style={{ top: '50px' }}
      >
        <Form onSubmit={this.handleAddPaletteSubmit} hideRequiredMark>
          <Form.Item
            label={<span className={css(styles.label)}>Palette Name</span>}
            colon={false}
            className={css(styles.formItem)}
          >
            {getFieldDecorator('addPaletteNameField', {
              rules: [
                { required: true, message: "You can't leave this empty." },
              ],
            })(
              <Input
                onChange={
                  // Sanitize input troublesome for URLS: https://secure.n-able.com/webhelp/NC_9-1-0_SO_en/Content/SA_docs/API_Level_Integration/API_Integration_URLEncoding.html
                  (e) => {
                    e.target.value = e.target.value.replace(
                      RegexPatterns.excludedInputCharacters,
                      ''
                    )
                  }
                }
              />
            )}
          </Form.Item>
          <MaterialPaletteSelection
            materialsLoading={false}
            blendElements={blendElements}
            selectedMaterials={selectedMaterials}
            handleMaterialAssign={this.handleMaterialAssign}
            handleMaterialUnassign={this.handleMaterialUnassign}
          />
          <Row className={css(styles.buttonRow)}>
            <Button
              className={css(styles.button)}
              type="primary"
              htmlType="submit"
              disabled={!selectedMaterials.length}
            >
              Submit
            </Button>
            <Button
              onClick={this.handleCancel}
              className={css(styles.button, styles.buttonCancel)}
            >
              Cancel
            </Button>
          </Row>
        </Form>
      </Modal>
    )
  }
}

CreatePalettesModal.propTypes = {
  authentication: PropTypes.shape({
    token: PropTypes.string,
    signIn: PropTypes.func,
    signOut: PropTypes.func,
  }).isRequired,
  selection: PropTypes.shape({
    currentAccountId: PropTypes.number,
    currentSubAccountId: PropTypes.number,
    currentCollectionId: PropTypes.number,
    currentPaletteId: PropTypes.number,
    setCurrentAccountId: PropTypes.func,
    setCurrentPaletteId: PropTypes.func,
    setCurrentCollectionId: PropTypes.func,
  }).isRequired,
  addPalette: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired,
  visible: PropTypes.bool.isRequired,
  blendElements: PropTypes.arrayOf(PropTypes.object).isRequired,
  setStateHandler: PropTypes.func.isRequired,
}

const WrappedComponent = compose(
  withConsumer(AuthenticationConsumer, { propName: 'authentication' }),
  withConsumer(SelectionConsumer, { propName: 'selection' }),
  withQueryResultAsProp({
    gqlDocument: GET_BLEND_ELEMENTS,
    resultPropName: 'blendElements',
  }),
  withMutationAsProp({
    gqlDocument: ADD_PALETTE,
    mutationPropName: 'addPalette',
    refetchQueries: [
      {
        gqlDocument: ACCOUNT_PALETTE_NAMES,
        variables: ({ selection }) => ({
          id: selection.currentSubAccountId || selection.currentAccountId,
        }),
      },
    ],
  })
)(Form.create()(CreatePalettesModal))

export { WrappedComponent as CreatePalettesModal }
