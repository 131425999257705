import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  Alert,
  Button,
  Checkbox,
  Col,
  Icon,
  Input,
  Popconfirm,
  Row,
  Spin,
} from 'antd'
import { StyleSheet, css } from 'aphrodite/no-important'
import { getMaterialDisplayName } from '../../utils/helpers'

const { Search } = Input

const styles = StyleSheet.create({
  animatedMaterial: {
    animationDuration: '1s',
    animationIterationCount: '1',
  },
  assignButtonCol: {
    alignItems: 'center',
    display: 'flex',
    height: '80px',
    justifyContent: 'center',
  },
  assignedMaterialsCol: {
    marginTop: '4px',
  },
  assignedMaterialsList: {
    boxShadow: '0 3px 6px rgba(0,0,0,.16), 0 3px 6px rgba(0,0,0,.23)',
    maxHeight: '25vh',
    height: '25vh',
    overflowY: 'auto',
  },
  checkbox: {
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  header: {
    marginTop: '5px',
    textAlign: 'center',
  },
  materialActionCol: {
    alignItems: 'center',
    display: 'flex',
    height: '45px',
    justifyContent: 'center',
    width: '20%',
  },
  materialContent: {
    fontSize: '14px',
    fontWeight: '500',
    paddingLeft: '8px',
    width: '80%',
  },
  materialRow: {
    alignItems: 'center',
    borderBottom: '1px solid #e8e8e8',
    display: 'flex',
    justifyContent: 'center',
  },
  spin: {
    marginTop: '40px',
    width: '100%',
  },
  unassignedMaterialsContainer: {
    backgroundColor: 'whitesmoke',
    borderBottom: '1px solid #a9a9a9',
    minHeight: '25vh',
    height: '25vh',
    overflowY: 'auto',
    resize: 'vertical',
  },
})

class MaterialPaletteSelection extends Component {
  static propTypes = {
    handleMaterialAssign: PropTypes.func.isRequired,
    handleMaterialUnassign: PropTypes.func.isRequired,
    blendElements: PropTypes.arrayOf(PropTypes.object).isRequired,
    materialsLoading: PropTypes.bool,
    selectedMaterials: PropTypes.arrayOf(PropTypes.object).isRequired,
  }

  static defaultProps = {
    materialsLoading: false,
  }

  state = {
    animatedMaterials: [],
    checkedMaterials: [],
    searchFilter: null,
  }

  handleAnimateAndAssign = () => {
    const { handleMaterialAssign } = this.props
    const { checkedMaterials } = this.state
    const firstChecked = checkedMaterials[0]
    handleMaterialAssign(checkedMaterials)
    this.setState(
      {
        animatedMaterials: [...checkedMaterials],
        checkedMaterials: [],
      },
      () => {
        const scrollPosition = document
          .querySelector(`#assignedMaterial${firstChecked.id}`)
          .getBoundingClientRect()

        document.querySelector('.ant-modal-wrap').scrollTop =
          document.querySelector('.ant-modal-wrap').scrollTop +
          (scrollPosition.top - 20)
        setTimeout(() => {
          this.setState({
            animatedMaterials: [],
          })
        }, 1000)
      }
    )
  }

  handleCheckChange = (e, material) => {
    const { checkedMaterials } = this.state
    const value = e.target.checked
    if (value) {
      this.setState({
        checkedMaterials: [material, ...checkedMaterials],
      })
    } else {
      this.setState({
        checkedMaterials: checkedMaterials.filter(
          (selected) => selected.id !== material.id
        ),
      })
    }
  }

  handleSearchChange = (e) => {
    const { value } = e.target
    this.setState({ searchFilter: value || null })
  }

  generateAssignedMaterials = () => {
    const { selectedMaterials, handleMaterialUnassign } = this.props
    const { animatedMaterials } = this.state
    if (selectedMaterials.length === 0) {
      // No materials are assigned
      return (
        <Alert
          message="No materials have been assigned"
          description="Choose materials from the unassigned materials list"
          type="info"
          showIcon
        />
      )
    }
    return selectedMaterials
      .sort((a, b) => {
        const aName = getMaterialDisplayName(a)
        const bName = getMaterialDisplayName(b)
        return aName.localeCompare(bName, undefined, { numeric: true })
      })
      .map((material) => {
        const isAnimated = animatedMaterials.some(
          (animated) => animated.id === material.id
        )
        return (
          <div
            className={css(
              isAnimated
                ? [styles.materialRow, styles.animatedMaterial]
                : styles.materialRow
            )}
            id={`assignedMaterial${material.id}`}
            key={material.id}
          >
            <div className={css(styles.materialContent)}>
              {getMaterialDisplayName(material)}
            </div>
            <div className={css(styles.materialActionCol)}>
              <Popconfirm
                placement="bottomRight"
                title={<p>Unassign material?</p>}
                onConfirm={() => handleMaterialUnassign(material)}
                okText="Yes"
                okType="danger"
                cancelText="No"
              >
                <Button type="danger" icon="close" size="small" />
              </Popconfirm>
            </div>
          </div>
        )
      })
  }

  generateUnassignedMaterials = () => {
    const { blendElements, selectedMaterials, materialsLoading } = this.props
    const { checkedMaterials, searchFilter } = this.state
    if (blendElements.length > 0) {
      const filteredMaterialsArray = [...blendElements]
        .sort((a, b) => {
          const aName = getMaterialDisplayName(a)
          const bName = getMaterialDisplayName(b)
          return aName.localeCompare(bName, undefined, { numeric: true })
        })
        .reduce((myArray, material) => {
          const myName = getMaterialDisplayName(material)
          const matchesCheckedBlendMode =
            checkedMaterials.length > 0
              ? checkedMaterials[0].blendMode === material.blendMode
              : true
          const matchesSelectedBlendMode =
            selectedMaterials.length > 0
              ? selectedMaterials[0].blendMode === material.blendMode
              : true
          const matchesFilter =
            searchFilter !== null
              ? myName.toLowerCase().includes(searchFilter.toLowerCase())
              : true
          const isSelected = selectedMaterials.some(
            (selected) => selected.id === material.id
          )

          if (
            matchesCheckedBlendMode &&
            matchesSelectedBlendMode &&
            matchesFilter &&
            !isSelected
          ) {
            myArray.push(
              <div className={css(styles.materialRow)} key={material.id}>
                <div className={css(styles.materialContent)}>{myName}</div>
                <div className={css(styles.materialActionCol)}>
                  <Checkbox
                    checked={checkedMaterials.some(
                      (checked) => checked.id === material.id
                    )}
                    className={css(styles.checkbox)}
                    onChange={(e) => this.handleCheckChange(e, material)}
                  />
                </div>
              </div>
            )
          }
          return myArray
        }, [])

      if (filteredMaterialsArray.length === 0) {
        if (searchFilter) {
          return (
            <Alert
              className={css(styles.alert)}
              message="No materials matched your search"
              description="Change your search term to view materials"
              type="info"
              showIcon
            />
          )
        }
        return (
          <Alert
            className={css(styles.alert)}
            message="All available materials have already been assigned"
            type="info"
            showIcon
          />
        )
      }
      return filteredMaterialsArray
    }
    if (materialsLoading) {
      return <Spin className={css(styles.spin)} tip="Loading..." />
    }
    return (
      <Alert
        className={css(styles.alert)}
        message="Failed to retrieve materials"
        type="error"
        showIcon
      />
    )
  }

  render = () => {
    const { searchFilter, checkedMaterials } = this.state
    return (
      <Row style={{ width: '90%', marginLeft: '5%' }}>
        <Col xs={24}>
          <h3 className={css(styles.header)}>Unassigned Materials</h3>
          <Search
            id="materialFilterByName"
            placeholder="Search"
            value={searchFilter}
            onChange={this.handleSearchChange}
          />
          <div className={css(styles.unassignedMaterialsContainer)}>
            {this.generateUnassignedMaterials()}
          </div>
        </Col>
        <Col xs={24} className={css(styles.assignButtonCol)}>
          <Button
            disabled={checkedMaterials.length === 0}
            onClick={this.handleAnimateAndAssign}
            size="large"
            type={checkedMaterials.length > 0 ? 'primary' : 'dashed'}
          >
            Assign Checked Materials
            <Icon type="arrow-down" />
          </Button>
        </Col>
        <Col className={css(styles.assignedMaterialsCol)} xs={24}>
          <h3 className={css(styles.header)}>Assigned Materials</h3>
          <div
            id="assignedMaterialsList"
            className={css(styles.assignedMaterialsList)}
          >
            {this.generateAssignedMaterials()}
          </div>
        </Col>
      </Row>
    )
  }
}
export { MaterialPaletteSelection }
