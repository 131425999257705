import React, { useEffect, useState, useRef } from 'react'
import { StyleSheet } from 'aphrodite/no-important'
import { Form, Input } from 'antd'
import PropTypes from 'prop-types'
import { useLazyQuery } from '@apollo/client'
import { StyledDropzone } from '../common/StyledDropzone'
import { getRoomCloudinaryPath } from './functions/getRoomCloudinaryPath'
import { GET_ROOMS_BY_NAME } from '../../graphql/fcRooms.graphql'

const classes = StyleSheet.create({
  dropdown: {
    width: '100%',
    height: 200,
    borderWidth: 2,
    borderColor: 'gray',
    borderStyle: 'dashed',
    borderRadius: 5,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  image: {
    maxWidth: '100%',
    maxHeight: '100%',
  },
})

export const IMAGE_KEY = 'image'
export const BASE_IMAGE_KEY = 'baseImage'

const MAX_ALLOWED_IMAGE_DIMENSION_IN_PX = 4096

export default function RoomForm({
  form,
  editMode,
  account,
  setFile,
  setBaseFile,
  room,
  visible,
  propertyId,
  onInputBlur,
}) {
  const { getFieldDecorator } = form
  const [preview, setPreview] = useState(null)
  const [getRoomsByName] = useLazyQuery(GET_ROOMS_BY_NAME)
  const [basePreview, setBasePreview] = useState(null)

  const imageFile = useRef(null)
  const baseImageFile = useRef(null)

  const handleDrop = async (files) => {
    const file = files[0]
    const dataURL = await new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onload = () => {
        resolve(reader.result)
      }
      reader.onerror = reject
      reader.readAsDataURL(file)
    })

    setPreview(dataURL)
    setFile(file)
    imageFile.current = file

    // Set file for validation
    form.setFieldsValue({
      [IMAGE_KEY]: file.name,
    })
  }

  const validateNameAvailability = async (rule, value) => {
    const trimmedValue = value.trim()
    if (trimmedValue && room?.name !== trimmedValue) {
      const rooms = await getRoomsByName({
        variables: {
          name: trimmedValue,
          filter: {
            accountId: account.id,
            projectId: propertyId,
          },
        },
        fetchPolicy: 'no-cache',
      }).then(({ data }) => {
        return data.roomsByName
      })

      if (rooms.length > 0) {
        throw new Error()
      }
    }
  }

  const getImageFileDimensions = (imageFile) => {
    return new Promise((resolve) => {
      const reader = new FileReader()
      reader.onload = () => {
        const image = new Image()
        image.src = reader.result
        image.onload = () => {
          resolve({
            width: image.width,
            height: image.height,
          })
        }
      }
      reader.readAsDataURL(imageFile)
    })
  }

  const validateImageSize = async (rule) => {
    let file = null
    if (rule.field === IMAGE_KEY && imageFile.current) {
      file = imageFile.current
    } else if (rule.field === BASE_IMAGE_KEY && baseImageFile.current) {
      file = baseImageFile.current
    }

    if (!file) {
      return true
    }

    const { width, height } = await getImageFileDimensions(file)

    if (
      width > MAX_ALLOWED_IMAGE_DIMENSION_IN_PX ||
      height > MAX_ALLOWED_IMAGE_DIMENSION_IN_PX
    ) {
      throw new Error()
    }

    return true
  }

  const handleBaseImageDrop = async (files) => {
    const file = files[0]
    const dataURL = await new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onload = () => {
        resolve(reader.result)
      }
      reader.onerror = reject
      reader.readAsDataURL(file)
    })

    setBasePreview(dataURL)
    setBaseFile(file)
    baseImageFile.current = file

    // Set file for validation
    form.setFieldsValue({
      [BASE_IMAGE_KEY]: file.name,
    })
  }

  useEffect(() => {
    if (visible) {
      setPreview(null)
      setBasePreview(null)
    }
  }, [visible])

  return (
    <Form>
      <Form.Item label="Name">
        {getFieldDecorator('name', {
          initialValue: room?.name || '',
          rules: [
            {
              required: true,
              message: 'Please input room name!',
            },
            {
              max: 255,
              message: 'Maximum name size is 255!',
            },
            {
              validator: validateNameAvailability,
              message: 'This name has already been used!',
            },
          ],
        })(<Input placeholder="Office, Corridor, ..." onBlur={onInputBlur} />)}
      </Form.Item>
      <Form.Item label="Image">
        {getFieldDecorator(IMAGE_KEY, {
          initialValue: room?.image || '',
          rules: [
            {
              required: true,
              message: 'Please upload room image!',
            },
            {
              validator: validateImageSize,
              message: `Max allowed image dimensions are ${MAX_ALLOWED_IMAGE_DIMENSION_IN_PX} x ${MAX_ALLOWED_IMAGE_DIMENSION_IN_PX} px`,
            },
          ],
        })(
          <StyledDropzone
            baseStyle={classes.dropdown}
            key={
              !editMode &&
              room?.image &&
              getRoomCloudinaryPath(account.cloud_folder_name, room?.image)
            }
            accept="image/*"
            onDrop={handleDrop}
            message="Drop an image, or click here to select one."
            imgWidth="auto"
            imgHeight="auto"
            imageStyles={classes.image}
            imgSrc={
              room?.image &&
              getRoomCloudinaryPath(account.cloud_folder_name, room?.image)
            }
            previewSrc={preview}
          />
        )}
      </Form.Item>
      <Form.Item label="Base Image">
        {getFieldDecorator(BASE_IMAGE_KEY, {
          initialValue: room?.baseImage || '',
          rules: [
            {
              required: true,
              message: 'Please upload a room base image!',
            },
            {
              validator: validateImageSize,
              message: `Max allowed image dimensions are ${MAX_ALLOWED_IMAGE_DIMENSION_IN_PX} x ${MAX_ALLOWED_IMAGE_DIMENSION_IN_PX} px`,
            },
          ],
        })(
          <StyledDropzone
            baseStyle={classes.dropdown}
            key={
              !editMode &&
              room?.baseImage &&
              getRoomCloudinaryPath(account.cloud_folder_name, room?.baseImage)
            }
            accept="image/*"
            onDrop={handleBaseImageDrop}
            message="Drop an image, or click here to select one."
            imgWidth="auto"
            imgHeight="auto"
            imageStyles={classes.image}
            imgSrc={
              room?.baseImage &&
              getRoomCloudinaryPath(account.cloud_folder_name, room?.baseImage)
            }
            previewSrc={basePreview}
          />
        )}
      </Form.Item>
    </Form>
  )
}

RoomForm.propTypes = {
  form: PropTypes.object.isRequired,
  editMode: PropTypes.bool,
  account: PropTypes.object.isRequired,
  setFile: PropTypes.func.isRequired,
  setBaseFile: PropTypes.func.isRequired,
  room: PropTypes.object,
  visible: PropTypes.bool.isRequired,
  propertyId: PropTypes.number.isRequired,
  onInputBlur: PropTypes.func,
}

RoomForm.defaultProps = {
  editMode: false,
  room: null,
  onInputBlur: () => {},
}
