import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Typography } from 'antd'
import { useQuery } from '@apollo/client'
import { StyleSheet, css } from 'aphrodite'
import ModalDrawer from '../../../common/ModalDrawer'

import { getSwatchesCloudinaryPath } from '../../../../functions/getSwatchesCloudinaryPath'
import { GET_SWATCHES } from '../../../../graphql/fcSwatches.graphql'

import { Filter, Input } from '../../../common/Filter'
import SmallCard from '../../../common/SmallCard'
import { useInfinitScroll } from '../../../../hooks/useInfinitScroll'

const classes = StyleSheet.create({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    height: 'calc(100vh - 180px)',
    overflow: 'auto',
    alignContent: 'flex-start',
  },
  input: {
    width: 284,
  },
})

const PAGE_SIZE = 30
export default function SwatchDrawer({
  visible,
  setVisible,
  selectedSwatchId,
  onSelect,
  roomId,
}) {
  const [filterName, setFilterName] = useState(null)
  const { data, loading, fetchMore } = useQuery(GET_SWATCHES, {
    notifyOnNetworkStatusChange: true,
    variables: {
      filter: {
        name: filterName,
        roomId,
      },
      pagination: {
        page: 0,
        size: PAGE_SIZE,
      },
      ordering: {
        columns: [{ name: 'name', order: 'ASC' }],
      },
    },
  })

  const { onScroll } = useInfinitScroll({
    loading,
    pageSize: PAGE_SIZE,
    totalCount: data?.swatches?.totalCount,
    currentCount: data?.swatches?.items.length,
    cb: async (pagination) => {
      await fetchMore({
        variables: {
          pagination,
        },
      })
    },
  })

  const filter = {
    value: filterName,
    set: setFilterName,
  }

  return (
    <ModalDrawer
      title="Select Swatch"
      visible={visible}
      setVisible={setVisible}
      getContainer="#layer-modal"
      onScroll={onScroll}
    >
      <Filter loading={loading} data={filter}>
        <Input
          inputClass={classes.input}
          field="name"
          label="Name"
          placeholder="Search by name ..."
        />
      </Filter>
      <Typography.Title level={4}>Swatches</Typography.Title>
      <div className={css(classes.root)}>
        {data?.swatches?.items.map((swatch) => (
          <SmallCard
            key={swatch?.id}
            loading={loading}
            selected={!loading && selectedSwatchId === swatch?.id}
            data={{
              ...swatch,
              imageSrc: getSwatchesCloudinaryPath(swatch?.image ?? 'undefined'),
            }}
            onSelect={onSelect}
          />
        ))}
      </div>
    </ModalDrawer>
  )
}

SwatchDrawer.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  selectedSwatchId: PropTypes.number,
  onSelect: PropTypes.func.isRequired,
  roomId: PropTypes.number.isRequired,
}

SwatchDrawer.defaultProps = {
  selectedSwatchId: null,
}
