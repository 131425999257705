import React from 'react'
import PropTypes from 'prop-types'
import { useMutation } from '@apollo/client'
import { Button, message, Popconfirm, Tooltip } from 'antd'
import {
  DELETE_LAYER_MATERIAL,
  LAYER_MATERIALS,
} from '../../../graphql/fcMaterials.graphql'
import { ACCOUNT_WITH_MATERIALS } from '../../../graphql/accounts.graphql'

export default function MaterialTableActions({ accountId, material }) {
  const [deleteLayerMaterial, { loading }] = useMutation(DELETE_LAYER_MATERIAL)

  const handleDeleteLayerMaterial = async (materialId) => {
    try {
      await deleteLayerMaterial({
        variables: {
          id: materialId,
        },
        refetchQueries: [
          LAYER_MATERIALS,
          { query: ACCOUNT_WITH_MATERIALS, variables: { id: accountId } },
        ],
      })
      message.success('Flooring material was successfully deleted!')
    } catch (e) {
      console.error(e)
      message.error('Flooring material was not deleted!')
    }
  }

  return (
    <Popconfirm
      placement="left"
      title={`Are you sure you want to delete flooring material ${material.displayName}?`}
      onConfirm={() => handleDeleteLayerMaterial(material.id)}
      okText="Yes"
      cancelText="No"
    >
      <Tooltip title="Delete flooring material">
        <Button type="danger" icon="delete" size="large" loading={loading} />
      </Tooltip>
    </Popconfirm>
  )
}

MaterialTableActions.propTypes = {
  material: PropTypes.shape({
    id: PropTypes.number.isRequired,
    displayName: PropTypes.string.isRequired,
  }).isRequired,
  accountId: PropTypes.number.isRequired,
}

MaterialTableActions.defaultProps = {}
