import { Button, Form } from 'antd'
import { css, StyleSheet } from 'aphrodite/no-important'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { compose } from 'recompose'

import {
  generateUpdateFormItemInput,
  generateUnsafeUpdateFormItemInput,
  generateUpdateFormItemSelect,
  withConsumer,
  withMutationAsProp,
  withQueryResultAsProp,
  generateDivisionCheckboxes,
  withEnumValuesAsProp,
} from '../../../utils'

import { AuthenticationConsumer } from '../../../contexts'

import {
  UPDATE_USER_BY_ID,
  USER,
  ROLES,
  UPDATE_USER_DIVISIONS_BY_ID,
} from '../../../graphql/users.graphql'
import ChangePasswordModal from './ChangePasswordModal'

const styles = StyleSheet.create({
  updateUserForm: {
    padding: '0px 10px 0px 10px',
    margin: 'auto',
  },
  changePasswordButton: {
    margin: '10px 0 10px auto',
  },
})

const updateUserFormItemLayout = {
  labelCol: {
    // 1600px ≤ width
    xxl: {
      span: 2,
    },
    // 1200px ≤ width < 1600px
    xl: {
      span: 2,
    },
    // 992px ≤ width < 1200px
    lg: {
      span: 2,
    },
    // 768px ≤ width < 992px
    md: {
      span: 4,
    },
    // 576px ≤ width < 768px
    sm: {
      span: 4,
    },
    // width < 576px and also default setting
    xs: {
      span: 24,
    },
  },
  wrapperCol: {
    xxl: {
      span: 10,
    },
    xl: {
      span: 10,
    },
    lg: {
      span: 10,
    },
    md: {
      span: 20,
    },
    sm: {
      span: 20,
    },
    xs: {
      span: 24,
    },
  },
}

class UserForm extends Component {
  static propTypes = {
    authentication: PropTypes.shape({
      token: PropTypes.string.isRequired,
      appConfig: PropTypes.object.isRequired,
    }).isRequired,
    updateUserById: PropTypes.func.isRequired,
    updateUserDivisions: PropTypes.func.isRequired,
    user: PropTypes.shape({
      id: PropTypes.number.isRequired,
      email: PropTypes.string,
      first_name: PropTypes.string,
      last_name: PropTypes.string,
      role: PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string,
      }).isRequired,
      divisions: PropTypes.arrayOf(PropTypes.number),
    }).isRequired,
    roles: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string,
      })
    ).isRequired,
    divisions: PropTypes.arrayOf(PropTypes.string).isRequired,
  }

  constructor(props) {
    super(props)

    this.state = { isChangePasswordModalVisible: false }
  }

  handleUpdateUserFormItemInputChange = (userId, targetField, label, value) => {
    console.log(
      `handleUpdateUserFormItemInputChange: userId: ${userId}, targetField: ${targetField}, label: ${label}, value: ${value}`
    )
    // Convert email address to lowercase before sending as user update.
    if (targetField === 'email') {
      value = value.toLowerCase()
    }
    const { updateUserById } = this.props
    updateUserById({
      id: userId,
      input: { [targetField]: value },
    })
  }

  handleUserDivisionsChange = (userId, divisions) => {
    const { updateUserDivisions } = this.props
    updateUserDivisions({
      userId,
      input: {
        divisions,
      },
    })
  }

  changePasswordButton = () => (
    <Button
      className={css(styles.changePasswordButton)}
      type="primary"
      icon="user"
      onClick={() => this.setState({ isChangePasswordModalVisible: true })}
    >
      Change password
    </Button>
  )

  render() {
    const { user, roles, divisions } = this.props
    const { isChangePasswordModalVisible } = this.state
    return (
      <Form key="updateUserForm" className={css(styles.updateUserForm)}>
        <ChangePasswordModal
          isVisible={isChangePasswordModalVisible}
          onClose={() => this.setState({ isChangePasswordModalVisible: false })}
          userId={user.id}
        />
        {generateUnsafeUpdateFormItemInput(
          this.handleUpdateUserFormItemInputChange,
          updateUserFormItemLayout,
          user.id,
          user.email,
          'email',
          'Email'
        )}
        {generateUpdateFormItemInput(
          this.handleUpdateUserFormItemInputChange,
          updateUserFormItemLayout,
          user.id,
          user.first_name,
          'first_name',
          'First Name'
        )}
        {generateUpdateFormItemInput(
          this.handleUpdateUserFormItemInputChange,
          updateUserFormItemLayout,
          user.id,
          user.last_name,
          'last_name',
          'Last Name'
        )}
        {
          // Letting a user change _their own_ role is probably just asking for trouble.
          // Let's not do that. Display as disabled (read-only). Provide a no-op handler.
          generateUpdateFormItemSelect(
            () => {},
            updateUserFormItemLayout,
            user.id,
            roles,
            (role) => role.id,
            (role) => role.name,
            false,
            user.role.id,
            'role',
            'Role',
            true,
            this.changePasswordButton()
          )
        }
        {generateDivisionCheckboxes(
          'Subcribe',
          updateUserFormItemLayout,
          user.divisions,
          this.handleUserDivisionsChange,
          user.id,
          divisions
        )}
      </Form>
    )
  } // render()
} // class UserForm

const WrappedComponent = compose(
  withConsumer(AuthenticationConsumer, { propName: 'authentication' }),
  withQueryResultAsProp({
    gqlDocument: USER,
    variables: ({ userId }) => ({ id: userId }),
    resultPropName: 'user',
  }),
  withQueryResultAsProp({
    gqlDocument: ROLES,
    resultPropName: 'roles',
  }),
  withMutationAsProp({
    gqlDocument: UPDATE_USER_BY_ID,
    mutationPropName: 'updateUserById',
  }),
  withMutationAsProp({
    gqlDocument: UPDATE_USER_DIVISIONS_BY_ID,
    mutationPropName: 'updateUserDivisions',
  }),
  withEnumValuesAsProp({
    typeName: 'Division',
    propName: 'divisions',
  }),
  Form.create()
)(UserForm)

export { WrappedComponent as UserForm }
