import { Button, Form, message, Modal, Row } from 'antd'

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { ApolloConsumer, makeReference } from '@apollo/client'
import { compose } from 'recompose'
import { StyleSheet, css } from 'aphrodite'
import cloneDeep from 'lodash/cloneDeep'
// import bounceKeyframes from '../../../stylesheets/bouncierKeyframes'

import { MaterialPaletteSelection } from '../common/MaterialPaletteSelection'

import {
  withConsumer,
  withMutationAsProp,
  withQueryResultAsProp,
} from '../../utils/enhancers'

import {
  ACCOUNT_PALETTE_QUERY,
  GET_BLEND_ELEMENTS,
  GET_PALETTE,
  GET_COLOR_COLLECTIONS,
  UPDATE_PALETTE,
} from '../../graphql/colors.graphql'

import { AuthenticationConsumer, SelectionConsumer } from '../../contexts'
import { ErrorModal } from '../common/ErrorModal'

const styles = StyleSheet.create({
  animatedSelection: {
    // animationName: [bounceKeyframes],
    animationDuration: '1s',
    animationIterationCount: '1',
  },
  button: {
    float: 'right',
    width: '30%',
  },
  buttonMargin: {
    marginRight: '8px',
    marginBottom: '8px',
  },
  buttonRow: {
    marginTop: '35px',
    padding: '0px 16px 12px 0px',
  },
  colorCard: {
    backgroundColor: 'white',
    border: '2px solid #e8e8e8',
    borderRadius: '5px',
    height: '100px',
    width: '100px',
    display: 'inline-block',
    verticalAlign: 'top',
    cursor: 'pointer',
    position: 'relative',
  },
  colorCardVirtualized: {
    marginTop: '2px',
    width: '97%',
  },
  colorsCol: {
    backgroundColor: 'whitesmoke',
    minHeight: '540px',
  },
  colorsContainer: {
    height: '420px',
    padding: '8px',
    overflowY: 'scroll',
  },
  colorName: {
    fontSize: '13px',
    padding: '4px',
    overflow: 'hidden',
    height: '44px',
  },
  dividerTop: {
    background: '#a9a9a9',
    marginBottom: 0,
  },
  filter: {
    width: '100%',
  },
  filterCol: {
    padding: '0 10px 5px 10px',
  },
  filtersCollapse: {
    margin: '0 12px',
  },
  filterSelect: {
    width: '100%',
  },
  formCol: {
    padding: '10px 16px',
  },
  formItem: {
    marginBottom: '5px',
  },
  header: {
    fontSize: '18px',
    margin: '12px 0px 8px 12px',
  },
  iconCorner: {
    position: 'absolute',
    bottom: 5,
    right: 5,
  },
  iconGreen: {
    color: 'rgb(28, 184, 65)',
  },
  iconRed: {
    color: 'rgb(228, 134, 134)',
  },
  label: {
    fontWeight: '500',
  },
  selectedColor: {
    // backgroundColor: '#e6f7ff',
    borderColor: '#1890ff',
  },
  selectionsCol: {
    backgroundColor: '#e6e6e6',
    minHeight: '540px',
  },
  selectionsContainer: {
    height: '592px',
    padding: '8px 0px 8px 8px',
    overflowY: 'scroll',
  },
  spin: {
    marginTop: '40px',
    width: '100%',
  },
  vendorCollapse: {
    backgroundColor: 'white',
    marginBottom: '12px',
  },
  vendorPanelContents: {
    paddingLeft: '12px',
  },
})
class PaletteMaterialSelectionsModal extends Component {
  static getSortedSelectionsFromProps = (props) => {
    if (
      props.palette.palette_selections !== undefined &&
      props.palette.palette_selections.length > 0
    ) {
      const clonedPaletteSelections = cloneDeep(
        props.palette.palette_selections
      )

      clonedPaletteSelections.sort((a, b) => {
        // Treat null like it is higher than anything else.
        if (
          !(a.color && a.color.identifier) &&
          !(b.color && b.color.identifier)
        ) {
          return 0
        }
        if (!(a.color && a.color.identifier)) {
          return 1
        }
        if (!(b.color && b.color.identifier)) {
          return -1
        }

        return a.color.identifier.localeCompare(b.color.identifier)
      })

      return clonedPaletteSelections
    }
    return []
  }

  static getSelectedBlendElementsFromProps = (props) => {
    const { blendElements, palette } = props
    const selectedBlendElements =
      palette && palette.materials
        ? palette.materials.map((paletteMaterial) =>
            blendElements.find(
              (blendElement) => blendElement.id === paletteMaterial.element_id
            )
          )
        : []

    return selectedBlendElements
  }

  state = {
    // sort initial palette color selection.
    selections: PaletteMaterialSelectionsModal.getSortedSelectionsFromProps(
      this.props
    ),
    selectedBlendElements:
      PaletteMaterialSelectionsModal.getSelectedBlendElementsFromProps(
        this.props
      ),
    visible: true,
  }

  handleCancel = () => {
    const { selection, setStateHandler } = this.props
    const { setCurrentPaletteId } = selection
    this.setState({ visible: false })
    setTimeout(() => {
      setStateHandler('editPaletteMaterialSelections', false)
      setCurrentPaletteId(null)
    }, 200)
  }

  handleMaterialAssign = (checkedMaterials) => {
    const { selectedBlendElements } = this.state
    this.setState({
      selectedBlendElements: [...selectedBlendElements, ...checkedMaterials],
    })
  }

  handleMaterialUnassign = (material) => {
    const { selectedBlendElements } = this.state

    this.setState({
      selectedBlendElements: [
        ...selectedBlendElements.filter(
          (selected) => selected.id !== material.id
        ),
      ],
    })
  }

  handleUpdatePaletteSubmit = (e) => {
    const { form, selection, updatePalette, palette } = this.props
    const { selections, selectedBlendElements } = this.state
    e.preventDefault()
    form.validateFields((errors, values) => {
      // eslint-disable-next-line camelcase
      const palette_id = selection.currentPaletteId
      if (!errors) {
        const paletteSelections = selections.map((paletteSelection) => ({
          color_id: paletteSelection.color_id,
          blend_mode: paletteSelection.blend_mode,
          palette_id,
          name: paletteSelection.name,
          hex: paletteSelection.hex,
        }))
        updatePalette({
          palette_id,
          name: values.paletteNameInput,
          palette_selections: paletteSelections,
          account_id:
            selection.currentSubAccountId || selection.currentAccountId,
          // Clean up the objects to match the SelectedElementInput type.
          selected_elements: selectedBlendElements.map((materialSelection) => ({
            id: materialSelection.id,
            blend_mode: materialSelection.blend_mode,
            display_name: materialSelection.display_name,
            identifier: materialSelection.identifier,
            material_category: materialSelection.material_category,
            name: materialSelection.name,
            remarks: materialSelection.remarks,
          })),
        })
          .then(() => {
            message.success(`Updated palette "${palette.name}"`)
            this.handleCancel()
          })
          .catch(ErrorModal)
      }
    })
  }

  render = () => {
    const { palette, blendElements } = this.props

    const { visible, selectedBlendElements } = this.state

    if (!palette) {
      return null
    }

    return (
      <Modal
        visible={visible}
        title={
          palette.name !== undefined && palette.name !== null
            ? `Edit "${palette.name}" Materials`
            : 'Edit Materials'
        }
        footer={null}
        onCancel={this.handleCancel}
        bodyStyle={{ padding: '0' }}
        style={{ top: '50px' }}
        width="450px"
      >
        <MaterialPaletteSelection
          materialsLoading={false}
          blendElements={blendElements}
          selectedMaterials={selectedBlendElements}
          handleMaterialAssign={this.handleMaterialAssign}
          handleMaterialUnassign={this.handleMaterialUnassign}
        />
        <Row className={css(styles.buttonRow)}>
          <Button
            className={css(styles.button)}
            onClick={this.handleUpdatePaletteSubmit}
            type="primary"
          >
            Submit
          </Button>
          <Button
            onClick={this.handleCancel}
            className={css(styles.button, styles.buttonMargin)}
          >
            Cancel
          </Button>
        </Row>
      </Modal>
    )
  }
} // class PaletteMaterialSelectionsModal

PaletteMaterialSelectionsModal.propTypes = {
  authentication: PropTypes.shape({
    token: PropTypes.string,
    signIn: PropTypes.func,
    signOut: PropTypes.func,
  }).isRequired,
  selection: PropTypes.shape({
    currentPaletteId: PropTypes.number,
    currentAccountId: PropTypes.number,
    currentSubAccountId: PropTypes.number,
    setCurrentAccountId: PropTypes.func,
    setCurrentPaletteId: PropTypes.func,
  }).isRequired,
  form: PropTypes.object.isRequired,
  palette: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string,
    palette_selections: PropTypes.arrayOf(PropTypes.object).isRequired,
    materials: PropTypes.arrayOf(PropTypes.object).isRequired,
  }).isRequired,
  updatePalette: PropTypes.func.isRequired,
  blendElements: PropTypes.arrayOf(PropTypes.object).isRequired,
  setStateHandler: PropTypes.func.isRequired,
}

const WrappedComponent = compose(
  withConsumer(AuthenticationConsumer, { propName: 'authentication' }),
  withConsumer(SelectionConsumer, { propName: 'selection' }),
  withConsumer(ApolloConsumer, { propName: 'apolloClient' }),
  withQueryResultAsProp({
    gqlDocument: GET_BLEND_ELEMENTS,
    resultPropName: 'blendElements',
  }),
  withQueryResultAsProp({
    gqlDocument: GET_PALETTE,
    variables: ({ selection }) => ({ id: selection.currentPaletteId }),
    resultPropName: 'palette',
    propName: 'palette',
  }),
  withQueryResultAsProp({
    gqlDocument: GET_COLOR_COLLECTIONS,
    propName: 'colorCollections',
    resultPropName: 'colorVendorProductInfos',
  }),
  withMutationAsProp({
    gqlDocument: UPDATE_PALETTE,
    mutationPropName: 'updatePalette',
    refetchQueries: [
      {
        gqlDocument: ACCOUNT_PALETTE_QUERY,
        variables: ({ selection }) => ({
          id: selection.currentSubAccountId || selection.currentAccountId,
        }),
      },
    ],
    update(cache) {
      const rootId = cache.identify(makeReference('ROOT_QUERY'))
      cache.evict({ id: rootId, fieldName: 'colors' })
    },
  })
)(Form.create()(PaletteMaterialSelectionsModal))
export { WrappedComponent as PaletteMaterialSelectionsModal }
