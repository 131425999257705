import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Filter } from '../../../common/Filter/Filter'
import { Input } from '../../../common/Filter/Input'
import { QueryParams } from '../../../../const/queryParams'

export const PropertiesFilter = ({ filter, setFilter, queryParams }) => {
  const filterData = {
    value: filter.projectName,
    set: (projectName) => {
      setFilter({ ...filter, projectName })
      queryParams
        .set(QueryParams.PAGE)
        .set(QueryParams.PROJECT_NAME, projectName)
        .update()
    },
  }

  return (
    <Filter loading={false} data={filterData}>
      <Input
        field="projectName"
        label="Project Name"
        placeholder="Search by project name ..."
      />
    </Filter>
  )
}
PropertiesFilter.propTypes = {
  filter: PropTypes.shape({
    projectName: PropTypes.string,
  }).isRequired,
  setFilter: PropTypes.func.isRequired,
  queryParams: PropTypes.shape({
    get: PropTypes.func.isRequired,
    set: PropTypes.func.isRequired,
    update: PropTypes.func.isRequired,
  }).isRequired,
}

export const useDefaultFilter = (
  params,
  setCurrentPage,
  setPageSize,
  defaultPageSize
) => {
  const [filter, setFilter] = useState({
    projectName: params.get(QueryParams.PROJECT_NAME) || undefined,
  })
  const handleChange = (value) => {
    setFilter(value)
    setCurrentPage(1)
    setPageSize(Number(params.get(QueryParams.PAGE_SIZE) || defaultPageSize))
  }
  return { filter, setFilter: handleChange }
}
