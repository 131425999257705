import React from 'react'
import { Icon, Popconfirm, Tooltip } from 'antd'

export default function MaskCardActions({
  onEditClick,
  handleRemoveMask,
  onEditDescriptionPoint,
  mask,
}) {
  return [
    <Tooltip title="Edit mask" key="edit">
      <Icon
        type="edit"
        theme="twoTone"
        twoToneColor="#1890ff"
        onClick={() => onEditClick(mask)}
      />
    </Tooltip>,
    <Popconfirm
      key="delete"
      placement="left"
      title={`Are you sure you want to delete mask ${mask.material.display_name}?`}
      onConfirm={() => handleRemoveMask(mask)}
      okText="Yes"
      cancelText="No"
    >
      <Tooltip title="Delete mask">
        <Icon type="delete" theme="twoTone" twoToneColor="#ff4d4f" />
      </Tooltip>
    </Popconfirm>,
    <Tooltip title="Edit description point" key="description-point">
      <Icon type="plus-circle" onClick={() => onEditDescriptionPoint(mask)} />
    </Tooltip>,
  ]
}
