import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { css, StyleSheet } from 'aphrodite/no-important'
import { useParams } from 'react-router-dom/cjs/react-router-dom'
import { Button } from 'antd'
import MaskModal from './MaskModal'
import MaskCardList from './MaskCardList'
import { useAccountAndProperty } from '../../../hooks/useAccountAndProperty'
import MaskDescriptionPointModal from './MaskDescriptionPointModal'

const classes = StyleSheet.create({
  root: {
    padding: '5px 20px',
  },
  addButton: {
    margin: '0 0 5px auto',
    display: 'block',
  },
})

export default function RoomMasksTab({ room }) {
  const params = useParams()
  const { currentAccount } = useAccountAndProperty(
    Number(params.subAccountId || params.accountId),
    Number(params.propertyId)
  )
  const [visible, setVisible] = useState(false)
  const [maskToUpdate, setMaskToUpdate] = useState(null)
  const [usedMaterials, setUsedMaterials] = useState([])
  const [maskToEditDescriptionPoint, setMaskToEditDescriptionPoint] =
    useState(null)

  const handleCloseModal = () => {
    setMaskToUpdate(null)
    setVisible(false)
  }

  const handleEditMaskClick = (mask) => {
    setMaskToUpdate(mask)
    setVisible(true)
  }

  const handleEditDescriptionPoint = (mask) => {
    setMaskToEditDescriptionPoint(mask)
  }

  const handleCloseEditDescriptionPointModal = () =>
    setMaskToEditDescriptionPoint(null)

  return (
    <div className={css(classes.root)}>
      <Button
        icon="plus"
        className={css(classes.addButton)}
        type="primary"
        onClick={() => setVisible(true)}
      >
        Add Mask
      </Button>
      <MaskModal
        mask={maskToUpdate}
        visible={visible}
        handleCloseModal={handleCloseModal}
        roomId={room.id}
        account={currentAccount}
        usedMaterials={usedMaterials}
      />
      <MaskDescriptionPointModal
        onClose={handleCloseEditDescriptionPointModal}
        mask={maskToEditDescriptionPoint}
        account={currentAccount}
        roomId={room.id}
      />
      <MaskCardList
        onEditClick={handleEditMaskClick}
        onEditDescriptionPoint={handleEditDescriptionPoint}
        roomId={room.id}
        account={currentAccount}
        setUsedMaterials={setUsedMaterials}
      />
    </div>
  )
}

RoomMasksTab.propTypes = {
  room: PropTypes.objectOf({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
}
RoomMasksTab.defaultProps = {}
