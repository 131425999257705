import React, { useEffect, useState } from 'react'
import { css, StyleSheet } from 'aphrodite/no-important'
import { Form, InputNumber, Select } from 'antd'
import PropTypes from 'prop-types'
import Text from 'antd/es/typography/Text'
import { StyledDropzone } from '../../common/StyledDropzone'
import { getMaskCloudinaryPath } from '../functions/getMaskCloudinaryPath'

const classes = StyleSheet.create({
  dropdown: {
    width: '100%',
    height: 200,
    borderWidth: 2,
    borderColor: 'gray',
    borderStyle: 'dashed',
    borderRadius: 5,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'darkgray',
  },
  image: {
    maxWidth: '100%',
    maxHeight: '100%',
  },
  renderIndexDescription: {
    display: 'block',
    lineHeight: '20px',
  },
})

export const IMAGE_KEY = 'image'

export default function MaskForm({
  form,
  editMode,
  setFile,
  mask,
  visible,
  roomId,
  account,
  materials,
}) {
  const { getFieldDecorator } = form
  const [preview, setPreview] = useState(null)

  const handleDrop = async (files) => {
    const file = files[0]
    const dataURL = await new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onload = () => {
        resolve(reader.result)
      }
      reader.onerror = reject
      reader.readAsDataURL(file)
    })

    setPreview(dataURL)
    setFile(file)
    // Set file for validation
    form.setFieldsValue({
      [IMAGE_KEY]: file.name,
    })
  }

  useEffect(() => {
    if (visible === false) {
      setPreview(null)
    }
  }, [visible])

  return (
    <Form>
      <Form.Item label="Material">
        {getFieldDecorator('materialId', {
          initialValue: mask?.material?.id ?? null,
          getValueFromEvent: (materialId) => materialId || null,
          rules: [
            {
              required: true,
              message: 'Please select material id!',
            },
          ],
        })(
          <Select
            showSearch
            placeholder="Select a material"
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.props.children.toLowerCase().includes(input.toLowerCase())
            }
            allowClear
          >
            {materials.map((material) => (
              <Select.Option key={material.id} value={material.id}>
                {material.display_name}
              </Select.Option>
            ))}
          </Select>
        )}
      </Form.Item>
      <Form.Item label="Render Index">
        {getFieldDecorator('renderIndex', {
          initialValue: mask?.renderIndex || 1,
          rules: [
            {
              required: true,
              message: 'Please input render index!',
            },
          ],
        })(<InputNumber min={1} />)}
        <Text type="secondary" className={css(classes.renderIndexDescription)}>
          When two masks overlap each other, the mask with the higher index will
          be displayed on top.
        </Text>
      </Form.Item>
      <Form.Item label="Image">
        {getFieldDecorator(IMAGE_KEY, {
          initialValue: mask?.image || '',
          rules: [
            {
              required: true,
              message: 'Please upload mask image!',
            },
          ],
        })(
          <StyledDropzone
            baseStyle={classes.dropdown}
            key={
              !editMode &&
              mask?.image &&
              getMaskCloudinaryPath(
                account.cloud_folder_name,
                mask.image,
                roomId
              )
            }
            accept="image/*"
            onDrop={handleDrop}
            message="Drop an image, or click here to select one."
            imgWidth="auto"
            imgHeight="auto"
            imageStyles={classes.image}
            imgSrc={
              mask?.image &&
              getMaskCloudinaryPath(
                account.cloud_folder_name,
                mask.image,
                roomId
              )
            }
            previewSrc={preview}
          />
        )}
      </Form.Item>
    </Form>
  )
}

MaskForm.propTypes = {
  form: PropTypes.object.isRequired,
  editMode: PropTypes.bool,
  setFile: PropTypes.func.isRequired,
  mask: PropTypes.object,
  visible: PropTypes.bool.isRequired,
  roomId: PropTypes.number.isRequired,
  account: PropTypes.object.isRequired,
  materials: PropTypes.array.isRequired,
}

MaskForm.defaultProps = {
  editMode: false,
  mask: null,
}
