import React, { useCallback, useState } from 'react'
import ProductLineModal from '../components/swatches/productLines/ProductLineModal'
import ProductLineTable from '../components/swatches/productLines/ProductLineTable'

export default function SwatchProductLinesPage() {
  const [currentPage, setCurrentPage] = useState(0)
  const [productLineToEdit, setProductLineToEdit] = useState(null)
  const [isModalVisible, setIsModalVisible] = useState(false)

  const handleEditRoom = useCallback((productLine) => {
    setProductLineToEdit(productLine)
    setIsModalVisible(true)
  }, [])

  const handleCloseModal = () => {
    setProductLineToEdit(null)
    setIsModalVisible(false)
  }

  return (
    <>
      <ProductLineModal
        handleCloseModal={handleCloseModal}
        visible={isModalVisible}
        productLine={productLineToEdit}
      />
      <ProductLineTable
        handleEditProductLine={handleEditRoom}
        setIsModalVisible={setIsModalVisible}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </>
  )
}

SwatchProductLinesPage.propTypes = {}
SwatchProductLinesPage.defaultProps = {}
