import { BatchAssignments } from '../../../common/AssignmentTable/const/batchAssignments'

const getProjectIds = (data, item, swatchId, type) => {
  if (item.id !== swatchId) {
    return item.assignment.projectIds
  }

  return type === BatchAssignments.ASSIGN
    ? data.swatchesAssignments.projects.items.map((item) => item.id)
    : []
}

export const updateCache = (data, input, type) => {
  const { projectId, swatchId } = input[0] || {}

  if (projectId) {
    return {
      swatchesAssignments: {
        ...data.swatchesAssignments,
        swatches: {
          ...data.swatchesAssignments.swatches,
          items: data.swatchesAssignments.swatches.items.map((item) => ({
            ...item,
            assignment: {
              ...item.assignment,
              projectIds:
                type === BatchAssignments.ASSIGN
                  ? [...item.assignment.projectIds, projectId]
                  : item.assignment.projectIds.filter(
                      (projId) => projId !== projectId
                    ),
            },
          })),
        },
      },
    }
  }
  return {
    swatchesAssignments: {
      ...data.swatchesAssignments,
      swatches: {
        ...data.swatchesAssignments.swatches,
        items: data.swatchesAssignments.swatches.items.map((item) => ({
          ...item,
          assignment: {
            ...item.assignment,
            projectIds: getProjectIds(data, item, swatchId, type),
          },
        })),
      },
    },
  }
}
