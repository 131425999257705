import { Layout } from 'antd'
import PropTypes from 'prop-types'
import React from 'react'
import { compose } from 'recompose'
import { StyleSheet, css } from 'aphrodite'

import { AuthenticationConsumer, SelectionConsumer } from '../../../contexts'
import { CreatePalettesForm } from './CreatePalettesForm'
import { withConsumer } from '../../../utils'

const { Content } = Layout
const styles = StyleSheet.create({
  content: {
    backgroundColor: 'whitesmoke',
    marginBottom: '24px',
    paddingTop: '8px',
    minHeight: '540px',
  },
  contentXs: {
    padding: '0',
  },
  layout: {
    background: '#fff',
    padding: '14px 0',
  },
  layoutXs: {
    padding: '8px 0',
  },
  singleForm: {
    marginTop: 12,
    padding: '12px 12px',
    minHeight: 300,
    overflowY: 'auto',
  },
})

const PalettesForm = () => (
  <React.Fragment>
    <Content className={css(styles.content)}>
      <CreatePalettesForm />
    </Content>
  </React.Fragment>
)

PalettesForm.propTypes = {
  authentication: PropTypes.shape({
    token: PropTypes.string,
    signIn: PropTypes.func,
    signOut: PropTypes.func,
  }).isRequired,
  selection: PropTypes.shape({
    currentAccountId: PropTypes.number,
    setCurrentAccountId: PropTypes.func,
  }).isRequired,
}

const WrappedComponent = compose(
  withConsumer(AuthenticationConsumer, { propName: 'authentication' }),
  withConsumer(SelectionConsumer, { propName: 'selection' })
)(PalettesForm)

export { WrappedComponent as PalettesForm }
