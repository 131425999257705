import { useQuery } from '@apollo/client'
import { useMemo } from 'react'
import { ACCOUNTS } from '../graphql/accounts.graphql'

export const useAccountAndProperty = (accountId, propertyId) => {
  const { loading, data, error } = useQuery(ACCOUNTS)
  const currentAccount = useMemo(
    () => data?.accounts?.find((acc) => acc.id === Number(accountId)),
    [loading, accountId]
  )

  const currentProperty = useMemo(
    () =>
      currentAccount?.properties?.find(
        (property) => property.id === Number(propertyId)
      )?.name,
    [currentAccount]
  )

  return { currentAccount, currentProperty, loading, error }
}
