import React from 'react'
import { ViewTemplatesForm } from './ViewTemplatesForm'
import { BrochureLayoutsForm } from './BrochureLayoutsForm'

const ManageTemplatesForm = () => (
  <React.Fragment>
    <ViewTemplatesForm />
    <div style={{ margin: '50px' }} />
    <BrochureLayoutsForm />
  </React.Fragment>
)

export { ManageTemplatesForm }
