import React from 'react'
import PropTypes from 'prop-types'
import Skeleton from '../../common/skeletons/Skeleton'

export default function CardActionSkeleton({ descriptionCount }) {
  return (
    <>
      <Skeleton style={{ width: '100%', height: 22 }} />
      {descriptionCount > 0 && (
        <div style={{ marginTop: 14 }}>
          {[...Array(descriptionCount).keys()].map((index) => (
            <Skeleton
              key={index}
              style={{
                width: '100%',
                marginTop: 8,
                height: 14,
                marginBottom: 8,
              }}
            />
          ))}
        </div>
      )}
      <Skeleton
        style={{
          width: 'calc(100% + 48px)',
          height: 48,
          position: 'relative',
          left: -24,
          margin: '18px 0 -24px',
        }}
      />
    </>
  )
}

CardActionSkeleton.propTypes = {
  descriptionCount: PropTypes.number,
}
CardActionSkeleton.defaultProps = {
  descriptionCount: 0,
}
