import React from 'react'
import PropTypes from 'prop-types'
import { css, StyleSheet } from 'aphrodite/no-important'
import ButtonGroup from 'antd/es/button/button-group'
import { Button } from 'antd'
import { classes } from './AssignementTableStyles'
import { BatchAssignments } from './const/batchAssignments'

const internalClasses = StyleSheet.create({
  buttons: {
    display: 'flex',
    justifyContent: 'center',
  },
  button: {
    margin: 2,
  },
  title: {
    margin: 0,
    height: 100,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
})

export default function MultiGridHeader({
  item,
  style,
  onBatchAssignment,
  assignLoading,
}) {
  return (
    <div
      key={item.id}
      style={style}
      className={css(
        classes.cell,
        classes.bottomBorder,
        classes.rightBorder,
        classes.darkBorder,
        classes.column
      )}
    >
      <p className={css(internalClasses.title)}>{item.name}</p>
      <ButtonGroup>
        <Button
          loading={assignLoading}
          type="danger"
          size="small"
          icon="minus"
          onClick={() => onBatchAssignment(BatchAssignments.UNASSIGN, item)}
        />
        <Button
          loading={assignLoading}
          type="primary"
          size="small"
          icon="plus"
          onClick={() => onBatchAssignment(BatchAssignments.ASSIGN, item)}
        />
      </ButtonGroup>
    </div>
  )
}

MultiGridHeader.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  style: PropTypes.object.isRequired,
  onBatchAssignment: PropTypes.func.isRequired,
  assignLoading: PropTypes.bool,
}
MultiGridHeader.defaultProps = {
  assignLoading: false,
}
