import React from 'react'
import { StyleSheet, css } from 'aphrodite/no-important'
import { Spin, Icon } from 'antd'

const classes = StyleSheet.create({
  root: {
    display: 'flex',
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: 'rgba(255, 255, 255, 0.6)',
    justifyContent: 'center',
    alignItems: 'center',
  },
})

const antIcon = <Icon type="loading" style={{ fontSize: 80 }} spin />

export default function LayerModalLoading() {
  return (
    <div className={css(classes.root)}>
      <Spin indicator={antIcon} />
    </div>
  )
}
