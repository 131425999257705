import React, { useMemo, useState } from 'react'
import { Button, Tooltip } from 'antd'
import { StyleSheet, css } from 'aphrodite/no-important'
import { useQuery } from '@apollo/client'
import PropTypes from 'prop-types'
import { GET_ROOMS } from '../../../graphql/fcRooms.graphql'
import RoomTableActions from './RoomTableActions'
import { getRoomCloudinaryPath } from '../functions/getRoomCloudinaryPath'
import RoomFilters from './RoomFilters'
import Alert from '../../common/Alert'
import Table, { TABLE_ITEMS_PER_PAGE } from '../../common/Table'

const classes = StyleSheet.create({
  img: {
    maxWidth: 100,
    maxHeight: 100,
  },
  tooltipImage: {
    maxWidth: 150,
    minWidth: 150,
    minHeight: 150,
  },
  tooltip: {
    maxWidth: 550,
  },
  alert: {
    margin: 9,
  },
  tableActions: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: 8,
  },
  addButton: {
    marginLeft: 'auto',
  },
})

const columns = (
  handleEditRoom,
  handleAddScheme,
  cloudFolder,
  sort,
  setSort
) => [
  {
    title: 'Image',
    dataIndex: 'image',
    key: 'image',
    width: 120,
    render(fileName) {
      const src = getRoomCloudinaryPath(cloudFolder, fileName)
      return (
        <Tooltip
          placement="right"
          overlayClassName={css(classes.tooltip)}
          title={
            <>
              <img className={css(classes.tooltipImage)} src={src} alt="room" />
            </>
          }
        >
          <img className={css(classes.img)} src={src} alt="room" />
        </Tooltip>
      )
    },
  },
  {
    title: 'Base Image',
    dataIndex: 'baseImage',
    key: 'baseImage',
    width: 120,
    render(fileName) {
      let src = null
      if (fileName) {
        src = getRoomCloudinaryPath(cloudFolder, fileName)
      }

      return src ? (
        <Tooltip
          placement="right"
          overlayClassName={css(classes.tooltip)}
          title={
            <>
              <img
                className={css(classes.tooltipImage)}
                src={src}
                alt="base room"
              />
            </>
          }
        >
          <img className={css(classes.img)} src={src} alt="base room" />
        </Tooltip>
      ) : (
        'None'
      )
    },
  },
  {
    title: 'Name',
    sorter: true,
    dataIndex: 'name',
    key: 'name',
    sortOrder: sort,
    onHeaderCell: () => ({
      onClick: () => setSort(sort === 'ascend' ? 'descend' : 'ascend'),
    }),
  },
  {
    title: 'Actions',
    dataIndex: 'actions',
    key: 'actions',
    render(_, room) {
      return (
        <RoomTableActions
          room={room}
          handleEditRoom={handleEditRoom}
          handleAddScheme={handleAddScheme}
        />
      )
    },
    width: 200,
  },
]

function RoomTable({
  propertyId,
  handleEditRoom,
  cloudFolder,
  setIsModalVisible,
  handleAddScheme,
}) {
  const [sort, setSort] = useState('ascend')
  const [currentPage, setCurrentPage] = useState(0)
  const [tableOrder, setTableOrder] = useState([
    {
      name: 'name',
      order: 'ASC',
    },
  ])
  const [filters, setFilters] = useState({})

  const { loading, data, error } = useQuery(GET_ROOMS, {
    variables: {
      pagination: {
        page: currentPage,
        size: TABLE_ITEMS_PER_PAGE,
      },
      ordering: {
        columns: tableOrder,
      },
      filter: {
        ...filters,
        projectId: propertyId,
      },
    },
    fetchPolicy: 'cache-and-network',
  })

  const rooms = useMemo(
    () => data?.rooms?.items?.map((room) => ({ ...room, key: room.id })),
    [data?.rooms?.items]
  )

  return (
    <>
      {error && <Alert margin />}
      <>
        <div className={css(classes.tableActions)}>
          <RoomFilters filters={filters} setFilters={setFilters} />
          <Button
            className={css(classes.addButton)}
            type="primary"
            icon="plus"
            onClick={() => setIsModalVisible(true)}
          >
            Add Room
          </Button>
        </div>
        {rooms && (
          <Table
            loading={loading}
            data={rooms}
            columns={columns(
              handleEditRoom,
              handleAddScheme,
              cloudFolder,
              sort,
              setSort
            )}
            emptyText="There are currently no active rooms to display"
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            totalCount={data?.rooms.totalCount}
            setTableOrder={setTableOrder}
            itemsPerPage={TABLE_ITEMS_PER_PAGE}
          />
        )}
      </>
    </>
  )
}

export default React.memo(RoomTable)

RoomTable.propTypes = {
  propertyId: PropTypes.number.isRequired,
  handleEditRoom: PropTypes.func.isRequired,
  cloudFolder: PropTypes.string.isRequired,
  setIsModalVisible: PropTypes.func.isRequired,
  handleAddScheme: PropTypes.func.isRequired,
}
