import React from 'react'
import PropTypes from 'prop-types'
import { Form, Select as AntdSelect } from 'antd'
import { css } from 'aphrodite/no-important'
import { classes } from './styles'

const { Option } = AntdSelect

const FilterSelect = ({
  field,
  label,
  placeholder,
  parent,
  visible,
  ...props
}) => {
  const { loading, data } = parent || {}
  const { value, set, list } = data || {}
  const handleChange = (_, option) => set?.(option?.props?.value || null)
  return (
    visible && (
      <Form.Item className={css(classes.formItem)} label={label}>
        <AntdSelect
          placeholder={placeholder}
          className={css(classes.select)}
          loading={loading}
          disabled={loading}
          allowClear
          showSearch
          onChange={handleChange}
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
          value={value}
          {...props}
        >
          <Option value={null}>None</Option>
          {list?.map((building) => (
            <Option key={building.id} value={building.id}>
              {building.name}
            </Option>
          ))}
        </AntdSelect>
      </Form.Item>
    )
  )
}

FilterSelect.propTypes = {
  field: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  parent: PropTypes.shape({
    loading: PropTypes.bool,
    data: PropTypes.object,
  }),
  visible: PropTypes.bool,
}

FilterSelect.defaultProps = {
  parent: null,
  visible: true,
}

export const Select = FilterSelect
