import { message, Button, Form, Icon, Input } from 'antd'
import { css, StyleSheet } from 'aphrodite/no-important'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { compose } from 'recompose'
import { withRouter } from 'react-router-dom'

import { AuthenticationConsumer } from '../../../contexts'
import { withConsumer } from '../../../utils'

const { Item } = Form

const styles = StyleSheet.create({
  credentialsForm: {
    border: '1px solid #d8dee2',
    borderRadius: '5px',
    padding: '20px',
    margin: 'auto',
    width: '340px',
  },
  formItemLast: {
    marginBottom: '0px !important',
  },
  signInButton: {
    width: '100%',
  },
})

class CredentialsForm extends Component {
  static propTypes = {
    form: PropTypes.object.isRequired,
    authentication: PropTypes.shape({
      token: PropTypes.string,
      signIn: PropTypes.func,
    }).isRequired,
    nextRoute: PropTypes.string.isRequired,
    history: PropTypes.object.isRequired,
  }

  handleSignInButtonPress = (event) => {
    event.preventDefault()
    const { form, authentication, history, nextRoute } = this.props
    form.validateFieldsAndScroll(async (errors, { email, password }) => {
      if (!errors) {
        try {
          const signInResult = await authentication.signIn(email, password)
          if (signInResult) {
            history.push(nextRoute)
          } else {
            message.error('Incorrect email or password. Please try again.', 5)
          }
        } catch (error) {
          console.error(
            'Encountered an unexpected error while attempting to sign in:',
            error
          )
        }
      }
    })
  }

  render() {
    const { form } = this.props
    const { getFieldDecorator } = form
    return (
      <Form className={css(styles.credentialsForm)}>
        <Item>
          {getFieldDecorator('email', {
            rules: [
              {
                required: true,
                message: 'Please enter your email address.',
              },
            ],
          })(
            <Input
              prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder="Email address"
              onPressEnter={this.handleSignInButtonPress}
            />
          )}
        </Item>
        <Item>
          {getFieldDecorator('password', {
            rules: [
              {
                required: true,
                message: 'Please enter your password.',
              },
            ],
          })(
            <Input
              prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
              type="password"
              placeholder="Password"
              onPressEnter={this.handleSignInButtonPress}
            />
          )}
        </Item>
        <Item className={css(styles.formItemLast)}>
          <Button
            type="primary"
            className={css(styles.signInButton)}
            onClick={this.handleSignInButtonPress}
          >
            Sign in
          </Button>
        </Item>
      </Form>
    )
  }
}

const WrappedComponent = compose(
  withRouter,
  withConsumer(AuthenticationConsumer, { propName: 'authentication' }),
  Form.create()
)(CredentialsForm)

export { WrappedComponent as CredentialsForm }
