import React from 'react'
import PropTypes from 'prop-types'
import { Card } from 'antd'
import CardActionSkeleton from '../../rooms/masks/CardActionSkeleton'
import Skeleton from './Skeleton'

export default function CardsSkeleton({ cardCount, width, descriptionCount }) {
  return [...Array(cardCount).keys()].map((index) => (
    <Card
      key={index}
      style={{ width }}
      cover={<Skeleton style={{ width: '100%', height: '200px' }} />}
    >
      <CardActionSkeleton descriptionCount={descriptionCount} />
    </Card>
  ))
}

CardsSkeleton.propTypes = {
  cardCount: PropTypes.number,
  width: PropTypes.number,
  descriptionCount: PropTypes.number,
}
CardsSkeleton.defaultProps = {
  cardCount: 4,
  width: 300,
  descriptionCount: 0,
}
