import { Modal } from 'antd'
import PropTypes from 'prop-types'

const ErrorModal = (error) =>
  Modal.error({
    title: 'Error',
    content: error.message.replace(/.*[Ee]rror: /, ''),
    maskClosable: true,
    okText: 'Close',
  })

ErrorModal.propTypes = {
  error: PropTypes.shape({
    message: PropTypes.string.isRequired,
  }).isRequired,
}

export { ErrorModal }
