import PropTypes from 'prop-types'
import React from 'react'
import { compose } from 'recompose'

import { UserForm } from '../components/forms/user/UserForm'

import { AuthenticationConsumer } from '../contexts'

import { renderNothingIf, withConsumer } from '../utils'

const UserPage = ({ authentication }) => (
  <UserForm userId={authentication.decodedToken.id} />
)

UserPage.propTypes = {
  authentication: PropTypes.shape({
    token: PropTypes.string.isRequired,
    decodedToken: PropTypes.shape({
      id: PropTypes.number.isRequired,
    }).isRequired,
  }).isRequired,
}

const WrappedComponent = compose(
  withConsumer(AuthenticationConsumer, { propName: 'authentication' }),
  renderNothingIf(({ authentication }) => {
    console.log('authentication:', authentication)
    console.log('authentication.decodedToken:', authentication.decodedToken)
    return !(
      authentication &&
      authentication.decodedToken &&
      authentication.decodedToken.id
    )
  })
)(UserPage)

export { WrappedComponent as UserPage }
