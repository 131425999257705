import React from 'react'
import PropTypes from 'prop-types'
import LayerTable from './LayerTable'

export default function RoomFlooringTab({ account, roomId }) {
  return <LayerTable account={account} roomId={roomId} />
}

RoomFlooringTab.propTypes = {
  roomId: PropTypes.number.isRequired,
  account: PropTypes.object.isRequired,
}
RoomFlooringTab.defaultProps = {}
