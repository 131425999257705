import React, { useState, useRef, useCallback } from 'react'
import PropTypes from 'prop-types'
import {
  Modal,
  Row,
  Col,
  InputNumber,
  Typography,
  Slider,
  Icon,
  Tooltip,
} from 'antd'
import { css, StyleSheet } from 'aphrodite'
import InteractiveFloorCovering from '@anewgo/interactive-floor-covering'
import { blue } from '@ant-design/colors'
import SwatchDrawer from './SwatchDrawer/SwatchDrawer'
import MaskDrawer from './MaskDrawer/MaskDrawer'
import { getRoomCloudinaryPath } from '../functions/getRoomCloudinaryPath'
import { getMaskCloudinaryPath } from '../functions/getMaskCloudinaryPath'
import { getSwatchesCloudinaryPath } from '../../../functions/getSwatchesCloudinaryPath'
import ImageSkeleton from '../../common/skeletons/ImageSkeleton'
import LayerConfirmationDialog from './LayerConfirmationDialog'
import LayerModalLoading from './LayerModalLoading'
import { layerModalModes, layerModalModesPropType } from '../const/layerModal'

const classes = StyleSheet.create({
  genericSelectRoot: {
    display: 'flex',
    flexDirection: 'row',
    gap: '8px',
    width: '100%',
    height: '100px',
    padding: '10px',
    cursor: 'pointer',
    borderRadius: 4,
    marginBottom: 16,

    transition: 'border 300ms',
    border: '1px solid #d9d9d9',
    ':hover': {
      border: `1px solid ${blue.primary}`,
    },
  },
  genericSelectImage: {
    height: 80,
    width: 80,
    backgroundColor: 'darkgray',
    color: 'white',
    fontSize: '50px',
    lineHeight: '80px',
    textAlign: 'center',
  },
  genericSelectTextContainer: {
    flexGrow: 1,
    minWidth: 0,
  },
  genericSelectImageSelectText: {
    boxSizing: 'border-box',
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '16px',
  },
})

function GenericSelect({
  selected,
  image,
  title,
  subtitle,
  selectText,
  onClick,
}) {
  return (
    <div className={css(classes.genericSelectRoot)} onClick={onClick}>
      <div>
        {selected ? (
          <ImageSkeleton
            src={image}
            className={css(classes.genericSelectImage)}
          />
        ) : (
          <div className={css(classes.genericSelectImage)}>
            <Icon type="picture" />
          </div>
        )}
      </div>
      <div className={css(classes.genericSelectTextContainer)}>
        {selected ? (
          <div>
            <Tooltip title={title}>
              <Typography.Title level={4} ellipsis>
                {title}
              </Typography.Title>
            </Tooltip>
            <Typography>{subtitle}</Typography>
          </div>
        ) : (
          <div className={css(classes.genericSelectImageSelectText)}>
            <Typography>{selectText}</Typography>
          </div>
        )}
      </div>
    </div>
  )
}

GenericSelect.propTypes = {
  selected: PropTypes.bool.isRequired,
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  selectText: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
}

function MaskSelect({ cloudFolder, room, selectedMask, onSelect }) {
  const [drawerVisible, setDrawerVisible] = useState(false)

  return (
    <>
      <GenericSelect
        selected={!!selectedMask}
        image={selectedMask?.image}
        title={selectedMask?.material?.display_name}
        subtitle="(Click to change the selected mask)"
        selectText="Click to select a mask"
        onClick={() => setDrawerVisible(true)}
      />
      <MaskDrawer
        visible={drawerVisible}
        setVisible={setDrawerVisible}
        room={room}
        selectedMaskId={selectedMask?.id}
        cloudFolder={cloudFolder}
        onSelect={(...args) => {
          setDrawerVisible(false)
          onSelect(...args)
        }}
      />
    </>
  )
}

MaskSelect.propTypes = {
  cloudFolder: PropTypes.string.isRequired,
  room: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    image: PropTypes.string,
  }).isRequired,
  selectedMask: PropTypes.shape({
    id: PropTypes.number,
    image: PropTypes.string,
    material: PropTypes.shape({
      id: PropTypes.number,
      display_name: PropTypes.string,
    }),
  }),
  onSelect: PropTypes.func.isRequired,
}

MaskSelect.defaultProps = {
  selectedMask: null,
}

function SwatchSelect({ selectedSwatch, onSelect, roomId }) {
  const [drawerVisible, setDrawerVisible] = useState(false)

  return (
    <>
      <GenericSelect
        selected={!!selectedSwatch}
        image={selectedSwatch?.image}
        title={selectedSwatch?.name}
        subtitle="(Click to change the selected swatch)"
        selectText="Click to select a swatch"
        onClick={() => setDrawerVisible(true)}
      />
      <SwatchDrawer
        visible={drawerVisible}
        setVisible={setDrawerVisible}
        selectedSwatchId={selectedSwatch?.id}
        onSelect={(...args) => {
          setDrawerVisible(false)
          onSelect(...args)
        }}
        roomId={roomId}
      />
    </>
  )
}

SwatchSelect.propTypes = {
  selectedSwatch: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    image: PropTypes.string,
  }).isRequired,
  onSelect: PropTypes.func.isRequired,
  roomId: PropTypes.number.isRequired,
}

export default function LayerModal({ account, room, layer, onClose, mode }) {
  const cloudFolder = account.cloud_folder_name
  const imageSource = getRoomCloudinaryPath(cloudFolder, room.image, '')

  const floorCoveringRef = useRef(null)
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false)
  const [layerImage, setLayerImage] = useState(null)
  const [loading, setLoading] = useState(false)

  const [covering, setCovering] = useState({
    id: layer?.id,
    mask: layer?.mask
      ? {
          ...layer.mask,
          image: getMaskCloudinaryPath(
            cloudFolder,
            layer.mask.image,
            room.id,
            ''
          ),
        }
      : null,
    swatch: layer?.swatch
      ? {
          ...layer.swatch,
          image: getSwatchesCloudinaryPath(layer.swatch.image, ''),
        }
      : null,
    settings: layer?.setting?.settings
      ? JSON.parse(layer.setting?.settings)
      : {
          rotation: 0,
          repetitions: { x: 1, y: 1 },
          offset: { x: 0, y: 0 },
        },
  })

  function handleTransformationChange(points) {
    setCovering((covering) => ({
      ...covering,
      settings: {
        ...covering.settings,
        points,
      },
    }))
  }

  function handleRepetitionXChange(newRepetitions) {
    setCovering((covering) => ({
      ...covering,
      settings: {
        ...covering.settings,
        repetitions: {
          x: newRepetitions || 1,
          y: covering.settings.repetitions.y,
        },
      },
    }))
  }

  function handleRepetitionYChange(newRepetitions) {
    setCovering((covering) => ({
      ...covering,
      settings: {
        ...covering.settings,
        repetitions: {
          x: covering.settings.repetitions.x,
          y: newRepetitions || 1,
        },
      },
    }))
  }

  function handleOffsetXChange(newOffset) {
    setCovering((covering) => ({
      ...covering,
      settings: {
        ...covering.settings,
        offset: {
          x: newOffset || 0,
          y: covering.settings.offset.y,
        },
      },
    }))
  }

  function handleOffsetYChange(newOffset) {
    setCovering((covering) => ({
      ...covering,
      settings: {
        ...covering.settings,
        offset: {
          x: covering.settings.offset.x,
          y: newOffset || 0,
        },
      },
    }))
  }

  function handleRotationChange(newRotation) {
    setCovering((covering) => ({
      ...covering,
      settings: {
        ...covering.settings,
        rotation: newRotation || 0,
      },
    }))
  }

  function handleSelectSwatch(newSwatch) {
    setCovering((covering) => ({
      ...covering,
      swatch: {
        ...newSwatch,
        image: getSwatchesCloudinaryPath(newSwatch.image, ''),
      },
    }))
  }

  function handleSelectMask(newMask) {
    setCovering((covering) => ({
      ...covering,
      mask: {
        ...newMask,
        image: getMaskCloudinaryPath(cloudFolder, newMask.image, room.id, ''),
      },
    }))
  }

  async function handleSubmit() {
    const image = await floorCoveringRef.current.takeLayerPicture()

    setLayerImage(image)
    setConfirmationDialogOpen(true)
  }

  function handleCancel() {
    setConfirmationDialogOpen(false)
  }

  function handleAfterSubmit(close) {
    setConfirmationDialogOpen(false)
    if (close) onClose()
  }

  const handleLoadStart = useCallback(() => setLoading(true), [])
  const handleLoadEnd = useCallback(() => setLoading(false), [])

  let title = 'Add flooring'
  let okBtn = 'Add flooring'
  if (layer) {
    if (mode === layerModalModes.update) {
      title = `Edit flooring - ${layer.name}`
      okBtn = 'Edit flooring'
    } else {
      title = `Copy flooring - ${layer.name.slice(0, -7)}`
      okBtn = 'Copy flooring'
    }
  }

  return (
    <>
      <Modal
        title={title}
        className="full-screen"
        onOk={() => handleSubmit()}
        okButtonProps={{ disabled: !covering.mask || !covering.swatch }}
        onCancel={() => onClose()}
        okText={okBtn}
        width="100%"
        bodyStyle={{
          height: 'calc(100vh - 10px - 55px - 53px)',
        }}
        visible
        // CSS animations mess with react-three-fibers resize functionality
        transitionName=""
        maskTransitionName=""
      >
        <Row type="flex" style={{ height: '100%' }} id="layer-modal">
          <Col span={19}>
            {covering.swatch && covering.mask ? (
              <>
                <InteractiveFloorCovering
                  ref={floorCoveringRef}
                  room={{
                    ...room,
                    image: imageSource,
                  }}
                  floorCovering={covering}
                  onTransformationChange={handleTransformationChange}
                  onLoadStart={handleLoadStart}
                  onLoadEnd={handleLoadEnd}
                />
                {loading && <LayerModalLoading />}
              </>
            ) : (
              <Row
                type="flex"
                justify="center"
                align="middle"
                style={{ height: '100%' }}
              >
                <Typography style={{ fontSize: 25 }}>
                  Please select a mask and a swatch
                </Typography>
              </Row>
            )}
          </Col>
          <Col span={5} style={{ padding: '25px' }}>
            <Row type="flex" justify="center" style={{ height: '100%' }}>
              <div style={{ minWidth: 0 }}>
                {/* Repetitions controls */}
                <Col span={24}>
                  <Row>
                    <Typography.Title level={4}>Mask</Typography.Title>
                  </Row>
                  <Row>
                    <MaskSelect
                      cloudFolder={cloudFolder}
                      room={room}
                      selectedMask={covering?.mask}
                      onSelect={handleSelectMask}
                    />
                  </Row>
                </Col>
                <Col span={24}>
                  <Row>
                    <Typography.Title level={4}>Swatch</Typography.Title>
                  </Row>
                  <Row>
                    <SwatchSelect
                      selectedSwatch={covering?.swatch}
                      onSelect={handleSelectSwatch}
                      roomId={room.id}
                    />
                  </Row>
                </Col>
                <Col span={24}>
                  <Row>
                    <Typography.Title level={4}>
                      Swatch Repetitions
                    </Typography.Title>
                  </Row>
                  <Row gutter={8}>
                    <Col span={12}>
                      <Typography>X Axis</Typography>
                      <InputNumber
                        min={1}
                        max={100}
                        value={covering.settings.repetitions.x}
                        onChange={handleRepetitionXChange}
                      />
                    </Col>
                    <Col span={12}>
                      <Typography>Y Axis</Typography>
                      <InputNumber
                        min={1}
                        max={100}
                        value={covering.settings.repetitions.y}
                        onChange={handleRepetitionYChange}
                      />
                    </Col>
                  </Row>
                </Col>

                {/* Offset controls */}
                <Col span={24} style={{ marginTop: '15px' }}>
                  <Row>
                    <Typography.Title level={4}>Offset</Typography.Title>
                  </Row>
                  <Row>
                    <Row>
                      <Row>
                        <Typography>X Axis</Typography>
                      </Row>
                      <Row gutter={8}>
                        <Col span={12}>
                          <Slider
                            value={covering.settings.offset.x}
                            min={0}
                            max={1}
                            step={0.001}
                            onChange={handleOffsetXChange}
                          />
                        </Col>
                        <Col span={12}>
                          <InputNumber
                            value={covering.settings.offset.x}
                            min={0}
                            max={1}
                            step={0.01}
                            onChange={handleOffsetXChange}
                          />
                        </Col>
                      </Row>
                    </Row>
                    <Row>
                      <Row>
                        <Typography>Y Axis</Typography>
                      </Row>
                      <Row gutter={8}>
                        <Col span={12}>
                          <Slider
                            value={covering.settings.offset.y}
                            min={0}
                            max={1}
                            step={0.001}
                            onChange={handleOffsetYChange}
                          />
                        </Col>
                        <Col span={12}>
                          <InputNumber
                            value={covering.settings.offset.y}
                            min={0}
                            max={1}
                            step={0.01}
                            onChange={handleOffsetYChange}
                          />
                        </Col>
                      </Row>
                    </Row>
                  </Row>
                </Col>

                {/* Rotation Controls */}
                <Col span={24} style={{ marginTop: '15px' }}>
                  <Row>
                    <Typography.Title level={4}>Rotation</Typography.Title>
                  </Row>
                  <Row>
                    <Col span={12}>
                      <Slider
                        value={covering.settings.rotation}
                        min={0}
                        max={360}
                        step={0.1}
                        onChange={handleRotationChange}
                      />
                    </Col>
                    <Col span={12}>
                      <InputNumber
                        value={covering.settings.rotation}
                        min={0}
                        max={360}
                        step={0.1}
                        onChange={handleRotationChange}
                      />
                    </Col>
                  </Row>
                </Col>
              </div>
            </Row>
          </Col>
        </Row>
      </Modal>
      {confirmationDialogOpen && (
        <LayerConfirmationDialog
          account={account}
          layer={layer}
          room={room}
          mask={covering.mask}
          swatch={covering.swatch}
          settings={covering.settings}
          layerImage={layerImage}
          mode={mode}
          onCancel={handleCancel}
          onSubmit={handleAfterSubmit}
        />
      )}
    </>
  )
}

LayerModal.propTypes = {
  account: PropTypes.object.isRequired,
  layer: PropTypes.object,
  room: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    image: PropTypes.string,
  }),
  onClose: PropTypes.func.isRequired,
  mode: layerModalModesPropType.isRequired,
}

LayerModal.defaultProps = {
  layer: null,
  room: null,
}

LayerModal.modes = layerModalModes
