import { Button, Form, Icon, Popconfirm, Table } from 'antd'
import { css, StyleSheet } from 'aphrodite/no-important'
import changeCase from 'change-case'
import PropTypes from 'prop-types'
import React, { Component } from 'react'

import { StyledDropzone } from '../../common/StyledDropzone'

import {
  generateAddFormItemRequiredInput,
  generateAddFormItemRequiredSelect,
  generateUnsafeUpdateFormItemInput,
  generateUpdateFormItemInput,
  generateUpdateFormItemSelect,
} from '../../../utils'

const { Item } = Form

const styles = StyleSheet.create({
  addAccountForm: {
    border: '1px solid #d8dee2',
    borderRadius: '10px',
    padding: '0px 10px 0px 10px',
    margin: 'auto',
    marginTop: '5px',
  },
  dropzoneBaseStyle: {
    width: 150,
    height: 150,
    borderWidth: 2,
    borderColor: 'lightgray',
    borderStyle: 'dashed',
    borderRadius: 5,
    backgroundColor: 'darkgray',
    float: 'left',
  },
  updateAccountForm: {
    padding: '0px 10px 0px 10px',
    margin: 'auto',
  },
  dropZoneButton: {
    position: 'absolute',
    top: '110px',
    marginLeft: '20px',
  },
})

class SubAccountsForm extends Component {
  static propTypes = {
    form: PropTypes.object.isRequired,
    addAccount: PropTypes.func.isRequired,
    authentication: PropTypes.shape({
      appConfig: PropTypes.object.isRequired,
      token: PropTypes.string,
    }).isRequired,
    deleteAccountById: PropTypes.func.isRequired,
    updateAccountById: PropTypes.func.isRequired,
    accountTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
    accounts: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        account_name: PropTypes.string,
        prospect_number: PropTypes.string,
        account_type: PropTypes.string,
        contact_first_name: PropTypes.string,
        contact_last_name: PropTypes.string,
        phone: PropTypes.string,
        email: PropTypes.string,
      })
    ).isRequired,
    selection: PropTypes.shape({
      currentAccountId: PropTypes.number,
      setCurrentAccountId: PropTypes.func,
    }).isRequired,
    parentId: PropTypes.number.isRequired,
    handleAddAccountButtonPress: PropTypes.func.isRequired,
    handleUpdateAccount: PropTypes.func.isRequired,
    handleDeleteAccountButtonPress: PropTypes.func.isRequired,
    handleResetLogo: PropTypes.func.isRequired,
    handleOnDrop: PropTypes.func.isRequired,
    updateAccountFormItemLayout: PropTypes.object.isRequired,
  }

  constructor() {
    super()
    this.state = { previews: {} }
  }

  render() {
    const {
      form,
      accounts,
      accountTypes,
      authentication,
      parentId,
      handleAddAccountButtonPress,
      handleUpdateAccount,
      handleDeleteAccountButtonPress,
      handleResetLogo,
      handleOnDrop,
      updateAccountFormItemLayout,
    } = this.props

    const { appConfig } = authentication
    const { previews } = this.state

    const { getFieldDecorator } = form
    return (
      <React.Fragment>
        <Form
          key="addAccountForm"
          layout="inline"
          className={css(styles.addAccountForm)}
        >
          {generateAddFormItemRequiredInput(
            getFieldDecorator,
            'account_name',
            '* Account Name'
          )}
          {generateAddFormItemRequiredInput(
            getFieldDecorator,
            'prospect_number',
            '* Prospect #'
          )}
          {generateAddFormItemRequiredSelect(
            getFieldDecorator,
            accountTypes,
            false,
            'account_type',
            '* Account Type',
            true
          )}
          <Item>
            <Button
              type="primary"
              onClick={() => handleAddAccountButtonPress(parentId)}
            >
              Add Account
            </Button>
          </Item>
        </Form>
        <Table
          key="accountsTable"
          size="middle"
          bordered
          pagination={false}
          dataSource={accounts.map((account) => ({
            key: account.id,
            ...account,
          }))}
          columns={[
            {
              title: 'Account Name',
              dataIndex: 'account_name',
              key: 'account_name',
              sorter: (a, b) => a.account_name.localeCompare(b.account_name),
              defaultSortOrder: 'ascend',
            },
            {
              title: 'Prospect #',
              dataIndex: 'prospect_number',
              key: 'prospect_number',
            },
            {
              title: 'Type',
              dataIndex: 'account_type',
              key: 'account_type',
              render: (text) => changeCase.titleCase(text),
            },
            {
              key: 'delete',
              width: 150,
              render: (text, account) => (
                <Popconfirm
                  placement="bottomRight"
                  title="Delete this account?"
                  onConfirm={() => handleDeleteAccountButtonPress(account.id)}
                  okText="Yes"
                  cancelText="No"
                >
                  {
                    // this will hide the delete button for our 'TEST' account and
                    // thus it will not be deleteable from the dashboard.
                    account.id !== 1 && (
                      <Button type="danger">
                        <Icon type="delete" />
                      </Button>
                    )
                  }
                </Popconfirm>
              ),
            },
          ]}
          expandedRowRender={(account) => (
            <Form
              key="updateAccountForm"
              className={css(styles.updateAccountForm)}
            >
              {generateUpdateFormItemInput(
                handleUpdateAccount,
                updateAccountFormItemLayout,
                account.id,
                account.account_name,
                'account_name',
                'Account Name'
              )}
              {appConfig && appConfig.showCloudFolderField
                ? generateUnsafeUpdateFormItemInput(
                    handleUpdateAccount,
                    updateAccountFormItemLayout,
                    account.id,
                    account.cloud_folder_name,
                    'cloud_folder_name',
                    'Cloud Folder Name'
                  )
                : null}
              {generateUpdateFormItemInput(
                handleUpdateAccount,
                updateAccountFormItemLayout,
                account.id,
                account.prospect_number,
                'prospect_number',
                'Prospect #'
              )}
              {generateUpdateFormItemSelect(
                handleUpdateAccount,
                updateAccountFormItemLayout,
                account.id,
                accountTypes,
                (accountType) => accountType,
                (accountType) => changeCase.titleCase(accountType),
                false,
                account.account_type,
                'account_type',
                'Account Type',
                true
              )}
              <Item label="Logo" {...updateAccountFormItemLayout}>
                <StyledDropzone
                  className={css(styles.dropZone)}
                  key={account.account_logo}
                  accept="image/*"
                  baseStyle={styles.dropzoneBaseStyle}
                  onDrop={handleOnDrop({
                    folder: `app/${account.cloud_folder_name}/assets/custom`,
                    accountId: account.id,
                    targetField: 'account_logo',
                    label: 'Account Logo',
                    fileNameToDelete: account.account_logo,
                  })}
                  message="Drop an image, or click here to select one."
                  imgWidth={146}
                  imgHeight={146}
                  imgSrc={
                    account.account_logo &&
                    `https://res.cloudinary.com/renderinghouse/image/upload/w_146,h_146,c_fill/app/${account.cloud_folder_name}/assets/custom/${account.account_logo}`
                  }
                  previewSrc={previews[`${account.id}account_logo`]}
                />
                {(previews[`${account.id}account_logo`] ||
                  account.account_logo !== null) && (
                  <Popconfirm
                    title="Reset account logo to default Sherwin-Williams logo?"
                    onConfirm={handleResetLogo({
                      folder: `app/${account.cloud_folder_name}/assets/custom`,
                      accountId: account.id,
                      targetField: 'account_logo',
                      label: 'Account Logo',
                      fileNameToDelete: account.account_logo,
                    })}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button
                      className={css(styles.dropZoneButton)}
                      type="danger"
                      size="large"
                    >
                      Reset Logo
                    </Button>
                  </Popconfirm>
                )}
              </Item>
              {<span>Primary Contact:</span>}
              {generateUpdateFormItemInput(
                handleUpdateAccount,
                updateAccountFormItemLayout,
                account.id,
                account.contact_first_name,
                'contact_first_name',
                'First Name'
              )}
              {generateUpdateFormItemInput(
                handleUpdateAccount,
                updateAccountFormItemLayout,
                account.id,
                account.contact_last_name,
                'contact_last_name',
                'Last Name'
              )}
              {generateUnsafeUpdateFormItemInput(
                handleUpdateAccount,
                updateAccountFormItemLayout,
                account.id,
                account.email,
                'email',
                'Email'
              )}
              {generateUnsafeUpdateFormItemInput(
                handleUpdateAccount,
                updateAccountFormItemLayout,
                account.id,
                account.phone,
                'phone',
                'Phone'
              )}
            </Form>
          )}
        />
      </React.Fragment>
    )
  }
}

export default SubAccountsForm
