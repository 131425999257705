import React from 'react'
import PropTypes from 'prop-types'
import ButtonGroup from 'antd/es/button/button-group'
import { Button, Popconfirm, Tooltip, message } from 'antd'
import { useMutation } from '@apollo/client'
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom'
import { DELETE_ROOM, GET_ROOMS } from '../../../graphql/fcRooms.graphql'
import { Tabs } from '../const/tabs'

export default function RoomTableActions({ room, handleAddScheme }) {
  const params = useParams()
  const history = useHistory()
  const [deleteRoom, { loading }] = useMutation(DELETE_ROOM)

  const handleDeleteRoom = async (roomId) => {
    try {
      await deleteRoom({
        variables: {
          id: roomId,
          accountId: Number(params.accountId),
        },
        refetchQueries: [GET_ROOMS],
      })
      message.success('Room was not deleted!')
    } catch (e) {
      console.error(e)
      message.error('Room was not deleted!')
    }
  }

  return (
    <ButtonGroup>
      <Tooltip title="Add scheme">
        <Button
          onClick={() => handleAddScheme(room.id)}
          type="primary"
          icon="bg-colors"
          size="large"
        />
      </Tooltip>

      <Tooltip title="Edit room">
        <Button
          onClick={() => history.push(`rooms/${room.id}/${Tabs.EDIT}`)}
          type="primary"
          icon="edit"
          size="large"
        />
      </Tooltip>

      <Popconfirm
        placement="left"
        title={`Are you sure you want to delete room ${room.name}?`}
        onConfirm={() => handleDeleteRoom(room.id)}
        okText="Yes"
        cancelText="No"
      >
        <Tooltip title="Delete room">
          <Button type="danger" icon="delete" size="large" loading={loading} />
        </Tooltip>
      </Popconfirm>
    </ButtonGroup>
  )
}

RoomTableActions.propTypes = {
  room: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  handleAddScheme: PropTypes.func.isRequired,
}
