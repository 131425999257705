import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { css, StyleSheet } from 'aphrodite/no-important'
import { Button } from 'antd'
import SchemesCardList from './SchemesCardList'
import SchemesModal from './SchemesModal/SchemesModal'
import SchemePreviewModal from './SchemePreviewModal'

const classes = StyleSheet.create({
  root: {
    padding: '5px 20px',
  },
  addButton: {
    marginLeft: 'auto',
    display: 'block',
    marginBottom: 5,
  },
})

export default function RoomSchemesTab({ room, account, project }) {
  const [isVisible, setIsVisible] = useState(false)
  const [schemeToEdit, setSchemeToEdit] = useState(null)
  const [previewScheme, setPreviewScheme] = useState(null)

  const handleEditClick = (scheme) => {
    setIsVisible(true)
    setSchemeToEdit(scheme)
  }

  const handleClose = () => {
    setIsVisible(false)
    setSchemeToEdit(null)
  }

  return (
    <div className={css(classes.root)}>
      <Button
        icon="plus"
        className={css(classes.addButton)}
        type="primary"
        onClick={() => setIsVisible(true)}
      >
        Add Scheme
      </Button>
      <SchemesCardList
        roomId={room?.id}
        onEditClick={handleEditClick}
        account={account}
        onShowPreview={(scheme) => setPreviewScheme(scheme)}
      />
      <SchemesModal
        scheme={schemeToEdit}
        visible={isVisible}
        onClose={handleClose}
        roomId={room?.id}
        account={account}
      />
      <SchemePreviewModal
        scheme={previewScheme}
        onClose={() => setPreviewScheme(null)}
        account={account}
        room={room}
        project={project}
      />
    </div>
  )
}

RoomSchemesTab.propTypes = {
  room: PropTypes.object.isRequired,
  account: PropTypes.object.isRequired,
  project: PropTypes.object.isRequired,
}
RoomSchemesTab.defaultProps = {}
