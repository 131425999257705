/* eslint-disable react/destructuring-assignment */

import React from 'react'
import { Modal, Form, Input, InputNumber, Button, Popconfirm } from 'antd'
import PropTypes from 'prop-types'
import { StyleSheet, css } from 'aphrodite'
import { compose } from 'recompose'
import { withConsumer, withMutationAsProp } from '../../utils'
import { AuthenticationConsumer, SelectionConsumer } from '../../contexts'
import {
  ADD_SCHEME,
  ACCOUNT_SCHEME_SELECTIONS,
} from '../../graphql/colors.graphql'
import { ErrorModal } from '../common'
import { RegexPatterns } from '../../const/regex'

// import { errorModal } from '../../../utils/helper'

const fields = { name: 'nameField', cost: 'costField' }
const styles = StyleSheet.create({
  button: {
    marginLeft: '10px',
    float: 'right',
  },
  form: {
    padding: '20px',
  },
  buttonRow: {
    margin: '0',
  },
})

class CreateSchemeModal extends React.Component {
  static propTypes = {
    form: PropTypes.object.isRequired,
    visible: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    scheme: PropTypes.object,
    selection: PropTypes.shape({
      currentAccountId: PropTypes.number,
      currentSubAccountId: PropTypes.number,
      setCurrentAccountId: PropTypes.func,
      setCurrentSchemeId: PropTypes.func,
    }).isRequired,
    addScheme: PropTypes.func.isRequired,
    authentication: PropTypes.shape({
      token: PropTypes.string,
      signIn: PropTypes.func,
      signOut: PropTypes.func,
    }).isRequired,
  }

  static defaultProps = {
    scheme: null,
  }

  //
  // Helpers
  //

  isEditing = () => this.props.scheme !== null

  initialFieldValue = (key) => (this.isEditing() ? this.props.scheme[key] : '')

  title = () => (this.isEditing() ? 'Edit Scheme' : 'Add Scheme')

  validateFields = (completionHandler) => {
    // Check the validity of the name and cost fields
    this.props.form.validateFields(
      Object.values(fields),
      { force: true },
      (errors, values) => {
        // Check for errors
        if (errors) {
          ErrorModal(errors[fields.name])
          ErrorModal(errors[fields.cost])
          return
        }

        // Call the completion handler, supplying the field values
        completionHandler(values[fields.name], values[fields.cost])
      }
    )
  }

  //
  // Event handlers
  //

  handleCancel = () => {
    const { handleClose } = this.props
    handleClose()
  }

  handleAddSchemeSubmit = (e) => {
    const { addScheme, handleClose, form, selection } = this.props

    e.preventDefault()
    form.validateFields((errors, values) => {
      if (!errors) {
        const name = values.nameField
        // eslint-disable-next-line camelcase
        const account_id =
          selection.currentSubAccountId || selection.currentAccountId
        addScheme({
          input: {
            name,
            account_id,
          },
        })
          .then(({ data }) => {
            selection.setCurrentSchemeId(data.addScheme)
            handleClose()
          })
          .catch(ErrorModal)
      }
    })
  }

  //
  // JSX
  //

  nameInputWithDecorator = () => {
    const {
      form: { getFieldDecorator },
    } = this.props

    return getFieldDecorator(fields.name, {
      validateTrigger: 'onBlur',
      initialValue: this.initialFieldValue('name'),
      rules: [
        {
          required: true,
          message: 'Please enter a name',
        },
      ],
    })(
      <Input
        size="large"
        placeholder="Enter scheme name"
        onChange={
          // Sanitize input troublesome for URLS: https://secure.n-able.com/webhelp/NC_9-1-0_SO_en/Content/SA_docs/API_Level_Integration/API_Integration_URLEncoding.html
          (e) => {
            e.target.value = e.target.value.replace(
              RegexPatterns.excludedInputCharacters,
              ''
            )
          }
        }
      />
    )
  }

  costInputWithDecorator = () => {
    const {
      form: { getFieldDecorator },
    } = this.props

    return getFieldDecorator(fields.cost, {
      validateTrigger: 'onBlur',
      initialValue: this.initialFieldValue('cost'),
      rules: [
        {
          required: true,
          message: 'Please enter a cost, or 0',
        },
      ],
    })(<InputNumber placeholder="Enter scheme cost" />)
  }

  renderForm = () => (
    <Form className={css(styles.form)} onSubmit={this.handleAddSchemeSubmit}>
      <Form.Item label="Name">{this.nameInputWithDecorator()}</Form.Item>
      <Form.Item className={css(styles.buttonRow)}>
        <Button className={css(styles.button)} type="primary" htmlType="submit">
          Submit
        </Button>
        {this.isEditing() && (
          <Popconfirm
            title="Are you sure you want to delete this scheme?"
            placement="bottomRight"
            onConfirm={this.handleDelete}
            okText="Yes"
            okType="danger"
            cancelText="No"
          >
            <Button className={css(styles.button)} type="danger">
              Delete
            </Button>
          </Popconfirm>
        )}
        <Button className={css(styles.button)} onClick={this.handleCancel}>
          Cancel
        </Button>
      </Form.Item>
    </Form>
  )

  render = () => (
    <Modal
      bodyStyle={{ padding: '0' }}
      title={this.title()}
      visible={this.props.visible}
      width="500px"
      footer={null}
      onCancel={this.handleCancel}
    >
      {this.renderForm()}
    </Modal>
  )
}

const WrappedComponent = compose(
  withConsumer(AuthenticationConsumer, { propName: 'authentication' }),
  withConsumer(SelectionConsumer, { propName: 'selection' }),
  withMutationAsProp({
    gqlDocument: ADD_SCHEME,
    mutationPropName: 'addScheme',
    resultantPropName: 'addScheme',
    variables: ({ input }) => ({ input }),
    refetchQueries: [
      {
        gqlDocument: ACCOUNT_SCHEME_SELECTIONS,
        variables: ({ selection }) => ({
          id: selection.currentSubAccountId || selection.currentAccountId,
        }),
      },
    ],
  })
)(Form.create()(CreateSchemeModal))

export { WrappedComponent as CreateSchemeModal }
