import React from 'react'
import PropTypes from 'prop-types'
import { css } from 'aphrodite/no-important'
import { Checkbox } from 'antd'
import { classes } from './AssignementTableStyles'

export default function Cell({ style, checked, onChange }) {
  return (
    <div
      style={style}
      className={css(
        classes.cell,
        classes.rightBorder,
        classes.bottomBorder,
        classes.lightBorder
      )}
    >
      <Checkbox
        onChange={(event) => onChange(event.target.checked)}
        checked={checked}
      />
    </div>
  )
}

Cell.propTypes = {
  style: PropTypes.object.isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
}
Cell.defaultProps = {}
