/* eslint-disable react/no-this-in-sfc */
import React from 'react'
import { branch, renderNothing } from 'recompose'
import { Mutation, Query } from '@apollo/client/react/components'

import { TYPE } from '../graphql/introspection.graphql'

const renderNothingIf = (test) => branch(test, renderNothing)

// HOC class for wrapping with a plain component (such as a Context Provider)
// that doesn't need any props.
const withComponent = (Component) => (OriginalComponent) => {
  const HOC = (props) => (
    <Component>
      <OriginalComponent {...props} />
    </Component>
  )
  return HOC
}

// The name of the injected prop is an option.
const withConsumer =
  (Consumer, { propName = 'contextValue' } = {}) =>
  (OriginalComponent) => {
    const HOC = (props) => (
      <Consumer>
        {
          // Inject the context value as a new prop in the original component.
          (value) => <OriginalComponent {...{ [propName]: value }} {...props} />
        }
      </Consumer>
    )
    return HOC
  }

const withMutationAsProp =
  ({
    gqlDocument,
    mutationPropName,
    refetchQueries,
    update,
    awaitRefetchQueries = false,
  }) =>
  (OriginalComponent) => {
    const HOC = (props) => (
      <Mutation
        mutation={gqlDocument}
        awaitRefetchQueries={awaitRefetchQueries}
        update={update}
        refetchQueries={
          refetchQueries && refetchQueries.length
            ? refetchQueries.map(
                ({ gqlDocument: refetchGqlDocument, variables }) => ({
                  query: refetchGqlDocument,
                  // The variables option object is an optional object or a function
                  // that returns an object. Apollo client will send an  empty object
                  // by default.
                  variables:
                    typeof variables === 'function'
                      ? variables(props)
                      : variables,
                })
              )
            : undefined
        }
      >
        {(mutationFunction /* , { loading, error, called, data } */) => {
          // The ApolloLink should display an error popup, no need to display it here.

          const simplifiedMutationFunction = (variables) => {
            console.log('simplifiedMutationFunction: variables:', variables)
            return mutationFunction({
              variables,
              // optimisticResponse,
              // refetchQueries,
              // update,
            })
          }
          // Always render the component, don't for example render null while
          // the mutation is in progress.
          return (
            <OriginalComponent
              {...{ [mutationPropName]: simplifiedMutationFunction }}
              {...props}
            />
          )
        }}
      </Mutation>
    )
    return HOC
  }

// Don't render the inner component if the query is in progress or fails.
// This has the advantage of allowing the inner component to require the
// data the query provides.
const withQueryResultAsProp =
  ({
    gqlDocument,
    variables,
    resultPropName,
    transformFunc,
    // The name of the injected prop is resultPropName by default, unless we
    // provide propName.
    propName,
    fetchPolicy,
  }) =>
  (OriginalComponent) => {
    const HOC = (props) => (
      <Query
        query={gqlDocument}
        variables={
          typeof variables === 'function' ? variables(props) : variables
        }
        fetchPolicy={fetchPolicy}
      >
        {({ loading, error, data }) => {
          // Return null if there is no data ready.
          if (loading) {
            return null
          }

          // Do not render component.
          if (error) {
            // The ApolloLink should display an error popup, no need to display it here.
            return null
          }
          // Look up the resultPropName, and optionally transform it.
          const propValue = transformFunc
            ? transformFunc(data[resultPropName])
            : data[resultPropName]
          return (
            <OriginalComponent
              {...{ [propName || resultPropName]: propValue }}
              {...props}
            />
          )
        }}
      </Query>
    )
    return HOC
  }

const withEnumValuesAsProp =
  ({
    typeName,
    // The name of the injected prop is enumValues by default, unless we
    // provide propName.
    propName,
  }) =>
  (OriginalComponent) => {
    const HOC = (props) => (
      <Query
        query={TYPE}
        variables={{
          typeName,
        }}
      >
        {({ loading, error, data }) => {
          if (loading) {
            return null
          }

          // Do not render component.
          if (error) {
            // The ApolloLink should display an error popup, no need to display it here.
            return null
          }
          return (
            <OriginalComponent
              {...{
                [propName || 'enumValues']: data.__type.enumValues.map(
                  (enumValue) => enumValue.name
                ),
              }}
              {...props}
            />
          )
        }}
      </Query>
    )
    return HOC
  }

export {
  renderNothingIf,
  withComponent,
  withConsumer,
  withEnumValuesAsProp,
  withMutationAsProp,
  withQueryResultAsProp,
}
