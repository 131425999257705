import { Menu, Icon, Layout } from 'antd'
import omit from 'lodash/omit'
import PropTypes from 'prop-types'
import React from 'react'
import { Link, Route, Switch, withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { css, StyleSheet } from 'aphrodite/no-important'

import SubMenu from 'antd/es/menu/SubMenu'
import { AuthenticationConsumer } from '../../contexts'
import { getMatchedKey, menuItemConfig, routeConfig } from '../../pages'
import { withConsumer } from '../../utils'

const classes = StyleSheet.create({
  link: {
    display: 'flex',
    alignItems: 'center',
    margin: 0,
  },
})

const generateMenuItems = (config, history, selectedKey) =>
  Object.values(config).map((menu) => {
    const hasSubmenu = menu?.subMenu?.length

    if (hasSubmenu) {
      return (
        <SubMenu
          key={menu.key}
          onTitleClick={() => history.push(menu.toPath)}
          title={
            <>
              <Icon
                type={menu.iconType}
                theme="outlined"
                size="large"
                style={{ fontSize: 18 }}
              />
              {menu.itemTitle}
            </>
          }
          className={`${css(classes.menuItem)}${
            selectedKey === menu.key ? ' ant-menu-item-selected' : ''
          }`}
        >
          {menu.subMenu.map((subMenu) => (
            <Menu.Item
              key={subMenu.key}
              title={subMenu.itemTitle}
              className={css(classes.menuItem)}
            >
              <Link to={subMenu.toPath} className={css(classes.link)}>
                <Icon
                  type={subMenu.iconType}
                  theme="outlined"
                  size="large"
                  style={{ fontSize: 18 }}
                />
                <span className="nav-text">{subMenu.itemTitle}</span>
              </Link>
            </Menu.Item>
          ))}
        </SubMenu>
      )
    }

    return (
      <Menu.Item
        key={menu.key}
        title={menu.itemTitle}
        className={css(classes.menuItem)}
      >
        <Link to={menu.toPath} className={css(classes.link)}>
          <Icon
            type={menu.iconType}
            theme="outlined"
            size="large"
            style={{ fontSize: 18 }}
          />
          <span className="nav-text">{menu.itemTitle}</span>
        </Link>
      </Menu.Item>
    )
  })

const generateRoutes = (config) =>
  Object.values(config).map(({ key, ...rest }) => <Route key={key} {...rest} />)

// AppLayout receives location React router.
const AppLayout = ({ authentication, location, history }) => {
  const { appConfig } = authentication
  const { showAdminMenuOption, showReportsMenuOption } = appConfig

  const selectedKey = getMatchedKey(location)

  return (
    <Layout style={{ height: '100vh' }}>
      <Layout.Sider>
        <Menu theme="dark" mode="inline" selectedKeys={[selectedKey]} multiple>
          {generateMenuItems(
            omit(menuItemConfig, [
              !showAdminMenuOption && 'ADMIN',
              !showReportsMenuOption && 'REPORTS',
            ]),
            history,
            selectedKey
          )}
        </Menu>
      </Layout.Sider>
      <Layout.Content>
        <Switch>
          {generateRoutes(
            omit(routeConfig, [
              !showAdminMenuOption && 'ADMIN',
              !showReportsMenuOption && 'REPORTS',
            ])
          )}
        </Switch>
      </Layout.Content>
    </Layout>
  )
}

AppLayout.propTypes = {
  authentication: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
}

const WrappedComponent = compose(
  withConsumer(AuthenticationConsumer, { propName: 'authentication' }),
  withRouter
)(AppLayout)

export { WrappedComponent as AppLayout }
