/* eslint-disable react/destructuring-assignment,radix */
import {
  Button,
  Col,
  Divider,
  Form,
  // Icon,
  Input,
  Modal,
  Row,
  Select,
  message,
} from 'antd'

import { StyleSheet, css } from 'aphrodite'
import PropTypes from 'prop-types'
import React from 'react'
import { ApolloConsumer } from '@apollo/client'
import { compose } from 'recompose'

import {
  ACCOUNT_MATERIALS,
  GET_SCHEME_BY_ID,
  MATERIAL_PALETTES,
  UPDATE_SCHEME,
} from '../../graphql/colors.graphql'
import { AuthenticationConsumer, SelectionConsumer } from '../../contexts'
import { ErrorModal } from '../common'
import { Swatch } from '../common/Swatch'

import {
  clearCacheBySchemeNameQuery,
  executeQuery,
  withConsumer,
  withMutationAsProp,
  withQueryResultAsProp,
} from '../../utils'
import { RegexPatterns } from '../../const/regex'

const FormItem = Form.Item
const { Search } = Input
const { Option } = Select

const styles = StyleSheet.create({
  animatedSelection: {
    // animationName: [bounceKeyframes],
    animationDuration: '1s',
    animationIterationCount: '1',
  },
  button: {
    float: 'right',
    width: '10%',
  },
  buttonMargin: {
    marginRight: '8px',
    marginBottom: '8px',
  },
  buttonRow: {
    marginTop: '35px',
    padding: '0px 16px 12px 0px',
  },
  colorCard: {
    backgroundColor: 'white',
    border: '2px solid #e8e8e8',
    borderRadius: '5px',
    height: '100px',
    width: '100px',
    display: 'inline-block',
    verticalAlign: 'top',
    cursor: 'pointer',
    position: 'relative',
  },
  colorCardVirtualized: {
    marginTop: '2px',
    width: '97%',
  },
  colorsCol: {
    backgroundColor: 'whitesmoke',
  },
  colorsContainer: {
    height: '420px',
    padding: '8px',
    overflowY: 'scroll',
  },
  colorName: {
    fontSize: '13px',
    padding: '4px',
    overflow: 'hidden',
    height: '44px',
  },
  dividerTop: {
    background: '#a9a9a9',
    marginBottom: 0,
  },
  filter: {
    width: '100%',
  },
  filterCol: {
    padding: '0 10px 5px 10px',
  },
  filtersCollapse: {
    margin: '0 12px',
  },
  filterSelect: {
    width: '100%',
  },
  formCol: {
    padding: '10px 16px',
  },
  formItem: {
    marginBottom: '5px',
  },
  header: {
    fontSize: '18px',
    margin: '12px 0px 8px 12px',
  },
  iconCorner: {
    position: 'absolute',
    bottom: 5,
    right: 5,
  },
  iconGreen: {
    color: 'rgb(28, 184, 65)',
  },
  iconRed: {
    color: 'rgb(228, 134, 134)',
  },
  label: {
    fontWeight: '500',
  },
  selectedColor: {
    // backgroundColor: '#e6f7ff',
    borderColor: '#1890ff',
  },
  selectionsCol: {
    backgroundColor: '#e6e6e6',
    minHeight: '540px',
  },
  selectionsContainer: {
    height: '592px',
    padding: '8px 0px 8px 8px',
    overflowY: 'scroll',
  },
  spin: {
    marginTop: '40px',
    width: '100%',
  },
  vendorCollapse: {
    backgroundColor: 'white',
    marginBottom: '12px',
  },
  vendorPanelContents: {
    paddingLeft: '12px',
  },
})

// const textureLibrary = library => (library === libraries.customOverlay ? null : library)

class SchemeSelectionModal extends React.Component {
  static propTypes = {
    schemeElement: PropTypes.object,
    scheme: PropTypes.object,
    account: PropTypes.object.isRequired,
    selection: PropTypes.object.isRequired,
    updateScheme: PropTypes.func.isRequired,
    form: PropTypes.object.isRequired,
    apolloClient: PropTypes.object.isRequired,
  }

  static defaultProps = {
    schemeElement: null,
    scheme: null,
  }

  state = {
    colorsArray: null,
    selections: this.props.scheme.scheme_elements,
    visible: true,
    lowerSearchText: '',
  }

  setLowerSearchText = (newLowerSearchText) => {
    this.setState({
      lowerSearchText: newLowerSearchText,
    })
  }

  //
  // Helpers
  //

  // The form is considered to be in edit mode if an existing scheme element has
  // been passed through props
  isEditing = () => this.props.schemeElement !== null

  handleCancel = () => {
    const { selection } = this.props
    const { setCurrentSchemeId } = selection
    this.setState({ visible: false })
    setCurrentSchemeId(null)
  }

  handleMaterialSelectionChange = async (values) => {
    const { apolloClient } = this.props

    // eslint-disable-next-line no-useless-escape
    const selectedElementId = parseInt(values.match(/\/([^\/]+)\/?$/)[1])

    const selectedMaterialId = parseInt(
      values.match(/:(.*)/g).pop().replace(':', '')
    )

    const { data } = await apolloClient.query({
      query: MATERIAL_PALETTES,
      variables: { id: selectedMaterialId },
    })

    const selectedMaterial = data.material

    // Merge all the palettes together by adding colors from each additional palette to the map only
    // if they are not already added.
    const combinedPaletteSelectionsMap = new Map()

    selectedMaterial.palettes.forEach((palette) => {
      palette.palette_selections.forEach((paletteSelection) => {
        if (!combinedPaletteSelectionsMap.has(paletteSelection.color.id)) {
          combinedPaletteSelectionsMap.set(
            paletteSelection.color.id,
            paletteSelection
          )
        }
      })
    })

    const combinedPaletteSelections = [...combinedPaletteSelectionsMap.values()]

    const availableColorOptions = combinedPaletteSelections.sort((a, b) => {
      // Treat null like it is higher than anything else.
      if (!a.color.identifier && !b.color.identifier) {
        return 0
      }
      if (!a.color.identifier) {
        return 1
      }
      if (!b.color.identifier) {
        return -1
      }

      return a.color.identifier.localeCompare(b.color.identifier)
    })

    this.setState({
      colorsArray: availableColorOptions,
      materialId: selectedElementId,
    })
  } // handleMaterialSelectionChange

  removeSchemeSelection = (targetSchemeSelection) => {
    const { selections } = this.state

    // A selection matches if both color ID AND material ID match.
    // Keep a selection if either its color ID or material ID don't match the one we want to delete.
    const remainingSelections = selections.filter(
      (selection) =>
        selection.color_id !== targetSchemeSelection.color_id ||
        selection.material.id !== targetSchemeSelection.material.id
    )

    this.setState({
      selections: remainingSelections,
    })
  } // removeSchemeSelection

  setMaterialColorSelection = (targetColorSelection) => {
    const {
      selections: materialSelections,
      // This tracks the currently selected material by its blendElement ID, not its material ID.
      materialId: selectedMaterialBlendElementId,
    } = this.state

    const selectedMaterial = this.props.account.materials.find(
      (material) => material.element_id === selectedMaterialBlendElementId
    )

    // If no material was selected, do nothing.
    if (
      selectedMaterialBlendElementId === undefined ||
      selectedMaterialBlendElementId === null
    ) {
      return
    }

    // If the scheme has the current material, update the matching
    // materialSelection's color fields.
    let didUpdate = false
    const updatedSelections = materialSelections.map((materialSelection) => {
      if (materialSelection.element_id === selectedMaterialBlendElementId) {
        const updatedMaterialSelection = {
          ...materialSelection,
          color_id: targetColorSelection.color_id,
          color: targetColorSelection.color,
        }

        didUpdate = true
        return updatedMaterialSelection
      }
      return materialSelection
    })

    if (didUpdate) {
      // Use updated Selections if they were updated.
      this.setState({
        selections: updatedSelections,
      })
    } else {
      // If the scheme doesn't have the current material, create and add a new materialSelection.
      const newMaterialSelection = {
        id: targetColorSelection.id,
        blend_mode: 'MULTIPLY',
        color_id: targetColorSelection.color_id,
        element_id: selectedMaterialBlendElementId,
        color: targetColorSelection.color,
        material: selectedMaterial,
      }

      this.setState({
        selections: [newMaterialSelection, ...materialSelections],
      })
    }
  } // setMaterialColor

  clearElevationCache = (account, scheme) =>
    executeQuery(
      clearCacheBySchemeNameQuery({
        clientName: account.account_name,
        schemeName: scheme.name,
      }),
      'Clearing cache by elevation'
    )

  handleUpdateSchemeSubmit = (e) => {
    const { form, selection, updateScheme, account, scheme } = this.props
    const { selections } = this.state
    e.preventDefault()
    form.validateFields((errors, values) => {
      // eslint-disable-next-line camelcase
      const scheme_id = selection.currentSchemeId
      if (!errors) {
        const schemeSelections = selections.map((schemeSelection) => ({
          color_id: schemeSelection.color_id,
          blend_mode: schemeSelection.blend_mode,
          name: schemeSelection.name,
          scheme_id,
          element_id: schemeSelection.element_id,
        }))

        updateScheme({
          scheme_id,
          name: values.schemeNameInput,
          scheme_selections: schemeSelections,
          account_id:
            selection.currentSubAccountId || selection.currentAccountId,
        })
          .then(() => {
            message.success(`Updated Scheme "${values.schemeNameInput}"`)
            this.clearElevationCache(account, scheme)
            this.handleCancel()
          })
          .catch(ErrorModal)
      }
    })
  }

  generateMaterialsOptions = (materials) =>
    materials.map(this.generateMaterialsList)

  generateMaterialsList = (material) => (
    <Option
      key={`${material.display_name}:${material.id}`}
      value={`${material.display_name}:${material.id}/${material.element_id}`}
    >
      {material.display_name}
    </Option>
  )

  generateColorCard = (colorSelection) => (
    <div
      style={{
        justifySelf: 'center',
      }}
    >
      <Swatch
        key={`${colorSelection.id}`}
        color={{
          id: colorSelection.color.id,
          identifier: colorSelection.color.identifier,
          name: colorSelection.color.name,
          colorId: colorSelection.color.id,
          hex: colorSelection.color.hex,
          identifier_prefix:
            colorSelection.color.color_vendor_product_info
              .product_identifier_prefix,
        }}
        selected={false}
        cornerIconType="plus"
        size="large"
        dirName=""
        onClick={() => this.setMaterialColorSelection(colorSelection)}
      />
    </div>
  ) // generateColorCard

  generateSchemeSelectionCard = (selection) => (
    <Swatch
      key={`${selection.id}`}
      color={{
        id: selection.color.id,
        identifier: selection.color.identifier,
        name: selection.color.name,
        colorId: selection.color.id,
        hex: selection.color.hex,
        identifier_prefix:
          selection.color.color_vendor_product_info.product_identifier_prefix,
      }}
      material={selection.material.display_name}
      selected={false}
      cornerIconType="close"
      size="large"
      dirName=""
      onClick={() => this.removeSchemeSelection(selection)}
    />
  ) // generateSchemeSelectionCard

  render = () => {
    const { colorsArray, selections, visible, lowerSearchText } = this.state
    const { form, scheme } = this.props
    const { getFieldDecorator } = form
    return (
      <Modal
        title={
          this.props.scheme !== null
            ? `Scheme Options for ${scheme.name}`
            : 'Edit Scheme'
        }
        visible={visible}
        bodyStyle={{ padding: '0' }}
        style={{ top: '50px' }}
        width="90%"
        footer={null}
        onCancel={this.handleCancel}
      >
        <Row>
          <Col xs={24} md={6} xl={5} className={css(styles.formCol)}>
            <Form hideRequiredMark>
              <FormItem
                label={<span className={css(styles.label)}>Scheme Name</span>}
                colon={false}
                className={css(styles.formItem)}
              >
                {getFieldDecorator('schemeNameInput', {
                  initialValue: this.props.scheme !== null ? scheme.name : null,
                  rules: [
                    { required: true, message: "You can't leave this empty." },
                  ],
                })(
                  <Input
                    onChange={
                      // Sanitize input troublesome for URLS: https://secure.n-able.com/webhelp/NC_9-1-0_SO_en/Content/SA_docs/API_Level_Integration/API_Integration_URLEncoding.html
                      (e) => {
                        e.target.value = e.target.value.replace(
                          RegexPatterns.excludedInputCharacters,
                          ''
                        )
                      }
                    }
                  />
                )}
              </FormItem>
              <FormItem
                label={
                  <span className={css(styles.label)}>Account Materials</span>
                }
                colon={false}
                className={css(styles.formItem)}
              >
                {getFieldDecorator('materialSelection', {
                  initialValue: 'Please Select a Material',
                  rules: [
                    { required: true, message: "You can't leave this empty." },
                  ],
                })(
                  <Select
                    defaultActiveFirstOption={false}
                    onChange={this.handleMaterialSelectionChange}
                    placeholder="Select Material"
                  >
                    <Option key="all" value="all">
                      All Collections
                    </Option>
                    {this.props.account !== null &&
                      this.generateMaterialsOptions(
                        [...this.props.account.materials].sort((a, b) =>
                          a.display_name.localeCompare(b.display_name)
                        )
                      )}
                  </Select>
                )}
              </FormItem>
            </Form>
          </Col>
          {colorsArray !== null && visible && (
            <Col xs={24} md={18} xl={12} className={css(styles.colorsCol)}>
              <h3 className={css(styles.header)}>Colors</h3>
              <Divider className={css(styles.dividerTop)} />
              <div>
                <Search
                  style={{
                    margin: '15px 0 15px 0',
                  }}
                  placeholder="Search by Color Name or Number"
                  onChange={(event) => {
                    const newLowerSearchText = event.target.value
                      .trim()
                      .toLowerCase()
                    this.setLowerSearchText(newLowerSearchText)
                  }}
                />
                <div
                  style={{
                    overflow: 'scroll',
                    height: '530px',
                    display: 'grid',
                    gridGap: '4px',
                    gridTemplateColumns: 'repeat(4, 1fr)',
                  }}
                >
                  {(lowerSearchText === ''
                    ? colorsArray
                    : colorsArray.filter(
                        (selection) =>
                          (selection.color.name &&
                            selection.color.name
                              .toLowerCase()
                              .includes(lowerSearchText)) ||
                          (selection.color.identifier &&
                            selection.color.identifier
                              .toLowerCase()
                              .includes(lowerSearchText))
                      )
                  ).map(this.generateColorCard)}
                </div>
              </div>
            </Col>
          )}
          {selections !== null && visible && (
            <Col xs={24} md={24} xl={7} className={css(styles.selectionsCol)}>
              <h3 className={css(styles.header)}>Current Scheme Selections</h3>
              <Divider className={css(styles.dividerTop)} />
              <div
                className={css(styles.selectionsContainer)}
                id="schemeSelectionsContainer"
              >
                {[...selections]
                  .sort((a, b) =>
                    a.material.display_name.localeCompare(
                      b.material.display_name,
                      undefined,
                      { numeric: true }
                    )
                  )
                  .map((stateSelections) =>
                    this.generateSchemeSelectionCard(stateSelections)
                  )}
              </div>
            </Col>
          )}
        </Row>
        <Row className={css(styles.buttonRow)}>
          <Button
            className={css(styles.button)}
            onClick={this.handleUpdateSchemeSubmit}
            type="primary"
          >
            Submit
          </Button>
          <Button
            onClick={this.handleCancel}
            className={css(styles.button, styles.buttonMargin)}
          >
            Cancel
          </Button>
        </Row>
      </Modal>
    )
  }
}

const WrappedComponent = compose(
  withConsumer(AuthenticationConsumer, { propName: 'authentication' }),
  withConsumer(SelectionConsumer, { propName: 'selection' }),
  withConsumer(ApolloConsumer, { propName: 'apolloClient' }),
  withQueryResultAsProp({
    gqlDocument: GET_SCHEME_BY_ID,
    variables: ({ selection }) => ({ id: selection.currentSchemeId }),
    resultPropName: 'scheme',
    propName: 'scheme',
  }),
  withQueryResultAsProp({
    gqlDocument: ACCOUNT_MATERIALS,
    variables: ({ selection }) => ({
      id: selection.currentSubAccountId || selection.currentAccountId,
      assignedToPalette: true,
    }),
    fetchPolicy: 'cache-and-network',
    resultPropName: 'account',
    propName: 'account',
  }),
  withMutationAsProp({
    gqlDocument: UPDATE_SCHEME,
    mutationPropName: 'updateScheme',
    propName: 'scheme',
    variables: ({
      name,
      selection,
      // eslint-disable-next-line camelcase
      scheme_selections,
    }) => ({ scheme_id: selection.currentSchemeId, name, scheme_selections }),
    refetchQueries: [
      {
        gqlDocument: GET_SCHEME_BY_ID,
        variables: ({ selection }) => ({ id: selection.currentSchemeId }),
      },
    ],
  })
)(Form.create()(SchemeSelectionModal))

export { WrappedComponent as SchemeSelectionModal }
