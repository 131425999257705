import React from 'react'
import { Tabs } from 'antd'
import { Route, Switch, Redirect } from 'react-router-dom'
import {
  useHistory,
  useLocation,
  useRouteMatch,
} from 'react-router-dom/cjs/react-router-dom'
import PageHeader from '../components/common/PageHeader'
import { routeKeys } from './const/routeKeys'
import SwatchesPage from './SwatchesPage'
import SwatchAssignmentsPage from './SwatchAssignmentsPage'
import SwatchProductLinesPage from './SwatchProductLinesPage'

const { TabPane } = Tabs

export default function FloorCoveringPage() {
  const history = useHistory()
  const location = useLocation()
  const { path } = useRouteMatch()

  return (
    <>
      <PageHeader title="Swatches" />
      <Tabs
        tabBarStyle={{ marginBottom: 0 }}
        defaultActiveKey={location.pathname}
        onChange={(routeKey) => history.push(routeKey)}
      >
        <TabPane tab="Swatch List" key={routeKeys.SWATCHES} />
        <TabPane tab="Swatch Assignments" key={routeKeys.SWATCH_ASSIGNMENTS} />
        <TabPane
          tab="Floor Covering Product Lines"
          key={routeKeys.FLOOR_COVERING_PRODUCT_LINES}
        />
      </Tabs>
      <Switch>
        <Route exact path={`${path}/swatches`} component={SwatchesPage} />
        <Route
          path={`${path}/swatch-assignments`}
          component={SwatchAssignmentsPage}
        />
        <Route
          path={`${path}/product-lines`}
          component={SwatchProductLinesPage}
        />
        <Redirect
          exact
          path={routeKeys.FLOOR_COVERING}
          to={routeKeys.SWATCHES}
        />
      </Switch>
    </>
  )
}
