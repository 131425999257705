/* eslint-disable react/destructuring-assignment */
import React from 'react'
import PropTypes from 'prop-types'
import { StyleSheet, css } from 'aphrodite'
import { Icon, Popconfirm, Table } from 'antd'

//
// Styles
//

const styles = StyleSheet.create({
  card: {
    width: '55%',
    float: 'left',
    margin: '5px',
  },
  cardActionLeft: {
    borderRight: '1px solid #e8e8e8',
    ':hover': {
      color: '#f5222d',
    },
  },
  cardActionRight: {
    ':hover': {
      color: '#1890ff',
    },
  },
  cardActionsContainer: {
    clear: 'both',
    borderTop: '1px solid #e8e8e8',
    listStyle: 'none',
    padding: 0,
  },
  cardIcon: {
    float: 'right',
  },
  cardSubtext: {
    fontSize: '13px',
    fontWeight: '350',
    color: '#676464',
  },
  swatchAdd: {
    textAlign: 'center',
    transition: 'all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1)',
    cursor: 'pointer',
    ':hover': {
      color: '#1890ff',
    },
    fontSize: '24px',
    padding: '0 4px',
  },
  swatchContainer: {},
  swatchCard: {
    display: 'flex',
    width: '33%',
    float: 'left',
    margin: '5px 1px 5px 1px',
  },
  schemeList: {
    width: '40%',
    display: 'inline-block',
    float: 'left',
  },
  schemeSpan: {
    cursor: 'pointer',
  },
  schemeName: {
    display: 'inline',
  },
  trashIcon: {
    display: 'inline-block',
    float: 'right',
  },
})

const showSchemeElementForm = (schemeId, setCurrentViewerId) => {
  setCurrentViewerId(schemeId)
}

const SchemeList = (props) => {
  const { schemes, setCurrentViewerId, handleDelete } = props
  const columns = [
    {
      title: 'Scheme Name',
      dataIndex: 'name',
      key: 'name',
      // eslint is incorrect about this being a component with no display name
      // eslint-disable-next-line react/display-name
      render: (text, name) => (
        <div
          className={css(styles.schemeSpan)}
          onClick={() => showSchemeElementForm(name.id, setCurrentViewerId)}
        >
          <div
            className={css(styles.schemeName)}
            onClick={() => showSchemeElementForm(name.id, setCurrentViewerId)}
          >
            {name.name}
          </div>
          <div className={css(styles.trashIcon)}>
            <Popconfirm
              title="Are you sure you want to delete this scheme?"
              placement="bottomRight"
              onConfirm={handleDelete}
              okText="Yes"
              okType="danger"
              cancelText="No"
            >
              <li className={css(styles.cardAction, styles.cardActionLeft)}>
                <Icon className={css(styles.cardIcon)} type="delete" />
              </li>
            </Popconfirm>
          </div>
        </div>
      ),
    },
  ]
  return (
    <div className={css(styles.schemeList)}>
      <Table columns={columns} dataSource={schemes} bordered />
    </div>
  )
}

SchemeList.propTypes = {
  handleDelete: PropTypes.func.isRequired,
  schemes: PropTypes.arrayOf(PropTypes.object).isRequired,
  setCurrentViewerId: PropTypes.func.isRequired,
}

export { SchemeList }
