import React, { useCallback } from 'react'
import { Form, Modal, message, Input, Select } from 'antd'
import PropTypes from 'prop-types'
import { useMutation } from '@apollo/client'
import { compose } from 'recompose'
import changeCase from 'change-case'
import {
  ACCOUNT_PROPERTIES_BY_ACCOUNT_ID,
  ADD_PROPERTY,
} from '../../../../graphql/properties.graphql'
import { withEnumValuesAsProp } from '../../../../utils'
import { RegexPatterns } from '../../../../const/regex'

function AddPropertyModal({
  form,
  divisions,
  visible,
  handleCloseModal,
  account,
}) {
  const { getFieldDecorator } = form
  const [addProperty, { loading: addLoading }] = useMutation(ADD_PROPERTY, {
    update(cache, { data }, { variables }) {
      const query = {
        query: ACCOUNT_PROPERTIES_BY_ACCOUNT_ID,
        variables: { accountId: account.id },
      }
      const previousData = cache.readQuery(query) || {
        account: {
          id: account.id,
          account_name: account.account_name,
          account_type: account.account_type,
          properties: account.properties || [],
        },
      }

      const newData = {
        account: {
          ...previousData.account,
          properties: [
            ...previousData.account.properties,
            {
              ...data.addProperty,
              ...variables.input,
              parent_account: variables.parentId,
              is_active: true,
              is_finalized: false,
              is_shopping_cart_mode: false,
              project_id: null,
              rep: null,
            },
          ],
        },
      }

      cache.writeQuery({
        ...query,
        data: newData,
      })
    },
  })

  const handleClose = useCallback(() => {
    handleCloseModal()
    form.resetFields()
  }, [handleCloseModal, form])

  const handleAddProperty = async (property) => {
    try {
      await addProperty({
        variables: {
          parentId: account.id,
          input: property,
        },
      })
      message.success('Project was successfully added!')
      handleClose()
    } catch (err) {
      message.error('Project was not added!')
      console.error(err)
    }
  }

  const handleSubmit = async () => {
    let propertyData = null
    try {
      propertyData = await form.validateFields()
    } catch (e) {
      message.error('Form is not valid!')
      return
    }
    await handleAddProperty(propertyData)
  }

  return (
    <Modal
      title="Add Project"
      visible={visible}
      onOk={handleSubmit}
      onCancel={handleClose}
      okText="Add project"
      okButtonProps={{ loading: addLoading }}
    >
      <Form key="addPropertyForm">
        <Form.Item label="Project Name">
          {getFieldDecorator('name', {
            initialValue: '',
            rules: [
              {
                required: true,
                message: 'Please input project name!',
              },
              {
                pattern:
                  RegexPatterns.noSpecialCharacterExcludeHyphenAndPostrophe,
                message:
                  'Please remove special characters! (hyphen and apostrophe allowed)',
              },
            ],
          })(<Input placeholder="Project name" />)}
        </Form.Item>
        <Form.Item label="Division">
          {getFieldDecorator('division', {
            initialValue: divisions[0],
            rules: [
              {
                required: true,
                message: 'Please select division!',
              },
            ],
          })(
            <Select>
              {divisions.map((division) => (
                <Select.Option key={`${division}`} value={division}>
                  {changeCase.titleCase(division)}
                </Select.Option>
              ))}
            </Select>
          )}
        </Form.Item>
      </Form>
    </Modal>
  )
}

AddPropertyModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired,
  divisions: PropTypes.arrayOf(PropTypes.string).isRequired,
  account: PropTypes.shape({
    id: PropTypes.number.isRequired,
    account_name: PropTypes.string.isRequired,
    account_type: PropTypes.string.isRequired,
    properties: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string,
        project_id: PropTypes.string,
        division: PropTypes.string,
        is_active: PropTypes.bool,
        is_finalized: PropTypes.bool,
        rep: PropTypes.shape({
          id: PropTypes.number.isRequired,
          full_name: PropTypes.string,
          greeting_name: PropTypes.string,
          email: PropTypes.string,
          phone: PropTypes.string,
        }),
      })
    ).isRequired,
  }).isRequired,
}

export default compose(
  withEnumValuesAsProp({
    typeName: 'Division',
    propName: 'divisions',
  }),
  Form.create()
)(AddPropertyModal)
