/* eslint-disable camelcase */
import { Button, Form } from 'antd'
import { css, StyleSheet } from 'aphrodite'
import { ApolloConsumer } from '@apollo/client'
import { compose } from 'recompose'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import {
  generateAddFormItemOptionalInput,
  generateAddFormItemRequiredInput,
  withConsumer,
  withMutationAsProp,
} from '../../../utils'
import {
  ADD_PRODUCT_INFO,
  GET_COLOR_COLLECTIONS,
} from '../../../graphql/colors.graphql'
import { SelectionConsumer } from '../../../contexts'

const styles = StyleSheet.create({
  addPropertyForm: {
    border: '1px solid #d8dee2',
    borderRadius: '10px',
    padding: '0px 10px 0px 10px',
    margin: 'auto',
    marginLeft: '10px',
    marginRight: '10px',
  },
  updatePropertyForm: {
    padding: '0px 10px 0px 10px',
    margin: 'auto',
  },
  tableStyle: {
    border: '1px solid #d8dee2',
    borderRadius: '10px',
    padding: '0px 10px 0px 10px',
    marginLeft: '10px',
    marginRight: '10px',
  },
})

const { Item } = Form

class ProductLineCreateForm extends Component {
  handleAddProductButtonPress = async () => {
    const { addColorVendorProductInfo, form, addLineToProductLines } =
      this.props

    form.validateFieldsAndScroll(
      async (
        errors,
        { product_name, product_group, product_identifier_prefix }
      ) => {
        if (!errors) {
          console.log('Using form values: ', {
            product_name,
            product_group,
            product_identifier_prefix,
          })
          form.resetFields()
          const productResult = await addColorVendorProductInfo({
            input: {
              product_name,
              product_group,
              product_identifier_prefix,
            },
          })

          if (
            productResult &&
            productResult.data &&
            productResult.data.addColorVendorProductInfo
          ) {
            addLineToProductLines(productResult.data.addColorVendorProductInfo)
          }
        }
      }
    )
  }

  render = () => {
    const { form } = this.props
    const { getFieldDecorator } = form
    return (
      <React.Fragment>
        <Form
          key="addMaterialForm"
          layout="inline"
          className={css(styles.addPropertyForm)}
        >
          {generateAddFormItemRequiredInput(
            getFieldDecorator,
            'product_name',
            '* Product Line Name'
          )}
          {generateAddFormItemOptionalInput(
            getFieldDecorator,
            'product_group',
            '* Product Line Group'
          )}
          {generateAddFormItemOptionalInput(
            getFieldDecorator,
            'product_identifier_prefix',
            'Product Line Prefix'
          )}
          <Item>
            <Button type="primary" onClick={this.handleAddProductButtonPress}>
              Add Product Line
            </Button>
          </Item>
        </Form>
      </React.Fragment>
    )
  }
}

ProductLineCreateForm.propTypes = {
  form: PropTypes.object.isRequired,
  addLineToProductLines: PropTypes.func.isRequired,
  addColorVendorProductInfo: PropTypes.func.isRequired,
}

const WrappedComponent = compose(
  withConsumer(SelectionConsumer, { propName: 'selection' }),
  withConsumer(ApolloConsumer, { propName: 'apolloClient' }),
  withMutationAsProp({
    gqlDocument: ADD_PRODUCT_INFO,
    mutationPropName: 'addColorVendorProductInfo',
    refetchQueries: [
      {
        gqlDocument: GET_COLOR_COLLECTIONS,
      },
    ],
  })
)(Form.create()(ProductLineCreateForm))

export { WrappedComponent as ProductLineCreateForm }
