import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { css, StyleSheet } from 'aphrodite'
import Alert from './Alert'

const BORDER_SIZE = 1

const classes = StyleSheet.create({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  alert: {
    marginBottom: 20,
    width: '100%',
  },
  image: {
    backgroundColor: 'darkgray',
    maxWidth: '100%',
    maxHeight: '100%',
    border: `${BORDER_SIZE}px solid #888`,
    cursor: 'pointer',
  },
  imageHolder: {
    position: 'relative',
    display: 'inline-block',
    maxHeight: 'calc(100% - 39px)',
    userSelect: 'none',
  },
  point: {
    position: 'absolute',
    width: 30,
    height: 30,
    transform: 'translateX(-50%) translateY(-50%)',
    borderRadius: '50%',
    backgroundColor: '#fff',
    border: '1px solid #000',
    zIndex: 2,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 300,
    color: '#000',
    cursor: 'pointer',
  },
})

export default function ChoosePointFromPicture({
  maskUrl,
  currentPoint,
  setCurrentPoint,
  onDeletePoint,
}) {
  const imageHolderRef = useState(null)

  const handleImageClick = (e) => {
    e.preventDefault()
    const { width, height, left, top } =
      imageHolderRef.current.getBoundingClientRect()

    const contentX = e.clientX - left
    const contentY = e.clientY - top
    const x = (contentX / width) * 100
    const y = (contentY / height) * 100

    setCurrentPoint({ x: Number(x.toFixed(2)), y: Number(y.toFixed(2)) })
  }

  const handleDeletePoint = (e) => {
    e.stopPropagation()
    onDeletePoint()
  }

  return (
    <div className={css(classes.root)}>
      <Alert
        className={css(classes.alert)}
        type="info"
        message="Click on the image to select a point or click on the point to remove it"
        showIcon
      />
      {maskUrl && (
        <div className={css(classes.imageHolder)}>
          {currentPoint && (
            <span
              onClick={handleDeletePoint}
              className={css(classes.point)}
              style={{
                left: `${currentPoint.x}%`,
                top: `${currentPoint.y}%`,
              }}
            >
              X
            </span>
          )}
          {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
          <img
            ref={imageHolderRef}
            className={css(classes.image)}
            onClick={handleImageClick}
            src={maskUrl}
            alt=""
          />
        </div>
      )}{' '}
    </div>
  )
}

ChoosePointFromPicture.propTypes = {
  maskUrl: PropTypes.string.isRequired,
  currentPoint: PropTypes.object.isRequired,
  setCurrentPoint: PropTypes.func.isRequired,
  onDeletePoint: PropTypes.func.isRequired,
}
ChoosePointFromPicture.defaultProps = {}
