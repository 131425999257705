import React, { useEffect, useMemo, useState } from 'react'
import { Form, Input as AntdInput } from 'antd'
import { css } from 'aphrodite/no-important'
import PropTypes from 'prop-types'
import { debounce } from 'lodash'
import { classes } from './styles'

const FilterInput = ({
  field,
  label,
  placeholder,
  parent,
  inputClass,
  formItemClass,
  labelClass,
  ...props
}) => {
  const { loading, data } = parent || {}
  const { value, set } = data || {}

  const [current, setCurrent] = useState(value)
  const update = useMemo(() => debounce(set, 500), [set])

  useEffect(() => {
    if (current !== value) {
      update(current)
    }
  }, [current])

  const handleChange = (e) => {
    const { value } = e.target
    setCurrent(value)
  }

  return (
    <Form.Item
      labelCol={{ className: css(labelClass) }}
      className={css(classes.formItem, formItemClass)}
      label={label}
    >
      <AntdInput
        className={css(classes.input, inputClass)}
        placeholder={placeholder}
        value={current}
        disabled={loading}
        onChange={handleChange}
        allowClear
        {...props}
      />
    </Form.Item>
  )
}

FilterInput.propTypes = {
  field: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  parent: PropTypes.shape({
    loading: PropTypes.bool,
    data: PropTypes.object,
  }),
  inputClass: PropTypes.object,
  formItemClass: PropTypes.object,
  labelClass: PropTypes.object,
}

FilterInput.defaultProps = {
  parent: null,
  inputClass: null,
  formItemClass: null,
  labelClass: null,
}

export const Input = FilterInput
