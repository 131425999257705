/* eslint-disable react/destructuring-assignment */

import React from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import { Spin } from 'antd'
import { isEqual } from 'lodash'
import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer'
import {
  Masonry,
  createCellPositioner,
} from 'react-virtualized/dist/commonjs/Masonry'
import {
  CellMeasurerCache,
  CellMeasurer,
} from 'react-virtualized/dist/commonjs/CellMeasurer'

class MasonryGrid extends React.Component {
  static propTypes = {
    height: PropTypes.number,
    cellDimensions: PropTypes.object.isRequired,
    dataSource: PropTypes.arrayOf(PropTypes.object),
    renderCell: PropTypes.func.isRequired,
    loading: PropTypes.bool,
  }

  static defaultProps = {
    height: null,
    dataSource: null,
    loading: false,
  }

  constructor(props) {
    super(props)

    this.gutterSize = 10
  }

  //
  // Lifecycle
  //

  componentDidUpdate = (prevProps) => {
    if (!isEqual(prevProps, this.props)) this.forceLayout()
  }

  //
  // Helpers
  //

  cellPositioner = () => {
    if (!this._cellPositioner) {
      this._cellPositioner = createCellPositioner({
        cellMeasurerCache: this.cache(),
        columnCount: this.columns(),
        columnWidth: this.props.cellDimensions.width,
        spacer: this.gutterSize,
      })
    }
    return this._cellPositioner
  }

  cache = () => {
    if (!this._cache) {
      const { width, minHeight } = this.props.cellDimensions
      this._cache = new CellMeasurerCache({
        defaultHeight: minHeight,
        defaultWidth: width,
        fixedWidth: true,
      })
    }

    return this._cache
  }

  columns = () =>
    Math.max(
      1,
      Math.floor(
        this.width / (this.props.cellDimensions.width + this.gutterSize)
      )
    )

  onFirstRender = (callback) => {
    // Intercept the first time the component gets rendered. Fixes this bug:
    // https://github.com/bvaughn/react-virtualized/issues/979
    window.requestAnimationFrame(() => {
      const node = ReactDOM.findDOMNode(this) // eslint-disable-line
      if (node && !this.node) {
        this.node = node
        callback()
      }
    })
  }

  resetCellPositioner = () => {
    this._cellPositioner.reset({
      cellMeasurerCache: this.cache(),
      columnCount: this.columns(),
      columnWidth: this.props.cellDimensions.width,
      spacer: this.gutterSize,
    })
  }

  //
  // Event handlers
  //

  onResize = ({ width }) => {
    this.width = width
    this.forceLayout()
  }

  forceLayout = () => {
    // also called by parent
    this.cache().clearAll()
    this.resetCellPositioner()
    this.Masonry.clearCellPositions()
  }

  //
  // Render
  //

  renderCell = ({ index, key, parent, style }) => {
    const { dataSource, cellDimensions } = this.props
    const datum = dataSource[index]

    return (
      <CellMeasurer
        cache={this.cache()}
        index={index}
        key={key}
        parent={parent}
      >
        <div
          key={key}
          style={{
            ...style,
            width: cellDimensions.width,
          }}
        >
          {this.props.renderCell(datum)}
        </div>
      </CellMeasurer>
    )
  }

  renderLoaded = () => {
    this.onFirstRender(this.forceLayout)
    return (
      <AutoSizer onResize={this.onResize}>
        {({ width, height, scrollTop }) => {
          this.width = width
          return (
            <Masonry
              key={this.props.dataSource.length}
              scrollTop={scrollTop}
              cellCount={this.props.dataSource.length}
              cellMeasurerCache={this.cache()}
              cellPositioner={this.cellPositioner()}
              cellRenderer={this.renderCell}
              height={this.props.height ?? height}
              width={this.width}
              overscanByPixels={this.props.cellDimensions.minHeight * 5}
              ref={(instance) => {
                this.Masonry = instance
              }}
            />
          )
        }}
      </AutoSizer>
    )
  }

  render = () => (this.props.loading ? <Spin /> : this.renderLoaded())
}

export default MasonryGrid
