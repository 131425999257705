/* eslint-disable no-restricted-syntax */

// Based on example by Jhey Tompkins at:
// https://medium.com/techtrument/enumeration-objects-in-javascript-ec06a83f39f2
// Accept an array of strings, or an array of key,value pairs.
class Enumeration {
  constructor(input) {
    if (Array.isArray(input)) {
      // Assume input is an array of strings.
      const keys = input
      for (const key of keys) {
        this[key.toUpperCase()] = key
      }
    } else {
      // Assume input is an object.
      const obj = input
      Object.keys(obj).forEach((key) => {
        this[key.toUpperCase()] = obj[key]
      })
    }
    return Object.freeze(this)
  }
}

export { Enumeration }
