import React from 'react'
import PropTypes from 'prop-types'
import { css, StyleSheet } from 'aphrodite/no-important'
import { Alert as AntdAlert } from 'antd'

const classes = StyleSheet.create({
  alert: {
    width: '100%',
  },
  margin: {
    margin: 8,
  },
})
export default function Alert({
  margin,
  message,
  type,
  description,
  ...props
}) {
  return (
    <AntdAlert
      className={
        margin ? css(classes.alert, classes.margin) : css(classes.alert)
      }
      message={message}
      description={description}
      type={type || 'error'}
      {...props}
    />
  )
}

Alert.propTypes = {
  margin: PropTypes.bool,
  message: PropTypes.string,
  description: PropTypes.string,
  type: PropTypes.oneOf(['success', 'error', 'info', 'warn']),
}
Alert.defaultProps = {
  margin: false,
  message: 'Something went wrong. Try to reload page.',
  description: null,
  type: 'error',
}
