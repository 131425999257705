import React from 'react'
import PropTypes from 'prop-types'
import ButtonGroup from 'antd/es/button/button-group'
import { Button, Popconfirm, Tooltip, message } from 'antd'
import { useMutation } from '@apollo/client'
import {
  DELETE_PRODUCT_LINE,
  GET_PRODUCT_LINES,
} from '../../../graphql/fcPruductLines.graphql'
import { swatchFormGetProductLinesVariables } from '../SwatchModal/SwatchForm'

export default function ProductLineTableActions({
  productLine,
  handleEditProductLine,
}) {
  const [deleteProductLine, { loading }] = useMutation(DELETE_PRODUCT_LINE)

  const handleDeleteProductLine = async (productLineId) => {
    try {
      await deleteProductLine({
        variables: {
          id: productLineId,
        },
        refetchQueries: [GET_PRODUCT_LINES],
        // Update cache for SwatchModal
        update(cache, { data }) {
          if (!data?.deleteSwatchVendorProductInfo?.id) return

          const previousData = cache.readQuery({
            query: GET_PRODUCT_LINES,
            variables: swatchFormGetProductLinesVariables,
          })

          const items = (
            previousData?.swatchesAssignmentsResultInfos?.items || []
          ).filter((item) => item.id !== data.deleteSwatchVendorProductInfo.id)

          cache.writeQuery({
            query: GET_PRODUCT_LINES,
            variables: swatchFormGetProductLinesVariables,
            data: {
              swatchesAssignmentsResultInfos: {
                items,
                totalCount: items.length,
              },
            },
          })
        },
      })
      message.success('Product Line was successfully deleted!')
    } catch (e) {
      console.error(e)
      message.error('Product Line was not deleted!')
    }
  }

  return (
    <ButtonGroup>
      <Tooltip title="Edit Product Line">
        <Button
          onClick={() => handleEditProductLine(productLine)}
          type="primary"
          icon="edit"
          size="large"
        />
      </Tooltip>
      <Popconfirm
        placement="left"
        title={`Are you sure you want to delete Product Line ${productLine.product_name}?`}
        onConfirm={() => handleDeleteProductLine(productLine.id)}
        okText="Yes"
        cancelText="No"
      >
        <Tooltip title="Delete Product Line">
          <Button type="danger" icon="delete" size="large" loading={loading} />
        </Tooltip>
      </Popconfirm>
    </ButtonGroup>
  )
}

ProductLineTableActions.propTypes = {
  productLine: PropTypes.shape({
    id: PropTypes.number.isRequired,
    product_name: PropTypes.string.isRequired,
    product_group: PropTypes.string.isRequired,
    product_identifier_prefix: PropTypes.string,
  }).isRequired,
  handleEditProductLine: PropTypes.func.isRequired,
}
