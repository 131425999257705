import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Card, message } from 'antd'
import Meta from 'antd/es/card/Meta'
import { useMutation, useQuery } from '@apollo/client'
import { css, StyleSheet } from 'aphrodite/no-important'
import { MASKS_BY_ROOM_ID, DELETE_MASK } from '../../../graphql/fcMasks.graphql'
import { getMaskCloudinaryPath } from '../functions/getMaskCloudinaryPath'
import MaskCardActions from './MaskCardActions'
import Alert from '../../common/Alert'
import ImageSkeleton from '../../common/skeletons/ImageSkeleton'
import CardActionSkeleton from './CardActionSkeleton'
import CardsSkeleton from '../../common/skeletons/CardsSkeleton'

const CARD_WIDTH = 300
const classes = StyleSheet.create({
  maskImage: {
    height: 200,
    backgroundColor: 'darkgray',
    borderBottom: '1px solid lightgray',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  cards: {
    display: 'grid',
    gridTemplateColumns: `repeat(auto-fill, ${CARD_WIDTH}px)`,
    gridTemplateRows: 'repeat(auto-fill, 342px)',
    gap: 10,
  },
  card: {
    width: CARD_WIDTH,
  },
})

export default function MaskCardList({
  onEditClick,
  onEditDescriptionPoint,
  roomId,
  account,
  setUsedMaterials,
}) {
  const [deleteMask, { loading: deleteMaskLoading }] = useMutation(DELETE_MASK)
  const { loading, data, error } = useQuery(MASKS_BY_ROOM_ID, {
    variables: {
      id: roomId,
      pagination: {
        page: 0,
        size: Number.MAX_SAFE_INTEGER,
      },
    },
  })

  const handleRemoveMask = async (mask) => {
    try {
      await deleteMask({
        variables: {
          id: mask.id,
        },
        update(cache, { data }) {
          cache.modify({
            fields: {
              masksByRoomId(existingMasks, { readField }) {
                return existingMasks.filter(
                  (mask) => readField('id', mask) !== data.deleteMask.id
                )
              },
            },
          })
        },
      })
      message.success('Mask was successfully deleted!')
    } catch (e) {
      console.error(e)
      message.error('Mask was not deleted!')
    }
  }

  const masksByRoomId = data?.masksByRoomId && [...data?.masksByRoomId]

  useEffect(() => {
    if (masksByRoomId) {
      setUsedMaterials(data?.masksByRoomId?.map((mask) => mask?.material?.id))
    }
  }, [data?.masksByRoomId])

  return (
    <>
      {data?.masksByRoomId && data.masksByRoomId.length <= 0 && (
        <Alert
          type="info"
          message="No mask inserted!"
          description="Please insert mask to start using floor covering."
        />
      )}
      {error && <Alert />}
      <div className={css(classes.cards)}>
        {loading && <CardsSkeleton descriptionCount={2} width={CARD_WIDTH} />}
        {masksByRoomId
          ?.sort((a, b) => b.renderIndex - a.renderIndex)
          .map((mask) => (
            <Card
              disabled
              key={mask.id}
              className={css(classes.card)}
              cover={
                <div className={css(classes.maskImage)}>
                  <ImageSkeleton
                    alt={mask.material.display_name}
                    style={{ maxHeight: '100%', maxWidth: '100%' }}
                    skeletonProps={{ style: { width: '100%', height: '100%' } }}
                    src={getMaskCloudinaryPath(
                      account.cloud_folder_name,
                      mask.image,
                      roomId,
                      'w_200,h_200,c_fit'
                    )}
                  />
                </div>
              }
              actions={
                deleteMaskLoading
                  ? null
                  : MaskCardActions({
                      onEditClick,
                      onEditDescriptionPoint,
                      handleRemoveMask,
                      mask,
                    })
              }
            >
              {!deleteMaskLoading && (
                <Meta
                  title={mask.material.display_name}
                  description={
                    <>
                      <span>
                        Render Index: <b>{mask.renderIndex}</b>
                      </span>
                      <br />
                      <span>
                        {`Material: `}
                        <b>
                          {mask.material?.id
                            ? mask.material.display_name
                            : 'None'}
                        </b>
                      </span>
                    </>
                  }
                />
              )}
              {deleteMaskLoading && <CardActionSkeleton descriptionCount={2} />}
            </Card>
          ))}
      </div>
    </>
  )
}

MaskCardList.propTypes = {
  onEditClick: PropTypes.func.isRequired,
  onEditDescriptionPoint: PropTypes.func.isRequired,
  roomId: PropTypes.number.isRequired,
  account: PropTypes.object.isRequired,
  setUsedMaterials: PropTypes.func.isRequired,
}
MaskCardList.defaultProps = {}
