import { ApolloConsumer } from '@apollo/client'
import { compose, withState, withHandlers } from 'recompose'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import {
  withConsumer,
  withMutationAsProp,
  withQueryResultAsProp,
} from '../../../utils'
import {
  GET_COLOR_COLLECTIONS,
  ADD_COLOR,
} from '../../../graphql/colors.graphql'
import { ProductLinesList } from './ProductLinesList'
import { ProductLineCreateForm } from './ProductLineCreateForm'
import { AuthenticationConsumer, SelectionConsumer } from '../../../contexts'

class ProductLinesForm extends Component {
  render = () => {
    console.log(this.props)
    const {
      colorCollections,
      addLineToProductLines,
      productLines,
      updateProductLines,
    } = this.props
    // const { productLines } = this.state
    return (
      <React.Fragment>
        <ProductLineCreateForm
          setStateHandler={this.setStateHandler}
          addLineToProductLines={addLineToProductLines}
          productLines={productLines}
        />
        {colorCollections.length > 0 && (
          <ProductLinesList
            updateProductLines={updateProductLines}
            productLines={productLines}
          />
        )}
      </React.Fragment>
    )
  }
}

ProductLinesForm.propTypes = {
  authentication: PropTypes.shape({
    token: PropTypes.string,
    signIn: PropTypes.func,
    signOut: PropTypes.func,
  }).isRequired,
  colorCollections: PropTypes.arrayOf(PropTypes.object).isRequired,
  productLines: PropTypes.arrayOf(PropTypes.object).isRequired,
  addLineToProductLines: PropTypes.func.isRequired,
  updateProductLines: PropTypes.func.isRequired,
}

const WrappedComponent = compose(
  withConsumer(AuthenticationConsumer, { propName: 'authentication' }),
  withConsumer(SelectionConsumer, { propName: 'selection' }),
  withConsumer(ApolloConsumer, { propName: 'apolloClient' }),
  withState('isSearching', 'setIsSearching', false),
  withState('collectionPrefix', 'setCollectionPrefix', null),
  withQueryResultAsProp({
    gqlDocument: GET_COLOR_COLLECTIONS,
    propName: 'colorCollections',
    resultPropName: 'colorVendorProductInfos',
    fetchPolicy: 'network-only',
  }),
  withMutationAsProp({
    gqlDocument: ADD_COLOR,
    mutationPropName: 'addColorLibrary',
  }),
  withState(
    'productLines',
    'setProductLines',
    (props) => props.colorCollections
  ),
  withHandlers({
    // eslint-disable-next-line max-len
    addLineToProductLines:
      ({ setProductLines }) =>
      (newLine) =>
        setProductLines((lines) => [newLine, ...lines]),
    updateProductLines:
      ({ setProductLines }) =>
      (updatedLine) =>
        setProductLines((lines) => {
          const indexToUpdate = lines.findIndex(
            (line) => line.id === updatedLine.id
          )
          lines.splice(indexToUpdate, 1, updatedLine)
          return lines
        }),
  })
)(ProductLinesForm)

export { WrappedComponent as ProductLinesForm }
