import React from 'react'
import PropTypes from 'prop-types'
import ButtonGroup from 'antd/es/button/button-group'
import { generatePath } from 'react-router-dom'
import { Button, Popconfirm, Tooltip } from 'antd'
import { routeKeys } from '../../../../pages/const/routeKeys'
import { SharePropertyModal } from '../../../modals/SharePropertyModal'
import { generatePropertyURL } from '../../../../utils'

const Buildings = ({ accountId, subAccountId, propertyId, history }) => {
  const path = generatePath(
    subAccountId ? routeKeys.BUILDINGS_SUB_ACCOUNT : routeKeys.BUILDINGS,
    {
      propertyId,
      subAccountId,
      accountId,
    }
  )
  return (
    <Tooltip title="View Buildings">
      <Button
        onClick={() => history.push(path)}
        type="secondary"
        icon="home"
        size="large"
      />
    </Tooltip>
  )
}
Buildings.propTypes = {
  accountId: PropTypes.number.isRequired,
  subAccountId: PropTypes.number.isRequired,
  propertyId: PropTypes.number.isRequired,
  history: PropTypes.object.isRequired,
}

const Rooms = ({ accountId, subAccountId, propertyId, history }) => {
  const path = generatePath(
    subAccountId ? routeKeys.ROOMS_SUB_ACCOUNT : routeKeys.ROOMS,
    {
      propertyId,
      accountId,
      subAccountId,
    }
  )
  return (
    <Tooltip title="View Rooms">
      <Button
        onClick={() => history.push(path)}
        type="secondary"
        icon="codepen"
        size="large"
      />
    </Tooltip>
  )
}
Rooms.propTypes = {
  accountId: PropTypes.number.isRequired,
  subAccountId: PropTypes.number.isRequired,
  propertyId: PropTypes.number.isRequired,
  history: PropTypes.object.isRequired,
}

const Invite = ({ account, property }) => {
  const propertyURL = generatePropertyURL(account, property)
  return <SharePropertyModal property={property} propertyURL={propertyURL} />
}
Invite.propTypes = {
  account: PropTypes.object.isRequired,
  property: PropTypes.object.isRequired,
}

const Lock = ({ propertyId, handleFinalizePropertyButtonPress }) => {
  return (
    <Tooltip title="Lock">
      <Popconfirm
        placement="bottomRight"
        title="Move to finalized projects?"
        onConfirm={() => handleFinalizePropertyButtonPress(propertyId)}
        okText="Yes"
        cancelText="No"
      >
        <Button type="primary" icon="unlock" size="large" />
      </Popconfirm>
    </Tooltip>
  )
}
Lock.propTypes = {
  propertyId: PropTypes.number.isRequired,
  handleFinalizePropertyButtonPress: PropTypes.func.isRequired,
}

const Unlock = ({ propertyId, handleActivatePropertyButtonPress }) => {
  return (
    <Tooltip title="Unlock">
      <Popconfirm
        placement="bottomRight"
        title="Move to active projects?"
        onConfirm={() => handleActivatePropertyButtonPress(propertyId)}
        okText="Yes"
        cancelText="No"
      >
        <Button type="primary" icon="lock" size="large" />
      </Popconfirm>
    </Tooltip>
  )
}
Unlock.propTypes = {
  propertyId: PropTypes.number.isRequired,
  handleActivatePropertyButtonPress: PropTypes.func.isRequired,
}

const Archive = ({ propertyId, handleDeactivatePropertyButtonPress }) => {
  return (
    <Tooltip title="Archive">
      <Popconfirm
        placement="bottomRight"
        title="Move to inactive projects?"
        onConfirm={() => handleDeactivatePropertyButtonPress(propertyId)}
        okText="Yes"
        cancelText="No"
      >
        <Button type="danger" icon="folder-open" size="large" />
      </Popconfirm>
    </Tooltip>
  )
}
Archive.propTypes = {
  propertyId: PropTypes.number.isRequired,
  handleDeactivatePropertyButtonPress: PropTypes.func.isRequired,
}

const Activate = ({ propertyId, handleActivatePropertyButtonPress }) => {
  return (
    <Tooltip title="Activate">
      <Popconfirm
        placement="bottomRight"
        title="Move to active projects?"
        onConfirm={() => handleActivatePropertyButtonPress(propertyId)}
        okText="Yes"
        cancelText="No"
      >
        <Button type="secondary" icon="folder" size="large" />
      </Popconfirm>
    </Tooltip>
  )
}
Activate.propTypes = {
  propertyId: PropTypes.number.isRequired,
  handleActivatePropertyButtonPress: PropTypes.func.isRequired,
}

const Delete = ({ propertyId, handleDeletePropertyButtonPress }) => {
  return (
    <Tooltip title="Delete">
      <Popconfirm
        placement="bottomRight"
        title="Delete this project?"
        onConfirm={() => handleDeletePropertyButtonPress(propertyId)}
        okText="Yes"
        cancelText="No"
      >
        <Button type="danger" icon="delete" size="large" />
      </Popconfirm>
    </Tooltip>
  )
}
Delete.propTypes = {
  propertyId: PropTypes.number.isRequired,
  handleDeletePropertyButtonPress: PropTypes.func.isRequired,
}

/**
 * Creates column for selected actions
 * @param {[Buildings|Rooms|Invite|Lock|Archive|Delete]} actions Actions to render in group
 * @param {number} width Width of column
 * @returns {JSX.Element|{width: number, title: string, render(*): *, key: string}}
 * @constructor
 */
export const PropertiesTableActions = (actions, width = 120) => {
  return {
    title: 'Actions',
    key: 'actions',
    width,
    render(property) {
      return <ButtonGroup>{actions(property)}</ButtonGroup>
    },
  }
}
PropertiesTableActions.Buildings = Buildings
PropertiesTableActions.Rooms = Rooms
PropertiesTableActions.Invite = Invite
PropertiesTableActions.Lock = Lock
PropertiesTableActions.Unlock = Unlock
PropertiesTableActions.Archive = Archive
PropertiesTableActions.Activate = Activate
PropertiesTableActions.Delete = Delete
