import React from 'react'
import PropTypes from 'prop-types'
import { Form, Input } from 'antd'
import Title from 'antd/es/typography/Title'
import { css, StyleSheet } from 'aphrodite/no-important'
import SmallCard from '../../../common/SmallCard'
import getLayerCloudinaryPath from '../../functions/getLayerCloudinaryPath'

const classes = StyleSheet.create({
  maskList: {
    display: 'flex',
    flexWrap: 'wrap',
  },
})

const getCurrentData = (selected, mask, cloudFolder, roomId) => {
  const input = {}
  if (selected.__typename === 'PaletteSelection') {
    input.hex = selected.hex
  } else if (selected.__typename === 'RoomLayer') {
    input.imageSrc = getLayerCloudinaryPath(
      cloudFolder,
      selected?.image,
      roomId,
      'w_90,h_90,c_fill'
    )
  }

  input.secondaryName = selected.product_identifier_prefix
    ? `${selected.name} (${selected.product_identifier_prefix}${selected.identifier})`
    : selected.name

  return {
    id: mask.id,
    name: mask.material.display_name,
    ...input,
  }
}

export default function SchemesForm({
  form,
  scheme,
  maskSelection,
  cloudFolder,
  roomId,
  onMaskUnselect,
}) {
  const maskSelectionArray = Object.values(maskSelection)

  return (
    <>
      <Form>
        <Title level={3}>Current Scheme</Title>
        <Form.Item label="Name">
          {form.getFieldDecorator('name', {
            initialValue: scheme?.name || '',
            rules: [
              {
                required: true,
                message: 'Please input swatch name!',
              },
              {
                max: 255,
                message: 'Maximum name size is 255!',
              },
            ],
          })(<Input placeholder="Wooden scheme, ..." />)}
        </Form.Item>
      </Form>
      <div className={css(classes.maskList)}>
        {maskSelectionArray.map(({ selected, mask }) => (
          <SmallCard
            width={140}
            onUnselect={onMaskUnselect}
            key={selected?.scheme?.id}
            data={getCurrentData(selected, mask, cloudFolder, roomId)}
          />
        ))}
      </div>
    </>
  )
}

SchemesForm.propTypes = {
  form: PropTypes.object.isRequired,
  scheme: PropTypes.object.isRequired,
  maskSelection: PropTypes.object.isRequired,
  cloudFolder: PropTypes.string.isRequired,
  roomId: PropTypes.number.isRequired,
  onMaskUnselect: PropTypes.func,
}

SchemesForm.defaultProps = {
  onMaskUnselect: null,
}
