import React, { useMemo, useCallback } from 'react'
import changeCase from 'change-case'
import { Switch, Table } from 'antd'
import PropTypes from 'prop-types'
import { generatePropertyURL } from '../../../../utils'
import { PropertiesTableActions } from './PropertiesTableActions'
import { QueryParams } from '../../../../const/queryParams'

export const PropertiesTable = ({
  type,
  account,
  properties: { properties, total, filtered, hasFilter },
  actions,
  expandedRow,
  updatePropertyById,
  queryParams,
  currentPage,
  setCurrentPage,
  defaultPageSize,
  pageSize,
  setPageSize,
  actionsWidth = 120,
}) => {
  const handleIsShoppingCartModeSwitchOnChange = useCallback(
    (propertyId, checked) =>
      updatePropertyById({
        id: propertyId,
        input: {
          is_shopping_cart_mode: checked,
        },
      }),
    [updatePropertyById]
  )

  const handleChangePage = useCallback(
    (page) => {
      setCurrentPage(page)
      queryParams.set(QueryParams.PAGE, page > 1 ? page : '').update()
    },
    [queryParams]
  )

  const handleShowSizeChange = useCallback(
    (currentPage, pageSize) => {
      setPageSize(pageSize)
      queryParams
        .set(
          QueryParams.PAGE_SIZE,
          pageSize !== defaultPageSize ? pageSize : ''
        )
        .update()
    },
    [queryParams]
  )

  const columns = [
    {
      title: 'Project Name',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      defaultSortOrder: 'ascend',
      // Fix for sorting.
      // Without that fix we had three states ['ascend', 'descend', undefined]
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: 'Division',
      dataIndex: 'division',
      key: 'division',
      render: (text) => changeCase.pascalCase(text),
    },
    {
      key: 'useShoppingCartMode',
      title: 'Cart',
      render(text, property) {
        return (
          <Switch
            checked={property.is_shopping_cart_mode}
            onChange={(checked) =>
              handleIsShoppingCartModeSwitchOnChange(property.id, checked)
            }
          />
        )
      },
    },
    {
      key: 'url',
      title: 'Visualizer URL',
      render(text, property) {
        const propertyURL = generatePropertyURL(account, property)
        return (
          <a href={propertyURL} target="_blank" rel="noopener noreferrer">
            {propertyURL}
          </a>
        )
      },
    },
    PropertiesTableActions(actions, actionsWidth),
  ]

  const addons = {}
  if (expandedRow) {
    addons.onExpand = (expanded, record) => {
      record.visible = expanded
    }
    addons.expandedRowRender = expandedRow
  }

  const renderTotal = useCallback(
    (filtered) => (total, range) => {
      let text = `${range[0]}-${range[1]} of ${total} projects`
      if (hasFilter) {
        text += ` (${filtered} filtered out)`
      }
      return text
    },
    [filtered]
  )

  return (
    <Table
      locale={{
        emptyText: `There are currently no ${type} projects to display`,
      }}
      key="propertiesTable"
      size="middle"
      bordered
      pagination={{
        current: currentPage,
        pageSize,
        total,
        onChange: handleChangePage,
        onShowSizeChange: handleShowSizeChange,
        position: total > defaultPageSize ? 'both' : 'bottom',
        showTotal: renderTotal(filtered),
        pageSizeOptions: ['25', '50', '100', '250', '500', '1000'],
        showSizeChanger: true,
      }}
      dataSource={properties}
      columns={columns}
      {...addons}
    />
  )
}
PropertiesTable.propTypes = {
  type: PropTypes.string.isRequired,
  account: PropTypes.object.isRequired,
  properties: PropTypes.shape({
    properties: PropTypes.arrayOf(PropTypes.object).isRequired,
    total: PropTypes.number.isRequired,
    filtered: PropTypes.number.isRequired,
    hasFilter: PropTypes.bool.isRequired,
  }).isRequired,
  actions: PropTypes.func.isRequired,
  expandedRow: PropTypes.func,
  updatePropertyById: PropTypes.func.isRequired,
  actionsWidth: PropTypes.number,
  queryParams: PropTypes.shape({
    get: PropTypes.func.isRequired,
    set: PropTypes.func.isRequired,
    update: PropTypes.func.isRequired,
  }).isRequired,
  currentPage: PropTypes.number.isRequired,
  setCurrentPage: PropTypes.func.isRequired,
  setPageSize: PropTypes.func.isRequired,
  pageSize: PropTypes.number.isRequired,
  defaultPageSize: PropTypes.number.isRequired,
}
PropertiesTable.defaultProps = {
  actionsWidth: 120,
  expandedRow: undefined,
}

export const useProperties = (account, filter, type) => {
  const accountProperties = useMemo(
    () =>
      account.properties
        .map((property) => ({ key: property.id, ...property }))
        .filter(type),
    [account.properties, type]
  )
  const properties = useMemo(() => {
    if (filter.projectName) {
      const search = filter.projectName.trim().toLowerCase()
      return accountProperties.filter((property) =>
        property.name?.toLowerCase().includes(search)
      )
    }
    return accountProperties
  }, [accountProperties, filter.projectName])
  return {
    properties,
    total: properties.length,
    filtered: accountProperties.length - properties.length,
    hasFilter: Boolean(filter.projectName) && filter.projectName?.length > 0,
  }
}
