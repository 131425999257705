/* eslint-disable react/destructuring-assignment */

import { Layout, Button, Form } from 'antd'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { StyleSheet, css } from 'aphrodite'

import { withState, compose, lifecycle, withHandlers } from 'recompose'
import { CreateSchemeModal } from '../../modals/CreateSchemeModal'
import { SchemeCard } from '../../common/SchemeCards'
import { SchemeList } from '../../common/SchemeList'
import { SchemeSelectionModal } from '../../modals/SchemeSelectionModal'
import {
  DELETE_SCHEME,
  ACCOUNT_SCHEME_SELECTIONS,
} from '../../../graphql/colors.graphql'
import { AuthenticationConsumer, SelectionConsumer } from '../../../contexts'

import {
  withConsumer,
  withMutationAsProp,
  withQueryResultAsProp,
} from '../../../utils'
import { SchemeFilter } from './SchemeFilter'

const { Item } = Form
const { Content } = Layout
const styles = StyleSheet.create({
  content: {
    backgroundColor: 'whitesmoke',
    marginBottom: '24px',
    minHeight: '50vh',
  },
  contentXs: {
    padding: '0',
  },
  header: {
    marginTop: '5px',
    paddingLeft: '12px',
  },
  layout: {
    background: '#fff',
    padding: '14px',
  },
  layoutXs: {
    padding: '8px',
  },
  singleForm: {
    marginTop: 12,
    padding: '12px 12px',
    minHeight: 300,
    overflowY: 'auto',
  },
  tabPane: {
    backgroundColor: 'white',
    padding: '22px 30px',
  },
})

class CreateSchemeForm extends Component {
  state = {
    addSchemeVisible: false,
    filter: {
      schemeName: undefined,
    },
  }

  handleCreateSchemeButtonPress = () => {
    this.setState({
      addSchemeVisible: true,
    })
  }

  handleAddSchemeCancel = () => {
    this.setState({
      addSchemeVisible: false,
    })
  }

  showSchemeElementForm = (scheme) => {
    this.props.selection.setCurrentSchemeId(scheme.id)
  }

  renderSchemeCard = (account, schemeId) => {
    const { schemes } = account
    const selectedScheme = schemes.filter((scheme) => scheme.id === schemeId)
    let theCard
    if (selectedScheme.length > 0) {
      theCard = (
        <SchemeCard
          key={selectedScheme[0].id}
          size="large"
          title={selectedScheme[0].name}
          scheme_id={selectedScheme[0].id}
          schemeElements={selectedScheme[0].scheme_elements}
          handleSelectSwatch2={(element) =>
            this.showSchemeElementForm(selectedScheme[0], element)
          }
          handleAddSwatch2={() =>
            this.showSchemeElementForm(selectedScheme[0], null)
          }
          handleEdit={() => this.showSchemeElementForm(selectedScheme[0])}
          handleDelete={() =>
            this.props.deleteScheme({ scheme_id: selectedScheme[0].id })
          }
        />
      )
    } else {
      theCard = null
    }
    return theCard
  }

  renderSchemeList = (account, setCurrentViewerId, currentViewerId) => {
    const { schemes } = account
    const search = this.state.filter.schemeName
    const filteredSchemes = search
      ? schemes.filter((scheme) =>
          scheme.name?.toLowerCase().includes(search?.toLowerCase())
        )
      : schemes
    const theList = (
      <SchemeList
        schemes={filteredSchemes}
        setCurrentViewerId={setCurrentViewerId}
        handleDelete={() =>
          this.props.deleteScheme({ scheme_id: currentViewerId })
        }
      />
    )
    return theList
  }

  setFilter = (value) => {
    this.setState({
      filter: value,
    })
  }

  render() {
    const { addSchemeVisible, communityFilter } = this.state
    const { selection, currentViewerId, setCurrentViewerId } = this.props
    return (
      <React.Fragment>
        <Content className={css(styles.content)}>
          <SchemeFilter filter={this.state.filter} setFilter={this.setFilter}>
            <Item>
              <Button
                type="primary"
                icon="plus"
                onClick={this.handleCreateSchemeButtonPress}
              >
                Create New Scheme
              </Button>
            </Item>
          </SchemeFilter>
          {addSchemeVisible && (
            <CreateSchemeModal
              visible={addSchemeVisible}
              handleClose={this.handleAddSchemeCancel}
            />
          )}
          {selection.currentSchemeId !== null && (
            <SchemeSelectionModal
              communityIdFilter={communityFilter}
              schemeId={selection.currentSchemeId}
            />
          )}
          {this.props.account !== undefined && (
            <div
              style={{
                height: '700px',
                overflow: 'auto',
              }}
            >
              {this.renderSchemeList(
                this.props.account,
                setCurrentViewerId,
                currentViewerId
              )}
              {currentViewerId !== null &&
                this.renderSchemeCard(this.props.account, currentViewerId)}
            </div>
          )}
        </Content>
      </React.Fragment>
    )
  }
}

CreateSchemeForm.defaultProps = {
  currentViewerId: null,
}

CreateSchemeForm.propTypes = {
  authentication: PropTypes.shape({
    token: PropTypes.string,
  }).isRequired,
  selection: PropTypes.shape({
    currentAccountId: PropTypes.number,
    setCurrentSchemeId: PropTypes.func,
    currentSubAccountId: PropTypes.number,
    currentSchemeId: PropTypes.number,
  }).isRequired,
  deleteScheme: PropTypes.func.isRequired,
  account: PropTypes.object.isRequired,
  setCurrentViewerId: PropTypes.func.isRequired,
  currentViewerId: PropTypes.number,
}

const WrappedComponent = compose(
  withConsumer(AuthenticationConsumer, { propName: 'authentication' }),
  withConsumer(SelectionConsumer, { propName: 'selection' }),
  withState('currentViewerId', 'setCurrentViewerId', null),
  withHandlers({
    handleSetViewer: (props) => () => {
      props.setCurrentViewerId(null)
    },
  }),
  lifecycle({
    componentDidMount() {
      const { handleSetViewer } = this.props
      document.addEventListener('load', handleSetViewer())
    },
    componentDidUpdate(prevProps) {
      if (
        this.props.selection.currentAccountId !==
        prevProps.selection.currentAccountId
      ) {
        this.props.setCurrentViewerId(null)
      }
    },
  }),
  withQueryResultAsProp({
    gqlDocument: ACCOUNT_SCHEME_SELECTIONS,
    variables: ({ selection }) => ({
      id: selection.currentSubAccountId || selection.currentAccountId,
    }),
    resultPropName: 'account',
    propName: 'account',
  }),
  withMutationAsProp({
    gqlDocument: DELETE_SCHEME,
    mutationPropName: 'deleteScheme',
    variables: ({
      // eslint-disable-next-line camelcase
      scheme_id,
    }) => ({ scheme_id }),
    refetchQueries: [
      {
        gqlDocument: ACCOUNT_SCHEME_SELECTIONS,
        variables: ({ selection }) => ({
          id: selection.currentSubAccountId || selection.currentAccountId,
        }),
      },
    ],
  })
)(CreateSchemeForm)

export { WrappedComponent as CreateSchemeForm }
