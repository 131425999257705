import React from 'react'
import PropTypes from 'prop-types'
import { Skeleton as AntdSkeleton } from 'antd'

export default function Skeleton({ style, ...rest }) {
  return (
    <>
      {/* We need an instance of Skeleton to be on the page so that antd styles are loaded */}
      <div style={{ display: 'none' }}>
        <AntdSkeleton />
      </div>
      <div
        {...rest}
        className={`ant-skeleton ant-skeleton-active ${rest.className ?? ''}`}
        style={style}
      >
        <div
          className="ant-skeleton-avatar"
          style={{ width: '100%', height: '100%' }}
        />
      </div>
    </>
  )
}

Skeleton.propTypes = {
  style: PropTypes.object,
}
Skeleton.defaultProps = {
  style: {},
}
