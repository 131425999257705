import React from 'react'
import { PropTypes } from 'prop-types'
import { Modal, Form, Input, Button, message } from 'antd'
import { useMutation } from '@apollo/client'
import { ADD_LAYER, UPDATE_LAYER } from '../../../graphql/fcLayers.graphql'
import { layerModalModes, layerModalModesPropType } from '../const/layerModal'

function LayerConfirmationDialog({
  form,
  layer,
  account,
  layerImage,
  room,
  mask,
  swatch,
  settings,
  onCancel,
  onSubmit,
  mode,
}) {
  const { getFieldDecorator } = form

  const [addLayer, { loading: addLoading }] = useMutation(ADD_LAYER)
  const [updateLayer, { loading: updateLoading }] = useMutation(UPDATE_LAYER)
  const submitting = addLoading || updateLoading
  const editMode = mode === layerModalModes.update && !!layer
  const upsertLayer = editMode ? updateLayer : addLayer

  async function handleSubmit(close) {
    let input = null

    try {
      input = await form.validateFields()
    } catch (e) {
      message.error('Form is not valid!')
      console.error(e)
      return
    }

    try {
      // TODO: Have the production canvas return a Blob instead of a HTMLImageElement
      const blob = await fetch(layerImage).then((res) => res.blob())

      await upsertLayer({
        variables: {
          accountId: account.id,
          id: layer?.id,
          input: {
            name: input.name,
            imageFile: blob,
            roomId: room.id,
            maskId: mask.id,
            swatchId: swatch.id,
            setting: {
              renderIndex: 0,
              settings: JSON.stringify(settings),
            },
          },
        },
        update(cache, value) {
          cache.evict({ fieldName: 'layers' })
          const roomIdentity = cache.identify(room)
          const valueIdentity = cache.identify(value)

          cache.modify({
            id: roomIdentity,
            fields: {
              layers(field) {
                return [...field, { __ref: valueIdentity }]
              },
            },
          })
        },
      })

      message.success(`Flooring ${input.name} was successfully added!`)
      onSubmit(close)
    } catch (e) {
      message.error('Flooring was not updated!')
      console.error(e)
    }
  }

  return (
    <Modal
      title={editMode ? 'Edit flooring' : 'Add flooring'}
      onCancel={() => !submitting && onCancel()}
      visible
      footer={
        <>
          <Button disabled={submitting} onClick={onCancel}>
            Cancel
          </Button>
          {editMode ? (
            <Button
              loading={submitting}
              type="primary"
              onClick={() => handleSubmit(true)}
            >
              Submit
            </Button>
          ) : (
            <>
              <Button
                loading={submitting}
                type="primary"
                onClick={() => handleSubmit(false)}
              >
                Submit &amp; Continue
              </Button>
              <Button
                loading={submitting}
                type="primary"
                onClick={() => handleSubmit(true)}
              >
                Submit &amp; Close
              </Button>
            </>
          )}
        </>
      }
    >
      <Form>
        <Form.Item label="Name">
          {getFieldDecorator('name', {
            initialValue: layer?.name ?? '',
            rules: [
              {
                required: true,
                message: 'Please input flooring name!',
              },
              {
                max: 255,
                message: 'Maximum name size is 255!',
              },
            ],
          })(<Input placeholder="Office carpet, Corridor pavement, ..." />)}
        </Form.Item>
        <Form.Item label="Preview">
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              maxHeight: 250,
              border: '1px solid #d9d9d9',
              borderRadius: 4,
            }}
          >
            <img
              src={layerImage}
              style={{ maxWidth: '100%', maxHeight: 'inherit' }}
              alt={layer?.name ?? 'Preview Image'}
            />
          </div>
        </Form.Item>
      </Form>
    </Modal>
  )
}

LayerConfirmationDialog.propTypes = {
  form: PropTypes.object.isRequired,
  layer: PropTypes.object,
  account: PropTypes.object.isRequired,
  layerImage: PropTypes.string.isRequired,
  room: PropTypes.object.isRequired,
  mask: PropTypes.object.isRequired,
  swatch: PropTypes.object.isRequired,
  settings: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  mode: layerModalModesPropType.isRequired,
}

LayerConfirmationDialog.defaultProps = {
  layer: null,
}

export default Form.create({ name: 'confirm_layer' })(LayerConfirmationDialog)
