import React from 'react'
import PropTypes from 'prop-types'
import { css, StyleSheet } from 'aphrodite/no-important'
import { useSelectionContext } from '../../../contexts'
import SelectAccount from '../../common/SelectAccount'
import { Filter, Input } from '../../common/Filter'

const classes = StyleSheet.create({
  root: {
    padding: '0 10px',
  },
  row: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    width: '100%',
  },
  form: {
    width: '100%',
  },
  formItem: {
    marginBottom: 4,
  },
  label: {
    minWidth: 130,
  },
  input: {
    minWidth: 240,
  },
  updateButton: {
    marginLeft: 'auto',
  },
})

export default function SwatchAssignmentsFilter({ accounts, filters }) {
  const { currentAccountId, currentSubAccountId, hasAccountSubAccount } =
    useSelectionContext()
  const { swatchName, setSwatchName, projectName, setProjectName } = filters

  const filter = {
    swatchName: {
      value: swatchName,
      set: (name) => setSwatchName(name),
    },
    projectName: {
      value: projectName,
      set: (name) => setProjectName(name),
    },
  }

  const shouldDisplayNameFilters =
    (!hasAccountSubAccount && currentAccountId) ||
    (hasAccountSubAccount && currentSubAccountId)

  return (
    <div className={css(classes.root)}>
      <div className={css(classes.row)}>
        <SelectAccount
          formClass={classes.form}
          formItemClass={classes.formItem}
          labelClass={classes.label}
          selectClass={classes.input}
          accounts={accounts}
        />
      </div>
      {shouldDisplayNameFilters && (
        <Filter data={filter} loading={false}>
          <Input
            field="swatchName"
            label="Swatch Name"
            placeholder="Search by swatch name ..."
            inputClass={classes.input}
            labelClass={classes.label}
          />
          <Input
            field="projectName"
            label="Project Name"
            placeholder="Search by project name ..."
            inputClass={classes.input}
            labelClass={classes.label}
          />
        </Filter>
      )}
    </div>
  )
}

SwatchAssignmentsFilter.propTypes = {
  accounts: PropTypes.array,
  filters: PropTypes.shape({
    swatchName: PropTypes.string.isRequired,
    setSwatchName: PropTypes.func.isRequired,
    projectName: PropTypes.string.isRequired,
    setProjectName: PropTypes.func.isRequired,
  }).isRequired,
}
SwatchAssignmentsFilter.defaultProps = {
  accounts: [],
}
