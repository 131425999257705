import { Collapse, Form, Select, message } from 'antd'

import { css, StyleSheet } from 'aphrodite/no-important'
import changeCase from 'change-case'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { compose } from 'recompose'

import { RepsForm } from './RepsForm'

import {
  UPDATE_PROPERTY_BY_ID,
  PROPERTY_AND_ACCOUNT_BY_PROPERTY_ID,
} from '../../../graphql/properties.graphql'

import { REPS } from '../../../graphql/reps.graphql'

import {
  UPLOAD_PAINT_GUIDE,
  DELETE_PAINT_GUIDE,
} from '../../../graphql/uploads.graphql'

import {
  generateUpdateFormItemInput,
  generatePaintGuideUploadAndDeleteItem,
  generateDownloadBrochureButton,
  generateUnsafeUpdateFormItemInput,
  generateUpdateFormItemInputNumber,
  generateUpdateFormItemSelect,
  withEnumValuesAsProp,
  withMutationAsProp,
  withQueryResultAsProp,
} from '../../../utils'
import { RegexPatterns } from '../../../const/regex'

const { Panel } = Collapse
const { Item } = Form
const { Option } = Select

const styles = StyleSheet.create({
  updatePropertyForm: {
    padding: '0px 10px 0px 10px',
    margin: 'auto',
  },
})

const updatePropertyFormItemLayout = {
  labelCol: {
    // 1600px ≤ width
    xxl: {
      span: 6,
    },
    // 1200px ≤ width < 1600px
    xl: {
      span: 6,
    },
    // 992px ≤ width < 1200px
    lg: {
      span: 6,
    },
    // 768px ≤ width < 992px
    md: {
      span: 8,
    },
    // 576px ≤ width < 768px
    sm: {
      span: 14,
    },
    // width < 576px and also default setting
    xs: {
      span: 24,
    },
  },
  wrapperCol: {
    xxl: {
      span: 18,
    },
    xl: {
      span: 18,
    },
    lg: {
      span: 18,
    },
    md: {
      span: 16,
    },
    sm: {
      span: 10,
    },
    xs: {
      span: 24,
    },
  },
}

class UpdatePropertyForm extends Component {
  static propTypes = {
    divisions: PropTypes.arrayOf(PropTypes.string).isRequired,
    property: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string,
      project_id: PropTypes.string,
      division: PropTypes.string,
      street_address: PropTypes.string,
      latitude: PropTypes.number,
      longitude: PropTypes.number,
      is_active: PropTypes.bool,
      is_finalized: PropTypes.bool,
      paint_guide_url: PropTypes.string,
      paint_guide_name: PropTypes.string,
      account: PropTypes.shape({
        id: PropTypes.number.isRequired,
        account_name: PropTypes.string.isRequired,
        account_type: PropTypes.string.isRequired,
      }).isRequired,
      rep: PropTypes.shape({
        id: PropTypes.number,
      }),
    }).isRequired,
    reps: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        full_name: PropTypes.string,
        greeting_name: PropTypes.string,
        email: PropTypes.string,
        phone: PropTypes.string,
      })
    ).isRequired,
    updatePropertyById: PropTypes.func.isRequired,
    uploadPaintGuide: PropTypes.func.isRequired,
    deletePaintGuide: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)

    this.state = {
      formErrors: {
        name: null,
        project_id: null,
      },
      isDownloadButtonLoading: false,
    }
  }

  static defaultValue = {
    property: {
      paint_guide_url: null,
      paint_guide_name: null,
    },
  }

  handleUpdatePropertyFormItemInputChange = (
    propertyId,
    targetField,
    label,
    value
  ) => {
    if (value && typeof value === 'string') {
      const containsSpecialCharacter = !value.match(
        RegexPatterns.noSpecialCharacterExcludeHyphenAndPostrophe
      )
      this.setState({
        formErrors: {
          [targetField]: containsSpecialCharacter
            ? 'Please remove special characters! (hyphen is allowed)'
            : null,
        },
      })

      if (containsSpecialCharacter) {
        message.error('Form is not valid!')
        return
      }
    }

    if (!value) {
      this.setState({
        formErrors: {
          [targetField]: null,
        },
      })
    }

    console.log(
      `handleUpdatePropertyFormItemInputChange: propertyId: ${propertyId}, targetField: ${targetField}, label: ${label}, value: ${value}`
    )
    const { updatePropertyById } = this.props
    updatePropertyById({
      id: propertyId,
      input: { [targetField]: value },
    })
  }

  handleDownloadBrochure = async (property) => {
    this.setState({
      isDownloadButtonLoading: true,
    })

    const url = `${process.env.INGLENOOK_SERVER}/api/cv/pdf`
    const getTheBrochure = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        dst: `${process.env.FRONT_END_APP_SERVER}/account/${encodeURIComponent(
          property.account.account_name
        )}/project/${property.id}/print/brochure?allSchemes=true`,
        clientName: property.account.account_name,
        communityName: property.name,
        isPortrait: true,
        paintGuideUrl: null,
      }),
    })

    const blob = await getTheBrochure.blob()
    const newBlob = new Blob([blob], { type: 'application/pdf' })

    // for IE
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(newBlob)
      return
    }

    const data = window.URL.createObjectURL(newBlob)
    const link = document.createElement('a')
    link.href = data
    link.download = `${property.name}_brochure.pdf`
    link.click()

    this.setState({
      isDownloadButtonLoading: false,
    })
  }

  render = () => {
    const { divisions, property, reps, uploadPaintGuide, deletePaintGuide } =
      this.props

    const { formErrors, isDownloadButtonLoading } = this.state
    const {
      id: propertyId,
      paint_guide_name: paintGuideName,
      paint_guide_url: paintGuideURL,
    } = property
    return (
      <React.Fragment>
        <Form
          key="updatePropertyForm"
          className={css(styles.updatePropertyForm)}
        >
          {generateUpdateFormItemInput(
            this.handleUpdatePropertyFormItemInputChange,
            updatePropertyFormItemLayout,
            property.id,
            property.name,
            'name',
            'Project Name',
            false,
            formErrors.name
          )}
          {generateUpdateFormItemSelect(
            this.handleUpdatePropertyFormItemInputChange,
            updatePropertyFormItemLayout,
            property.id,
            divisions,
            (division) => division,
            (division) => changeCase.titleCase(division),
            false,
            property.division,
            'division',
            'Division'
          )}
          {generateUpdateFormItemInput(
            this.handleUpdatePropertyFormItemInputChange,
            updatePropertyFormItemLayout,
            property.id,
            property.project_id,
            'project_id',
            'Project ID (optional)',
            false,
            formErrors.project_id
          )}
          <Item label="Rep (optional)" {...updatePropertyFormItemLayout}>
            <Select
              key={`${property.id}${property.rep && property.rep.id}`}
              defaultValue={property.rep && property.rep.id}
              onBlur={(value) =>
                this.handleUpdatePropertyFormItemInputChange(
                  property.id,
                  'rep_id',
                  'Rep (optional)',
                  value
                )
              }
              onChange={(value) =>
                this.handleUpdatePropertyFormItemInputChange(
                  property.id,
                  'rep_id',
                  'Rep (optional)',
                  value
                )
              }
              onPressEnter={(evt) => evt.target.blur()}
            >
              <Option value={null}>Select an option.</Option>
              {reps.map((rep) => (
                <Option
                  key={`${property.id}${property.rep && property.rep.id}${
                    rep.id
                  }`}
                  value={rep.id}
                >
                  {rep.full_name}
                </Option>
              ))}
            </Select>
            <Collapse>
              <Panel header="Manage Reps" key="1">
                <RepsForm />
              </Panel>
            </Collapse>
          </Item>

          {generateUnsafeUpdateFormItemInput(
            this.handleUpdatePropertyFormItemInputChange,
            updatePropertyFormItemLayout,
            property.id,
            property.street_address,
            'street_address',
            'Street Address (optional)'
          )}
          {generateUpdateFormItemInputNumber(
            this.handleUpdatePropertyFormItemInputChange,
            updatePropertyFormItemLayout,
            property.id,
            property.latitude,
            'latitude',
            'Latitude (optional)',
            { min: -90, max: 90, step: 0.0001 }
          )}
          {generateUpdateFormItemInputNumber(
            this.handleUpdatePropertyFormItemInputChange,
            updatePropertyFormItemLayout,
            property.id,
            property.longitude,
            'longitude',
            'Longitude (optional)',
            { min: -180, max: 180, step: 0.0001 }
          )}
          {generatePaintGuideUploadAndDeleteItem({
            paintGuideName,
            paintGuideURL,
            itemLayout: updatePropertyFormItemLayout,
            handleUpload: uploadPaintGuide,
            accountName: property.account.account_name,
            propertyId,
            handleDelete: deletePaintGuide,
          })}
          {generateDownloadBrochureButton({
            itemLayout: updatePropertyFormItemLayout,
            handleDownloadBrochure: () => {
              this.handleDownloadBrochure(property)
            },
            isLoading: isDownloadButtonLoading,
          })}
        </Form>
      </React.Fragment>
    )
  }
}

const WrappedComponent = compose(
  withEnumValuesAsProp({
    typeName: 'Division',
    propName: 'divisions',
  }),
  withQueryResultAsProp({
    gqlDocument: REPS,
    resultPropName: 'reps',
    transformFunc: (reps) =>
      [...reps].sort((a, b) => a.full_name.localeCompare(b.full_name)),
  }),
  withQueryResultAsProp({
    gqlDocument: PROPERTY_AND_ACCOUNT_BY_PROPERTY_ID,
    variables: ({ propertyId }) => ({ propertyId }),
    resultPropName: 'property',
  }),
  withMutationAsProp({
    gqlDocument: UPDATE_PROPERTY_BY_ID,
    mutationPropName: 'updatePropertyById',
    refetchQueries: [
      {
        gqlDocument: PROPERTY_AND_ACCOUNT_BY_PROPERTY_ID,
        variables: ({ propertyId }) => ({ propertyId }),
      },
    ],
  }),
  withMutationAsProp({
    gqlDocument: UPLOAD_PAINT_GUIDE,
    mutationPropName: 'uploadPaintGuide',
    refetchQueries: [
      {
        gqlDocument: PROPERTY_AND_ACCOUNT_BY_PROPERTY_ID,
        variables: ({ propertyId }) => ({ propertyId }),
      },
    ],
  }),
  withMutationAsProp({
    gqlDocument: DELETE_PAINT_GUIDE,
    mutationPropName: 'deletePaintGuide',
    refetchQueries: [
      {
        gqlDocument: PROPERTY_AND_ACCOUNT_BY_PROPERTY_ID,
        variables: ({ propertyId }) => ({ propertyId }),
      },
    ],
  }),
  Form.create()
)(UpdatePropertyForm)

export { WrappedComponent as UpdatePropertyForm }
