import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Typography } from 'antd'
import { useQuery } from '@apollo/client'
import { StyleSheet, css } from 'aphrodite'
import { blue } from '@ant-design/colors'
import ModalDrawer from '../../../common/ModalDrawer'
import { MASKS_BY_ROOM_ID } from '../../../../graphql/fcMasks.graphql'
import { getMaskCloudinaryPath } from '../../functions/getMaskCloudinaryPath'
import SmallCard from '../../../common/SmallCard'

const classes = StyleSheet.create({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  card: {
    width: 100,
    maxHeight: 150,
    margin: 4,
    cursor: 'pointer',
    overflow: 'hidden',
    ':before': {
      content: "' '",
      width: '100%',
      height: '100px',
      position: 'absolute',
      transition: '300ms',
      border: '2px solid transparent',
    },
  },
  activeCard: {
    ':before': {
      border: `2px solid ${blue.primary}`,
      backgroundColor: 'rgba(0, 0, 0, .3)',
    },
  },
  meta: {
    padding: 5,
  },
  icon: {
    fontSize: 25,
    position: 'absolute',
    top: 6,
    right: -90,
    color: '#fff',
    transition: '350ms',
  },
  activeIcon: {
    right: 6,
  },
})

export default function MaskDrawer({
  visible,
  setVisible,
  room,
  selectedMaskId,
  cloudFolder,
  onSelect,
}) {
  const { data, loading } = useQuery(MASKS_BY_ROOM_ID, {
    variables: {
      id: room.id,
      pagination: {
        page: 0,
        size: Number.MAX_SAFE_INTEGER,
      },
    },
  })

  const masks = useMemo(
    () => (loading ? new Array(9).fill({}) : data?.masksByRoomId ?? []),
    [loading, data?.masksByRoomId]
  )

  return (
    <ModalDrawer
      title="Select Mask"
      visible={visible}
      setVisible={setVisible}
      getContainer="#layer-modal"
    >
      <Typography.Title level={4}>Masks</Typography.Title>
      <div className={css(classes.root)}>
        {masks.map((mask) => (
          <SmallCard
            key={mask?.id}
            loading={loading}
            selected={!loading && selectedMaskId === mask?.id}
            data={{
              ...mask,
              name: mask?.material?.display_name,
              imageSrc: getMaskCloudinaryPath(
                cloudFolder,
                mask?.image ?? 'generic',
                room.id,
                'w_90,h_90,c_fill'
              ),
            }}
            onSelect={onSelect}
          />
        ))}
      </div>
    </ModalDrawer>
  )
}

MaskDrawer.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  room: PropTypes.object.isRequired,
  selectedMaskId: PropTypes.number,
  cloudFolder: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
}

MaskDrawer.defaultProps = {
  selectedMaskId: null,
}
