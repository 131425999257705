import { Button, Form, Icon, Popconfirm, Table, Input, message } from 'antd'
import { css, StyleSheet } from 'aphrodite/no-important'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { compose } from 'recompose'

import {
  ADD_REP,
  DELETE_REP_BY_ID,
  REPS,
  UPDATE_REP_BY_ID,
} from '../../../graphql/reps.graphql'

import {
  generateUnsafeAddFormItemOptionalInput,
  generateAddFormItemRequiredInput,
  generateUnsafeUpdateFormItemInput,
  generateUpdateFormItemInput,
  withMutationAsProp,
  withQueryResultAsProp,
} from '../../../utils'

const { Item } = Form
const { Search } = Input

const styles = StyleSheet.create({
  addRepForm: {
    padding: '0px 10px 0px 10px',
    marginBottom: '10px',
  },
  updateRepForm: {
    padding: '0px 10px 0px 10px',
    margin: 'auto',
  },
  searchInput: {
    margin: '0 0 0 10px',
    width: '400px',
  },
})

const updateRepFormItemLayout = {
  labelCol: {
    // 1600px ≤ width
    xxl: {
      span: 3,
    },
    // 1200px ≤ width < 1600px
    xl: {
      span: 4,
    },
    // 992px ≤ width < 1200px
    lg: {
      span: 5,
    },
    // 768px ≤ width < 992px
    md: {
      span: 7,
    },
    // 576px ≤ width < 768px
    sm: {
      span: 24,
    },
    // width < 576px and also default setting
    xs: {
      span: 24,
    },
  },
  wrapperCol: {
    xxl: {
      span: 21,
    },
    xl: {
      span: 20,
    },
    lg: {
      span: 19,
    },
    md: {
      span: 17,
    },
    sm: {
      span: 24,
    },
    xs: {
      span: 24,
    },
  },
}

class RepsForm extends Component {
  static propTypes = {
    form: PropTypes.object.isRequired,
    addRep: PropTypes.func.isRequired,
    deleteRepById: PropTypes.func.isRequired,
    updateRepById: PropTypes.func.isRequired,
    reps: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        first_name: PropTypes.string,
        last_name: PropTypes.string,
      })
    ).isRequired,
  }

  constructor(props) {
    super(props)

    this.state = {
      pageSize: 25,
      lowerSearchText: '',
    }
  }

  handleAddRepButtonPress = () => {
    const { addRep, form } = this.props
    // eslint-disable-next-line camelcase
    form.validateFieldsAndScroll(
      async (errors, { full_name, greeting_name, email, phone }) => {
        if (!errors) {
          console.log('handleAddRepButtonPress: Using form values: ', {
            full_name,
            greeting_name,
            email,
            phone,
          })
          form.resetFields()
          // Create rep. If the rep is created, but also throws an error or we
          // lose the connection, we will not set the password, and the rep will
          // stay in a the default NEEDS_PASSWORD state.
          const result = await addRep({
            input: {
              full_name: full_name?.trim(),
              greeting_name: greeting_name?.trim(),
              email: email?.trim(),
              phone: phone?.trim(),
            },
          })
          console.log('addRep result:', result)
        }
      }
    )
  }

  handleDeleteRepButtonPress = (repId) => {
    console.log('handleDeleteRepButtonPress: repId:', repId)
    const { deleteRepById } = this.props
    deleteRepById({
      id: repId,
    })
  }

  handleUpdateRepFormItemInputChange = (repId, targetField, label, value) => {
    console.log(
      `handleUpdateRepFormItemInputChange: repId: ${repId}, targetField: ${targetField}, label: ${label}, value: ${value}`
    )
    const { updateRepById } = this.props
    const trimmedValue = value?.trim()

    if (targetField === 'full_name' && !trimmedValue) {
      message.error('Full Name can not be empty value!')
      return
    }
    if (targetField === 'greeting_name' && !trimmedValue) {
      message.error('Greeting Name can not be empty value!')
      return
    }

    updateRepById({
      id: repId,
      input: {
        [targetField]:
          trimmedValue && trimmedValue.length > 0 ? trimmedValue : null,
      },
    })
  }

  handleShowSizeChange = (pageNumber, pageSize) => {
    this.setState({
      pageSize,
    })
  }

  handleSearchFieldChange = (e) => {
    this.setState({
      lowerSearchText: e.target.value.trim().toLowerCase(),
    })
  }

  showTotal = (total, range) => {
    return `${range[0]}-${range[1]} of ${total} Reps`
  }

  getTableDataSource = () => {
    const { reps } = this.props
    const { lowerSearchText } = this.state

    const filteredReps = lowerSearchText
      ? reps.filter((rep) => {
          return (
            rep.email?.toLowerCase().includes(lowerSearchText) ||
            rep.full_name?.toLowerCase().includes(lowerSearchText)
          )
        })
      : reps

    return filteredReps.map((rep) => ({ key: rep.id, ...rep }))
  }

  render() {
    const { form } = this.props
    const { getFieldDecorator } = form
    const { pageSize } = this.state
    const { getTableDataSource } = this

    return (
      <React.Fragment>
        <Form
          key="addRepForm"
          layout="inline"
          className={css(styles.addRepForm)}
        >
          {generateAddFormItemRequiredInput(
            getFieldDecorator,
            'full_name',
            '* Full Name'
          )}
          {generateAddFormItemRequiredInput(
            getFieldDecorator,
            'greeting_name',
            '* Name to Greet with'
          )}
          {generateUnsafeAddFormItemOptionalInput(
            getFieldDecorator,
            'email',
            'Email'
          )}
          {generateUnsafeAddFormItemOptionalInput(
            getFieldDecorator,
            'phone',
            'Phone'
          )}
          <Item>
            <Button type="primary" onClick={this.handleAddRepButtonPress}>
              <Icon type="user-add" />
              Add Rep
            </Button>
          </Item>
        </Form>

        <Search
          className={css(styles.searchInput)}
          placeholder="Search by Full Name of Email"
          onChange={this.handleSearchFieldChange}
        />

        <Table
          key="repsTable"
          size="middle"
          bordered
          pagination={{
            pageSize,
            onShowSizeChange: this.handleShowSizeChange,
            position: 'both',
            showTotal: this.showTotal,
            pageSizeOptions: ['10', '25', '50', '100'],
            showSizeChanger: true,
          }}
          dataSource={getTableDataSource()}
          columns={[
            {
              title: 'Full Name',
              dataIndex: 'full_name',
              key: 'full_name',
              defaultSortOrder: 'ascend',
              sorter: (a, b) => a.full_name.localeCompare(b.full_name),
            },
            {
              title: 'Greeting Name',
              dataIndex: 'greeting_name',
              key: 'greeting_name',
              sorter: (a, b) => a.greeting_name.localeCompare(b.greeting_name),
            },
            {
              title: 'Email',
              dataIndex: 'email',
              key: 'email',
              sorter: (a, b) => a.email.localeCompare(b.email),
            },
            {
              title: 'Phone',
              dataIndex: 'phone',
              key: 'phone',
              sorter: (a, b) => a.phone.localeCompare(b.phone),
            },
            {
              key: 'delete',
              width: 150,
              render: (text, rep) => (
                <Popconfirm
                  placement="bottomRight"
                  title="Delete this rep?"
                  onConfirm={() => this.handleDeleteRepButtonPress(rep.id)}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button type="danger">
                    <Icon type="delete" />
                  </Button>
                </Popconfirm>
              ),
            },
          ]}
          expandedRowRender={(rep) => (
            <Form key="updateRepForm" className={css(styles.updateRepForm)}>
              {generateUpdateFormItemInput(
                this.handleUpdateRepFormItemInputChange,
                updateRepFormItemLayout,
                rep.id,
                rep.full_name,
                'full_name',
                'Full Name'
              )}
              {generateUpdateFormItemInput(
                this.handleUpdateRepFormItemInputChange,
                updateRepFormItemLayout,
                rep.id,
                rep.greeting_name,
                'greeting_name',
                'Name to Greet with'
              )}
              {generateUnsafeUpdateFormItemInput(
                this.handleUpdateRepFormItemInputChange,
                updateRepFormItemLayout,
                rep.id,
                rep.email,
                'email',
                'Email'
              )}
              {generateUnsafeUpdateFormItemInput(
                this.handleUpdateRepFormItemInputChange,
                updateRepFormItemLayout,
                rep.id,
                rep.phone,
                'phone',
                'Phone'
              )}
            </Form>
          )}
        />
      </React.Fragment>
    )
  }
}

const WrappedComponent = compose(
  withQueryResultAsProp({
    gqlDocument: REPS,
    resultPropName: 'reps',
  }),
  withMutationAsProp({
    gqlDocument: ADD_REP,
    mutationPropName: 'addRep',
    refetchQueries: [{ gqlDocument: REPS }],
  }),
  withMutationAsProp({
    gqlDocument: DELETE_REP_BY_ID,
    mutationPropName: 'deleteRepById',
    refetchQueries: [{ gqlDocument: REPS }],
  }),
  withMutationAsProp({
    gqlDocument: UPDATE_REP_BY_ID,
    mutationPropName: 'updateRepById',
  }),
  Form.create()
)(RepsForm)

export { WrappedComponent as RepsForm }
