import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useQuery } from '@apollo/client'
import { LAYER_MATERIALS } from '../../../graphql/fcMaterials.graphql'
import Table from '../../common/Table'
import Alert from '../../common/Alert'
import MaterialTableActions from './MaterialTableActions'

const columns = (accountId) => [
  {
    title: 'Name',
    dataIndex: 'displayName',
    key: 'displayName',
    width: 250,
  },
  {
    title: 'Actions',
    dataIndex: 'actions',
    key: 'actions',
    render(_, material) {
      return <MaterialTableActions accountId={accountId} material={material} />
    },
    width: 200,
  },
]

const TABLE_ITEMS_PER_PAGE = 10
export default function MaterialTable({ accountId, filterName }) {
  const [currentPage, setCurrentPage] = useState(0)

  const { data, loading, error } = useQuery(LAYER_MATERIALS, {
    variables: {
      pagination: {
        page: currentPage,
        size: TABLE_ITEMS_PER_PAGE,
      },
      ordering: {
        columns: [{ name: 'display_name', order: 'ASC' }],
      },
      filter: { accountId, displayName: filterName },
    },
    fetchPolicy: 'cache-and-network',
  })

  if (error) {
    return <Alert margin />
  }

  return (
    <Table
      loading={loading}
      columns={columns(accountId)}
      data={data?.layerMaterials.items}
      emptyText="There are currently no active flooring materials to display"
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      totalCount={data?.layerMaterials.totalCount}
      itemsPerPage={TABLE_ITEMS_PER_PAGE}
    />
  )
}

MaterialTable.propTypes = {
  accountId: PropTypes.number.isRequired,
  filterName: PropTypes.string.isRequired,
}
MaterialTable.defaultProps = {}
