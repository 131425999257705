import { Button, Form, Icon, Popconfirm, Table } from 'antd'
import { css, StyleSheet } from 'aphrodite/no-important'
import changeCase from 'change-case'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { compose } from 'recompose'

import { StyledDropzone } from '../../common/StyledDropzone'

import { AuthenticationConsumer, SelectionConsumer } from '../../../contexts'

import {
  ADD_ACCOUNT,
  DELETE_ACCOUNT_BY_ID,
  UPDATE_ACCOUNT_BY_ID,
  ACCOUNTS,
} from '../../../graphql/accounts.graphql'

import { UPLOAD_FILE } from '../../../graphql/uploads.graphql'

import {
  generateAddFormItemRequiredInput,
  generateAddFormItemRequiredSelect,
  generateUnsafeUpdateFormItemInput,
  generateUpdateFormItemInput,
  generateUpdateFormItemSelect,
  withConsumer,
  withEnumValuesAsProp,
  withMutationAsProp,
  withQueryResultAsProp,
} from '../../../utils'

import SubAccountsForm from './SubAccountsForm'

const { Item } = Form

const styles = StyleSheet.create({
  addAccountForm: {
    border: '1px solid #d8dee2',
    borderRadius: '10px',
    padding: '0px 10px 0px 10px',
    margin: 'auto',
  },
  dropzoneBaseStyle: {
    width: 150,
    height: 150,
    borderWidth: 2,
    borderColor: 'lightgray',
    borderStyle: 'dashed',
    borderRadius: 5,
    backgroundColor: 'darkgray',
    float: 'left',
  },
  updateAccountForm: {
    padding: '0px 10px 0px 10px',
    margin: 'auto',
  },
  dropZoneButton: {
    position: 'absolute',
    top: '110px',
    marginLeft: '20px',
  },
})

const updateAccountFormItemLayout = {
  labelCol: {
    // 1600px ≤ width
    xxl: {
      span: 3,
    },
    // 1200px ≤ width < 1600px
    xl: {
      span: 4,
    },
    // 992px ≤ width < 1200px
    lg: {
      span: 4,
    },
    // 768px ≤ width < 992px
    md: {
      span: 6,
    },
    // 576px ≤ width < 768px
    sm: {
      span: 6,
    },
    // width < 576px and also default setting
    xs: {
      span: 24,
    },
  },
  wrapperCol: {
    xxl: {
      span: 21,
    },
    xl: {
      span: 20,
    },
    lg: {
      span: 20,
    },
    md: {
      span: 18,
    },
    sm: {
      span: 18,
    },
    xs: {
      span: 24,
    },
  },
}

class AccountsForm extends Component {
  static propTypes = {
    form: PropTypes.object.isRequired,
    addAccount: PropTypes.func.isRequired,
    authentication: PropTypes.shape({
      appConfig: PropTypes.object.isRequired,
      token: PropTypes.string,
    }).isRequired,
    deleteAccountById: PropTypes.func.isRequired,
    updateAccountById: PropTypes.func.isRequired,
    accountTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
    accounts: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        account_name: PropTypes.string,
        prospect_number: PropTypes.string,
        account_type: PropTypes.string,
        contact_first_name: PropTypes.string,
        contact_last_name: PropTypes.string,
        phone: PropTypes.string,
        email: PropTypes.string,
      })
    ).isRequired,
    selection: PropTypes.shape({
      currentAccountId: PropTypes.number,
      setCurrentAccountId: PropTypes.func,
      setCurrentSubAccountId: PropTypes.func,
    }).isRequired,
    uploadFile: PropTypes.func.isRequired,
  }

  constructor() {
    super()
    this.state = { previews: {} }
  }

  handleAddAccountButtonPress = (parentId) => {
    const { addAccount, form, selection } = this.props
    form.validateFieldsAndScroll(
      async (
        errors,
        {
          // eslint-disable-next-line camelcase
          account_name,
          // eslint-disable-next-line camelcase
          prospect_number,
          // eslint-disable-next-line camelcase
          account_type,
        }
      ) => {
        // Set the default cloud folder name for a new account.
        const cloudFolderName = `cv/shw/${changeCase.snakeCase(account_name)}`
        console.log(
          'handleAddAccountButtonPress: Using new cloud folder name:',
          cloudFolderName
        )
        if (!errors) {
          form.resetFields()
          const mutationResult = await addAccount({
            input: {
              account_name,
              cloud_folder_name: cloudFolderName,
              prospect_number,
              account_type,
              parent_id: parentId,
            },
          })

          if (
            mutationResult &&
            mutationResult.data &&
            mutationResult.data.addAccount
          ) {
            if (parentId) {
              selection.setCurrentAccountId(parentId)
              selection.setCurrentSubAccountId(
                mutationResult.data.addAccount.id
              )
            } else {
              selection.setCurrentAccountId(mutationResult.data.addAccount.id)
              selection.setCurrentSubAccountId(null)
            }
          }
        }
      }
    )
  }

  handleOnDrop =
    ({
      folder = 'color_visualizer',
      accountId,
      targetField,
      label,
      fileNameToDelete,
    } = {}) =>
    async (acceptedFiles, rejectedFiles) => {
      console.log(
        'acceptedFiles:',
        acceptedFiles,
        ', rejectedFiles:',
        rejectedFiles,
        ', accountId:',
        accountId,
        ', targetField:',
        targetField,
        ', label:',
        label,
        ', fileNameToDelete:',
        fileNameToDelete
      )
      const { uploadFile, updateAccountById } = this.props

      const dataURL = await new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onload = () => {
          resolve(reader.result)
        }
        reader.onerror = reject
        reader.readAsDataURL(acceptedFiles[0])
      })

      this.setState((state) => ({
        previews: {
          ...state.previews,
          [`${accountId}${targetField}`]: dataURL,
        },
      }))

      const droppedFileSize = acceptedFiles[0].size
      let uploadFileResult
      try {
        uploadFileResult = await uploadFile({
          folder,
          file: acceptedFiles[0],
          file_name_to_delete: fileNameToDelete,
        })
      } catch (error) {
        console.error('Encountered an error while uploading:', error)
      }
      // Once the file has been uploaded, save the name of the file to the
      // appropriate table and column.

      if (
        uploadFileResult &&
        uploadFileResult.data &&
        uploadFileResult.data.uploadFile &&
        uploadFileResult.data.uploadFile.cloud_file_name
      ) {
        // eslint-disable-next-line camelcase
        const { cloud_file_name } = uploadFileResult.data.uploadFile
        updateAccountById({
          id: accountId,
          input: {
            [targetField]: cloud_file_name,
            uploadFileSize: droppedFileSize,
          },
        })
      }
    }

  handleResetLogo =
    ({ accountId, targetField, label, fileNameToDelete } = {}) =>
    async (acceptedFiles, rejectedFiles) => {
      console.log(
        'acceptedFiles:',
        acceptedFiles,
        ', rejectedFiles:',
        rejectedFiles,
        ', accountId:',
        accountId,
        ', targetField:',
        targetField,
        ', label:',
        label,
        ', fileNameToDelete:',
        fileNameToDelete
      )
      const { updateAccountById } = this.props

      this.setState(() => ({
        previews: {},
      }))

      updateAccountById({
        id: accountId,
        input: { [targetField]: null },
      })
    }

  handleCancel = () => {}

  handleDeleteAccountButtonPress = async (accountId) => {
    // eslint-disable-next-line
    const { deleteAccountById, selection } = this.props
    await deleteAccountById({
      id: accountId,
    })
    // Set account selection to default (nothing selected) if we delete the
    // currently selected account.
    if (selection.currentAccountId === accountId) {
      selection.setCurrentAccountId(null)
    }
  }

  handleUpdateAccountFormItemInputChange = (
    accountId,
    targetField,
    label,
    value
  ) => {
    console.log(
      `handleUpdateAccountFormItemInputChange: accountId: ${accountId}, targetField: ${targetField}, label: ${label}, value: ${value}`
    )
    // eslint-disable-next-line
    const { updateAccountById } = this.props
    updateAccountById({
      id: accountId,
      input: { [targetField]: value },
    })
  }

  render() {
    const { form, accounts, accountTypes, authentication } = this.props

    // Display only top-level accounts (accounts that do not have a parent).
    const topLevelAccounts = accounts.filter((acc) => !acc.parent_account)
    const { appConfig } = authentication
    const { previews } = this.state

    const { getFieldDecorator } = form
    return (
      <React.Fragment>
        <Form
          key="addAccountForm"
          layout="inline"
          className={css(styles.addAccountForm)}
        >
          {generateAddFormItemRequiredInput(
            getFieldDecorator,
            'account_name',
            '* Account Name'
          )}
          {generateAddFormItemRequiredInput(
            getFieldDecorator,
            'prospect_number',
            '* Prospect #'
          )}
          {generateAddFormItemRequiredSelect(
            getFieldDecorator,
            accountTypes,
            false,
            'account_type',
            '* Account Type'
          )}
          <Item>
            <Button
              type="primary"
              // We are creating top level account, so there is no parent id, therefore null.
              onClick={() => this.handleAddAccountButtonPress(null)}
            >
              Add Account
            </Button>
          </Item>
        </Form>
        <Table
          key="accountsTable"
          size="middle"
          bordered
          pagination={false}
          dataSource={topLevelAccounts.map((account) => ({
            key: account.id,
            ...account,
          }))}
          columns={[
            {
              title: 'Account Name',
              dataIndex: 'account_name',
              key: 'account_name',
              sorter: (a, b) => a.account_name.localeCompare(b.account_name),
              defaultSortOrder: 'ascend',
            },
            {
              title: 'Prospect #',
              dataIndex: 'prospect_number',
              key: 'prospect_number',
            },
            {
              title: 'Type',
              dataIndex: 'account_type',
              key: 'account_type',
              render: (text) => changeCase.titleCase(text),
            },
            {
              key: 'delete',
              width: 150,
              render: (text, account) => (
                <Popconfirm
                  placement="bottomRight"
                  title="Delete this account?"
                  onConfirm={() =>
                    this.handleDeleteAccountButtonPress(account.id)
                  }
                  okText="Yes"
                  cancelText="No"
                >
                  {
                    // this will hide the delete button for our 'TEST' account and
                    // thus it will not be deleteable from the dashboard.
                    account.id !== 1 && (
                      <Button type="danger">
                        <Icon type="delete" />
                      </Button>
                    )
                  }
                </Popconfirm>
              ),
            },
          ]}
          expandedRowRender={(account) => (
            <React.Fragment>
              <Form
                key="updateAccountForm"
                className={css(styles.updateAccountForm)}
              >
                {generateUpdateFormItemInput(
                  this.handleUpdateAccountFormItemInputChange,
                  updateAccountFormItemLayout,
                  account.id,
                  account.account_name,
                  'account_name',
                  'Account Name'
                )}
                {appConfig && appConfig.showCloudFolderField
                  ? generateUnsafeUpdateFormItemInput(
                      this.handleUpdateAccountFormItemInputChange,
                      updateAccountFormItemLayout,
                      account.id,
                      account.cloud_folder_name,
                      'cloud_folder_name',
                      'Cloud Folder Name'
                    )
                  : null}
                {generateUpdateFormItemInput(
                  this.handleUpdateAccountFormItemInputChange,
                  updateAccountFormItemLayout,
                  account.id,
                  account.prospect_number,
                  'prospect_number',
                  'Prospect #'
                )}
                {generateUpdateFormItemSelect(
                  this.handleUpdateAccountFormItemInputChange,
                  updateAccountFormItemLayout,
                  account.id,
                  accountTypes,
                  (accountType) => accountType,
                  (accountType) => changeCase.titleCase(accountType),
                  false,
                  account.account_type,
                  'account_type',
                  'Account Type'
                )}
                <Item label="Logo" {...updateAccountFormItemLayout}>
                  <StyledDropzone
                    className={css(styles.dropZone)}
                    key={account.account_logo}
                    accept="image/*"
                    baseStyle={styles.dropzoneBaseStyle}
                    onDrop={this.handleOnDrop({
                      folder: `app/${account.cloud_folder_name}/assets/custom`,
                      accountId: account.id,
                      targetField: 'account_logo',
                      label: 'Account Logo',
                      fileNameToDelete: account.account_logo,
                    })}
                    onFileDialogCancel={this.handleCancel}
                    message="Drop an image, or click here to select one."
                    imgWidth={146}
                    imgHeight={146}
                    imgSrc={
                      account.account_logo &&
                      `https://res.cloudinary.com/renderinghouse/image/upload/w_146,h_146,c_fill/app/${account.cloud_folder_name}/assets/custom/${account.account_logo}`
                    }
                    previewSrc={previews[`${account.id}account_logo`]}
                  />
                  {(previews[`${account.id}account_logo`] ||
                    account.account_logo !== null) && (
                    <Popconfirm
                      title="Reset account logo to default Sherwin-Williams logo?"
                      onConfirm={this.handleResetLogo({
                        folder: `app/${account.cloud_folder_name}/assets/custom`,
                        accountId: account.id,
                        targetField: 'account_logo',
                        label: 'Account Logo',
                        fileNameToDelete: account.account_logo,
                      })}
                      onCancel={this.handleCancel}
                      okText="Yes"
                      cancelText="No"
                    >
                      <Button
                        className={css(styles.dropZoneButton)}
                        type="danger"
                        size="large"
                      >
                        Reset Logo
                      </Button>
                    </Popconfirm>
                  )}
                </Item>
                {<span>Primary Contact:</span>}
                {generateUpdateFormItemInput(
                  this.handleUpdateAccountFormItemInputChange,
                  updateAccountFormItemLayout,
                  account.id,
                  account.contact_first_name,
                  'contact_first_name',
                  'First Name'
                )}
                {generateUpdateFormItemInput(
                  this.handleUpdateAccountFormItemInputChange,
                  updateAccountFormItemLayout,
                  account.id,
                  account.contact_last_name,
                  'contact_last_name',
                  'Last Name'
                )}
                {generateUnsafeUpdateFormItemInput(
                  this.handleUpdateAccountFormItemInputChange,
                  updateAccountFormItemLayout,
                  account.id,
                  account.email,
                  'email',
                  'Email'
                )}
                {generateUnsafeUpdateFormItemInput(
                  this.handleUpdateAccountFormItemInputChange,
                  updateAccountFormItemLayout,
                  account.id,
                  account.phone,
                  'phone',
                  'Phone'
                )}
              </Form>
              <div style={{ marginLeft: 10, marginTop: 10 }}>
                <span>Sub Accounts:</span>
                <SubAccountsForm
                  {...this.props}
                  accounts={
                    account.sub_accounts.filter(
                      (subAcc) => subAcc.id !== account.id
                    ) || []
                  }
                  parentId={account.id}
                  handleAddAccountButtonPress={this.handleAddAccountButtonPress}
                  handleUpdateAccount={
                    this.handleUpdateAccountFormItemInputChange
                  }
                  handleDeleteAccountButtonPress={
                    this.handleDeleteAccountButtonPress
                  }
                  handleResetLogo={this.handleResetLogo}
                  handleOnDrop={this.handleOnDrop}
                  updateAccountFormItemLayout={updateAccountFormItemLayout}
                />
              </div>
            </React.Fragment>
          )}
        />
      </React.Fragment>
    )
  }
}

const WrappedComponent = compose(
  withConsumer(AuthenticationConsumer, { propName: 'authentication' }),
  withConsumer(SelectionConsumer, { propName: 'selection' }),
  withEnumValuesAsProp({
    typeName: 'AccountType',
    propName: 'accountTypes',
  }),
  withQueryResultAsProp({
    gqlDocument: ACCOUNTS,
    resultPropName: 'accounts',
  }),
  withMutationAsProp({
    gqlDocument: ADD_ACCOUNT,
    mutationPropName: 'addAccount',
    refetchQueries: [{ gqlDocument: ACCOUNTS }],
  }),
  withMutationAsProp({
    gqlDocument: DELETE_ACCOUNT_BY_ID,
    mutationPropName: 'deleteAccountById',
    refetchQueries: [{ gqlDocument: ACCOUNTS }],
  }),
  withMutationAsProp({
    gqlDocument: UPDATE_ACCOUNT_BY_ID,
    mutationPropName: 'updateAccountById',
  }),
  withMutationAsProp({
    gqlDocument: UPLOAD_FILE,
    mutationPropName: 'uploadFile',
  }),
  Form.create()
)(AccountsForm)

export { WrappedComponent as AccountsForm }
