import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useQuery } from '@apollo/client'
import { css, StyleSheet } from 'aphrodite/no-important'
import { Divider } from 'antd'
import { GET_LAYERS } from '../../../../graphql/fcLayers.graphql'
import SmallCard from '../../../common/SmallCard'
import Alert from '../../../common/Alert'
import { useInfinitScroll } from '../../../../hooks/useInfinitScroll'
import getLayerCloudinaryPath from '../../functions/getLayerCloudinaryPath'
import Spinner from '../../../common/Spinner'
import { Filter, Input } from '../../../common/Filter'
import { PAGE_SIZE } from './const/pagination'

const classes = StyleSheet.create({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    alignContent: 'flex-start',
    height: 'calc(100vh - 270px)',
    overflowY: 'auto',
  },
  spinner: {
    zIndex: 1,
    backgroundColor: 'rgba(255, 255,255, .6)',
  },
  formItem: {
    paddingBottom: 8,
  },
  divider: {
    margin: 8,
  },
  input: {
    minWidth: 340,
  },
})

export default function LayerSelection({
  roomId,
  mask,
  onLayerChange,
  cloudFolder,
}) {
  const [filterName, setFilterName] = useState(null)
  const { loading, data, error, fetchMore } = useQuery(GET_LAYERS, {
    notifyOnNetworkStatusChange: true,
    skip: !mask || !roomId,
    variables: {
      filter: {
        roomId,
        maskId: mask?.id,
        name: filterName,
      },
      pagination: {
        page: 0,
        size: PAGE_SIZE,
      },
      ordering: {
        columns: [{ name: 'name', order: 'ASC' }],
      },
    },
  })

  const { onScroll } = useInfinitScroll({
    loading,
    pageSize: PAGE_SIZE,
    totalCount: data?.layers?.totalCount,
    currentCount: data?.layers?.items.length,
    cb: async (pagination) => {
      await fetchMore({
        variables: {
          pagination,
        },
      })
    },
  })

  const filter = {
    value: filterName,
    set: setFilterName,
  }

  return (
    <>
      {error && <Alert />}
      <div className={css(classes.root)} onScroll={onScroll}>
        {loading && <Spinner spinnerClass={classes.spinner} />}
        {data?.layers && (
          <>
            <Filter loading={loading} data={filter}>
              <Input
                inputClass={classes.input}
                formItemClass={classes.formItem}
                field="name"
                label="Name"
                placeholder="Search by name ..."
              />
            </Filter>
            <Divider className={css(classes.divider)} />
          </>
        )}

        {data?.layers.totalCount === 0 && (
          <Alert
            type="info"
            message={`No flooring ${filterName?.length ? ' found' : ''}.`}
          />
        )}
        {data?.layers.items.map((layer) => (
          <SmallCard
            key={layer?.id}
            data={{
              ...layer,
              imageSrc: getLayerCloudinaryPath(
                cloudFolder,
                layer?.image ?? 'generic',
                roomId,
                'w_90,h_90,c_fill'
              ),
            }}
            onSelect={(layer) => onLayerChange(layer, mask)}
          />
        ))}
      </div>
    </>
  )
}

LayerSelection.propTypes = {
  mask: PropTypes.object.isRequired,
  onLayerChange: PropTypes.func.isRequired,
  cloudFolder: PropTypes.string.isRequired,
  roomId: PropTypes.number.isRequired,
}
LayerSelection.defaultProps = {}
