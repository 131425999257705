import React from 'react'
import { Tabs } from 'antd'
import { MaterialsForm } from '../colors'
import LayerTab from '../layers/LayerTab'

const { TabPane } = Tabs
const TABS = {
  COLORS: 'COLORS',
  FLOORING: 'FLOORING',
}

export default function MaterialTabs() {
  return (
    <Tabs defaultActiveKey={TABS.COLORS}>
      <TabPane tab="Colors" key={TABS.COLORS}>
        <MaterialsForm />
      </TabPane>
      <TabPane tab="Flooring" key={TABS.FLOORING}>
        <LayerTab />
      </TabPane>
    </Tabs>
  )
}

MaterialTabs.propTypes = {}
MaterialTabs.defaultProps = {}
