import { Form, Tabs } from 'antd'
import PropTypes from 'prop-types'
import React, { useMemo } from 'react'
import { compose } from 'recompose'

import { AuthenticationConsumer, SelectionConsumer } from '../contexts'
import { PropertiesForm } from '../components/forms/properties/PropertiesForm'
import { InactivePropertiesForm } from '../components/forms/properties/InactivePropertiesForm'
import { FinalizedPropertiesForm } from '../components/forms/properties/FinalizedPropertiesForm'
import { ACCOUNTS } from '../graphql/accounts.graphql'

import { withConsumer, withQueryResultAsProp } from '../utils'
import SelectAccount from '../components/common/SelectAccount'
import { useWorkingAccount } from '../hooks/useWorkingAccount'

const { TabPane } = Tabs
const { Item } = Form

const selectAccountFormItemLayout = {
  labelCol: {
    // 1600px ≤ width
    xxl: {
      span: 2,
    },
    // 1200px ≤ width < 1600px
    xl: {
      span: 2,
    },
    // 992px ≤ width < 1200px
    lg: {
      span: 2,
    },
    // 768px ≤ width < 992px
    md: {
      span: 4,
    },
    // 576px ≤ width < 768px
    sm: {
      span: 4,
    },
    // width < 576px and also default setting
    xs: {
      span: 24,
    },
  },
  wrapperCol: {
    xxl: {
      span: 10,
    },
    xl: {
      span: 10,
    },
    lg: {
      span: 10,
    },
    md: {
      span: 10,
    },
    sm: {
      span: 10,
    },
    xs: {
      span: 24,
    },
  },
}

const PropertiesPage = ({ accounts, selection }) => {
  const { selectedAccount, selectedSubAccount } = useWorkingAccount(
    accounts,
    selection
  )
  const accountURL = useMemo(() => {
    if (selectedSubAccount) {
      return encodeURI(
        `${process.env.FRONT_END_APP_SERVER}/account/${selectedAccount.account_name}/subAccount/${selectedSubAccount.account_name}`
      )
    }
    if (selectedAccount) {
      return encodeURI(
        `${process.env.FRONT_END_APP_SERVER}/account/${selectedAccount?.account_name}`
      )
    }
    return null
  }, [selectedAccount?.account_name, selectedSubAccount?.account_name])

  return (
    <>
      <SelectAccount accounts={accounts} />
      {/* If an account is selected, evaluate to an Item with a URL to the account. Otherwise, */}
      {/* evaluate to null. */}
      {accountURL && (
        <>
          <Item label="Visualizer URL" {...selectAccountFormItemLayout}>
            <a href={accountURL} target="_blank" rel="noopener noreferrer">
              {accountURL}
            </a>
          </Item>
          <Tabs defaultActiveKey="1" tabBarStyle={{ marginBottom: 0 }}>
            <TabPane tab="Active Projects" key="1">
              <PropertiesForm />
            </TabPane>
            <TabPane tab="Finalized Projects" key="2">
              <FinalizedPropertiesForm />
            </TabPane>
            <TabPane tab="Inactive Projects" key="3">
              <InactivePropertiesForm />
            </TabPane>
          </Tabs>
        </>
      )}
    </>
  )
}

PropertiesPage.propTypes = {
  accounts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      account_name: PropTypes.string.isRequired,
    })
  ).isRequired,
  authentication: PropTypes.shape({
    token: PropTypes.string,
    signIn: PropTypes.func,
    signOut: PropTypes.func,
  }).isRequired,
  selection: PropTypes.shape({
    currentAccountId: PropTypes.number,
    currentSubAccountId: PropTypes.number,
    setCurrentAccountId: PropTypes.func,
    setCurrentSubAccountId: PropTypes.func,
  }).isRequired,
}

const WrappedComponent = compose(
  withConsumer(AuthenticationConsumer, { propName: 'authentication' }),
  withConsumer(SelectionConsumer, { propName: 'selection' }),
  withQueryResultAsProp({
    gqlDocument: ACCOUNTS,
    resultPropName: 'accounts',
  })
)(PropertiesPage)

export { WrappedComponent as PropertiesPage }
