import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { message } from 'antd'

import { useMutation } from '@apollo/client'
import AssignementTable from '../../common/AssignmentTable/AssignmentTable'
import {
  ASSIGN_SWATCHES,
  GET_SWATCHES_ASSIGNMENTS,
} from '../../../graphql/fcSwatches.graphql'
import { BatchAssignments } from '../../common/AssignmentTable/const/batchAssignments'
import { SwatchAssignmentType } from './const/swatchAssignmentType'
import { useSelectionContext } from '../../../contexts'
import { updateCache } from './functions/cache'

export default function SwatchAssignmentsTable({
  projects,
  initialSelections,
  currentSelections,
  swatches,
  blockInfinityScroll,
  loading,
  handleChange,
  fetchMore,
  swatchName,
  projectName,
}) {
  const { currentAccountId, currentSubAccountId, hasAccountSubAccount } =
    useSelectionContext()

  const accountId = hasAccountSubAccount
    ? currentSubAccountId
    : currentAccountId

  const [assignSwatches, { loading: assignLoading }] =
    useMutation(ASSIGN_SWATCHES)
  const infiniteScrollSettings = {
    loading: !blockInfinityScroll && loading,
    fetchMoreRow: () => {
      fetchMore('swatches', swatches.length)
    },
    fetchMoreColumn: () => {
      fetchMore('projects', projects.length)
    },
  }

  // We need to merge initialSelections and currentSelections
  const selection = useMemo(() => {
    return [...initialSelections, ...currentSelections.assign].filter(
      ([projectId, swatchId]) =>
        !currentSelections.remove.some(
          ([delProjectId, delSwatchId]) =>
            delProjectId === projectId && delSwatchId === swatchId
        )
    )
  }, [initialSelections, currentSelections])

  const handleBatchAssignments = async (type, item) => {
    const input = []

    if (item.__typename === SwatchAssignmentType.PROPERTY) {
      input.push({ projectId: item.id })
    } else if (item.__typename === SwatchAssignmentType.SWATCH) {
      input.push({ swatchId: item.id })
    }

    try {
      await assignSwatches({
        variables: {
          [type === BatchAssignments.ASSIGN ? 'assign' : 'remove']: input,
          [type !== BatchAssignments.ASSIGN ? 'assign' : 'remove']: [],
          accountId,
        },
        update(cache) {
          cache.evict({ fieldName: 'swatches' })
          cache.updateQuery(
            {
              query: GET_SWATCHES_ASSIGNMENTS,
              variables: {
                filter: {
                  accountId,
                  swatches: swatchName,
                  projects: projectName,
                },
              },
            },
            (data) => updateCache(data, input, type)
          )
        },
      })
      message.success('Swatch assignments were updated!')
    } catch (e) {
      console.error(e)
      message.error('Swatch assignments were not updated!')
    }
  }

  return (
    <AssignementTable
      rows={projects}
      columns={swatches}
      loading={blockInfinityScroll && loading}
      assignLoading={assignLoading}
      onChange={handleChange}
      checkedArray={selection}
      infiniteScroll={infiniteScrollSettings}
      onBatchAssignment={handleBatchAssignments}
    />
  )
}

SwatchAssignmentsTable.propTypes = {
  projects: PropTypes.object.isRequired,
  initialSelections: PropTypes.array.isRequired,
  currentSelections: PropTypes.shape({
    assign: PropTypes.array.isRequired,
    remove: PropTypes.array.isRequired,
  }).isRequired,
  swatches: PropTypes.array.isRequired,
  blockInfinityScroll: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  fetchMore: PropTypes.func.isRequired,
  swatchName: PropTypes.string.isRequired,
  projectName: PropTypes.string.isRequired,
}
SwatchAssignmentsTable.defaultProps = {}
