import { StyleSheet } from 'aphrodite/no-important'

export const classes = StyleSheet.create({
  select: {
    width: '100%',
    minWidth: 240,
  },
  input: {
    width: '100%',
    minWidth: 240,
  },
  formItem: {
    margin: 0,
  },
  filter: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },
})
