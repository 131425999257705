export const getRoomCloudinaryPath = (
  cloudFolderName,
  fileName,
  settings = 'w_150,h_150,c_fill'
) => {
  if (!cloudFolderName || !fileName)
    throw Error(
      'Method getRoomCloudinaryPath() must receive cloudFolderName and fileName'
    )

  return `https://res.cloudinary.com/renderinghouse/image/upload/${settings}/app/${cloudFolderName}/rooms/${fileName}`
}
